import AlloSureKidneyWidget from '../AlloSureKidney/AlloSureKidneyWidget';
import IBoxWidget from '../IBox/IBoxWidget';
import KidneyCareWidget from '../KidneyCare/KidneyCareWidget';
import { PatientProfile } from '../Profile';
import AlloCareWidget from '../AlloCare/AlloCareWidget';
import ImmunosuppressionWidget from '../Immunosuppression/ImmunosuppressionWidget';
import EventTimelineWidget from '../EventTimeline/EventTimelineWidget';
import AlloViewWidget from '../AlloView/AlloViewWidget';
import AlloSureHistogramWidget from '../AlloSureHistogram/AlloSureHistogramWidget';
import AlloIndividualChartWidget from '../AlloIndividualChart/AlloIndividualChartWidget';
import AlloSureViolinChartWidget from '../AlloSureViolinChart/AlloSureViolinChartWidget';

const WidgetTypes = {
  PatientProfile: PatientProfile,
  KidneyCare: KidneyCareWidget,
  AlloSure: AlloSureKidneyWidget,
  AlloCare: AlloCareWidget,
  IBox: IBoxWidget,
  Immunosuppression: ImmunosuppressionWidget,
  EventTimeline: EventTimelineWidget,
  AlloView: AlloViewWidget,
  AlloSureHistogram: AlloSureHistogramWidget,
  IndividualChart: AlloIndividualChartWidget,
  ViolinChart: AlloSureViolinChartWidget,
};

export { WidgetTypes };
