import {
  Grid,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  FormControl,
  FormLabel,
  Box,
  FormHelperText,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useContext, useEffect } from 'react';
import { convertISODateToLocal } from 'Utilities/dateHelpers';
import { IBoxContext } from '../state/IBoxContextProvider';
import Constants from '../Utilities/constants';

function EstimatedGFR({ validators, fhirPatient }) {
  const { state, dispatch, errorState, patientState } = useContext(IBoxContext);
  const gender =
    state?.isFemale == null ? fhirPatient?.gender !== 'male' : state?.isFemale;
  useEffect(() => {
    if (state.egfrStatus === 'calculated') {
      let egfr = 0;
      if (state.age && state.creatinine) {
        egfr =
          175 *
          Math.pow(state.creatinine, -1.154) *
          Math.pow(state.age, -0.203);
      }
      egfr = state.isBlack ? egfr * 1.212 : egfr;
      egfr = state.isFemale ? egfr * 0.742 : egfr;

      egfr = egfr && Math.round(egfr);
      dispatch({
        type: Constants.EGFR,
        payload: egfr,
      });
      validators.rules[Constants.EGFR](egfr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.age,
    state.creatinine,
    state.isBlack,
    state.isFemale,
    state.egfrStatus,
  ]);
  const onEGFRStatusChange = (e) => {
    if (e.target.value === 'calculated') {
      dispatch({
        type: 'calculated',
        payload: {
          [Constants.CREATININE]: null,
          [Constants.IS_BLACK]: null,
          [Constants.AGE]: null,
          [Constants.IS_FEMALE]: null,
        },
      });
      validators.resetValidators([Constants.EGFR]);
    } else {
      dispatch({
        type: Constants.EGFR,
        payload: null,
      });
      validators.resetValidators([
        Constants.CREATININE,
        Constants.AGE,
        Constants.IS_BLACK,
      ]);
    }
    dispatch({
      type: Constants.EGFR_STATUS,
      payload: e.target.value,
    });
  };

  return (
    <Grid item container spacing={3}>
      <Grid container direction="row">
        <Grid item container xs={12}>
          <Typography component="div">
            <Box p={1} fontWeight="fontWeightBold">
              Estimated GFR (eGFR, using MDRD)
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item xs={6}>
          <DatePicker
            required
            name={Constants.DATE_OF_EGFR_TAKEN}
            format="MM/DD/YYYY"
            margin="normal"
            label="Date of Creatinine Draw"
            minDate={patientState[Constants.TRANSPLANT_DATE] || undefined}
            value={convertISODateToLocal(state.dateOfEgfrTaken)}
            error={!!errorState[Constants.DATE_OF_EGFR_TAKEN]}
            onChange={(e) => {
              dispatch({
                type: Constants.DATE_OF_EGFR_TAKEN,
                payload: e?.$d,
              });
              validators.rules[Constants.DATE_OF_EGFR_TAKEN](e?.$d);
            }}
            helperText={
              !!errorState[Constants.DATE_OF_EGFR_TAKEN] &&
              errorState[Constants.DATE_OF_EGFR_TAKEN]
            }
            invalidLabel={''}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item xs={6}>
          <FormControlLabel
            value="egfr"
            name={Constants.EGFR_STATUS}
            checked={state?.egfrStatus === 'egfr'}
            onChange={onEGFRStatusChange}
            control={<Radio required={true} />}
            label="eGFR"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            value="calculated"
            name={Constants.EGFR_STATUS}
            checked={state?.egfrStatus === 'calculated'}
            onChange={onEGFRStatusChange}
            control={<Radio required={true} />}
            label="Not Available"
          />
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item xs={6}>
          {state.egfrStatus === 'egfr' && (
            <TextField
              placeholder="0.0 - 120.0 [mL/min/1.73m2]"
              name={Constants.EGFR}
              InputLabelProps={{ shrink: true }}
              required
              label="eGFR"
              type="text"
              variant="outlined"
              autoComplete="off"
              size="small"
              value={state.egfr || ''}
              onChange={(e) => {
                dispatch({ type: Constants.EGFR, payload: e.target.value });
                validators.rules[Constants.EGFR](e.target.value);
              }}
              error={!!errorState[Constants.EGFR]}
              helperText={
                !!errorState[Constants.EGFR] && errorState[Constants.EGFR]
              }
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {state?.egfrStatus === 'calculated' && (
            <FormGroup>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <TextField
                    name={Constants.AGE}
                    InputLabelProps={{ shrink: true }}
                    required
                    label="Age"
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    onChange={(e) => {
                      dispatch({
                        type: Constants.AGE,
                        payload: e.target.value,
                      });
                      validators.rules[Constants.AGE](e.target.value);
                    }}
                    value={state.age || ''}
                    error={!!errorState[Constants.AGE]}
                    helperText={
                      !!errorState[Constants.AGE] && errorState[Constants.AGE]
                    }
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name={Constants.CREATININE}
                    InputLabelProps={{ shrink: true }}
                    required
                    label="Creatinine (mg/dL)"
                    value={state.creatinine || ''}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    onChange={(e) => {
                      dispatch({
                        type: Constants.CREATININE,
                        payload: e.target.value,
                      });
                      validators.rules[Constants.CREATININE](e.target.value);
                    }}
                    error={!!errorState[Constants.CREATININE]}
                    helperText={
                      !!errorState[Constants.CREATININE] &&
                      errorState[Constants.CREATININE]
                    }
                  />
                </Grid>
                <Grid item>
                  <FormControl id="race" component="fieldset">
                    <FormLabel component="legend">Race</FormLabel>
                    <RadioGroup
                      name={Constants.IS_BLACK}
                      value={state.isBlack}
                      row
                      onChange={(e) => {
                        dispatch({
                          type: Constants.IS_BLACK,
                          payload: e.target.value === 'true',
                        });
                        validators.rules[Constants.IS_BLACK](e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio required />}
                        label="Black"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio required />}
                        label="Other"
                      />
                    </RadioGroup>
                    <FormHelperText error={!!errorState[Constants.IS_BLACK]}>
                      Must select at least one
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl id="gender" component="fieldset">
                    <FormLabel component="legend">Gender</FormLabel>
                    <RadioGroup
                      name={Constants.IS_FEMALE}
                      value={gender || ''}
                      row
                      onChange={(e) => {
                        dispatch({
                          type: Constants.IS_FEMALE,
                          payload: e.target.value === 'true',
                        });
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        checked={gender === true}
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value={false}
                        checked={gender === false}
                        control={<Radio />}
                        label="Male"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    placeholder="0.0 - 120.0 [mL/min/1.73m2]"
                    name={Constants.EGFR}
                    InputLabelProps={{ shrink: true }}
                    required
                    label="eGFR"
                    disabled
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    value={state.egfr || ''}
                    error={!!errorState[Constants.EGFR]}
                    helperText={
                      !!errorState[Constants.EGFR] && errorState[Constants.EGFR]
                    }
                  />
                </Grid>
              </Grid>
            </FormGroup>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EstimatedGFR;
