import * as React from 'react';

function SvgWater(props) {
  return (
    <svg {...props}>
      <path
        d="M9.507 4.763c1.688 2.546 2.532 4.211 2.532 4.995 0 1.177-1.115 2.413-2.436 2.413-1.322 0-2.592-.983-2.592-2.413 0-.953.832-2.618 2.496-4.995zm-.89 2.38C7.9 8.33 7.541 9.237 7.541 9.864c0 .942.738 1.779 1.323 1.779-.247-.24-.711-.598-.711-1.779 0-.787.155-1.693.464-2.72z"
        fill="#00aeef"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgWater;
