import React from 'react';
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import Constants from '../PersonalizationBaseline/Utilities/constants';

export default function MeasureSelect({
  idx,
  diastolicIdx,
  measureConfig,
  validation,
  dispatchMeasure,
  measuresState,
}) {
  return (
    <>
      <FormControlLabel
        style={{ margin: 0, padding: 0 }}
        control={
          <Checkbox
            checked={measuresState[idx]?.isAlertOn || false}
            onChange={(e) => {
              dispatchMeasure({
                type: Constants.MEASURE,
                payload: {
                  value: e.target.checked,
                  idx,
                  diastolicIdx,
                  measureName: measuresState[idx]?.measureName,
                  type: Constants.IS_ALERT_ON,
                },
              });
              validation.rules[Constants.MEASURE_CHECK](
                e.target.checked,
                idx,
                diastolicIdx
              );
            }}
            name={measureConfig.id}
          />
        }
      />
      {measureConfig.labelIcon}
      <Typography
        variant="body1"
        style={{ marginLeft: '1px', marginTop: '11px' }}
      >
        {measureConfig.label}
      </Typography>
    </>
  );
}
