import React, { useEffect, useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { SmartAuthenticationContext } from './SmartAuthentication';
import { gql, useMutation } from '@apollo/client';
import PageLoadingIndicator from '../Indicators/PageLoadingIndicator';

const statuses = {
  NONE: 0,
  ERROR: 1,
  FINISHED: 2,
  LOADING: 10,
  LOADING_FHIR_DATA: 11,
  LOADING_PATIENT_ADD: 12,
};

const ADD_PATIENT = gql`
  mutation AddPatient(
    $patient: String!
    $extId: String!
    $extIdType: IdentifierTypeEnum!
  ) {
    addPatientFhirData(patient: $patient, extId: $extId, extIdType: $extIdType)
  }
`;

export default function AddMissingPatient({ id, type }) {
  const {
    server: { issuerUri },
    authState: { accessToken },
  } = useContext(SmartAuthenticationContext);

  const [addStatus, setAddStatus] = useState({
    status: statuses.NONE,
    errorMessage: null,
    patient: null,
    patientId: null,
  });

  const { status, errorMessage, patient, patientId } = addStatus;
  const [addPatient] = useMutation(ADD_PATIENT, {
    update(cache, { data: { addPatientFhirData } }) {
      setAddStatus((prev) => ({
        ...prev,
        status: statuses.FINISHED,
        patientId: addPatientFhirData,
      }));
    },
  });

  useEffect(
    function () {
      async function addMissingPatient() {
        try {
          setAddStatus((prev) => ({
            ...prev,
            status: statuses.LOADING_FHIR_DATA,
          }));
          const response = await fetch(`${issuerUri}/patient/${id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              Accept: 'application/json+fhir',
            },
          });
          const patient = await response.text();
          setAddStatus((prev) => ({
            ...prev,
            status: statuses.LOADING_PATIENT_ADD,
            patient,
          }));
          addPatient({
            variables: {
              patient: patient,
              extId: id,
              extIdType: type,
            },
          });
        } catch (error) {
          setAddStatus((prev) => ({
            ...prev,
            status: statuses.ERROR,
            error: true,
            errorMessage: error,
          }));
        }
      }

      if (status === statuses.NONE && accessToken) {
        addMissingPatient();
      } else if (status === statuses.ERROR) {
        throw new Error(errorMessage);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accessToken, setAddStatus, status, errorMessage, patient]
  );

  let content;

  switch (status) {
    case statuses.LOADING_FHIR_DATA:
      content = (
        <PageLoadingIndicator>
          Loading patient data from EHR...
        </PageLoadingIndicator>
      );
      break;
    case statuses.LOADING_PATIENT_ADD:
      content = (
        <PageLoadingIndicator>Adding patient to AiTraC...</PageLoadingIndicator>
      );
      break;
    case statuses.FINISHED:
      content = <Redirect to={`/patient/${patientId}`} />;
      break;
    default:
      content = <PageLoadingIndicator />;
  }

  return content;
}
