import React from 'react';
const AugmentChildren = (children, newProps) => {
  if (React.isValidElement(children)) {
    return React.cloneElement(children, {
      ...children.props,
      ...newProps,
    });
  }
  return null;
};

export { AugmentChildren };
