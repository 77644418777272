import * as React from 'react';

function SvgIsxNoLevels(props) {
  return (
    <svg {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M2.23 32.913c3.72 0 4.382-17.66 4.96-17.66.58 0 1.173 17.618 4.273 17.618 2.655 0 3.913-27.684 4.326-27.684.413 0 1.508 27.684 3.72 27.684 2.48 0 4.48-14.625 5.27-14.625.787 0 1.576 14.667 5.799 14.667"
          stroke="#ed1c24"
          strokeWidth={1.9954623599999999}
        />
        <path
          d="M2.23 32.913c3.72 0 4.382-17.66 4.96-17.66.58 0 1.173 17.618 4.273 17.618 2.655 0 3.913-27.684 4.326-27.684.413 0 1.508 27.684 3.72 27.684 2.48 0 4.48-14.625 5.27-14.625.787 0 1.576 14.667 5.799 14.667"
          stroke="#fff"
          strokeWidth={0.9977311799999999}
        />
      </g>
    </svg>
  );
}

export default SvgIsxNoLevels;
