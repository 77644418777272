import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import { stableSort, getComparator } from '../../../../Utilities/tableSorting';

const useStyles = makeStyles((theme) => ({
  table: {
    // marginTop: theme.spacing(3),
    '& tbody td': {
      fontWeight: '300',
    },
    '& tbody tr:hover': {
      backgroundColor: '#F0F8FF',
    },
  },
  tableCell: {
    borderWidth: 0,
    fontWeight: 'bold',
    // whiteSpace: 'nowrap',
    borderBottom: '2px solid',
    padding: '0.1rem',
    backgroundColor: 'white',
  },
  alignLeft: {
    textAlign: 'left !important',
  },
  alignRight: {
    textAlign: 'right !important',
  },
  toolbar: {
    minHeight: '30px',
    '& .MuiIconButton-root': {
      padding: 0,
    },
    button: {
      padding: 0,
    },
  },
  actions: {
    padding: 0,
  },
}));

export default function useTable(
  records,
  headCells,
  filterFn,
  intialPage,
  pages
) {
  const classes = useStyles();

  // const pages = [4, 8, 16];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(intialPage);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();

  const TblContainer = (props) => (
    <Table className={classes.table}>{props.children}</Table>
  );

  const TblHead = (props) => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(cellId);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={`${classes.tableCell} ${headCell.className}`}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.sortable ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      component="div"
      page={page}
      rowsPerPageOptions={pages}
      rowsPerPage={rowsPerPage}
      count={records.length}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      className={classes.toolbar}
      classes={{ actions: classes.actions, toolbar: classes.toolbar }}
      backIconButtonProps={{ className: classes.actions }}
      nextIconButtonProps={{ className: classes.actions }}
    />
  );

  const recordsAfterPagingAndSorting = () => {
    let result = stableSort(
      filterFn.fn(records),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    return result;
  };

  return {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  };
}
