import React from 'react';
import Widgets from './Widgets';
import DemographicsHeader from '../../Display/DemographicsHeader';
import PatientLayout from '../../Layout/PatientLayout';
import { Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';

export default function DashboardPage(props) {
  const { id } = useParams();
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
      includeAllocare={true}
      includeImmunosuppression={true}
      days={7}
      averageSliceSize={1}
    >
      {(data) => {
        var header = <DemographicsHeader id={id} data={data} />;
        return (
          <PatientLayout
            header={header}
            subHeader={<Typography variant="h5">Dashboard</Typography>}
          >
            <Widgets id={props.match.params.id} data={data} />
          </PatientLayout>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}
