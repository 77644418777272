import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthentication from '../OAuth2/useAuthentication';
import { SmartAuthenticationContext } from './SmartAuthentication';
import PageLoadingIndicator from '../Indicators/PageLoadingIndicator';

const fetchPostConfig = {
  method: 'POST',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
};

export default function Callback() {
  const { authService } = useAuthentication();
  const {
    config: { clientId, redirectUri },
    setServerInfo,
    setAuthState,
  } = useContext(SmartAuthenticationContext);
  const [state, setState] = useState({
    isAuthenticated: false,
    isLoading: false,
    hasError: false,
    error: null,
  });
  const history = useHistory();

  useEffect(
    function () {
      async function TokenRequest() {
        try {
          setState((prev) => ({ ...prev, isLoading: true }));
          const params = new URLSearchParams(window.location.search);
          const code = params.get('code');
          const stateKey = params.get('state');
          const state = JSON.parse(sessionStorage.getItem(stateKey));
          const tokenUri = state.tokenUri;

          const tokenRequest = [
            'grant_type=authorization_code',
            `redirect_uri=${encodeURIComponent(redirectUri)}`,
            `client_id=${encodeURIComponent(clientId)}`,
            `code=${code}`,
          ];

          const requestBody = tokenRequest.join('&');
          const response = await fetch(tokenUri, {
            ...fetchPostConfig,
            body: requestBody,
          });
          const tokenResponse = await response.json();
          sessionStorage.setItem(
            stateKey,
            JSON.stringify({
              ...state,
              tokenResponse,
            })
          );
          setState((prev) => ({
            ...prev,
            isLoading: false,
            isAuthenticated: true,
          }));

          setServerInfo({
            issuerUri: state.issuerUri,
            tokenUri,
            authorizeUri: state.authorizeUri,
          });

          // For whatever reason EPIC decided that `need_patient_banner` should be a string, but the FHIR spec calls for JSON true/false types.
          const needPatientBanner = tokenResponse.need_patient_banner;
          let showBanner =
            (typeof needPatientBanner === 'boolean' && needPatientBanner) ||
            needPatientBanner === 'true';
          setAuthState({
            accessToken: tokenResponse.access_token,
            patient: tokenResponse.patient,
            showBanner,
          });

          authService.exchange(tokenResponse.id_token, state.clientIssuer);

          history.push(`/patient/match/${tokenResponse.patient}/EHR`);
        } catch (error) {
          setState((prev) => ({
            ...prev,
            hasError: true,
            error: error,
            isLoading: false,
          }));
        }
      }

      if (!(state.isAuthenticated || state.isLoading || state.hasError)) {
        TokenRequest();
      } else if (state.hasError) {
        throw new Error('SMART Callback error');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      clientId,
      redirectUri,
      authService,
      history,
      state.isAuthenticated,
      state.isLoading,
      state.hasError,
    ]
  );

  let content = (
    <PageLoadingIndicator>Getting access token...</PageLoadingIndicator>
  );

  return content;
}
