import React, { useState } from 'react';
import { Paper, Checkbox, makeStyles } from '@material-ui/core';
import {
  TranslatedTextField as TextField,
  TranslatedFormControlLabel as FormControlLabel,
} from '../../Localization';
import { ButtonSave } from '../../Controls';
import applyFilterToConfig from './applyFiltersToConfig';

import * as util from '../../../utility';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '300px',
    background: 'white',
    padding: '10px',
  },
  inputs: {
    width: '100%',
  },
}));

export default function PinNewList({
  config,
  patients,
  filters,
  order,
  orderBy,
  upsertListConfig,
  saving,
}) {
  const classes = useStyles();

  const [title, setTitle] = useState(config.title + ' (COPY)');
  const [isStaticList, setIsStaticList] = useState(config.isStaticList);

  const handlePinConfirmClick = () => {
    const configFilters = config.filter;
    if (isStaticList) {
      const patientIds = patients.map((p) => p.id);
      const updatedConfig = util.removeTypeName({
        ...config,
        title: title,
        id: null,
        filter: [],
        isStaticList: true,
        patientIds: patientIds,
        collectionId: null,
        hasDefaultSort: true,
        defaultSortOrderField: order,
        defaultSortOrderDirection: orderBy,
      });
      upsertListConfig({
        variables: updatedConfig,
      });
    } else {
      const updatedFilter = applyFilterToConfig(filters, configFilters);
      const updatedConfig = util.removeTypeName({
        ...config,
        title: title,
        id: null,
        filter: updatedFilter,
        hasDefaultSort: true,
        defaultSortOrderField: order,
        defaultSortOrderDirection: orderBy,
      });
      upsertListConfig({
        variables: updatedConfig,
      });
    }
  };

  const handleTitleChange = (event) => {
    const value = event.target.value;
    setTitle(value);
  };

  return (
    <Paper elevation={5} className={classes.root}>
      <div>
        <TextField
          className={classes.inputs}
          value={title}
          onChange={handleTitleChange}
          label="New title"
        />
      </div>
      <div>
        <FormControlLabel
          className={classes.inputs}
          control={
            <Checkbox
              checked={isStaticList}
              onClick={() => setIsStaticList((s) => !s)}
              disabled={config.isStaticList}
            />
          }
          label="Save as static list"
        />
      </div>
      <ButtonSave onSaveClick={handlePinConfirmClick} saving={saving} />
    </Paper>
  );
}
