function getDateDiffInDays(allosureDrawDate, date) {
  date = new Date(date);
  allosureDrawDate = new Date(allosureDrawDate);
  var diffMilli = Math.abs(allosureDrawDate - date);
  var diffDays = Math.ceil(diffMilli / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function validateDate(allosureDrawDate, date, expectedDiff) {
  const diffDays = getDateDiffInDays(allosureDrawDate, date);
  return diffDays <= expectedDiff;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function validateNumber(value, lowerLimit, upperLimit, decimals) {
  if (!value) {
    return false;
  }
  if (isNaN(value)) {
    return false;
  }
  if (lowerLimit !== null && value < lowerLimit) {
    return false;
  }
  if (upperLimit !== null && value > upperLimit) {
    return false;
  }

  if (
    value.toString().split('.')[1] &&
    value.toString().split('.')[1].length > decimals
  ) {
    return false;
  }

  return true;
}

export function validateEmail(email) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return true;
  }
  return false;
}
