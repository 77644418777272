import { Box } from '@material-ui/core';
import { TranslatedTypography } from 'Components/Localization';
import React from 'react';

export function NoDataChart({ range }) {
  return (
    <Box display="flex">
      <TranslatedTypography style={{ fontWeight: 'bold', alignSelf: 'center' }}>
        {`Last ${range || 0} Days. No Data Available`}
      </TranslatedTypography>
    </Box>
  );
}
