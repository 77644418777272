import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';

export const GET_DASHBOARD_USER_WIDGETS = gql`
  query DashboardUserWidgetsQuery($userId: String!) {
    userWidgets(userId: $userId) {
      widgetId
      title
      description
      displayOrder
      identifier
      url
      imageName
    }
  }
`;

export default function WithDashboardUserWidgets({ userId, children }) {
  return (
    <WithQueryResults QUERY={GET_DASHBOARD_USER_WIDGETS} variables={{ userId }}>
      {children}
    </WithQueryResults>
  );
}
