import * as React from 'react';

function SvgMood(props) {
  return (
    <svg {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M9.098 5.183s1.222-.42 2.118-.42c.897 0 2.278.363 2.278.45v3.51s-.237 1.82-2.15 1.82c0 0-2.246-.105-2.246-1.83z"
          fill="#231f20"
        />
        <path
          d="M12.798 7.288a.633.633 0 01-.901 0 .65.65 0 010-.91c.249-.252 1.15.659.901.91M12.572 9.254S12.434 8 11.262 8C10.09 8 9.986 9.254 9.986 9.254s.017-.244 1.19-.244c1.172 0 1.396.244 1.396.244"
          fill="#fff"
        />
        <path
          d="M7.85 12.066c-1.046 0-1.583-.552-1.85-1.018a2.724 2.724 0 01-.334-1.014l-.006-.001V6.397l.179.158c.006.006.653.574 1.97.574 1.334 0 2.224-.553 2.233-.559l.156-.098.004.185c0 .013.026 1.382.026 3.346 0 .845-.404 1.467-1.17 1.807a3.194 3.194 0 01-1.205.256z"
          fill="#000"
        />
        <path
          d="M5.556 6.162v3.875l.007.007c.002.022.047.535.344 1.056.28.49.848 1.07 1.944 1.07h.002c.065 0 .657-.002 1.248-.265.805-.358 1.23-1.015 1.23-1.905 0-1.964-.025-3.333-.026-3.347l-.007-.368-.31.196c-.008.006-.879.543-2.176.543-1.284 0-1.898-.541-1.904-.546zm.207.47s.664.601 2.043.601 2.29-.574 2.29-.574.027 1.354.027 3.34c0 1.95-2.194 1.963-2.27 1.963H7.85c-1.93 0-2.088-1.933-2.088-1.933V6.633z"
          fill="#eee"
        />
        <path
          d="M7.728 8.984a.67.67 0 00-.664-.67.67.67 0 00-.663.67zM9.641 8.984a.67.67 0 00-.663-.67.67.67 0 00-.664.67z"
          fill="#fff"
        />
        <path
          d="M6.797 9.776s.276.157 1.172.157c.897 0 1.241-.174 1.241-.174s-.068 1.219-1.24 1.219c-1.173 0-1.173-1.202-1.173-1.202"
          fill="#eee"
        />
      </g>
    </svg>
  );
}

export default SvgMood;
