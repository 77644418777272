import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import ChartContainer from './ChartContainer';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
  },
  chartsContainer: {
    display: 'flex',
    margin: '0px',
    border: '1px solid #d8d8',
    flexDirection: 'column',
  },
  container: {
    width: '100%',
    backgroundColor: 'white',
    paddingBottom: '20px',
    justifyContent: 'flex-start',
  },
}));

function Immunosuppression({ averages, range, frequency }) {
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box className={classes.container}>
        <Grid className={classes.root} container direction="column">
          <Grid className={classes.chartsContainer}>
            <Box>
              <ChartContainer
                data={averages}
                range={range}
                frequency={frequency}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Immunosuppression;
