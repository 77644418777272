/* eslint-disable react/react-in-jsx-scope */
import { gql } from '@apollo/client';
import TransformParameterState from './AlloViewTransformData';
import TransformInputData from './AlloViewTransformInputData';
import { LABS_DAYS } from './AlloViewConfig';
export default class PatientAlloViewService {
  client;
  patientId;
  // premisePath = window.location.pathname.split('/')[2];
  constructor(client) {
    // const url = window.location.href;
    this.patientId = window.location.pathname.split('/')[2];
    // this.patientId = url.substring(url.lastIndexOf('/') + 1);
    this.client = client;
  }
  async GetResults(queryParams) {
    const GET_ALLOVIEW_DATA = gql`
      query alloViewResult(
        $id: String!
        $startDate: DateTime
        $endDate: DateTime
      ) {
        patient(id: $id) {
          links {
            alloView {
              ... on BaseAlloViewType {
                alloViewRecords(startDate: $startDate, endDate: $endDate) {
                  ... on AlloViewRecordType {
                    result {
                      cibilAlloViewReportId
                      alloViewReportId
                      alloViewScore
                      systemGenerated
                    }
                    parameters {
                      patientId
                      riskEvaluationDate
                      creatinine
                      previousCreatinine
                      alloSureScore
                      egfr
                      dsaMfi
                      transplantDate
                      previousRejection
                      rejectionDiagnosisType
                      rejectionDiagnosisDate
                      hasPreviousTransplant
                      bkViremia
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const response = await this.client.query({
      query: GET_ALLOVIEW_DATA,
      variables: {
        id: this.patientId,
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
      },
    });
    console.log(
      'response results:',
      response.data.patient.links.alloView.alloViewRecords
    );

    const alloViewRecordResults =
      response.data.patient.links.alloView.alloViewRecords;
    const newResults = alloViewRecordResults.map((r) => ({
      result: { ...r.result },
      parameters: {
        ...r.parameters,
        transplantDate: new Date(r.parameters.transplantDate),
        rejectionDiagnosisDate: new Date(r.parameters.rejectionDiagnosisDate),
      },
    }));
    return newResults;
  }
  GetResult(queryParams) {
    return 1;
    // return HttpService.Get(
    //   `/${this.premisePath}/AlloView/GetResult/${this.patientId}`,
    //   {
    //     patId: queryParams.patId,
    //   }
    //);
  }
  GenerateAlloViewScore(data) {
    return 2;
    // return HttpService.Post(
    //   `/${this.premisePath}/AlloView/GenerateReport/${this.patientId}`,
    //   data
    // );
  }

  async GetParameters(id) {
    const GET_LABS_DATA = gql`
      query labsResult($id: String!, $days: Int!) {
        patient(id: $id) {
          fhir {
            ... on PatientType {
              birthDate
              gender
            }
          }
          alloSure {
            transplantDetail {
              transplantDate
              hasPreviousTransplant
              previousRejection
              rejectionDiagnosisDate
              rejectionDiagnosisType
            }
            score(days: $days) {
              ...Labs
            }
          }
          alloView {
            ... on BaseAlloViewType {
              previousParameters {
                patientId
                riskEvaluationDate
                creatinine
                alloSureScore
                dsaMfi
                egfr
                bkViremia
                transplantDate
                hasPreviousTransplant
                previousRejection
                rejectionDiagnosisDate
                rejectionDiagnosisType
              }
            }
          }
          links {
            ehr {
              ... on EhrType {
                labs(days: $days) {
                  creatinine {
                    ...Labs
                  }
                  dsaMfi {
                    ...Labs
                  }
                  bkVirus {
                    ...Labs
                  }
                }
              }
            }
          }
        }
      }
      fragment Labs on LabDataType {
        date
        value
      }
    `;
    const response = await this.client.query({
      query: GET_LABS_DATA,
      variables: {
        id: id,
        days: LABS_DAYS,
      },
    });

    const data = response.data.patient;
    const parameterState = TransformParameterState(data);
    parameterState.patientId = id;

    return parameterState;
  }

  async GenerateAlloViewReport(inputParameters) {
    inputParameters.patientId = this.patientId;
    const parameters = TransformInputData(inputParameters);
    const GEN_ALLOVIEW_SCORE = gql`
      mutation genAlloViewScore(
        $parameters: AnnotatedAlloViewParametersInputType!
      ) {
        generateAlloViewScore(parameters: $parameters) {
          alloViewReportId
          id
          alloViewScore
          createdDate
          systemGenerated
        }
      }
    `;
    return await this.client
      .mutate({
        mutation: GEN_ALLOVIEW_SCORE,
        variables: {
          parameters: parameters,
        },
      })
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export const PatientAlloViewContext = {
  alloViewService: new PatientAlloViewService(),
  responseTransformer: () => [],
  requestTransformer: () => {},
};
