import * as React from 'react';

function SvgUrine(props) {
  return (
    <svg {...props}>
      <g
        transform="matrix(.26458 0 0 .26458 3.836 4.465)"
        fill="none"
        fillRule="evenodd"
      >
        <path stroke="#8dc63f" strokeWidth={0.5} d="M2.015 22h14.82L18 3H1z" />
        <path fill="#f2d225" d="M17 9l-.75 12H2.787L2 9z" />
        <path
          stroke="#8dc63f"
          strokeWidth={0.6}
          strokeLinecap="square"
          d="M12.036 4h3.928"
        />
        <rect fill="#8dc63f" width={19} height={3} rx={0.48} />
        <path
          stroke="#8dc63f"
          strokeWidth={0.6}
          strokeLinecap="square"
          d="M12.036 9h3.928M12.036 15h3.928M12.036 6h3.928M12.036 12h3.928M12.036 18h3.928"
        />
      </g>
    </svg>
  );
}

export default SvgUrine;
