import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const defaultState = {
  open: false,
  message: 'Success',
  severity: 'success',
  duration: 2000,
};

export default function SnackBar({
  message,
  duration,
  open,
  onClose,
  severity,
}) {
  const classes = useStyles();
  const [snackBarState, setSnackBarState] = React.useState(defaultState);
  useEffect(() => {
    setSnackBarState((state) => ({
      ...state,
      open: open,
      message: message,
      duration: duration,
      severity: severity,
    }));
  }, [open, message, duration, severity]);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
    setSnackBarState({ ...snackBarState, open: false, message: '' });
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={snackBarState.open}
        autoHideDuration={snackBarState.duration}
        onClose={handleClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={snackBarState.severity}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
