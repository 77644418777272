import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Practitioner({ data }) {
  const { t } = useTranslation();

  let name;
  if (data.name && data.name.length > 0) {
    let given;
    let nameElement = data.name[0];
    if (nameElement.given) {
      given = nameElement.given;
    } else {
      given = [];
    }
    name = `${nameElement.prefix} ${given.join(', ')} ${nameElement.family}`;
  } else {
    t('missingPersonName');
  }

  return <div>{name}</div>;
}
