import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';

export default function WithTypesData({ typeNames, children }) {
  const types = typeNames
    .map((f) => `${f}: __type(name: "${f}") { fields { ...Field } }`)
    .join(' ');
  const GET_FILTER_TYPES = gql`
        fragment Field on __Field {
            name
            description
            type {
            name
            kind
            }
        }

        {
            ${types}
        }
    `;

  return (
    <WithQueryResults QUERY={GET_FILTER_TYPES}>{children}</WithQueryResults>
  );
}
