import { SvgIcon, Icon } from '@material-ui/core';
import {
  BloodPressure,
  MedicineSm,
  PulseOx,
  Scale,
  Selector,
  Steps,
  Urine,
  Mood,
  Water,
} from 'icons/IconComponents';
import React from 'react';
import Constants from '../PersonalizationBaseline/Utilities/constants';

const IconStyle = {
  marginLeft: '0',
  marginTop: '9px',
};

const MeasuresConfiguration = {};

MeasuresConfiguration[Constants.FLUID_INTAKE] = {
  id: Constants.FLUID_INTAKE,
  label: 'Fluid Intake',
  inputLowId: 'fluidIntakeLow',
  inputHighId: 'fluidIntakeHigh',
  inputPercentageId: 'fluidIntakePercentage',
  labelIcon: (
    <SvgIcon component={Water} viewBox="4 4 10 10" style={IconStyle} />
  ),
};

MeasuresConfiguration[Constants.URINE_OUTPUT] = {
  id: Constants.URINE_OUTPUT,
  label: 'Urine Output',
  inputLowId: 'urineLow',
  inputHighId: 'urineHigh',
  inputPercentageId: 'urinePercentage',
  labelIcon: <SvgIcon component={Urine} viewBox="3 3 9 9" style={IconStyle} />,
};

MeasuresConfiguration[Constants.WEIGHT] = {
  id: Constants.WEIGHT,
  label: 'Weight',
  inputLowId: 'weightLow',
  inputHighId: 'weightHigh',
  inputPercentageId: 'weightPercentage',
  labelIcon: (
    <SvgIcon component={Scale} viewBox="4 4 10 10" style={IconStyle} />
  ),
};

MeasuresConfiguration[Constants.BLOOD_PRESSURE_SYSTOLIC] = {
  id: Constants.BLOOD_PRESSURE_SYSTOLIC,
  label: 'BP Systolic',
  inputLowId: 'bpSystolicLow',
  inputHighId: 'bpSystolicHigh',
  inputPercentageId: 'bpSystolicPercentage',
  labelIcon: (
    <SvgIcon component={BloodPressure} viewBox="3 3 9 9" style={IconStyle} />
  ),
};

MeasuresConfiguration[Constants.BLOOD_PRESSURE_DIASTOLIC] = {
  id: Constants.BLOOD_PRESSURE_DIASTOLIC,
  label: 'BP Diastolic',
  inputLowId: 'bpDiastolicLow',
  inputHighId: 'bpDiastolicHigh',
  inputPercentageId: 'bpDiastolicPercentage',
  labelIcon: <SvgIcon />,
};

MeasuresConfiguration[Constants.PULSE_OX] = {
  id: Constants.PULSE_OX,
  label: 'Oxygen',
  inputLowId: 'oxygenLow',
  inputHighId: 'oxygenHigh',
  inputPercentageId: 'oxygenPercentage',
  labelIcon: (
    <SvgIcon component={PulseOx} viewBox="3 3 9 9" style={IconStyle} />
  ),
};

MeasuresConfiguration[Constants.TEMPERATURE] = {
  id: Constants.TEMPERATURE,
  label: 'Temperature',
  inputLowId: 'temperatureLow',
  inputHighId: 'temperatureHigh',
  inputPercentageId: 'temperaturePercentage',
  labelIcon: (
    <Icon style={{ marginTop: '8px' }}>
      <img
        src="/images/TEMP_ICON.svg"
        alt="temperature"
        width={9}
        height={27}
      />
    </Icon>
    // <SvgIcon
    //   component={TempIcon}
    //   viewBox="3 3 11 11"
    //   style={{ marginLeft: '0', marginTop: '9px' }}
    // ></SvgIcon>
  ),
};

MeasuresConfiguration[Constants.BLOOD_GLUCOSE] = {
  id: Constants.BLOOD_GLUCOSE,
  label: 'Glucose',
  inputLowId: 'glucoseLow',
  inputHighId: 'glucoseHigh',
  inputPercentageId: 'glucosePercentage',
  labelIcon: (
    <SvgIcon component={Selector} viewBox="3 3 9 9" style={IconStyle} />
  ),
};

MeasuresConfiguration[Constants.STEPS] = {
  id: Constants.STEPS,
  label: 'Steps',
  inputLowId: 'stepsLow',
  inputHighId: 'stepsHigh',
  inputPercentageId: 'stepsPercentage',
  labelIcon: <SvgIcon component={Steps} viewBox="3 3 9 9" style={IconStyle} />,
};

MeasuresConfiguration[Constants.MEDICATION_COMPLIANCE] = {
  id: Constants.MEDICATION_COMPLIANCE,
  label: 'Medication',
  inputLowId: 'medicationLow',
  inputHighId: 'medicationHigh',
  inputPercentageId: 'medicationPercentage',
  labelIcon: (
    <SvgIcon component={MedicineSm} viewBox="4 4 10 10" style={IconStyle} />
  ),
};

MeasuresConfiguration[Constants.MOOD] = {
  id: Constants.MOOD,
  label: 'Mood',
  inputLowId: 'moodLow',
  inputHighId: 'moodHigh',
  inputPercentageId: 'moodPercentage',
  labelIcon: <SvgIcon component={Mood} viewBox="4 4 10 10" style={IconStyle} />,
};

export { MeasuresConfiguration };
