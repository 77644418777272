import React from 'react';
import { makeStyles } from '@material-ui/core';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Moment from 'moment';

const isIE =
  navigator.userAgent.indexOf('MSIE') > -1 ||
  navigator.userAgent.indexOf('rv:') > -1;

const useStyles = makeStyles({
  timeline: {
    transform: 'rotate(90deg)',
    flexGrow: '0',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1280px)']: {
      position: 'absolute',
      left: '25%',
    },
  },
  timelineItem: {
    flexFlow: 'row!important',
  },
  timelineContentContainer: (props) => ({
    color: props.color,
    width: '15px',
  }),
  timelineContent: {
    transform: isIE
      ? 'translate(15px, 20px) rotate(-90deg)'
      : 'translate(0px, 20px) rotate(-90deg)',
    fontSize: '12px',
    fontWeight: '600',
  },
  timelineAppositeContentRoot: {
    width: '15px',
  },
  timelineAppositeContent: (props) => ({
    transform: isIE
      ? 'translate(-20px, 31px) rotate(-90deg)'
      : 'translate(-5px, 30px) rotate(-90deg)',
    color: props.color,
    fontSize: '12px',
    fontWeight: '600',
    marginRight: '15px',
    whiteSpace: 'pre',
  }),
  timelineIcon: {
    transform: 'rotate(-90deg)',
    color: 'red',
    fontSize: '40px',
  },
  secondaryTail: {
    backgroundColor: '#40b6fe',
    height: '70px',
    width: '11px',
    // eslint-disable-next-line no-useless-computed-key
    ['@media only screen and (max-width:959px)']: {
      height: 'auto',
    },
  },
  avatarItem: (props) => ({
    color: props.color,
    backgroundColor: 'white',
    border: `3px solid ${props.color}`,
    fontSize: '10px',
    fontWeight: '600',
    transform: 'rotate(-90deg)',
  }),
  dividerIndicator: (props) => ({
    height: '2px',
    backgroundColor: props.color,
    width: '20px',
    position: 'relative',
    top: '20px',
    left: '-20px',
  }),
  arrow: {
    borderColor: 'transparent #40b6fe',
    borderWidth: '6px 0 6px 6px',
    content: '',
    position: 'absolute',
    height: 0,
    width: 0,
    borderStyle: 'solid',
    transform: 'rotate(-90deg)',
  },
});

const EmptyTimelineItem = (data) => {
  const classes = useStyles(data);
  return (
    <TimelineItem className={classes.timelineItem}>
      <TimelineOppositeContent className={classes.timelineAppositeContentRoot}>
        <Typography className={classes.timelineAppositeContent}></Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector className={classes.secondaryTail}>
          <div className={classes.arrow}></div>
        </TimelineConnector>
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContentContainer}>
        <Typography className={classes.timelineContent}></Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

const TimelineDataItem = (data) => {
  const classes = useStyles(data);
  return (
    <TimelineItem className={classes.timelineItem}>
      <TimelineOppositeContent className={classes.timelineAppositeContentRoot}>
        <Typography className={classes.timelineAppositeContent}>
          {data.topLabel}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <Divider className={classes.dividerIndicator}></Divider>
        <Avatar className={classes.avatarItem}>{data.content}</Avatar>
        <TimelineConnector
          className={classes.secondaryTail}
        ></TimelineConnector>
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContentContainer}>
        <Typography className={classes.timelineContent}>
          {data.bottomLabel}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default function DemographicsTimeline({ data }) {
  const classes = useStyles();
  const patientEvent = data.patientEvent;
  return (
    <Timeline className={classes.timeline}>
      {EmptyTimelineItem({})}

      {TimelineDataItem({
        topLabel: patientEvent[3].eventName,
        content: Moment(patientEvent[3].eventDate).format('MM/DD'),
        bottomLabel: Moment(patientEvent[3].eventDate).format('YYYY'),
        color: 'red',
      })}
      {TimelineDataItem({
        topLabel: patientEvent[2].eventName,
        content: Moment(patientEvent[2].eventDate).format('MM/DD'),
        bottomLabel: Moment(patientEvent[2].eventDate).format('YYYY'),
        color: 'green',
      })}
      {TimelineDataItem({
        topLabel: patientEvent[1].eventName,
        content: Moment(patientEvent[1].eventDate).format('MM/DD'),
        bottomLabel: Moment(patientEvent[1].eventDate).format('YYYY'),
        color: 'orange',
      })}
      {EmptyTimelineItem({})}
      {TimelineDataItem({
        topLabel: patientEvent[0].eventName,
        content: Moment(patientEvent[0].eventDate).format('MM/DD'),
        bottomLabel: Moment(patientEvent[0].eventDate).format('YYYY'),
        color: 'red',
      })}
    </Timeline>
  );
}
