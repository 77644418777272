import React from 'react';
import {
  IconButton,
  Card,
  CardHeader,
  makeStyles,
  Checkbox,
} from '@material-ui/core';
import { DragHandle } from '@material-ui/icons';

import { TranslatedTypography as Typography } from '../../../Localization';

import { compoundTypes } from '../../../../filterSchema';

import FieldCard from './FieldCard';
import OrderableCard from './OrderableCard';

const useStyles = makeStyles((theme) => ({
  fields: {
    display: 'flex',
  },
  fieldListCards: {
    width: '10%',
    maxHeight: '10vh',
  },
  card: {
    flex: '0 0 auto',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '5px',
    background: '#FFFFFF',
    userSelect: 'none',
  },
  inactiveCard: {
    flex: '0 0 auto',
    marginLeft: '5px',
    marginRight: '5px',
    cursor: 'move',
    background: theme.palette.primary.light,
    userSelect: 'none',
  },
  filler: {
    height: '20px',
  },
  outputScroller: {
    maxHeight: '40vh',
    overflowY: 'scroll',
  },
  fieldCard: {
    textAlign: 'right',
  },
  fieldCatalogDiv: {
    display: 'flex',
    marginTop: '25px',
  },
  fieldCatalog: {
    width: '20%',
    textAlign: 'right',
  },
  description: {
    textAlign: 'right',
    width: '30%',
  },
  searchBox: {
    marginLeft: '25%',
  },
  checkbox: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  checked: {},
}));

export default function FieldPicker({
  fieldData,
  onFieldReorder,
  onFieldRemoveClick,
  onFieldAddClick,
  ...rest
}) {
  const classes = useStyles();

  const fieldCatalog = Object.keys(fieldData).filter(
    (f) =>
      fieldData[f].type.kind === 'SCALAR' ||
      compoundTypes.includes(fieldData[f].type.name)
  );

  const fields = rest.fields.map((f, i) => (
    <Card key={f} name={f} index={i} className={classes.fieldListCards}>
      <CardHeader
        title={fieldData[f] ? getFieldTitle(fieldData[f].description) : ''}
      />
    </Card>
  ));

  function getFieldTitle(f) {
    const descParts = f.split('|');
    const title = descParts[0];
    return title;
  }

  if (fields.length === 0) {
    fields.push(
      <Card key={'blank-card'} index={0}>
        <CardHeader title="No fields currently selected." />
      </Card>
    );
  }

  const fieldCatalogCards = fieldCatalog.map((f, i) => {
    let cardContent;
    if (rest.fields.includes(f)) {
      const fieldCard = (
        <FieldCard
          field={fieldData[f]}
          action={
            <div>
              <IconButton>
                <DragHandle />
              </IconButton>
              <Checkbox
                classes={{ root: classes.checkbox, checked: classes.checked }}
                checked={true}
                onChange={() => {
                  onFieldRemoveClick(f);
                }}
              ></Checkbox>
            </div>
          }
        />
      );
      cardContent = (
        <OrderableCard
          key={f}
          classes={classes}
          name={f}
          index={i}
          onCardReorder={onFieldReorder}
        >
          {fieldCard}
        </OrderableCard>
      );
    } else {
      const fieldCard = (
        <FieldCard
          field={fieldData[f]}
          action={
            <Checkbox
              classes={{ root: classes.checkbox, checked: classes.checked }}
              onChange={() => {
                onFieldAddClick(f);
              }}
            ></Checkbox>
          }
        />
      );
      cardContent = (
        <Card className={classes.card} key={f} name={f}>
          {fieldCard}
        </Card>
      );
    }
    return cardContent;
  });

  if (rest.fields.length > 0) {
    rest.fields.forEach((i) => {
      //waiting on schema fixes so fields aren't undefined
      const idx = fieldCatalogCards.findIndex((d) => d && d.key === i);
      const restIdx = rest.fields.findIndex((z) => z === i);
      const item = fieldCatalogCards[idx];
      fieldCatalogCards.splice(idx, 1);
      fieldCatalogCards.splice(restIdx, 0, item);
    });
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Fields
      </Typography>
      <div className={classes.fields}>{fields}</div>
      <div className={classes.fieldCatalogDiv}>
        <Typography variant="h5" gutterBottom className={classes.fieldCatalog}>
          Field Catalog
        </Typography>
        <Typography variant="h5" gutterBottom className={classes.description}>
          Description
        </Typography>
        <div className={classes.searchBox}>
          {/* this still needs to be hooked up
                    <SearchBox
                        label="Search"
                        trailingIcon={<FilterList fontSize="small" />}
                    /> */}
        </div>
      </div>

      <div className={classes.outputScroller}>{fieldCatalogCards}</div>
    </div>
  );
}
