import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Toolbar,
  makeStyles,
  Box,
} from '@material-ui/core';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import ErrorPage from '../Error/ErrorPage';

const useStyles = makeStyles((theme) => ({
  tableTitle: {
    fontWeight: 600,
  },
  tableContainer: {
    border: 'none',
    boxShadow: 'none',
    background: '#E8E8E8',
  },
  tableRow: {
    borderBottom: '2px solid black',
  },
  iconSideCell: {
    borderLeft: '0px',
  },
  iconCellHeader: {
    width: '20px',
  },
}));

export function ISxTakenReport({ medications }) {
  const classes = useStyles();

  return (
    <>
      <Box style={{ background: '#E8E8E8' }}>
        <Toolbar className={classes.toolbar}>
          <Typography className={classes.tableTitle} variant="subtitle1">
            Taken
          </Typography>
        </Toolbar>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.iconCellHeader}></TableCell>
                <TableCell className={classes.iconSideCell}>
                  Medication
                </TableCell>
                <TableCell>Strength</TableCell>
                <TableCell>Reported Schedule</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell>Data Source</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medications?.map((medication, i) => (
                <TableRow className={classes.tableRow} key={i}>
                  <TableCell>
                    <img src={medication.pillImage} alt="" width="50px" />
                  </TableCell>
                  <TableCell>{medication.statement.medication.text}</TableCell>
                  <TableCell>{medication.strength.text}</TableCell>
                  <TableCell>{(medication.schedule || []).join(';')}</TableCell>
                  <TableCell>{medication.updated}</TableCell>
                  <TableCell>AlloCare</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default ErrorBoundaryHOC(ISxTakenReport, ErrorPage);
