import React, { useState, useEffect, useRef } from 'react';
import { Chart, Title, Tooltip, Legend } from 'chart.js';
import { makeStyles } from '@material-ui/core';

const unosJson = [
  { x: 0, y: 1.0 },
  { x: 12, y: 0.97 },
  { x: 24, y: 0.95 },
  { x: 36, y: 0.93 },
  { x: 60, y: 0.88 },
  { x: 84, y: 0.83 },
  { x: 120, y: 0.74 },
];

Chart.plugins.register(Title, Tooltip, Legend);

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: 'bold',
  },
  chart: {
    height: '300px',
    display: 'inline',
  },
  calculatorChart: {
    height: '450px',
  },
  enterText: {
    fontWeight: 'bold',
    opacity: 0.3,
    zIndex: 999,
    marginLeft: '8%',
    position: 'absolute',
    fontSize: '30px',
    '@media (max-width:1590px)': {
      fontSize: '30px',
    },
    '@media (max-width:1030px)': {
      fontSize: '20px',
    },
  },
  hideText: {
    display: 'none',
  },
  widgetMarginTop: {
    marginTop: '100px',
  },
  chartContainer: {
    width: '99%', //100% causes this to lose responsiveness
  },
}));

function IBoxReport({ iboxData, widgetView, hasResult, height }) {
  const [chartOptions, setChartOptions] = useState(null);
  const canvasRef = useRef(null);
  const styles = useStyles();
  const isMajorTick = (index, mod) => index % (mod || 2) === 0;

  useEffect(() => {
    const chartRef = canvasRef.current;
    let iBoxChart = null;
    if (chartRef && chartOptions) {
      Chart.defaults.global.defaultFontFamily = `"Trade Gothic LT Std", "sans-serif", "Roboto", "Helvetica", "Arial"`;
      const myChartRef = chartRef.getContext('2d');
      iBoxChart = new Chart(myChartRef, chartOptions);
    }
    return () => {
      iBoxChart?.destroy();
    };
  }, [chartOptions]);

  useEffect(() => {
    let combined = [];
    if (iboxData?.current != null && iboxData?.prior != null) {
      combined = iboxData.current.concat(iboxData.prior);
    }
    let xMin = { x: 30 };
    let xMax = { x: 90 };
    if (combined.length > 0) {
      xMin = combined.reduce((prev, curr) => (prev.x < curr.x ? prev : curr));
      xMax = combined.reduce((prev, curr) => (prev.x > curr.x ? prev : curr));
    }
    combined = combined.concat(unosJson);
    const yM = combined.reduce((prev, curr) => (prev.y < curr.y ? prev : curr));
    const yMin = yM.y - 0.075;
    let currentSorted = [];
    let priorSorted = [];
    if (iboxData?.current != null) {
      currentSorted = [...iboxData.current.sort((a, b) => a.x > b.x)];
    }
    if (iboxData?.prior != null) {
      priorSorted = [...iboxData.prior.sort((a, b) => a.x > b.x)];
    }
    setChartOptions({
      type: 'line',
      data: {
        datasets: [
          {
            label: 'Current prognosis',
            data: currentSorted,
            borderColor: '#000',
            backgroundColor: 'rgba(0,0,0,0.5)',
            fill: '#808080',
          },
          {
            label: 'Prior prognosis',
            data: priorSorted,
            borderColor: '#808080',
            backgroundColor: 'rgba(0,0,0,0.5)',
            fill: false,
          },
          {
            label: 'UNOS Pop. Avg.',
            data: unosJson,
            borderColor: '#808080',
            fill: '#E5E5E5',
            borderDash: [6, 6],
          },
        ],
      },
      options: {
        layout: {
          padding: {
            bottom: 50,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const val = Math.floor(context.raw.y * 100) + '%';
                return val;
              },
            },
          },
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          },
        },
        scales: {
          xAxes: [
            {
              id: 'x',
              type: 'linear',
              scaleLabel: {
                display: true,
                labelString: 'Months Post Transplant',
                color: 'black',
                fontStyle: '600',
                fontSize: 13,
              },
              ticks: {
                min: xMin != null ? xMin.x : '',
                max: xMax != null ? xMax.x : '',
                align: 'center',
                beginAtZero: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'y',
              scaleLabel: {
                display: true,
                color: 'black',
                labelString: 'Probability of Graft Survival',
                fontStyle: '600',
                fontSize: 13,
              },
              ticks: {
                min: yMin,
                max: 1,
                align: 'center',
                beginAtZero: false,
                callback: function (label) {
                  if (isMajorTick(label, null)) {
                    return '';
                  } else {
                    return Math.floor(label * 100) + '%';
                  }
                },
              },
            },
          ],
        },
      },
    });
  }, [iboxData]);
  return (
    <div className={styles.chartContainer}>
      <span
        className={`${hasResult ? styles.hideText : styles.enterText} ${
          styles.widgetMarginTop
        }`}
        style={{ fontWeight: 'bold', fontSize: 15 }}
      >
        Click here to open AiKidney &trade; iBox Long-Term Prognosis app and
        enter data
      </span>
      <canvas
        className={widgetView ? styles.chart : styles.calculatorChart}
        ref={canvasRef}
        aria-label="AiKidney &trade; iBox Long-Term Prognosis Report"
        role="img"
        height={height}
      ></canvas>
    </div>
  );
}

export default IBoxReport;
