import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { TranslatedTypography } from 'Components/Localization';
import { useAuthentication } from 'Components/OAuth2';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    dialogTitle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px',
      textAlign: 'center',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    dialogPaper: {
      minHeight: '95vh',
      maxHeight: '95vh',
      minWidth: '80vw',
      maxWidth: '80vw',
    },
    dialogContent: {
      height: '83vh',
    },
    objectEmbedded: {
      width: '100%',
    },
  })
);

/**
 *
 * @typedef {{url: string, open: boolean, header: string}} reportState
 * @function {{(prevState: any) => reportState}}
 * @param {{reportState: reportState,  toggleReportModal }} reportState

 */

export default function PDFReportDialog({ reportState, toggleReportModal }) {
  const classes = useStyles();
  const handleClose = (e) => {
    e.stopPropagation();
    toggleReportModal((prevState) => ({ ...prevState, open: false }));
  };
  const { authState } = useAuthentication();
  let url;
  if (reportState.url) {
    url = new URL(reportState.url);
    const params = new URLSearchParams();
    params.append('access_token', authState.tokenResponse.access_token);
    url.search = params.toString();
  } else {
    url = '';
  }
  return (
    <Dialog
      open={reportState.open}
      scroll={'paper'}
      onClose={handleClose}
      aria-labelledby="report-viewer"
      aria-describedby="report-viewer-description"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <TranslatedTypography style={{ flexGrow: 1 }} variant="subtitle1">
          {reportState.header}
        </TranslatedTypography>

        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <iframe
          width="100%"
          height="100%"
          title="PDF Report"
          src={`/pdfjs-2.6.347-es5-dist/web/viewer.html?pdfUrl=${encodeURIComponent(
            url.toString()
          )}`}
        ></iframe>
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
