import React, { memo } from 'react';
import _ from 'lodash';
import {
  Typography,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  makeStyles,
  FormControl,
  Input,
  Radio,
  RadioGroup,
  Divider,
} from '@material-ui/core';
import AlloCareRowHeader from '../PersonalizationBaseline/BaselineRowHeader';
import MaskedInput from 'react-text-mask';
import AlloCareMeasureRow from './AlloCareMeasureRow';
import Constants from '../PersonalizationBaseline/Utilities/constants';
import { MeasuresConfiguration } from './MeasuresConfig';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    paddingLeft: '0px',
  },
  input: {
    height: 35,
  },
  radioStyles: {
    paddingLeft: '71px',
  },
  divider: {
    backgroundColor: theme.palette.common.black,
    height: '5px',
    width: '150px',
    borderRadius: 16,
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      // eslint-disable-next-line prettier/prettier
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const Row = memo(
  function MemoizedAllocareMeasure(props) {
    return <AlloCareMeasureRow {...props} />;
  },
  (prev, next) => {
    return _.isEqual(
      prev.measuresState[prev.idx],
      next.measuresState[next.idx]
    );
  }
);

export default function AlloCarePersonalization({
  validation,
  diastolicIdx,
  alertState,
  measuresState,
  dispatchAlert,
  dispatchMeasure,
  errorState,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.mainBox}>
      <AlloCareRowHeader />
      <form>
        {measuresState.map((measure, idx) => (
          <Row
            key={idx}
            idx={idx}
            diastolicIdx={diastolicIdx}
            measureConfig={MeasuresConfiguration[measure.measureName]}
            validation={validation}
            measuresState={measuresState}
            dispatchMeasure={dispatchMeasure}
            errorState={errorState}
          ></Row>
        ))}
        <Divider classes={{ root: classes.divider }} />
        {/* AlertType */}
        <Box style={{ marginBottom: '20px', marginTop: '10px' }}>
          <Grid container spacing={2} direction="row">
            <Grid item xs={2}>
              <Typography style={{ marginLeft: '20px', marginTop: '10px' }}>
                <strong>Send Alert</strong>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <FormControlLabel
                  style={{ margin: 0, padding: 0 }}
                  control={
                    <Checkbox
                      checked={alertState?.isEmail || false}
                      onChange={(e) => {
                        dispatchAlert({
                          type: e.target.name,
                          payload: e.target.checked,
                        });
                        validation.rules[Constants.EMAIL_REQUIRED](
                          e.target.checked
                        );
                      }}
                      name={Constants.IS_EMAIL}
                    />
                  }
                  label={
                    <Typography style={{ fontWeight: 'bold' }} variant="body1">
                      Email
                    </Typography>
                  }
                />
                <TextField
                  name={Constants.EMAIL_ADDRESS}
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  onChange={(e) => {
                    dispatchAlert({
                      type: e.target.name,
                      payload: e.target.value,
                    });
                    validation.rules[Constants.EMAIL_ADDRESS](e.target.value);
                  }}
                  value={alertState?.emailAddress || ''}
                  error={!!errorState[Constants.EMAIL_ADDRESS]}
                  helperText={
                    !!errorState[Constants.EMAIL_ADDRESS] &&
                    errorState[Constants.EMAIL_ADDRESS]
                  }
                  style={{ paddingLeft: '20px', width: '60%' }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                style={{ margin: 0, padding: 0 }}
                control={
                  <Checkbox
                    checked={alertState?.isText || false}
                    onChange={(e) => {
                      dispatchAlert({
                        type: e.target.name,
                        payload: e.target.checked,
                      });
                      validation.rules[Constants.PHONE_REQUIRED](
                        e.target.checked
                      );
                    }}
                    name={Constants.IS_TEXT}
                  />
                }
                label={
                  <Typography style={{ fontWeight: 'bold' }} variant="body1">
                    Text
                  </Typography>
                }
              />
              <FormControl>
                <Input
                  name={Constants.CONTACT_NUMBER}
                  value={alertState?.contactNumber}
                  onChange={(e) => {
                    dispatchAlert({
                      type: e.target.name,
                      payload: e.target.value,
                    });
                    validation.rules[Constants.CONTACT_NUMBER](e.target.value);
                  }}
                  error={!!errorState[Constants.CONTACT_NUMBER]}
                  helperText={
                    !!errorState[Constants.CONTACT_NUMBER] &&
                    errorState[Constants.CONTACT_NUMBER]
                  }
                  id="formatted-text-mask-input"
                  inputComponent={TextMaskCustom}
                  style={{ marginLeft: '20px' }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {/* AlertFrequency */}
        <Box>
          <Grid container spacing={2} direction="row">
            <Grid item xs={2}>
              <Typography style={{ marginLeft: '20px', marginTop: '10px' }}>
                <strong>Alert Frequency</strong>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <RadioGroup
                row
                aria-label="alertFrequency"
                name="frequency"
                value={alertState?.frequency || false}
                onChange={(e) => {
                  dispatchAlert({
                    type: e.target.name,
                    payload: e.target.value,
                  });
                }}
              >
                <FormControlLabel
                  value={Constants.IMMEDIATE_FREQUENCY}
                  control={<Radio />}
                  checked={
                    alertState?.frequency === Constants.IMMEDIATE_FREQUENCY
                  }
                  label="Immediate"
                  className={classes.radioStyles}
                />
                <FormControlLabel
                  value={Constants.DAILY_FREQUENCY}
                  control={<Radio />}
                  checked={alertState?.frequency === Constants.DAILY_FREQUENCY}
                  label="Daily"
                  className={classes.radioStyles}
                />
                <FormControlLabel
                  value={Constants.EVERY_3_DAYS_FREQUENCY}
                  checked={
                    alertState?.frequency === Constants.EVERY_3_DAYS_FREQUENCY
                  }
                  control={<Radio />}
                  label="Every 3 Days"
                  className={classes.radioStyles}
                />
                <FormControlLabel
                  value={Constants.WEEKLY_FREQUENCY}
                  control={<Radio />}
                  checked={alertState?.frequency === Constants.WEEKLY_FREQUENCY}
                  label="Weekly"
                  className={classes.radioStyles}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
}
