import { Button } from '@material-ui/core';
import { Tooltip, withStyles } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
  scoreIndicatorContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const PillButton = styled(Button)({
  margin: '8px',
  height: '14px',
  borderRadius: '50%',
  minWidth: '14px',
});

export function ScoreIndicator({ score }) {
  const classes = useStyles();
  const title = `Patient has a ${score}% likelihood of needing a biopsy to assess organ rejection`;
  let color = 'green';
  if (score >= 80 && score <= 100) {
    color = 'red';
  } else if (score >= 70 && score < 80) {
    color = 'orange';
  } else if (score >= 60 && score < 70) {
    color = 'yellow';
  }

  return (
    <div className={classes.scoreIndicatorContainer}>
      {score != null && (
        <LightTooltip title={title}>
          <PillButton
            type="button"
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ backgroundColor: color }}
          ></PillButton>
        </LightTooltip>
      )}
      {/* {score.toPrecision(4)} */}
    </div>
  );
}
