import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';
export const GET_IBOX_DATA = gql`
  query iboxData(
    $id: String!
    $includeResult: Boolean!
    $includeParameters: Boolean!
    $includeDetails: Boolean!
  ) {
    iBoxRecords(id: $id) {
      oid
      pid
      result @include(if: $includeResult) {
        ...ResultFragment
      }
      parameters @include(if: $includeParameters) {
        ...ParametersFragment
      }
      details @include(if: $includeDetails) {
        ...DetailsFragment
      }
    }
  }
  fragment ResultFragment on IBoxResultType {
    id
    survival1Years
    survival3Years
    survival5Years
    survival7Years
    survival10Years
    cStat
  }
  fragment ParametersFragment on IBoxParametersType {
    patientId
    riskEvaluationDate
    dateOfEgfrTaken
    egfrStatus
    egfr
    dateOfProteinuriaTaken
    proteinuriaUnit
    proteinuriaInGG
    proteinuriaDipstick
    dateOfDsaTaken
    dsaStatus
    dsaMfi
    dsaBinary
    dateOfHistologyTaken
    histologyStatus
    histologyDiagnosis
    diagnosisAbmr
    diagnosisTcmr
    diagnosisRecurrence
    diagnosisBk
    diagnosisCni
    diagnosisAki
    diagnosisOther
    age
    isFemale
    isBlack
    creatinine
    gScore
    ptcScore
    iScore
    tScore
    cgScore
    iftaScore
  }
  fragment DetailsFragment on IBoxDetailsType {
    accessionId
    medicalRecordNumber
    asScore
    prescriber
    reportType
    transplantOrgan
    monthsPostTx
    _Client
    clientSpecimanId
    donorRelationship
    dateCollected
    dateReceived
    dateReported
    unosCode
    monthsPostTx
    amReportDate
    amScore
    amRevised
    amComment
  }
`;
export default function WithIBoxQueryResults({
  id,
  includeResult,
  includeParameters,
  includeDetails,
  children,
}) {
  return (
    <WithQueryResults
      QUERY={GET_IBOX_DATA}
      variables={{ id, includeResult, includeParameters, includeDetails }}
    >
      {children}
    </WithQueryResults>
  );
}
