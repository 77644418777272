import { Grid, Box, Divider } from '@material-ui/core';
import { TranslatedTypography } from 'Components/Localization';
import React from 'react';
import EventTimelineLineChart from './EventTimelineLineChart';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

function ChartRow({
  chartConfigId,
  showXLabels,
  chartConfig,
  range,
  frequency,
  data,
  toggleExpand,
  isExpanded,
}) {
  return (
    <Grid container>
      <Grid
        container
        item
        xs={3}
        sm={3}
        lg={2}
        xl={2}
        alignItems="center"
        justify="space-between"
        //style={{ padding: '10px' }}
      >
        <Box ml={1} display="flex" flexDirection="row">
          {chartConfig.labelIcon}
          {!chartConfig.labelIcon && (
            <div style={{ width: '25px' }}>&nbsp;</div>
          )}
          <TranslatedTypography>{chartConfig.label} </TranslatedTypography>
        </Box>
        {/* <TranslatedTypography>
          {`${data.average || 0} ${data.unit || ''}`}
        </TranslatedTypography> */}
      </Grid>
      <Grid container item xs={1} justify="center" alignItems="center">
        <Divider orientation="vertical" />
        {chartConfig.hasChildren && !isExpanded && (
          <ArrowDropDownIcon
            className="ArrowDropDownIcon"
            onClick={() => toggleExpand(chartConfigId)}
          ></ArrowDropDownIcon>
        )}
        {chartConfig.hasChildren && isExpanded && (
          <ArrowDropUpIcon
            className="ArrowDropUpIcon"
            onClick={() => toggleExpand(chartConfigId)}
          ></ArrowDropUpIcon>
        )}
        {!chartConfig.hasChildren && <div style={{ width: '24px' }}></div>}
      </Grid>
      {isExpanded && (
        <Grid item xs={8} sm={8} lg={9} xl={9}>
          <div style={{ height: 80 }}></div>
        </Grid>
      )}
      {!isExpanded && (
        <Grid item xs={8} sm={8} lg={9} xl={9} style={{ height: 80 }}>
          {data.series.length > 0 && data.series[0].elements.length > 0 ? (
            <EventTimelineLineChart
              dataSets={chartConfig.getDataSets(data)}
              chartConfiguration={chartConfig}
              range={range}
              frequency={frequency}
              showXLabels={showXLabels}
              canvasHeight={120}
              labelDayFormat={'MM/DD/YYYY'}
              scaleXPadding={showXLabels ? 10 : 0}
            />
          ) : (
            <TranslatedTypography className="no-data">
              {`Last ${data.days || 0} No Data Available`}
            </TranslatedTypography>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default ChartRow;
