import React from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import { TranslatedTypography } from 'Components/Localization';
import { useParams, useHistory } from 'react-router-dom';

export default function NoAlloViewData() {
  const { id } = useParams();
  const history = useHistory();
  const add = (event) => {
    event.stopPropagation();
    return history.push(`/patient/${id}/alloViewRecord`);
  };
  return (
    <Container>
      <TranslatedTypography
        variant="h6"
        align="center"
        style={{ paddingBottom: '20px' }}
      >
        Unable to automatically generate AiKidney &trade; Likelihood of
        Rejection score. Please exercise user option to calculate AiKidney
        &trade; Likelihood of Rejection score if input parameters are known.
      </TranslatedTypography>
      <Grid container justify="center">
        <Button color="primary" variant="contained" onClick={add}>
          Add
        </Button>
      </Grid>
    </Container>
  );
}
