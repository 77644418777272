import { SvgIcon } from '@material-ui/core';
import { MedicineSm, IsxNoLevels } from 'icons/IconComponents';
import React from 'react';

const ChartTypes = {
  MedicationCompliance: 'medicationcompliance',
  TacroLevel: 'tacrolevel',
};
const ChartsConfiguration = {};

const waterIcon = new Image();
waterIcon.height = 10;
waterIcon.width = 10;
waterIcon.src = '/images/water.svg';
ChartsConfiguration[ChartTypes.MedicationCompliance] = {
  id: ChartTypes.MedicationCompliance,
  labelIcon: (
    <SvgIcon
      component={MedicineSm}
      style={{ height: '2em', width: '2em' }}
      viewBox="4 4 10 10"
    ></SvgIcon>
  ),
  label: 'Medication Compliance',
  color: (value) => {
    return value !== null ? 'black' : '';
  },
  getDataSets: (data) => {
    const pointColors = data.series[0].elements.map((d) => {
      return d.value !== null && d.value < 70
        ? 'red'
        : d.value !== null && d.value >= 70 && d.value <= 90
        ? 'yellow'
        : 'green';
    });
    return [
      {
        pointStyle: 'circle',
        pointRadius: 8,
        pointBorderColor: pointColors,
        label: 'Medication Compliance',
        borderWidth: 2,
        backgroundColor: pointColors,
        borderColor: 'black',
        data: data.series[0].elements,
      },
    ];
  },
  group: 1,
  dataLabelsPadding: 7,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.TacroLevel] = {
  id: ChartTypes.TacroLevel,
  labelIcon: (
    <SvgIcon
      component={IsxNoLevels}
      style={{ height: '2em', width: '2em' }}
      viewBox="3 3 30 32"
    ></SvgIcon>
  ),
  label: 'Tacro Level',
  color: (value) => {
    return value !== null && value >= 5 && value <= 15 ? 'green' : 'red';
  },
  getDataSets: (data) => {
    const pointColors = data.series[0].elements.map((d) => {
      return d.value !== null && d.value >= 5 && d.value <= 15
        ? 'green'
        : 'red';
    });
    return [
      {
        pointStyle: 'circle',
        pointRadius: 8,
        pointBorderColor: pointColors,
        label: 'Tacro Level',
        borderWidth: 2,
        backgroundColor: pointColors,
        borderColor: 'black',
        data: data.series[0].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 7,
  dataLabelsOffSet: 4,
};
const ImmunosuppressionReportFrequency = {
  daily: 1,
  threeDays: 3,
  tenDays: 10,
};

const ImmunosuppressionReportRange = {
  week: 7,
  month: 30,
  quarter: 90,
};

export {
  ChartsConfiguration,
  ChartTypes,
  ImmunosuppressionReportFrequency,
  ImmunosuppressionReportRange,
};
