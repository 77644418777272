import { createTheme } from '@material-ui/core';
import TradeGothic from './fonts/TradeGothicLTStd.ttf';
import { red } from '@material-ui/core/colors';

const tgothic = {
  fontFamily: 'Trade Gothic LT Std',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: `
    url(${TradeGothic}) format('truetype')
  `,
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#339AFE',
    },
    secondary: {
      main: '#f6f6f6',
    },
    background: {
      default: '#ffffff',
    },
  },
  props: {
    alignRight: {
      textAlign: 'right',
    },
    MuiTableCell: {
      // align: 'center',
    },
  },
  typography: {
    fontFamily: [
      'Trade Gothic LT Std',
      'sans-serif',
      'Roboto',
      'Helvetica',
      'Arial',
    ].join(','),
    subtitle2: {
      fontSize: 11,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [tgothic],
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: 'transparent',
      },
      head: {
        // paddingTop: "8px",
        // paddingBottom: "8px",
        fontWeight: 'bold',
        borderWidth: '3px',
        borderLeft: '1px solid',
        borderBottom: 'none',
        '&:first-child': {
          borderLeft: '0px',
          textAlign: 'left',
        },
        backgroundColor: 'transparent',
      },
    },
    MuiButton: {
      root: {
        margin: '5px',
      },
      containedSecondary: {
        backgroundColor: '#078ec1',
        color: '#fff',
        margin: '5px',
        '&:hover': {
          backgroundColor: 'rgb(17, 82, 147)',
          color: '#fff',
        },
      },
      containedPrimary: {
        // backgroundColor: '#078ec1',
        color: '#fff',
        // margin: '5px',
        // '&:hover': {
        //   backgroundColor: 'rgb(17, 82, 147)',
        //   color: '#fff',
        // },
      },
    },
    MuiRadio: {
      root: {
        color: '#4B8DF8',
      },
      colorSecondary: {
        '&$checked': {
          color: '#4B8DF8',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#4B8DF8',
      },
      colorSecondary: {
        '&$checked': {
          color: '#4B8DF8',
        },
      },
    },
  },
});
const alloViewTheme = createTheme({
  typography: {
    body1: {
      fontSize: '13px',
    },
    body2: {
      fontSize: '12px',
    },
    subtitle1: {
      fontSize: '13px',
    },
    subtitle2: {
      fontSize: '12px',
    },
    h4: {
      fontSize: '16px',
      fontWeight: 'bolder',
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#DDDDDD',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#DDDDDD',
    },
    info: {
      main: '#2E6FEE',
    },
  },
  overrides: {
    MuiButtonGroup: {
      grouped: {
        [`&.Mui-selected`]: {
          backgroundColor: '#000000',
          color: '#DDDDDD',
          '&:hover': {
            backgroundColor: '#000000',
            color: '#DDDDDD',
          },
        },
      },
    },
    MuiStepButton: {
      root: {
        [`&.Mui-selected`]: {
          backgroundColor: '#000000',
          color: '#DDDDDD',
          '&:hover': {
            backgroundColor: '#000000',
            color: '#DDDDDD',
          },
        },
        '&$Mui-selected': {
          backgroundColor: '#000000',
          color: '#DDDDDD',
          '&:hover': {
            backgroundColor: '#000000',
            color: '#DDDDDD',
          },
        },
      },
    },
  },
});

export default theme;
export { alloViewTheme };
