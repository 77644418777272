import React from 'react';
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  ThemeProvider,
} from '@material-ui/core';
import DemographicsHeader from '../../Display/DemographicsHeader';
import PatientLayout from '../../Layout/PatientLayout';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import ErrorPage from '../Error/ErrorPage';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';
import { useParams } from 'react-router-dom';
import AlloViewResults from './AlloViewResults';
import theme from '../../../theme';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '24px',
    color: 'black',
    width: '450px',
  },
}));

function AlloViewTitle() {
  const classes = useStyles();

  return (
    <Grid container item justify="flex-end">
      <Box
        display="inline"
        component="div"
        flexGrow={1}
        style={{ paddingLeft: '10%' }}
        className={classes.title}
      >
        <Typography variant="subtitle1" className={classes.title}>
          AiKidney &trade; Likelihood of Rejection
        </Typography>
        {/* <Box display="inline" component="div">
          <Typography variant="subtitle1" className={classes.title}>
            AiKidney &trade; Likelihood of Rejection
          </Typography>
        </Box> */}
      </Box>
    </Grid>
  );
}

function AlloViewPage() {
  const params = useParams();
  const id = params['id'];
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
      includeAllocare={true}
      includeImmunosuppression={true}
      days={10}
      averageSliceSize={1}
    >
      {function (data) {
        return (
          <PatientLayout
            header={
              <DemographicsHeader id={id} data={data} showTimeLine={false} />
            }
            subHeader={<AlloViewTitle />}
          >
            <ThemeProvider theme={theme}>
              <AlloViewResults data={data} />
            </ThemeProvider>
          </PatientLayout>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(AlloViewPage, ErrorPage);
