import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  type: {
    fontStyle: 'italic',
  },
  on: {
    fontWeight: 'bold',
  },
}));

export default function Encounter({ data }) {
  const { t } = useTranslation();
  const classes = useStyles();

  let type;
  if (data.type) {
    type = data.type[0].text;
  } else {
    type = t('unknownEncounterType');
  }

  let on;
  if (data.period) {
    on = t('formattedDate.LLL', { date: new Date(data.period.start) });
  } else {
    on = null;
  }

  return (
    <div>
      <div className={classes.type}>{type}</div>
      <div className={classes.on}>{on}</div>
    </div>
  );
}
