import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';

export const GET_IBOX_DATA = gql`
  query(
    $id: String!
    $includeResult: Boolean!
    $includeParameters: Boolean!
    $includeDetails: Boolean!
    $includeAllocare: Boolean!
    $includeImmunosuppression: Boolean!
    $days: Int!
    $averageSliceSize: Int!
  ) {
    patient(id: $id) {
      fhir {
        ... on PatientType {
          name {
            given
            family
          }
          gender
          birthDate
        }
        ... on ResolverErrorType {
          message
        }
      }
      eventTimeline {
        id
        patientEvent {
          eventName
          eventDate
        }
      }
      star {
        demographicInfo {
          patientName
          mrn
          dateOfBirth
          gender
          race
          language
          allergies
          abo
          maritalStatus
          currentBMI
          functionalStatus
          language
          education
          zip
          state
          insurance
        }
        widgetCurrentCondition {
          bMI
          iSx
          cmvStatus
          bkStatus
          rejection
        }
        widgetTransplantHistory {
          hlaMatch
          livingDonorType
          kdpi
          primaryDiagnosisAtTransplant
          previousTransplant
        }
      }
      links {
        ehr @include(if: $includeImmunosuppression) {
          ... on EhrType {
            averages(days: $days, averageSliceSize: $averageSliceSize) {
              tacrolevel {
                ...seriesDetails
              }
            }
            transplantMeds(days: $days) {
              mycophenolateMofetil {
                ...TransplantMeds
              }
              prednisone {
                ...TransplantMeds
              }
              sirolimus {
                ...TransplantMeds
              }
              tacrolimus {
                ...TransplantMeds
              }
              bactrim {
                ...TransplantMeds
              }
              valganciclovirl {
                ...TransplantMeds
              }
              rapamune {
                ...TransplantMeds
              }
            }
          }
        }
        ibox {
          ... on IBoxType {
            iBoxPatient {
              pid
              cibilPatientId
              firstName
              middleName
              lastName
              birthDate
              officialId
              transplantDate
              okraId
            }
            iBoxRecords {
              oid
              result @include(if: $includeResult) {
                ...ResultFragment
              }
              parameters @include(if: $includeParameters) {
                ...ParametersFragment
              }
              details @include(if: $includeDetails) {
                ...DetailsFragment
              }
            }
          }
          ... on ResolverErrorType {
            message
            details
          }
        }
        alloCare @include(if: $includeAllocare) {
          ... on BaseAlloCareType {
            averages(days: $days, averageSliceSize: $averageSliceSize) {
              fluidIntake {
                ...seriesDetails
              }
              temperature {
                ...seriesDetails
              }

              medicationcompliance @include(if: $includeImmunosuppression) {
                ...seriesDetails
              }
            }
            patientPersonalizationCondition {
              oliguriaCondition
              bmiCondition
              diabetesCondition
              hypertensionCondition
              copdCondition
              depressionCondition
            }
          }
          ... on ResolverErrorType {
            message
            details
          }
        }
        alloView {
          ... on BaseAlloViewType {
            alloViewRecords(startDate: null, endDate: null) {
              ... on AlloViewRecordType {
                result {
                  cibilAlloViewReportId
                  alloViewReportId
                  alloViewScore
                  systemGenerated
                }
                parameters {
                  patientId
                  riskEvaluationDate
                  creatinine
                  previousCreatinine
                  alloSureScore
                  egfr
                  dsaMfi
                  transplantDate
                  previousRejection
                  rejectionDiagnosisType
                  rejectionDiagnosisDate
                  hasPreviousTransplant
                  bkViremia
                }
              }
            }
          }
        }
      }
      alloSure {
        dsaResults {
          ...Labs
        }
      }
    }
    allAlloSureResult {
      ...Labs
    }
  }

  fragment Labs on LabDataType {
    date
    value
  }

  fragment TransplantMeds on MedicationPrescribedType {
    medicationDisplay
    rxDate
    unit
    value
    instructions
  }

  fragment seriesDetails on GraphDataType {
    average
    unit
    percentageWithinRange
    minRangeValue
    maxRangeValue
    series {
      elements {
        end
        start
        subCount
        value
      }
    }
  }

  fragment ResultFragment on IBoxResultType {
    id
    survival1Years
    survival3Years
    survival5Years
    survival7Years
    survival10Years
    cStat
  }
  fragment ParametersFragment on IBoxParametersType {
    patientId
    riskEvaluationDate
    dateOfEgfrTaken
    egfrStatus
    egfr
    dateOfProteinuriaTaken
    proteinuriaUnit
    proteinuriaInGG
    proteinuriaDipstick
    dateOfDsaTaken
    dsaStatus
    dsaMfi
    dsaBinary
    dateOfHistologyTaken
    histologyStatus
    histologyDiagnosis
    diagnosisAbmr
    diagnosisTcmr
    diagnosisRecurrence
    diagnosisBk
    diagnosisCni
    diagnosisAki
    diagnosisOther
    age
    isFemale
    isBlack
    creatinine
    gScore
    ptcScore
    iScore
    tScore
    cgScore
    iftaScore
  }
  fragment DetailsFragment on IBoxDetailsType {
    accessionId
    medicalRecordNumber
    asScore
    prescriber
    reportType
    transplantOrgan
    monthsPostTx
    _Client
    clientSpecimanId
    donorRelationship
    dateCollected
    dateReceived
    dateReported
    unosCode
    monthsPostTx
    amReportDate
    amScore
    amRevised
    amComment
  }
`;
export default function WithIBoxQueryWithLinks({
  id,
  includeResult,
  includeParameters,
  includeDetails,
  includeAllocare,
  includeImmunosuppression,
  days,
  averageSliceSize,
  children,
}) {
  return (
    <WithQueryResults
      QUERY={GET_IBOX_DATA}
      variables={{
        id,
        includeResult: includeResult || false,
        includeParameters: includeParameters || false,
        includeDetails: includeDetails || false,
        includeAllocare: includeAllocare || false,
        includeImmunosuppression: includeImmunosuppression || false,
        days: days || 0,
        averageSliceSize: averageSliceSize || 0,
      }}
    >
      {children}
    </WithQueryResults>
  );
}
