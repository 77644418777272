/* istanbul ignore file */

export default function AuthenticationConfig(
  clientId,
  redirectUri,
  tokenUri,
  logoutUri,
  authorizeUri,
  responseType,
  scope
) {
  let parameterMessage = (name) => new Error(`Parameter '${name}' is required`);
  if (!clientId) {
    throw parameterMessage('clientId');
  } else if (!redirectUri) {
    throw parameterMessage('redirectUri');
  } else if (!tokenUri) {
    throw parameterMessage('tokenUri');
  } else if (!logoutUri) {
    throw parameterMessage('logoutUri');
  } else if (!authorizeUri) {
    throw parameterMessage('authorizeUri');
  } else if (!responseType) {
    throw parameterMessage('responseType');
  } else if (!scope) {
    throw parameterMessage('scope');
  }

  this.clientId = clientId;
  this.redirectUri = redirectUri;
  this.tokenUri = tokenUri;
  this.logoutUri = logoutUri;
  this.authorizeUri = authorizeUri;
  this.responseType = responseType;
  this.scope = scope;
}
