import React from 'react';
import { TranslatedTypography } from 'Components/Localization';
import { Container, Link } from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';

export default function NoDsaData() {
  return (
    <Container>
      <TranslatedTypography
        variant="h5"
        align="center"
        style={{ paddingBottom: '48px' }}
      >
        <br />
        Patient has NO DSA records. <br />
        Contact
        <Link
          style={{ paddingLeft: '6px', paddingRight: '6px' }}
          href="mailto:customercare@caredx.com"
          variant="h5"
          target="_blank"
          rel="nofollow noopener"
        >
          customercare@caredx.com
          <sup>
            <EmailOutlinedIcon fontSize="small" />
          </sup>
        </Link>
        to learn more.
      </TranslatedTypography>
    </Container>
  );
}
