function wrapIECryptoOperation(operation) {
  return new Promise(function (resolve, reject) {
    operation.addEventListener('complete', function () {
      resolve(operation.result);
    });
    operation.addEventListener('error', function () {
      reject(operation.result);
    });
  });
}

const crypto = window.crypto || window.msCrypto;

const aitCrypto = {
  /**
   *
   * @param {Int8Array|Int16Array|Int32Array|Uint8Array|Uint16Array|Uint32Array} typedArray
   * @returns {Int8Array|Int16Array|Int32Array|Uint8Array|Uint16Array|Uint32Array}
   */
  getRandomValues(typedArray) {
    if (crypto) return crypto.getRandomValues(typedArray);
    return [];
  },
  subtle: {
    /**
     * @param {'SHA-1'|'SHA-256'|'SHA-384'|'SHA-512'} algorithm
     * @param {ArrayBuffer|ArrayBufferView} data
     * @returns {Promise<ArrayBuffer>}
     */
    digest(algorithm, data) {
      if (window.msCrypto) {
        return wrapIECryptoOperation(crypto.subtle.digest(algorithm, data));
      } else {
        return crypto.subtle.digest(algorithm, data);
      }
    },
  },
};

export default aitCrypto;
