import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route as BaseRoute,
} from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  ThemeProvider,
  Typography,
  Container,
  CssBaseline,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { fromPromise } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from '@apollo/client/link/context';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import DayJsUtils from '@date-io/dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import LoginIndicator from './Indicators/LoginIndicator';
import ViewPatientListPage from './Pages/ListViewer/ViewPatientListPage';
import EditListsPage from './Pages/EditLists/EditListsPage';
import {
  ListBuilderEditPage,
  ListBuilderNewPage,
} from './Pages/ListBuilder/ListBuilderPage';
import DashboardPage from './Pages/Widgets/DashboardPage';
import DashboardNew from './Pages/Widgets/DashboardNew';
import {
  SmartAuthenticationConfig,
  SmartAuthentication,
  useSmartAuthentication,
} from './Smart';
import {
  AuthenticationConfig,
  Authentication,
  useAuthentication,
} from './OAuth2';
import {
  Oauth2CallbackPage,
  SmartCallbackPage,
  SmartLaunchPage,
  SmartPatientMatch,
} from './Pages/Authentication';
import theme from '../theme';
import '../i18next';
import 'dayjs/locale/fr';
import PatientProfileDetails from './Pages/Widgets/Profile/PatientProfileDetailsContainer';
import KidneyCarePage from './Pages/Widgets/KidneyCare/KidneyCarePage';
import AccessTokenCopyToClipBoard from './Utilities/AccessTokenCopyToClipBoard';
import AlloCarePage from './Pages/AlloCare/AlloCarePage';
import IBoxPage from './Pages/Widgets/IBox/IBoxPage';
import ImmunosuppressionPage from './Pages/Immunosuppression/ImmunosuppressionPage';
import AlloSureHistrogramChartPage from './Pages/Widgets/AlloSureHistogram/AlloSureHistrogramChartPage';
import AlloIndividualChartPage from './Pages/Widgets/AlloIndividualChart/AlloIndividualChartPage';
import AlloSureViolinChartPage from './Pages/Widgets/AlloSureViolinChart/AlloSureViolinChartPage';
import EventTimelinePage from './Pages/EventTimeline/EventTimelinePage';
import AlloViewPage from './Pages/AlloView/AlloViewPage';
import AlloViewChart from './Pages/AlloView/AlloViewChart';
import './App.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import AlloViewRecord from './Pages/AlloView/AlloViewRecord';

dayjs.extend(localizedFormat);

const useStyles = makeStyles((theme) => ({
  route: {
    height: '90vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    flexGrow: 1,
    paddingTop: 15,
  },
  container: {
    marginTop: '75px',
  },
  containerNoShowBanner: {
    marginTop: '75px',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2px',
    marginRight: '15px',
    '& > *': {
      margin: '4px',
    },
  },
}));

const useMenuIconStyles = makeStyles((theme) => ({
  label: {
    color: 'white',
  },
}));

function App() {
  const oauthConfig = new AuthenticationConfig(
    process.env.REACT_APP_AUTH_CLIENT_ID,
    process.env.REACT_APP_AUTH_REDIRECT,
    process.env.REACT_APP_AUTH_TOKEN_URI,
    process.env.REACT_APP_AUTH_LOGOUT_URI,
    process.env.REACT_APP_AUTH_AUTHORIZE_URI,
    'code',
    ['openid', 'profile', 'email'].join(' ')
  );

  const smartConfig = new SmartAuthenticationConfig(
    process.env.REACT_APP_SMART_AUTH_CLIENT_ID,
    process.env.REACT_APP_SMART_AUTH_REDIRECT,
    process.env.REACT_APP_SMART_AUTH_SCOPE
  );

  return (
    <Router>
      <Authentication config={oauthConfig}>
        <SmartAuthentication config={smartConfig}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <DndProvider backend={HTML5Backend}>
              <MuiPickersUtilsProvider utils={DayJsUtils}>
                <Layout />
              </MuiPickersUtilsProvider>
            </DndProvider>
          </ThemeProvider>
        </SmartAuthentication>
      </Authentication>
    </Router>
  );
}

function Route({ path, component }) {
  const classes = useStyles();
  const { authState } = useAuthentication();
  let content;
  if (authState.isAuthenticated) {
    content = <BaseRoute path={path} component={component} />;
  } else {
    content = <Typography className={classes.route}>Please sign in</Typography>;
  }
  return content;
}

function Layout() {
  const classes = useStyles();
  const menuIconClasses = useMenuIconStyles();
  const { authState, authService } = useAuthentication();
  const {
    authState: smartAuthState,
    server: smartServer,
  } = useSmartAuthentication();
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL,
  });

  const accessToken =
    authState.tokenResponse && authState.tokenResponse.access_token;
  const userInfo = authService.getUser();

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        'x-client-time-zone': new Date().getTimezoneOffset(),
        'x-ehr-authorization': smartAuthState.accessToken
          ? `Bearer ${smartAuthState.accessToken}`
          : '',
        'x-issuer': smartServer.issuerUri,
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let error of graphQLErrors) {
          if (error.extensions != null) {
            switch (error.extensions.code) {
              case 'VALIDATION_ERROR':
                if (error.extensions.number === 'authorization') {
                  const oldHeaders = operation.getContext().headers;
                  const setTokenHeaderPromise = authService
                    .refresh()
                    .then((tokenResponse) => {
                      if (tokenResponse) {
                        operation.setContext({
                          headers: {
                            ...oldHeaders,
                            authorization: tokenResponse.access_token,
                          },
                        });
                      }
                      return forward(operation);
                    });
                  return fromPromise(setTokenHeaderPromise);
                }
                break;
              default:
                break;
            }
          }
        }
      }
    }
  );

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <div>
        <AppBar
          position="fixed"
          style={{ background: '#53565A', color: '#fafafa' }}
        >
          <Toolbar>
            <div className={classes.title}>
              <img
                src={`/images/AiTraC_logo_WHITE.png`}
                alt="AiTraC"
                width="120"
                height="35"
              ></img>
            </div>
            {authState.isAuthenticated &&
              accessToken &&
              process.env.REACT_APP_IS_DEV && (
                <AccessTokenCopyToClipBoard
                  accessToken={accessToken}
                  className={menuIconClasses}
                ></AccessTokenCopyToClipBoard>
              )}
            {userInfo && smartAuthState.showBanner && (
              <div className={classes.avatarContainer}>
                <Avatar
                  style={{ marginRight: '3px' }}
                  alt={userInfo.name}
                  src="/broken-image.jpg"
                />
                <Typography variant="body2">{userInfo.name}</Typography>
              </div>
            )}
            {smartAuthState.showBanner && <LoginIndicator />}
          </Toolbar>
        </AppBar>
        <Container
          maxWidth={false}
          fixed
          disableGutters={true}
          className={
            smartAuthState.showBanner
              ? classes.container
              : classes.containerNoShowBanner
          }
        >
          <RoutingSwitch />
        </Container>
      </div>
    </ApolloProvider>
  );
}

function RoutingSwitch() {
  return (
    <Switch>
      <Route path="/view-list/:id/:cid" component={ViewPatientListPage} />
      <Route path="/view-list/:id/" component={ViewPatientListPage} />
      <Route path="/edit-lists/list/new-list" component={ListBuilderNewPage} />
      <Route path="/edit-lists/list/:id/:cid" component={ListBuilderEditPage} />
      <Route path="/edit-lists/list/:id" component={ListBuilderEditPage} />
      <Route path="/edit-lists/" component={EditListsPage} />
      <Route path="/patient/match/:id/:type" component={SmartPatientMatch} />
      <Route path="/patient/:id/kidneyCareReport" component={KidneyCarePage} />
      <Route path="/patient/:id/iBoxData" component={IBoxPage} />
      <Route path="/patient/:id/details" component={PatientProfileDetails} />
      <Route path="/patient/:id/allocare" component={AlloCarePage} />
      <Route path="/patient/:id/eventTimeline" component={EventTimelinePage} />
      <Route path="/patient/:id/alloView" component={AlloViewPage} />
      <Route path="/patient/:id/alloViewChart" component={AlloViewChart} />
      <Route path="/patient/:id/alloViewRecord" component={AlloViewRecord} />
      <Route
        path="/patient/:id/immunosuppression"
        component={ImmunosuppressionPage}
      />
      <Route
        path="/patient/:id/alloSureHistrogramChartPage"
        component={AlloSureHistrogramChartPage}
      />
      <Route
        path="/patientcharts/:id/alloSureHistrogramChartPage"
        component={AlloSureHistrogramChartPage}
      />
      <Route
        path="/patient/:id/alloIndividualChartPage"
        component={AlloIndividualChartPage}
      />
      <Route
        path="/patientcharts/:id/alloIndividualChartPage"
        component={AlloIndividualChartPage}
      />
      <Route
        path="/patient/:id/alloSureViolinChartPage"
        component={AlloSureViolinChartPage}
      />
      <Route
        path="/patientcharts/:id/alloSureViolinChartPage"
        component={AlloSureViolinChartPage}
      />
      <Route path="/patient/:id" component={DashboardPage} />
      <Route path="/patientcharts/:id" component={DashboardNew} />
      <BaseRoute path="/implicit/callback" component={Oauth2CallbackPage} />
      <BaseRoute path="/smart/callback" component={SmartCallbackPage} />
      <BaseRoute path="/smart/launch/:issuer" component={SmartLaunchPage} />
      <Route path="/" component={ViewPatientListPage} />
    </Switch>
  );
}

export default App;
