import { Grid, makeStyles, Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useContext, useEffect, useState } from 'react';
import Constants from './Utilities/constants';
import DSA from './DataEntrySections/DSA';
import ThickDivider from './Controls/ThickDivider';
import NewPatientCalculation from './DataEntrySections/NewPatientCalculation';
import EstimatedGFR from './DataEntrySections/EstimatedGFR';
import Proteinuria from './DataEntrySections/Proteinuria';
import Histology from './DataEntrySections/Histology';
import { IBoxContext } from './state/IBoxContextProvider';
import { removeProperties, removeTypeName } from 'utility';
import { formatCibilData } from './Utilities/Data';
const intConversionFields = [
  Constants.DIAGNOSIS_ABMR,
  Constants.DIAGNOSIS_AKI,
  Constants.DIAGNOSIS_BK,
  Constants.DIAGNOSIS_TCMR,
  Constants.DIAGNOSIS_RECURRENCE,
  Constants.DIAGNOSIS_OTHER,
  Constants.DIAGNOSIS_CNI,
];
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  image: {
    margin: 10,
    alignSelf: 'center',
    width: 200,
  },
  subList: {
    marginLeft: '20px',
  },
  errorBox: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  formControl: {
    width: '100%',
  },
  formContainer: {
    '&:first-child': {
      marginTop: '15px',
    },
    '& > .MuiGrid-item': {
      padding: theme.spacing(1.5),
    },
  },
}));

function IBoxCalculatorDataEntry({
  setFormRefCallback,
  validation,
  mutateIBoxReport,
  formattedReportData,
  setReportData,
  fhirPatient,
}) {
  const classes = useStyles();
  const { state, dispatch, patientState } = useContext(IBoxContext);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(function () {
    if (!state?.dsaStatus) {
      dispatch({ type: Constants.DSA_STATUS, payload: 'not_available' });
    }

    if (!state?.histologyStatus) {
      dispatch({ type: Constants.HISTOLOGY_STATUS, payload: 'not_available' });
    }

    if (!state[Constants.PROTEINURIA_UNIT]) {
      dispatch({ type: Constants.PROTEINURIA_UNIT, payload: 'g/g' });
    }
    if (!state[Constants.EGFR_STATUS]) {
      dispatch({ type: Constants.EGFR_STATUS, payload: 'egfr' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Grid>
        <form
          ref={setFormRefCallback}
          onSubmit={async (e) => {
            e.preventDefault();
            setAlertMessage('');
            const errors = Object.keys(validation.rules).map((el) =>
              validation.rules[el]()
            );
            if (errors.every((p) => !p)) {
              try {
                const updatedState = Object.keys(state).reduce((acc, key) => {
                  acc[key] = state[key] === '' ? null : state[key];
                  return acc;
                }, {});
                if (
                  updatedState[Constants.HISTOLOGY_STATUS] ===
                  'diagnosis_available'
                ) {
                  intConversionFields.forEach(
                    (x) => (updatedState[x] = updatedState[x] ? 1 : 0)
                  );
                }
                updatedState[Constants.RISK_EVALUATION_DATE] =
                  updatedState[Constants.DATE_OF_PROTEINURIA_TAKEN];
                mutateIBoxReport({
                  variables: {
                    patient: removeProperties(patientState, [
                      '__typename',
                      'isNewPatient',
                    ]),
                    parameters: removeTypeName(updatedState),
                    gender: fhirPatient.gender,
                  },
                }).then((p) => {
                  setReportData(() => ({
                    prior: formattedReportData?.current || [],
                    current:
                      formatCibilData(
                        { ...state, ...updatedState },
                        patientState,
                        p.data.generatePatientRecord
                      )?.current || [],
                  }));
                });
              } catch (error) {
                setAlertMessage('Couldnt run report');
                window.scroll(0, 0);
                setReportData(null);
              }
            }
          }}
        >
          <Grid container className={classes.formContainer} direction="column">
            {!!alertMessage && (
              <Alert
                variant="filled"
                severity="error"
                className={classes.errorBox}
              >
                <AlertTitle>Error</AlertTitle>
                {alertMessage}
              </Alert>
            )}
            <NewPatientCalculation
              validators={validation}
            ></NewPatientCalculation>
            <Grid item>
              <EstimatedGFR
                validators={validation}
                fhirPatient={fhirPatient}
              ></EstimatedGFR>
            </Grid>
            <Grid item>
              <ThickDivider></ThickDivider>
            </Grid>
            <Grid item>
              <Proteinuria validators={validation}></Proteinuria>
            </Grid>
            <Grid item>
              <ThickDivider></ThickDivider>
            </Grid>
            <Grid item>
              <DSA validators={validation}></DSA>
            </Grid>
            <Grid item>
              <ThickDivider></ThickDivider>
            </Grid>
            <Grid item>
              <Histology validators={validation}></Histology>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Box>
  );
}

export default IBoxCalculatorDataEntry;
