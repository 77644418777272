/* istanbul ignore file */

export default class DateUtility {
  static months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  static formatDate(date) {
    if (!date) {
      return 'missing date';
    }
    const year_and_month = date.split('-');
    const day = year_and_month[2].split('T')[0];
    return `${day} ${DateUtility.months[Number(year_and_month[1]) - 1]} ${
      year_and_month[0]
    }`;
  }

  static monthsDifference(date1, date2) {
    let months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
  }
  static getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
