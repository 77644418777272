import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';
export const GET_IMMUNOSUPPRESSION_MEDICATIONS = gql`
  query(
    $id: String!
    $includeAllocare: Boolean!
    $includeEhr: Boolean!
    $days: Int!
    $averageSliceSize: Int!
  ) {
    patient(id: $id) {
      links {
        ehr @include(if: $includeEhr) {
          ... on EhrType {
            fhir {
              ... on PatientResourcesType {
                medications {
                  ... on MedicationPrescribedType {
                    rxDate
                    dailyDosage
                    instructions
                    medicationDisplay
                  }
                }
              }
            }
            averages(days: $days, averageSliceSize: $averageSliceSize) {
              tacrolevel {
                ...Details
              }
              sirolevel {
                ...Details
              }
              cyclolevel {
                ...Details
              }
            }
          }
        }
        alloCare @include(if: $includeAllocare) {
          ... on BaseAlloCareType {
            averages(days: $days, averageSliceSize: $averageSliceSize) {
              mood {
                ...Details
              }
              medicationcompliance {
                ...Details
              }
            }
            isxtaken(days: $days) {
              schedule
              form
              pillImage
              statement {
                medication {
                  ...CodeableConceptParts
                }
                effective {
                  ...DateTimeValue
                }
              }
              strength {
                text
              }
            }
          }
        }
      }
    }
  }

  fragment DateTimeValue on FhirDateTimeType {
    value
  }

  fragment Details on GraphDataType {
    unit
    averageWindowDays
    days
    average
    count
    series {
      name
      elements {
        start
        end
        value
        subCount
      }
    }
  }

  fragment CodeableConceptParts on CodeableConceptType {
    text
  }
`;
export default function WithImmunosuppressionData({
  id,
  days,
  averageSliceSize,
  includeAllocare,
  includeEhr,
  children,
}) {
  return (
    <WithQueryResults
      QUERY={GET_IMMUNOSUPPRESSION_MEDICATIONS}
      variables={{
        id,
        days: days || 7,
        averageSliceSize: averageSliceSize || 1,
        includeAllocare: includeAllocare || false,
        includeEhr: includeEhr || false,
      }}
    >
      {children}
    </WithQueryResults>
  );
}
