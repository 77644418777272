export default function TokenRepository() {}

TokenRepository.prototype = {
  storageKey: 'aitrac-token',
  /**
   * Get the token response object
   * @returns {{ access_token: string, expires_in: number, id_token?: string, refresh_expires_in?: number, refresh_token?: string, scope: string, session_state: string, token_type: string }}
   */
  get() {
    const tokenResponseJson = localStorage.getItem(this.storageKey);
    let tokenResponse;
    if (tokenResponseJson) {
      try {
        tokenResponse = JSON.parse(tokenResponseJson);
      } catch (error) {
        console.error(error);
        tokenResponse = null;
      }
    } else {
      tokenResponse = null;
    }
    return tokenResponse;
  },
  /**
   * Put the {tokenResponse} into storage
   * @param {{{ access_token: string, expires_in: number, id_token?: string, refresh_expires_in?: number, refresh_token?: string, scope: string, session_state: string, token_type: string }}} tokenResponse
   */
  put(tokenResponse) {
    const tokenResponseJson = JSON.stringify(tokenResponse);
    localStorage.setItem(this.storageKey, tokenResponseJson);
  },
  /**
   * Remove the token response object from storage
   */
  remove() {
    localStorage.removeItem(this.storageKey);
  },
};
