import * as React from 'react';

function SvgSelector(props) {
  return (
    <svg {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M8.334 10.138a.528.528 0 01-.529.525h-.794c-.29 0-.529-.082-.529-.183 0-.1.002-.224.004-.276H5.224c-.005.091-.013.231-.016.312-.004.08-.246.147-.537.147h-.835a.528.528 0 01-.529-.525V5.287c0-.288.238-.524.53-.524h3.968c.291 0 .53.236.53.524z"
          fill="#231f20"
        />
        <path
          d="M5.556 9.495a.12.12 0 01-.12.119H4.222a.12.12 0 01-.12-.119v-.68a.12.12 0 01.12-.119h1.216a.12.12 0 01.12.119zM7.673 9.495a.12.12 0 01-.12.119H6.337a.12.12 0 01-.12-.119v-.68a.12.12 0 01.12-.119h1.216a.12.12 0 01.12.119z"
          fill="#fff"
        />
        <path
          fill="#231f20"
          d="M6.02 12.17h.33v-1.835h-.992v1.836h.33v-.852h.331z"
        />
        <path
          d="M7.673 7.844a.33.33 0 01-.33.328H4.431a.33.33 0 01-.331-.328V5.68a.33.33 0 01.33-.327h2.911a.33.33 0 01.33.327z"
          fill="#fff"
        />
        <path
          d="M6.56 7.159a.697.697 0 01-.7.693c-.386 0-.7-.31-.7-.693 0-.383.7-1.458.7-1.458.203.323.7 1.075.7 1.458"
          fill="#231f20"
        />
        <path
          d="M6.56 7.159a.697.697 0 01-.7.693c-.386 0-.7-.31-.7-.693 0-.383.7-1.458.7-1.458.203.323.7 1.075.7 1.458z"
          stroke="#231f20"
          strokeWidth={0.13229}
        />
        <path
          d="M6.061 7.373c-.105.11-.14.248-.077.306.063.059.2.017.304-.094.106-.11.14-.248.077-.307-.062-.058-.198-.016-.304.095"
          fill="#fff"
        />
        <path
          d="M6.061 7.373c-.105.11-.14.248-.077.306.063.059.2.017.304-.094.106-.11.14-.248.077-.307-.062-.058-.198-.016-.304.095z"
          stroke="#fff"
          strokeWidth={0.13229}
        />
      </g>
    </svg>
  );
}

export default SvgSelector;
