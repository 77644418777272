/* istanbul ignore file */

import { convertLocalDateToISO } from 'Utilities/dateHelpers';
import Constants from '../Utilities/constants';
//import * as validation from '../Utilities/validation';
import * as validation from '../../../../Utilities/Validations';
import * as moment from 'moment';

const validationHelpers = (rules, state, dateReported, dispatchError) => {
  return {
    required: (name, conditionalDispatch) => {
      rules[name] = (val) => {
        let message = '';
        if (
          conditionalDispatch === undefined ||
          (typeof conditionalDispatch === 'function' && conditionalDispatch())
        ) {
          if (!(val || state[name])) message = Constants.REQUIRED;
        }

        dispatchError({
          type: name,
          payload: message,
        });
        return message;
      };
    },
    diagnosisValidator: () => {
      rules['diagnosis'] = (val) => {
        let message = '';
        if (state[Constants.HISTOLOGY_STATUS] === 'diagnosis_available') {
          if (
            !(
              val ||
              state[Constants.DIAGNOSIS_ABMR] ||
              state[Constants.DIAGNOSIS_AKI] ||
              state[Constants.DIAGNOSIS_CNI] ||
              state[Constants.DIAGNOSIS_BK] ||
              state[Constants.DIAGNOSIS_TCMR] ||
              state[Constants.DIAGNOSIS_RECURRENCE] ||
              state[Constants.DIAGNOSIS_OTHER]
            )
          )
            message = Constants.REQUIRED;
        }

        dispatchError({
          type: 'diagnosis',
          payload: message,
        });
        return message;
      };
    },
    dateHelper: (name, errorMessage, noOfDays, conditionalDispatch) => {
      rules[name] = (val) => {
        let message = '';
        if (
          conditionalDispatch === undefined ||
          (typeof conditionalDispatch === 'function' && conditionalDispatch())
        ) {
          if (
            dateReported &&
            !validation.validateDate(
              dateReported,
              convertLocalDateToISO(val || state[name]),
              noOfDays
            )
          ) {
            message = errorMessage;
          }
        }
        dispatchError({
          type: name,
          payload: message,
        });
        return message;
      };
    },
    numberValidator: (
      name,
      errorMessage,
      lowerLimit,
      upperLimit,
      decimals,
      conditionalDispatch
    ) => {
      rules[name] = (val) => {
        let message = '';
        if (
          conditionalDispatch === undefined ||
          (typeof conditionalDispatch === 'function' && conditionalDispatch())
        ) {
          if (!(val || state[name])) message = Constants.REQUIRED;
          if (
            !validation.validateNumber(
              val || state[name],
              lowerLimit,
              upperLimit,
              decimals
            )
          ) {
            message = errorMessage;
          }
        }
        dispatchError({
          type: name,
          payload: message,
        });
        return message;
      };
    },
  };
};

export function ValidationRules(
  dispatchError,
  state,
  patientState,
  dateReported
) {
  const rules = {};
  const validators = validationHelpers(
    rules,
    state,
    dateReported,
    dispatchError
  );

  rules[Constants.DATE_OF_EGFR_TAKEN] = (val) => {
    let message = '';

    if (!(val || state[Constants.DATE_OF_EGFR_TAKEN])) {
      message = Constants.REQUIRED;
    } else if (
      state[Constants.DATE_OF_EGFR_TAKEN] &&
      moment(state[Constants.DATE_OF_EGFR_TAKEN])
        .startOf('day')
        .diff(
          moment(val || patientState[Constants.TRANSPLANT_DATE]).startOf('day')
        ) < 0
    ) {
      message = 'Egfr Taken must be a date equal or after transplant date';
    }

    dispatchError({
      type: Constants.DATE_OF_EGFR_TAKEN,
      payload: message,
    });
    return message;
  };

  rules[Constants.TRANSPLANT_DATE] = (val) => {
    let message = '';
    if (patientState.isNewPatient) {
      if (!(val || patientState[Constants.TRANSPLANT_DATE])) {
        message = Constants.REQUIRED;
      } else if (
        patientState[Constants.BIRTH_DATE] &&
        moment(patientState[Constants.BIRTH_DATE]).diff(
          moment(val || patientState[Constants.TRANSPLANT_DATE])
        ) > 0
      ) {
        message = 'transplant date must be a date after birth date';
      }
    }

    dispatchError({
      type: Constants.TRANSPLANT_DATE,
      payload: message,
    });
    return message;
  };

  rules[Constants.BIRTH_DATE] = (val) => {
    let message = '';
    if (!(val || patientState[Constants.BIRTH_DATE])) {
      message = Constants.REQUIRED;
    } else if (
      moment(val || patientState[Constants.BIRTH_DATE]).diff(
        moment(moment().subtract(18, 'years').subtract(1, 'day'))
      ) > 0
    ) {
      message = 'Patient must be 18 years old';
    }
    dispatchError({
      type: Constants.BIRTH_DATE,
      payload: message,
    });
    return message;
  };

  rules[Constants.IS_BLACK] = (val) => {
    const message =
      state[Constants.EGFR_STATUS] === 'calculated' &&
      !(state[Constants.IS_BLACK]?.toString() || val?.toString())
        ? Constants.REQUIRED
        : '';
    dispatchError({
      type: Constants.IS_BLACK,
      payload: message,
    });
    return message;
  };

  rules[Constants.DSA_BINARY] = (val) => {
    const message =
      state[Constants.DSA_STATUS] === 'binary' &&
      !(state[Constants.DSA_BINARY]?.toString() || val?.toString())
        ? Constants.REQUIRED
        : '';
    dispatchError({
      type: Constants.DSA_BINARY,
      payload: message,
    });
    return message;
  };

  validators.diagnosisValidator();

  rules[Constants.DATE_OF_HISTOLOGY_TAKEN] = (val) => {
    let message = '';

    if (
      state[Constants.HISTOLOGY_STATUS] &&
      state[Constants.HISTOLOGY_STATUS] !== 'not_available'
    ) {
      if (!(val || state[Constants.DATE_OF_HISTOLOGY_TAKEN])) {
        message = Constants.REQUIRED;
      } else if (
        state[Constants.DATE_OF_HISTOLOGY_TAKEN] &&
        moment(state[Constants.DATE_OF_HISTOLOGY_TAKEN])
          .startOf('day')
          .diff(
            moment(val || patientState[Constants.TRANSPLANT_DATE]).startOf(
              'day'
            )
          ) < 0
      ) {
        message =
          'Histology Taken must be a date after or equal transplant date';
      }
    }
    dispatchError({
      type: Constants.DATE_OF_HISTOLOGY_TAKEN,
      payload: message,
    });
    return message;
  };

  rules[Constants.DATE_OF_PROTEINURIA_TAKEN] = (val) => {
    let message = '';

    if (!(val || state[Constants.DATE_OF_PROTEINURIA_TAKEN])) {
      message = Constants.REQUIRED;
    } else if (
      state[Constants.DATE_OF_PROTEINURIA_TAKEN] &&
      moment(state[Constants.DATE_OF_PROTEINURIA_TAKEN])
        .startOf('day')
        .diff(
          moment(val || patientState[Constants.TRANSPLANT_DATE]).startOf('day')
        ) < 0
    ) {
      message =
        'Proteinuria Taken must be a date after or equal to transplant date';
    }

    dispatchError({
      type: Constants.DATE_OF_PROTEINURIA_TAKEN,
      payload: message,
    });
    return message;
  };

  // validators.required(
  //   Constants.DATE_OF_DSA_TAKEN,
  //   () =>
  //     state[Constants.DSA_STATUS] &&
  //     state[Constants.DSA_STATUS] !== 'not_available'
  // );

  rules[Constants.DATE_OF_DSA_TAKEN] = (val) => {
    let message = '';

    if (
      state[Constants.DSA_STATUS] &&
      state[Constants.DSA_STATUS] !== 'not_available'
    ) {
      if (!(val || state[Constants.DATE_OF_DSA_TAKEN])) {
        message = Constants.REQUIRED;
      } else if (
        state[Constants.DATE_OF_DSA_TAKEN] &&
        moment(state[Constants.DATE_OF_DSA_TAKEN])
          .startOf('day')
          .diff(
            moment(val || patientState[Constants.TRANSPLANT_DATE]).startOf(
              'day'
            )
          ) < 0
      ) {
        message = 'DSA Taken must be a date after or equal to transplant date';
      }
    }
    dispatchError({
      type: Constants.DATE_OF_DSA_TAKEN,
      payload: message,
    });
    return message;
  };

  validators.required(
    Constants.G_SCORE,
    () => state[Constants.HISTOLOGY_STATUS] === 'banff_available'
  );

  validators.required(
    Constants.PROTEINURIA_DIPSTICK,
    () => state[Constants.PROTEINURIA_UNIT] === 'dipstick'
  );

  validators.required(
    Constants.I_SCORE,
    () => state[Constants.HISTOLOGY_STATUS] === 'banff_available'
  );
  validators.required(
    Constants.T_SCORE,
    () => state[Constants.HISTOLOGY_STATUS] === 'banff_available'
  );
  validators.required(
    Constants.CG_SCORE,
    () => state[Constants.HISTOLOGY_STATUS] === 'banff_available'
  );
  validators.required(
    Constants.PTC_SCORE,
    () => state[Constants.HISTOLOGY_STATUS] === 'banff_available'
  );
  validators.required(
    Constants.IFTA_SCORE,
    () => state[Constants.HISTOLOGY_STATUS] === 'banff_available'
  );

  validators.numberValidator(
    Constants.EGFR,
    Constants.ERROR_EGFR,
    0,
    120,
    2,
    () => state[Constants.EGFR_STATUS] === 'egfr'
  );
  validators.numberValidator(
    Constants.CREATININE,
    Constants.ERROR_CREATININE,
    null,
    null,
    2,
    () => state[Constants.EGFR_STATUS] === 'calculated'
  );
  validators.numberValidator(
    Constants.AGE,
    Constants.ERROR_AGE,
    0,
    150,
    0,
    () => state[Constants.EGFR_STATUS] === 'calculated'
  );
  validators.numberValidator(
    Constants.DSA_MFI,
    Constants.ERROR_DSA_MFI,
    0,
    20000,
    0,
    () => state[Constants.DSA_STATUS] === 'mfi'
  );
  validators.numberValidator(
    Constants.PROTEINURIA_IN_G_G,
    Constants.ERROR_PROTEINURIA_IN_G_G,
    0,
    12,
    0,
    () => state[Constants.PROTEINURIA_UNIT] === 'g/g'
  );

  return {
    rules: rules,
    resetValidators: function (validatorKeys) {
      const keys = Object.keys(this.rules);
      keys.forEach((p) => {
        if (validatorKeys.some((validatorKey) => validatorKey === p)) {
          dispatchError({
            type: p,
            payload: null,
          });
        }
      });
    },
  };
}
