import Constants from '../Utilities/constants';
import _ from 'lodash';
import { removeTypeName } from 'utility';

const DialogContentConfiguration = {};

DialogContentConfiguration[Constants.CONDITION_CONTENT] = {
  id: Constants.CONDITION_CONTENT,
  buttonLabel: 'Personalize',
  onClick: (conditionState, data, setDialogContentState, mutate) => {
    if (!_.isEqual(data, conditionState)) {
      mutate({
        variables: {
          patientPersonalizationCondition: removeTypeName(conditionState),
        },
      }).then((p) => {
        setDialogContentState(Constants.MEASURES_CONTENT);
      });
    } else {
      setDialogContentState(Constants.MEASURES_CONTENT);
    }
  },
  fullWidth: false,
  maxWidth: 'sm',
};

DialogContentConfiguration[Constants.MEASURES_CONTENT] = {
  id: Constants.MEASURES_CONTENT,
  buttonLabel: 'Submit',
  onClick: (errorState, measuresState, alertState, buttonsState) => {
    buttonsState.Events?.SubmitClicked(errorState, measuresState, alertState);
  },
  fullWidth: true,
  maxWidth: 'lg',
};

export { DialogContentConfiguration };
