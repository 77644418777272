import React from 'react';
import { Search, FilterListOutlined } from '@material-ui/icons';
import TranslatedTextField from '../Localization/TranslatedTextField';
import { makeStyles, IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  field: {
    '& div': {
      borderRadius: '20px',
      backgroundColor: 'white',
    },
  },
});

export const SearchBox = (props) => {
  const classes = useStyles();

  return (
    <TranslatedTextField
      className={`${[classes.field, props.className]?.join(' ')}`}
      size="small"
      variant="outlined"
      label={props.label}
      onChange={(e) => props.searchTextChangeHandler(e)}
      InputProps={{
        startAdornment: (
          <IconButton>
            <Search fontSize="small" />
          </IconButton>
        ),
        endAdornment: (
          <IconButton onClick={(e) => props.rAdornmentClick(e)}>
            <FilterListOutlined />
          </IconButton>
        ),
      }}
    />
  );
};
