import { Chart, Title, Tooltip, Legend } from 'chart.js';
import React, { useRef, useState, useEffect } from 'react';
import 'chartjs-adapter-moment';
import moment from 'moment';

Chart.plugins.register(Title, Tooltip, Legend);

const getXAxisDataSet = (data) => {
  const obj = {};
  if (data.length === 0) return obj;
  const recentReportDate = data[data.length - 1];

  for (let i = 0; i < 12; i++) {
    obj[i] = new Date(
      recentReportDate.date.getFullYear(),
      recentReportDate.date.getMonth() - i,
      1
    );
  }

  return obj;
};

function DsaChart({ dsaData }) {
  const [data] = useState(dsaData || []);
  const [chartOptions, setCharOptions] = useState(null);
  const canvasRef = useRef(null);
  useEffect(() => {
    const chartRef = canvasRef.current;
    let dsaChart = null;
    if (chartRef && chartOptions) {
      Chart.defaults.global.defaultFontFamily = `"Trade Gothic LT Std", "sans-serif", "Roboto", "Helvetica", "Arial"`;
      const myChartRef = chartRef.getContext('2d');
      dsaChart = new Chart(myChartRef, chartOptions);
    }
    return () => {
      dsaChart?.destroy();
    };
  }, [chartOptions]);
  useEffect(() => {
    const dataSet = [];
    const xLabels = getXAxisDataSet(data);
    dataSet.push(...data);

    setCharOptions({
      type: 'line',
      data: {
        datasets: [
          {
            data: dataSet.map((d) => ({
              y: d.score,
              x: d.date,
            })),
            backgroundColor: 'transparent',
            borderColor: '#1E38A0',
            hoverOffset: 4,
            tension: 0,
          },
        ],
        labels: xLabels,
      },
      fill: false,
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: () => {},
        plugins: {
          legend: {
            display: false,
            text: (ctx) =>
              'Point Style: ' + ctx.chart.data.datasets[0].pointStyle,
            labels: {},
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const val = ` ${moment(context.raw.receivedDate).format(
                  'MM/DD/YYYY'
                )}, ${context.raw.score}, ${context.raw.monthsPostTx} `;
                return val;
              },
              labelColor: function (context) {
                return {
                  borderWidth: 2,
                  borderDash: [2, 2],
                  borderRadius: 2,
                };
              },
            },
          },
        },
        scales: {
          yAxes: [
            {
              id: 'y',
              position: 'left',
              color: 'black',
              gridLines: {
                borderColor: 'black',
                drawOnChartArea: false,
                color: 'rgb(106,106,106)',
                drawTicks: false,
              },
              scaleLabel: {
                padding: 2,
                fontColor: '#8997CD',
                fontWeight: 'regular',
                fontSize: 14,
                display: true,
                labelString: 'DSA MFI',
                fontFamily: 'Calibri',
              },
              ticks: {
                beginAtZero: true,
                align: 'center',
                callback: function (label) {
                  return label;
                },
                padding: 5,
                min: 0,
                max: 30000,
                stepSize: 5000,
              },
            },
            {
              gridLines: {
                drawOnChartArea: false,
                drawTicks: false,
                borderColor: 'black',
              },
              ticks: {
                display: false,
                align: 'center',
                callback: function (label) {
                  return label;
                },
              },
              position: 'right',
            },
          ],
          xAxes: [
            {
              type: 'time',
              id: 'x',
              color: 'black',
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderColor: 'black',
              },
              time: {
                unit: 'month',
                displayFormats: {
                  month: 'MMM',
                },
                min: xLabels[0],
                max: xLabels[xLabels.length - 1],
              },
              labels: xLabels,
              ticks: {
                beginAtZero: true,
                align: 'center',
                minRotation: 30,
              },
              position: 'bottom',
            },
          ],
        },
      },
    });
  }, [data]);

  return (
    <div className="chartjs-chartContainer">
      <canvas
        ref={canvasRef}
        height={190}
        aria-label="DSA Report"
        role="img"
      ></canvas>
    </div>
  );
}

export default DsaChart;
