import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Typography,
  Toolbar,
  Container,
} from '@material-ui/core';
import DemographicsHeader from '../../Display/DemographicsHeader';
import Immunosuppression from './Chart/Immunosuppression';
import PatientLayout from '../../Layout/PatientLayout';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import ErrorPage from '../Error/ErrorPage';
import PrescribedISXReport from './PrescribedISXReport';
import ISxTakenReport from './ISxTaken';
import ImmunosuppresionTitle from './ImmunosuppresionTitle';
import MeasurementSelection from './MeasurementSelection';
import { useParams } from 'react-router-dom';
import ThickDivider from '../Widgets/IBox/Controls/ThickDivider';
import WithImmunosuppressionData from 'Components/GraphQL/WithImmunosuppresionData';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
  container: {
    width: '100%',
  },
  tableTitle: {
    fontWeight: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '8px',
    left: 11,
    right: 0,
    textAlign: 'left',
  },
  grayBackGround: {
    background: '#E8E8E8',
  },
}));

function ImmunosuppressionPage(props) {
  const [rangeFrequency, setRangeFrequency] = useState({
    range: 7,
    frequency: 1,
  });
  const { id } = useParams();
  function handleRangeFrequencyChange(e) {
    const value = e.target.value;
    const obj = {
      range: value.split(':')[0],
      frequency: value.split(':')[1],
    };
    setRangeFrequency(obj);
  }

  const classes = useStyles();

  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
      includeAllocare={true}
      days={7}
      averageSliceSize={1}
    >
      {function (data) {
        return (
          <PatientLayout
            header={
              <DemographicsHeader id={id} data={data} showTimeLine={true} />
            }
            subHeader={<ImmunosuppresionTitle />}
            rightControls={
              <MeasurementSelection
                rangeFrequency={`${rangeFrequency.range}:${rangeFrequency.frequency}`}
                onRangeFrequencyChange={handleRangeFrequencyChange}
              />
            }
          >
            <Container maxWidth="xl">
              <WithImmunosuppressionData
                id={id}
                days={rangeFrequency.range}
                averageSliceSize={rangeFrequency.frequency}
                includeAllocare={true}
                includeEhr={true}
              >
                {(data) => (
                  <Box
                    style={{
                      border: '2px solid darkgray',
                      marginBottom: '20px',
                    }}
                  >
                    <Box>
                      <Toolbar className={classes.toolbar}>
                        <Typography
                          className={classes.tableTitle}
                          style={{ width: '100%' }}
                          variant="subtitle1"
                        >
                          {`Labs & Measurements`}
                        </Typography>
                      </Toolbar>
                      <Immunosuppression
                        averages={{
                          ...data.patient.links.alloCare.averages,
                          tacrolevel:
                            data.patient.links.ehr.averages?.tacrolevel || {},
                          sirolevel:
                            data.patient.links.ehr.averages?.sirolevel || {},
                          cyclolevel:
                            data.patient.links.ehr.averages?.cyclolevel || {},
                        }}
                        range={rangeFrequency.range}
                        frequency={rangeFrequency.frequency}
                      />
                    </Box>

                    <Box className={classes.container}>
                      <PrescribedISXReport
                        medications={
                          data.patient.links.ehr.fhir?.medications || []
                        }
                      ></PrescribedISXReport>
                    </Box>
                    <Box>
                      <ThickDivider></ThickDivider>
                    </Box>
                    <Box>
                      <ISxTakenReport
                        medications={
                          data.patient.links.alloCare?.isxtaken || []
                        }
                      ></ISxTakenReport>
                    </Box>
                  </Box>
                )}
              </WithImmunosuppressionData>
            </Container>
          </PatientLayout>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(ImmunosuppressionPage, ErrorPage);
