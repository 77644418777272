import React, { useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import WithAlloCareConditionsData from '../../../GraphQL/WithAlloCareConditionsData';
import AlloCareCheckConditionsDialog from './AlloCareCheckConditionsDialog';
import { PersonalizationContextProvider } from '../PersonalizationBaseline/state/PersonalizationContextProvider';

export default function ConditionsData({ id, iBoxDetails }) {
  const [openConditionDialog, setOpenConditionDialog] = useState(false);
  const handleDialogState = (status) => {
    setOpenConditionDialog(status);
  };

  return (
    <WithAlloCareConditionsData id={id}>
      {(dataConditions, refetch) => {
        return (
          <Grid>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                refetch();
                handleDialogState(true);
              }}
            >
              Personalize
            </Button>
            <PersonalizationContextProvider>
              <AlloCareCheckConditionsDialog
                conditionDialogStatus={openConditionDialog}
                handleConditionDialogState={handleDialogState}
                data={
                  dataConditions.patient.links.alloCare
                    .patientPersonalizationCondition
                }
                id={id}
                contentToDisplay={'Conditions'}
                iBoxDetails={iBoxDetails}
              />
            </PersonalizationContextProvider>
          </Grid>
        );
      }}
    </WithAlloCareConditionsData>
  );
}
