import { gql, useMutation } from '@apollo/client';

export const UPDATE_USERWIDGETS_DISPLAY_ORDER = gql`
  mutation($userWidgetList: [UserWidgetInputType!]) {
    updateUserWidgetsDisplayOrder(userWidgets: $userWidgetList) {
      widgetId
      displayOrder
      description
      title
      identifier
      imageName
    }
  }
`;

export function useUserWidgetMutation() {
  const [mutate, { data, ...rest }] = useMutation(
    UPDATE_USERWIDGETS_DISPLAY_ORDER
  );
  return {
    mutate,
    data,
    rest,
  };
}
