import { createStyles, makeStyles, Box, Paper } from '@material-ui/core';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useRouteMatch,
  useParams,
} from 'react-router-dom';
import PatientProfileDetails from './PatientProfileDetails';
import PatientProfileSidePanel from './PatientProfileSidePanel';
import { gql } from '@apollo/client';
import WithQueryResults from 'Components/GraphQL/WithQueryResults';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    putRightUnderNavBar: {
      height: '100vh',
      display: 'flex',
      flexGrow: 1,
      maxHeight: `calc(100vh - 100px)`,
      borderBottom: '1px solid lightgray',
      borderTopLeftRadius: '0px',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

export const GET_STAR_PATIENT_DETAILS = gql`
  query starPatient($id: String!) {
    patient(id: $id) {
      star {
        id
        demographicInfo {
          patientName
          mrn
          dateOfBirth
          gender
          race
          language
          allergies
          abo
          maritalStatus
          currentBMI
          functionalStatus
          language
          education
          zip
          state
          insurance
        }
        widgetCurrentCondition {
          bMI
          iSx
          cmvStatus
          bkStatus
          rejection
        }
        widgetTransplantHistory {
          hlaMatch
          livingDonorType
          kdpi
          primaryDiagnosisAtTransplant
          previousTransplant
        }
        currentCondition {
          title
          icon
          fields {
            display
            value
            text
          }
        }
        transplantHistory {
          currentTransplant {
            txType
            txDate
            recipientCenter
            followupCenter
            primaryPhysician
            hLAMatch
            dSATx
            pRATx
            induction
            creatinineDischarge
          }
          donorDonorOrgan {
            organType
            donorType
            donorAge
            livingDonorCreatinine
            kDPI
            coldIschemicTime
            deceasedDonorCreatinine
            deceasedDonorCauseOfDeath
            cMVStatus
            eBVStatus
            hepCStatus
            increasedRisk
          }
          pastMedicalHistory {
            previousTransplants
            multiOrganTransplant
            primaryDiagnosisTx
            dateOfFirstDialysis
            yearsOnDialysis
            preganacy
            historyOfCancer
            otherMedicalHistory
          }
        }
      }
    }
  }
`;
const variables = {};
const basePath = '/patient/:id/details';

export default function PatientProfileDetailsContainer() {
  const { id } = useParams();
  variables.id = id;
  const classes = useStyles();
  const routeMatch = useRouteMatch(basePath);
  return (
    <>
      <WithQueryResults QUERY={GET_STAR_PATIENT_DETAILS} variables={variables}>
        {(data) => {
          return (
            <div className={classes.root}>
              <Switch>
                <Redirect
                  exact
                  from={`${basePath}`}
                  to={`${basePath}/currentcondition`}
                />
                <Route
                  exact
                  path={`${basePath}/:page?`}
                  render={(props) => (
                    <>
                      <Box
                        flexDirection="row"
                        className={classes.putRightUnderNavBar}
                        component={Paper}
                        boxShadow={0}
                      >
                        <Box ml={2} m={1}>
                          <PatientProfileSidePanel
                            starPatient={data}
                          ></PatientProfileSidePanel>
                        </Box>
                        <Box mr={2} m={1} flexGrow={1}>
                          <PatientProfileDetails
                            {...props}
                            initialLocation={routeMatch?.url}
                            starPatient={data}
                          ></PatientProfileDetails>
                        </Box>
                      </Box>
                    </>
                  )}
                />
              </Switch>
            </div>
          );
        }}
      </WithQueryResults>
    </>
  );
}
