import React from 'react';
import { SaveOutlined } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { TranslatedIconButton as IconButton } from '../Localization';

export default function ButtonSave({ onSaveClick, saving }) {
  return (
    <IconButton onClick={onSaveClick}>
      {saving ? <CircularProgress size={25} /> : <SaveOutlined />}
      Save
    </IconButton>
  );
}
