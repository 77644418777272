import { Grid } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useContext } from 'react';
import ThickDivider from '../Controls/ThickDivider';
import { IBoxContext } from '../state/IBoxContextProvider';
import Constants from '../Utilities/constants';
import * as moment from 'moment';
import { convertISODateToLocal } from 'Utilities/dateHelpers';

function NewPatientCalculation({ validators }) {
  const { dispatchPat, patientState, errorState } = useContext(IBoxContext);
  return (
    <>
      {patientState?.isNewPatient && (
        <>
          <Grid item>
            <Grid container spacing={3}>
              <Grid container item direction="row">
                <Grid item xs={6}>
                  <DatePicker
                    required
                    format="MM/DD/YYYY"
                    margin="normal"
                    label="Transplant Date"
                    name={Constants.TRANSPLANT_DATE}
                    minDate={
                      patientState[Constants.BIRTH_DATE]
                        ? moment(patientState[Constants.BIRTH_DATE]).add(
                            1,
                            'day'
                          )
                        : undefined
                    }
                    value={patientState[Constants.TRANSPLANT_DATE] || ''}
                    error={!!errorState[Constants.TRANSPLANT_DATE]}
                    onChange={(e) => {
                      dispatchPat({
                        type: Constants.TRANSPLANT_DATE,
                        payload: e?.$d,
                      });
                      validators.rules[Constants.TRANSPLANT_DATE](e?.$d);
                    }}
                    helperText={
                      !!errorState[Constants.TRANSPLANT_DATE] &&
                      errorState[Constants.TRANSPLANT_DATE]
                    }
                    invalidLabel={''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    required
                    format="MM/DD/YYYY"
                    margin="normal"
                    label="Date of Birth"
                    name={Constants.BIRTH_DATE}
                    maxDate={moment().subtract(18, 'years').subtract(1, 'day')}
                    value={convertISODateToLocal(
                      patientState[Constants.BIRTH_DATE]
                    )}
                    error={!!errorState[Constants.BIRTH_DATE]}
                    onChange={(e) => {
                      dispatchPat({
                        type: Constants.BIRTH_DATE,
                        payload: e?.$d,
                      });
                      validators.rules[Constants.BIRTH_DATE](e?.$d);
                    }}
                    helperText={
                      !!errorState[Constants.BIRTH_DATE] &&
                      errorState[Constants.BIRTH_DATE]
                    }
                    invalidLabel={''}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ThickDivider></ThickDivider>
          </Grid>
        </>
      )}
    </>
  );
}

export default NewPatientCalculation;
