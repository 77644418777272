import {
  Grid,
  Checkbox,
  FormControlLabel,
  Radio,
  FormGroup,
  FormControl,
  FormHelperText,
  Typography,
  Box,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useContext } from 'react';
import { convertISODateToLocal } from 'Utilities/dateHelpers';
import IBoxSelect from '../Controls/IBoxSelect';
import { IBoxContext } from '../state/IBoxContextProvider';
import Constants from '../Utilities/constants';

function Histology({ validators }) {
  const { dispatch, state, errorState, patientState } = useContext(IBoxContext);

  const onHistoLogyStatusChange = (e) => {
    dispatch({
      type: 'Histology',
      payload:
        e.target.value === 'banff_available'
          ? {
              [Constants.DIAGNOSIS_ABMR]: null,
              [Constants.DIAGNOSIS_AKI]: null,
              [Constants.DIAGNOSIS_TCMR]: null,
              [Constants.DIAGNOSIS_RECURRENCE]: null,
              [Constants.DIAGNOSIS_BK]: null,
              [Constants.DIAGNOSIS_CNI]: null,
              [Constants.DIAGNOSIS_OTHER]: null,
              [Constants.HISTOLOGY_STATUS]: e.target.value,
            }
          : {
              [Constants.G_SCORE]: null,
              [Constants.PTC_SCORE]: null,
              [Constants.I_SCORE]: null,
              [Constants.T_SCORE]: null,
              [Constants.CG_SCORE]: null,
              [Constants.IFTA_SCORE]: null,
              [Constants.DIAGNOSIS_AKI]: null,
              [Constants.HISTOLOGY_STATUS]: e.target.value,
            },
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item container direction="row" justify="space-between">
        <Typography component="div">
          <Box fontWeight="fontWeightBold">Histology</Box>
        </Typography>
        <Checkbox
          checked={state?.histologyStatus !== 'not_available'}
          inputProps={{ 'aria-label': 'Checkbox A' }}
          onChange={(e) => {
            dispatch({
              type: Constants.HISTOLOGY_STATUS,
              payload: !e.target.checked ? 'not_available' : 'banff_available',
            });
          }}
        />
      </Grid>
      {state?.histologyStatus !== 'not_available' && (
        <>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <DatePicker
                name={Constants.DATE_OF_HISTOLOGY_TAKEN}
                format="MM/DD/YYYY"
                margin="normal"
                required
                label="Date Histology Taken"
                minDate={patientState[Constants.TRANSPLANT_DATE] || undefined}
                value={convertISODateToLocal(state?.dateOfHistologyTaken)}
                error={!!errorState[Constants.DATE_OF_HISTOLOGY_TAKEN]}
                onChange={(e) => {
                  dispatch({
                    type: Constants.DATE_OF_HISTOLOGY_TAKEN,
                    payload: e?.$d,
                  });
                  validators.rules[Constants.DATE_OF_HISTOLOGY_TAKEN](e?.$d);
                }}
                helperText={
                  !!errorState[Constants.DATE_OF_HISTOLOGY_TAKEN] &&
                  errorState[Constants.DATE_OF_HISTOLOGY_TAKEN]
                }
                invalidLabel={''}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <FormControlLabel
                value="banff_available"
                name={Constants.HISTOLOGY_STATUS}
                checked={state?.histologyStatus === 'banff_available'}
                onChange={onHistoLogyStatusChange}
                control={<Radio />}
                label="Banff Available"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="diagnosis_available"
                name={Constants.HISTOLOGY_STATUS}
                checked={state?.histologyStatus === 'diagnosis_available'}
                onChange={onHistoLogyStatusChange}
                control={<Radio />}
                label="Diagnosis Available"
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              {state?.histologyStatus === 'banff_available' && (
                <FormGroup>
                  <Grid container direction="column" spacing={3}>
                    <Grid item container>
                      <IBoxSelect
                        id="glomerulitis"
                        validators={validators}
                        name={Constants.G_SCORE}
                        label="Glomerulitis"
                      ></IBoxSelect>
                    </Grid>
                    <Grid item>
                      <IBoxSelect
                        id="peritubular-capillaritis"
                        name={Constants.PTC_SCORE}
                        label="Peritubular Capillaritis"
                        validators={validators}
                      ></IBoxSelect>
                    </Grid>
                    <Grid item>
                      <IBoxSelect
                        id="intersitial-inflammation"
                        name={Constants.I_SCORE}
                        label="Interstitial Inflammation"
                        validators={validators}
                      ></IBoxSelect>
                    </Grid>
                    <Grid item>
                      <IBoxSelect
                        id="tubulitis"
                        name={Constants.T_SCORE}
                        validators={validators}
                        label="Tubulitis"
                      ></IBoxSelect>
                    </Grid>
                    <Grid item>
                      <IBoxSelect
                        id="transplant-glomerulopathy"
                        name={Constants.CG_SCORE}
                        label="Transplant glomerulopathy"
                        validators={validators}
                      ></IBoxSelect>
                    </Grid>
                    <Grid item>
                      <IBoxSelect
                        id="atrophy-fibrosis"
                        name={Constants.IFTA_SCORE}
                        validators={validators}
                        label="Atrophy-fibrosis"
                      ></IBoxSelect>
                    </Grid>
                  </Grid>
                </FormGroup>
              )}
            </Grid>
            <Grid item xs={6}>
              {state?.histologyStatus === 'diagnosis_available' && (
                <FormControl>
                  <FormGroup>
                    <FormHelperText error={!!errorState['diagnosis']}>
                      Must select at least one
                    </FormHelperText>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state[Constants.DIAGNOSIS_ABMR] || false}
                          onChange={(e) => {
                            dispatch({
                              type: Constants.DIAGNOSIS_ABMR,
                              payload: e.target.checked,
                            });
                            validators.rules['diagnosis'](e.target.checked);
                          }}
                        />
                      }
                      label="ABMR"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state[Constants.DIAGNOSIS_TCMR] || false}
                          onChange={(e) => {
                            dispatch({
                              type: Constants.DIAGNOSIS_TCMR,
                              payload: e.target.checked,
                            });
                            validators.rules['diagnosis'](e.target.checked);
                          }}
                        />
                      }
                      label="TCMR"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            state[Constants.DIAGNOSIS_RECURRENCE] || false
                          }
                          onChange={(e) => {
                            dispatch({
                              type: Constants.DIAGNOSIS_RECURRENCE,
                              payload: e.target.checked,
                            });
                            validators.rules['diagnosis'](e.target.checked);
                          }}
                        />
                      }
                      label="Recurrence"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state[Constants.DIAGNOSIS_BK] || false}
                          onChange={(e) => {
                            dispatch({
                              type: Constants.DIAGNOSIS_BK,
                              payload: e.target.checked,
                            });
                            validators.rules['diagnosis'](e.target.checked);
                          }}
                        />
                      }
                      label="BK"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state[Constants.DIAGNOSIS_CNI] || false}
                          onChange={(e) => {
                            dispatch({
                              type: Constants.DIAGNOSIS_CNI,
                              payload: e.target.checked,
                            });
                            validators.rules['diagnosis'](e.target.checked);
                          }}
                        />
                      }
                      label="CNI"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state[Constants.DIAGNOSIS_AKI] || false}
                          onChange={(e) => {
                            dispatch({
                              type: Constants.DIAGNOSIS_AKI,
                              payload: e.target.checked,
                            });
                            validators.rules['diagnosis'](e.target.checked);
                          }}
                        />
                      }
                      label="AKI"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state[Constants.DIAGNOSIS_OTHER] || false}
                          onChange={(e) => {
                            dispatch({
                              type: Constants.DIAGNOSIS_OTHER,
                              payload: e.target.checked,
                            });
                            validators.rules['diagnosis'](e.target.checked);
                          }}
                        />
                      }
                      label="Other"
                    />
                  </FormGroup>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Histology;
