import DemographicsHeader from '../../../Display/DemographicsHeader';
import React, { useState } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import PatientLayout from '../../../Layout/PatientLayout';
import KidneyCareData from './KidneyCareData';
import Search from '../../../Controls/Search';
import ErrorBoundaryHOC from '../../../ErrorBoundaryHOC';
import ErrorPage from '../../Error/ErrorPage';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  titleKidneyCare: {
    color: '#53565A',
    fontWeight: 600,
  },
}));

function KidneyCareTitle() {
  const classes = useStyles();
  return (
    <Box display="inline" component="div">
      <img src={`/images/KidneyCare_Logo_RGB.png`} alt="" width="120px"></img>
      <Typography variant="subtitle1" className={classes.titleKidneyCare}>
        KidneyCare Report
      </Typography>
    </Box>
  );
}

function KidneyCarePage(props) {
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  function handleSearch(e) {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === '') return items;
        else {
          return items.filter(
            (x) =>
              x.details?.dateReported?.toLowerCase().includes(target.value) ||
              x.details?.accessionId?.toLowerCase().includes(target.value) ||
              x.details?.prescriber?.toLowerCase().includes(target.value) ||
              x.details?._Client?.toLowerCase().includes(target.value) ||
              x.details?.dateCollected?.toLowerCase().includes(target.value) ||
              x.details?.amScore?.toLowerCase().includes(target.value) ||
              x.details?.asScore?.toLowerCase().includes(target.value) ||
              x.details?.amReportDate?.toLowerCase().includes(target.value) ||
              x.parameters?.Creatinine?.toLowerCase().includes(target.value) ||
              x.parameters?.dateOfProteinuriaTaken
                ?.toLowerCase()
                .includes(target.value) ||
              x.parameters?.proteinuriaDipstick
                ?.toLowerCase()
                .includes(target.value) ||
              x.parameters?.proteinuriaInGG
                ?.toString()
                .toLowerCase()
                .includes(target.value)
          );
        }
      },
    });
  }
  const params = useParams();
  const id = params['id'];
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
    >
      {function (data) {
        return (
          <PatientLayout
            header={<DemographicsHeader id={id} data={data} />}
            subHeader={<KidneyCareTitle />}
            rightControls={<Search onSearchChange={handleSearch} />}
          >
            <KidneyCareData filterFn={filterFn} />
          </PatientLayout>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(KidneyCarePage, ErrorPage);
