function TransformParameterState(data) {
  const patientData = data?.fhir;
  const alloSureData = data?.alloSure;
  const alloViewData = data?.alloView;
  const ehrLabsData = data?.links?.ehr?.labs;
  const parameterState = {
    egfrNotAvailable: {},
    hasEgfr: 'has_egfr',
    alloSureMetadata: {
      displayName: 'AlloSure',
      date: alloSureData?.score?.date,
      miscLabId: 999999,
    },
    egfrMetadata: {
      displayName: 'GFR',
      date: ehrLabsData?.creatinine?.date,
      miscLabId: 2559740,
    },
    creatinineMetadata: {
      displayName: 'Creat',
      date: ehrLabsData?.creatinine?.date,
      miscLabId: 4866647,
    },
    dsaMfiMetadata: {
      displayName: 'DSA_MFI_TESTING',
      date: ehrLabsData?.dsaMfi?.date,
      miscLabId: 4870202,
    },
    dsaBinaryMetadata: {
      displayName: 'DSA Binary',
      date: '2021-11-13T18:27:00.000Z',
      miscLabId: null,
    },
    previousParameters: {
      patientId: alloViewData?.previousParameters?.patientId,
      riskEvaluationDate: alloViewData?.previousParameters?.riskEvaluationDate,
      alloSureScore: alloViewData?.previousParameters?.alloSureScore,
      creatinine: alloViewData?.previousParameters?.creatinine,
      egfr: alloViewData?.previousParameters?.egfr,
      dsaStatus: 'mfi',
      dsaMfi: alloViewData?.previousParameters?.dsaMfi,
      dsaBinary: '',
      transplantDate: alloViewData?.previousParameters?.transplantDate,
      previousRejection: alloViewData?.previousParameters?.previousRejection,
      rejectionDiagnosisType:
        alloViewData?.previousParameters?.rejectionDiagnosisType,
      rejectionDiagnosisDate:
        alloViewData?.previousParameters?.rejectionDiagnosisDate,
      hasPreviousTransplant:
        alloViewData?.previousParameters?.hasPreviousTransplant,
      bkViremia: alloViewData?.previousParameters?.bkViremia,
      age: getAge(patientData?.birthDate),
    },
    alloSureScore: alloSureData?.score?.value,
    dsaDate: null,
    //patientId: labData?.patientId,
    riskEvaluationDate: new Date(),
    egfr: calculateEgfr(ehrLabsData, patientData),
    dsaStatus: 'mfi',
    dsaMfi: ehrLabsData?.dsaMfi?.value,
    age: getAge(patientData?.birthDate),
    dsaBinary: '',
    creatinine: ehrLabsData?.creatinine?.value,
    transplantDate: alloSureData?.transplantDetail?.transplantDate,
    previousRejection: alloSureData?.transplantDetail?.previousRejection,
    rejectionDiagnosisType:
      alloSureData?.transplantDetail?.rejectionDiagnosisType,
    rejectionDiagnosisDate:
      alloSureData?.transplantDetail?.rejectionDiagnosisDate,
    hasPreviousTransplant:
      alloSureData?.transplantDetail?.hasPreviousTransplant,
    bkViremia: ehrLabsData?.bkVirus?.value,
  };
  return parameterState;
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function calculateEgfr(labData, patientData) {
  if (patientData?.birthDate === null) {
    return 0;
  }
  console.log('birthdate', patientData?.birthDate);
  var age = getAge(patientData.birthDate);
  let calculation = 0;

  if (age && labData?.creatinine?.value) {
    calculation =
      175 *
      Math.pow(labData?.creatinine?.value, -1.154) *
      Math.pow(age, -0.203);
  }
  calculation =
    patientData?.gender === 'female' ? calculation * 0.742 : calculation;

  return Math.trunc(calculation);
}

export default TransformParameterState;
