import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';
export const GET_EVENTTIMELINE_DATA = gql`
  query eventTimelineResult($id: String!, $days: Int!) {
    patient(id: $id) {
      links {
        ehr {
          ... on EhrType {
            transplantMeds(days: $days) {
              mycophenolateMofetil {
                ...TransplantMeds
              }
              prednisone {
                ...TransplantMeds
              }
              sirolimus {
                ...TransplantMeds
              }
              tacrolimus {
                ...TransplantMeds
              }
              bactrim {
                ...TransplantMeds
              }
              valganciclovirl {
                ...TransplantMeds
              }
              rapamune {
                ...TransplantMeds
              }
            }
          }
        }
      }
    }
  }
  fragment TransplantMeds on MedicationPrescribedType {
    medicationDisplay
    rxDate
    unit
    value
    instructions
  }
`;
export default function WithEventTimelineData({ id, days, children }) {
  return (
    <WithQueryResults QUERY={GET_EVENTTIMELINE_DATA} variables={{ id, days }}>
      {children}
    </WithQueryResults>
  );
}
