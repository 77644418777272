import React from 'react';
import { gql, useMutation } from '@apollo/client';
import WithQueryResults from './WithQueryResults';

const GET_PATIENT_NOTES = gql`
  query notesQuery($patientId: String!) {
    notes(patientId: $patientId) {
      id
      patientId
      userId
      userFullName
      note
      createdAt
    }
  }
`;

const ADD_PATIENT_NOTE = gql`
  mutation notesQuery(
    $patientId: String!
    $note: String!
    $createdAt: DateTimeOffset
  ) {
    addPatientNote(
      note: { patientId: $patientId, note: $note, createdAt: $createdAt }
    ) {
      id
      patientId
      userId
      userFullName
      note
      createdAt
    }
  }
`;

const DELETE_PATIENT_NOTE = gql`
  mutation notesQuery($patientId: String!, $id: String!) {
    deletePatientNote(patientId: $patientId, id: $id) {
      id
      patientId
    }
  }
`;

const DELETE_ALL_PATIENT_NOTES = gql`
  mutation notesQuery($patientId: String!) {
    deleteAllPatientNotes(patientId: $patientId) {
      id
      patientId
    }
  }
`;

function WithPatientNotesMutation({ children, patientId }) {
  const [addNote] = useMutation(ADD_PATIENT_NOTE, {
    update(cache, { data: { addPatientNote } }) {
      cache.modify({
        fields: {
          list(patients = []) {
            const listFragment = cache.readFragment({
              id: `ListRowType:${addPatientNote.patientId}`,
              fragment: gql`
                fragment ListFragment on ListRowType {
                  notes
                }
              `,
            });
            const noteCount = listFragment.notes;
            cache.writeFragment({
              id: `ListRowType:${addPatientNote.patientId}`,
              fragment: gql`
                fragment ListFragment on ListRowType {
                  notes
                }
              `,
              data: {
                notes: noteCount + 1,
              },
            });
            return [...patients];
          },
          notes(existingNotes = []) {
            const newNoteRef = cache.writeQuery({
              data: {
                ...addPatientNote,
              },
              query: gql`
                query notesQuery($patientId: String!) {
                  notes(patientId: $patientId) {
                    id
                    patientId
                    userId
                    userFullName
                    note
                    createdAt
                  }
                }
              `,
              variables: {
                patientId: addPatientNote.patientId,
              },
            });
            return [...existingNotes, newNoteRef];
          },
        },
      });
    },
  });

  const [deleteNote] = useMutation(DELETE_PATIENT_NOTE, {
    update(cache, { data: { deletePatientNote } }) {
      cache.modify({
        fields: {
          list(patients = []) {
            const listFragment = cache.readFragment({
              id: `ListRowType:${deletePatientNote.patientId}`,
              fragment: gql`
                fragment ListFragment on ListRowType {
                  notes
                }
              `,
            });
            const noteCount = listFragment.notes;
            cache.writeFragment({
              id: `ListRowType:${deletePatientNote.patientId}`,
              fragment: gql`
                fragment ListFragment on ListRowType {
                  notes
                }
              `,
              data: {
                notes: noteCount - 1,
              },
            });
            return [...patients];
          },
          notes(existingNotes = []) {
            const deletedNoteRef = cache.writeFragment({
              data: deletePatientNote,
              fragment: gql`
                fragment DeletedNote on PatientNoteType {
                  id
                  patientId
                }
              `,
            });
            const prunedNotes = existingNotes.filter(
              (r) => r.__ref !== deletedNoteRef.__ref
            );
            return prunedNotes;
          },
        },
      });
    },
  });

  const [deleteAllNotes] = useMutation(DELETE_ALL_PATIENT_NOTES, {
    update(cache, { data: { deleteAllPatientNotes } }) {
      cache.modify({
        fields: {
          list(patients = []) {
            if (deleteAllPatientNotes.length) {
              const deletedNote = deleteAllPatientNotes[0];
              cache.writeFragment({
                id: `ListRowType:${deletedNote.patientId}`,
                fragment: gql`
                  fragment ListFragment on ListRowType {
                    notes
                  }
                `,
                data: {
                  notes: 0,
                },
              });
            }
            return [...patients];
          },
          notes(existingNotes = []) {
            const deletedRefs = [];
            for (let d = 0; d < deleteAllPatientNotes.length; d++) {
              const deleted = deleteAllPatientNotes[d];
              const deletedRef = cache.writeFragment({
                data: deleted,
                fragment: gql`
                  fragment DeletedNote on PatientNoteType {
                    id
                    patientId
                  }
                `,
              });
              deletedRefs.push(deletedRef);
            }
            const prunedNotes = existingNotes.filter(
              (r) => !deletedRefs.some((dr) => dr.__ref === r.__ref)
            );
            return prunedNotes;
          },
        },
      });
    },
  });

  const handleAddNote = (note) => {
    addNote({
      variables: {
        patientId,
        note,
        createdAt: new Date(),
      },
    });
  };

  const handleDeleteClick = (id, patientId) => {
    deleteNote({
      variables: {
        id,
        patientId,
      },
    });
  };

  const handleDeleteAllNotes = () => {
    deleteAllNotes({
      variables: {
        patientId,
      },
    });
  };

  const augmentedChild = children.bind(
    null,
    handleAddNote,
    handleDeleteClick,
    handleDeleteAllNotes
  );
  const childContent = augmentedChild();
  return childContent;
}

export default function WithPatientNotesData({ children, patientId }) {
  return (
    <WithPatientNotesMutation patientId={patientId}>
      {function (handleAddNote, handleDeleteClick, handleDeleteAllNotes) {
        return (
          <WithQueryResults QUERY={GET_PATIENT_NOTES} variables={{ patientId }}>
            {function (data) {
              const newChildProps = {
                ...children.props,
                data,
                handleAddNote,
                handleDeleteClick,
                handleDeleteAllNotes,
              };
              const augmentedChildren = React.cloneElement(
                children,
                newChildProps
              );

              return augmentedChildren;
            }}
          </WithQueryResults>
        );
      }}
    </WithPatientNotesMutation>
  );
}
