import React from 'react';
import WithIBoxQueryWithLinks from '../../../GraphQL/WithIBoxQueryWithLinks';
import ErrorBoundaryHOC from '../../../ErrorBoundaryHOC';
import ErrorPage from '../../Error/ErrorPage';
import { useParams } from 'react-router-dom';
import IBoxCalculatorContainer from './IBoxCalculatorContainer';
import { formatIBoxData } from './Utilities/Data';
import { IBoxContextProvider } from './state/IBoxContextProvider';

export function IBoxDataPage() {
  const { id } = useParams();
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
    >
      {(data) => {
        console.log('data>>', data);
        const iboxResponse = data.patient.links.ibox;
        if (iboxResponse.iBoxRecords && iboxResponse.iBoxPatient) {
          return (
            <IBoxContextProvider
              parameterState={
                iboxResponse.iBoxRecords
                  ? iboxResponse.iBoxRecords[0]?.parameters
                  : {}
              }
              patientState={iboxResponse.iBoxPatient}
              fhirPatientState={data.patient.fhir}
            >
              <IBoxCalculatorContainer
                formattedReportData={formatIBoxData(
                  iboxResponse.iBoxRecords,
                  iboxResponse.iBoxPatient
                )}
                record={iboxResponse.iBoxRecords[0]}
                fhirPatient={data.patient.fhir}
              ></IBoxCalculatorContainer>
            </IBoxContextProvider>
          );
        }
        return (
          <IBoxContextProvider fhirPatientState={data.patient.fhir}>
            <IBoxCalculatorContainer
              iboxData={null}
              fhirPatient={data.patient.fhir}
            />
          </IBoxContextProvider>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(IBoxDataPage, ErrorPage);
