import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Grid, Box } from '@material-ui/core';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
import { useParams } from 'react-router-dom';
import AlloSureViolinChart from './AlloSureViolinChart';
import './AlloSureViolinChartWidget.css';

function AlloSureViolinChartWidget({ widgetId, data }) {
  let widgetData = data?.patient?.links?.alloCare;

  const { dispatch } = useContext(SettingsContext);
  const { id } = useParams();
  if (widgetData.__typename === 'ResolverErrorType') {
    widgetData = null;
  }

  const history = useHistory();
  useEffect(() => {
    const onClick = () => {
      history.push(`/patient/${id}/alloSureViolinChartPage`);
    };
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: onClick,
      },
    });
  }, [dispatch, history, id, widgetId]);

  return (
    <Grid className="alloSureViolinChartWidgetContainer">
      <Box>
        <AlloSureViolinChart width={'650'} height={'320'} alloSureData={data} />
      </Box>
    </Grid>
  );
}

export default AlloSureViolinChartWidget;
