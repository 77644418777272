function TransformTrnasplantMeds(data) {
  const mycophenolateMofetil = {
    unit: data?.mycophenolateMofetil[0]?.unit,
    averageWindowDays: 1,
    days: 1,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: data?.mycophenolateMofetil[0]?.rxDate,
            end: data?.mycophenolateMofetil[0]?.rxDate,
            value: data?.mycophenolateMofetil[0]?.value,
            subCount: 0,
          },
        ],
      },
    ],
  };

  const tacrolimus = {
    unit: data?.tacrolimus[0]?.unit,
    averageWindowDays: 1,
    days: 1,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: data?.tacrolimus[0]?.rxDate,
            end: data?.tacrolimus[0]?.rxDate,
            value: data?.tacrolimus[0]?.value,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const prednisone = {
    unit: data?.prednisone[0]?.unit,
    averageWindowDays: 1,
    days: 1,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: data?.prednisone[0]?.rxDate,
            end: data?.prednisone[0]?.rxDate,
            value: data?.prednisone[0]?.value,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const rapamune = {
    unit: data?.rapamune[0]?.unit,
    averageWindowDays: 1,
    days: 1,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: data?.rapamune[0]?.rxDate,
            end: data?.rapamune[0]?.rxDate,
            value: data?.rapamune[0]?.value,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const valganciclovirl = {
    unit: data?.valganciclovirl[0]?.unit,
    averageWindowDays: 1,
    days: 1,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: data?.valganciclovirl[0]?.rxDate,
            end: data?.valganciclovirl[0]?.rxDate,
            value: data?.valganciclovirl[0]?.value,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const sirolimus = {
    unit: data?.sirolimus[0]?.unit,
    averageWindowDays: 1,
    days: 1,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: data?.sirolimus[0]?.rxDate,
            end: data?.sirolimus[0]?.rxDate,
            value: data?.sirolimus[0]?.value,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const bactrim = {
    unit: data?.bactrim[0]?.unit,
    averageWindowDays: 1,
    days: 1,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: data?.bactrim[0]?.rxDate,
            end: data?.bactrim[0]?.rxDate,
            value: data?.bactrim[0]?.value,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const alloMap = {
    unit: 'L',
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
    ],
  };

  const alloSure = {
    unit: 'L',
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
    ],
  };

  // sub group ends

  const inpatientAdmission = {
    unit: 'L',
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const erVisit = {
    unit: 'mg/dl',
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const biopsy = {
    unit: 'mmHg',
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 7,
    series: [
      {
        name: 'Systolic',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
      {
        name: 'Diastolic',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const newDiagnosis = {
    unit: 'count',
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const transplantMeds = {
    unit: null,
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: null,
            end: null,
            value: null,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const careDxTests = {
    unit: 'lbs',
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
    ],
  };
  const newStatus = {
    unit: '%',
    averageWindowDays: 1,
    days: 7,
    average: null,
    count: 0,
    series: [
      {
        name: 'Default',
        elements: [
          {
            start: '2021-09-01T00:00:00+00:00',
            end: '2021-09-01T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-02T00:00:00+00:00',
            end: '2021-09-02T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-03T00:00:00+00:00',
            end: '2021-09-03T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-04T00:00:00+00:00',
            end: '2021-09-04T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-05T00:00:00+00:00',
            end: '2021-09-05T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-06T00:00:00+00:00',
            end: '2021-09-06T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
          {
            start: '2021-09-07T00:00:00+00:00',
            end: '2021-09-07T23:59:59+00:00',
            value: 0.5,
            subCount: 0,
          },
        ],
      },
    ],
  };
  return {
    inpatientAdmission,
    erVisit,
    biopsy,
    newDiagnosis,
    transplantMeds,
    mycophenolateMofetil,
    careDxTests,
    newStatus,
    tacrolimus,
    prednisone,
    rapamune,
    valganciclovirl,
    sirolimus,
    bactrim,
    alloMap,
    alloSure,
  };
}

export default TransformTrnasplantMeds;
