import {
  Box,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import Moment from 'moment';
const drawerWidth = 240;
const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      border: 'none',
      boxShadow: 'none',
      '& > .MuiCardContent-root': {
        textAlign: 'center',
        height: '100%',
        overflow: 'auto',
        '& > .MuiDivider-middle': {
          backgroundColor: theme.palette.common.black,
        },
      },
    },
    bold: {
      fontWeight: 'bold',
    },
    root: {
      minHeight: '100%',
      width: drawerWidth,
    },
    thickBorder: {
      border: `2px solid ${grey[300]}`,
      boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 15%)`,
    },
    tileContainer: {
      display: 'flex',
      justifyContent: 'left',
      color: 'black',
      margin: theme.spacing(1),
      '& > *': {
        fontSize: theme.typography.subtitle2.fontSize,
        textAlign: 'left',
      },
    },

    blackColor: {
      color: theme.palette.common.black,
    },
  })
);

const labelVal = (displayText, displayValue) =>
  ((useStyles) => {
    const classes = useStyles();
    return (
      <Typography variant="subtitle2">
        <div className={classes.tileContainer}>
          <Box display="inline" fontWeight="fontWeightMedium">
            <label style={{ fontWeight: 'bold' }}>{displayText}:</label>
          </Box>
          <Box display="inline" style={{ paddingLeft: '5px' }}>
            {displayValue}
          </Box>
        </div>
      </Typography>
    );
  })(useStyles);

export default function PatientProfileSidePanel(starPatient) {
  const classes = useStyles();
  const demographicInfo =
    starPatient?.starPatient?.patient?.star?.demographicInfo;

  return demographicInfo ? (
    <Paper
      elevation={5}
      className={` ${classes.root} ${classes.thickBorder}`}
      variant="outlined"
    >
      <Card className={classes.card}>
        <CardHeader
          title={
            <div>
              <Typography variant="h5" component={'span'}>
                <Box display="inline" fontWeight="fontWeightMedium" m={1}>
                  <label className={classes.bold}>Patient Profile</label>
                </Box>
              </Typography>
            </div>
          }
          subheader={labelVal('MRN', demographicInfo.mrn)}
        ></CardHeader>

        <CardContent>
          {labelVal('Patient', demographicInfo.patientName)}
          {labelVal(
            'DOB',
            Moment(demographicInfo.dateOfBirth).format('MM/DD/YYYY')
          )}
          {labelVal('Gender', demographicInfo.gender)}
          {labelVal('Race', demographicInfo.race)}
          <Divider variant="middle" />
          {labelVal('ABO', demographicInfo.abo)}
          {labelVal('Current BMI', demographicInfo.currentBMI)}
          {labelVal('Functional Status', demographicInfo.functionalStatus)}
          <Divider variant="middle" />
          {labelVal('Language', demographicInfo.language)}
          {labelVal('Education', demographicInfo.education)}
          {labelVal('Marital Status', demographicInfo.maritalStatus)}
          {labelVal('Zip, State', demographicInfo.zip)}
          {labelVal('Insurance', demographicInfo.insurance)}
        </CardContent>
      </Card>
    </Paper>
  ) : null;
}
