import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Box } from '@material-ui/core';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
import NoEventTimelineData from './NoEventTimelineData';
import { useParams } from 'react-router-dom';
import DemographicsTimeline from '../../../DemographicsTimeline/DemographicsTimeline';

function EventTimelineWidget({ widgetId, data }) {
  const { dispatch } = useContext(SettingsContext);
  const widgetData = data?.patient?.eventTimeline;
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    const onClick = () => {
      history.push(`/patient/${id}/EventTimeline`);
    };
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: onClick,
      },
    });
  }, [dispatch, history, widgetId, id]);

  return widgetData === null ? (
    <NoEventTimelineData />
  ) : (
    <Box
      flex="1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: '75px' }}
    >
      <DemographicsTimeline data={widgetData} />
    </Box>
  );
}

export default EventTimelineWidget;
