import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Card, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    opacity: (props) => (props.isDragging ? 0.3 : 1),
    background: (props) =>
      props.isOver
        ? theme.palette.primary.dark
        : props.canDrop
        ? theme.palette.primary.light
        : 'unset',
  },
}));

export default function OrderableCard({
  name,
  index,
  children,
  classes,
  onCardReorder,
  onCardDrop,
}) {
  const ref = useRef(null);
  const theme = useTheme();
  const [{ isDragging }, drag] = useDrag({
    item: {
      name: name,
      index: index,
      type: 'ORDERABLE_CARD',
    },
    end: (item, monitor) => {},
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ['ORDERABLE_CARD', 'DRAGGABLE_CARD'],
    drop: (item, monitor) => {
      if (item.type === 'ORDERABLE_CARD') {
        onCardReorder(item.name, name);
      } else if (item.type === 'DRAGGABLE_CARD') {
        onCardDrop(item.name, index);
      }
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  });

  const defaultClasses = useStyles({ canDrop, isOver, isDragging });

  const cardClasses = {
    card: defaultClasses.card + ' ' + (classes && classes.card),
  };

  drop(drag(ref));

  return (
    <Card
      ref={ref}
      className={cardClasses.card}
      style={{ background: isOver ? theme.palette.primary.dark : '' }}
    >
      {children}
    </Card>
  );
}
