import * as util from '../../../utility';
import { operationAbbreviation } from '../../../Utilities/tableFiltering';

export default function applyFiltersToConfig(filters, configFilters) {
  const configFilterCopy = JSON.parse(JSON.stringify(configFilters)); // need a deep copy here...
  const props = Object.keys(filters);
  for (let cf = 0; cf < configFilterCopy.length; cf++) {
    const configFilter = configFilterCopy[cf];
    for (let pf = 0; pf < props.length; pf++) {
      const propPath = props[pf];
      const filter = filters[propPath];
      if (filter.operation === 'between') {
        const lePath = `${propPath}.le`;
        const gePath = `${propPath}.ge`;
        // a. Construct any paths we need
        //    only used for side-effects.
        util.objectFromPaths([lePath, gePath], configFilter);
        util.setPath(lePath, filter.start, configFilter);
        util.setPath(gePath, filter.end, configFilter);
      } else {
        const op = operationAbbreviation(filter.operation);
        const opPath = `${propPath}.${op}`;
        // Same as (a) above.
        util.objectFromPaths([opPath], configFilter);
        util.setPath(opPath, filter.value, configFilter);
      }
    }
  }
  return configFilterCopy;
}
