export default function SmartAuthenticationConfig(
  clientId,
  redirectUri,
  scope
) {
  let parameterMessage = (name) => new Error(`Parameter '${name}' is required`);
  if (!clientId) {
    throw parameterMessage('clientId');
  } else if (!redirectUri) {
    throw parameterMessage('redirectUri');
  } else if (!scope) {
    throw parameterMessage('scope');
  }

  this.clientId = clientId;
  this.redirectUri = redirectUri;
  this.scope = scope;
}
