import React, { createContext, useReducer } from 'react';
import { Reducer } from './Reducer';
const widgetState = {
  // hasData: false,
  widgetClicked: () => {},
};

const WidgetsSettings = {
  PatientProfile: widgetState,
  AlloSure: widgetState,
  KidneyCare: widgetState,
  AlloCare: widgetState,
  IBox: widgetState,
  Immunosuppression: widgetState,
  AlloView: widgetState,
};
const DashboardContainer = {
  widgetsState: WidgetsSettings,
  dispatch: (payload) => undefined,
};

const SettingsContext = createContext(DashboardContainer);

const WidgetSettingsProvider = ({ children }) => {
  const [widgetsState, dispatch] = useReducer(Reducer, WidgetsSettings);
  return (
    <SettingsContext.Provider value={{ widgetsState, dispatch }}>
      {children}
    </SettingsContext.Provider>
  );
};

const SettingsConsumer = SettingsContext.Consumer;

export { SettingsConsumer, SettingsContext, WidgetSettingsProvider };
