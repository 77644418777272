import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
import {
  makeStyles,
  Box,
  Grid,
  Container,
  Typography,
  Icon,
} from '@material-ui/core';
import {
  CurrentConditionGreenIcon,
  OrganTransplantIcon,
} from 'icons/IconComponents';

const useStyles = makeStyles({
  bold: {
    fontWeight: 'bold',
  },
  displayInline: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

const lineItem = (text, styles, val) => (
  <>
    <Grid item>
      <Box display="inline" fontWeight="fontWeightMedium" m={1}>
        <label className={styles.bold}>{text}: </label>
      </Box>
      <Box display="inline" fontWeight="fontWeightRegular" m={1}>
        {val}
      </Box>
    </Grid>
  </>
);
const subHeader = (icon, styles, text) => (
  <>
    <div className={styles.displayInline}>
      {icon}
      <Typography component={'span'}>
        <Box display="inline" fontWeight="fontWeightMedium" m={1}>
          <label className={styles.bold}>{text}</label>
        </Box>
      </Typography>
    </div>
  </>
);

export default function PatientProfile({ widgetId, data }) {
  const styles = useStyles();
  const { dispatch } = useContext(SettingsContext);
  const starData = data.patient.star;
  const transplantHistory = starData?.widgetTransplantHistory;
  const currentCondition = starData?.widgetCurrentCondition;
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    const onClick = () => {
      history.push(`/patient/${id}/details`);
    };
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: onClick,
      },
    });
  }, [dispatch, history, widgetId, id]);

  return starData ? (
    <Container>
      <Grid container direction="row">
        <Grid item xs={6}>
          <Grid container direction="column">
            {subHeader(
              <Icon
                component={OrganTransplantIcon}
                style={{
                  height: '2em',
                  width: '2em',
                  margin: '0px 5px 6px 7px',
                }}
                viewBox="0 0 47 30"
              ></Icon>,
              styles,
              'Transplant History'
            )}
            {lineItem('HLA Match', styles, transplantHistory.hlaMatch)}
            {lineItem('Donor Type', styles, transplantHistory.livingDonorType)}
            {lineItem('KDPI', styles, transplantHistory.kdpi)}
            {lineItem(
              'Primary Diagnosis',
              styles,
              transplantHistory.primaryDiagnosisAtTransplant
            )}
            {lineItem(
              'Previous Transplant',
              styles,
              transplantHistory.previousTransplant === 'Y' ? 'Yes' : 'No'
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="column">
            {subHeader(
              <Icon
                component={CurrentConditionGreenIcon}
                style={{
                  height: '2em',
                  width: '2em',
                  margin: '0px -10px 6px 7px',
                }}
                viewBox="0 0 47 30"
              ></Icon>,
              styles,
              'Current Condition'
            )}
            {lineItem('BMI', styles, currentCondition.bMI)}
            {lineItem('iSx', styles, currentCondition.iSx)}
            {lineItem('CMV Status', styles, currentCondition.cmvStatus)}
            {lineItem('BK Status', styles, currentCondition.bkStatus)}
            {lineItem(
              'Rejection Episode',
              styles,
              currentCondition.rejection ? currentCondition.rejection : 'None'
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  ) : null;
}
