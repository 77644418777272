import React from 'react';
import { Chip } from '@material-ui/core';
import { withTranslation } from 'react-i18next';

import { operationSymbol } from '../../Utilities/tableFiltering';

function FilterPanel({ t, filter, className, onFilterDelete }) {
  const handleDeleteClick = (property) => {
    return () => {
      onFilterDelete(property);
    };
  };

  return (
    <div className={className}>
      {Object.keys(filter).map((f) => {
        const fp = filter[f];
        if (fp.type === 'DateTime' || fp.type === 'DateTimeOffset') {
          fp.value = t('formattedDate.LLL', { date: new Date(fp.value) });
          fp.start = t('formattedDate.LLL', { date: new Date(fp.start) });
          fp.end = t('formattedDate.LLL', { date: new Date(fp.end) });
        } else if (fp.type === 'Int') {
          if (fp.value % 1 === 0) {
            fp.value = t('numberFormat', { value: parseInt(fp.value) });
          } else {
            fp.value = t('numberFormat', { value: parseFloat(fp.value) });
          }
          if (fp.start % 1 === 0) {
            fp.start = t('numberFormat', { value: parseInt(fp.start) });
          } else {
            fp.start = t('numberFormat', { value: parseFloat(fp.start) });
          }
          if (fp.end % 1 === 0) {
            fp.end = t('numberFormat', { value: parseInt(fp.end) });
          } else {
            fp.end = t('numberFormat', { value: parseFloat(fp.end) });
          }
        } else if (fp.type === 'Float') {
          fp.value = t('numberFormat', { value: parseFloat(fp.value) });
          fp.start = t('numberFormat', { value: parseFloat(fp.start) });
          fp.end = t('numberFormat', { value: parseFloat(fp.end) });
        } else if (fp.type === 'Decimal') {
          fp.value = t('numberFormat', { value: parseFloat(fp.value) });
          fp.start = t('numberFormat', { value: parseFloat(fp.start) });
          fp.end = t('numberFormat', { value: parseFloat(fp.end) });
        }
        const operation = operationSymbol(fp.operation);
        const label = t(
          [`FilterPanelChip.${fp.operation}`, 'FilterPanelChip.default'],
          {
            field: fp.title,
            value: fp.value,
            start: fp.start,
            end: fp.end,
            operation: operation,
          }
        );

        return <Chip key={f} label={label} onDelete={handleDeleteClick(f)} />;
      })}
    </div>
  );
}

export default withTranslation()(FilterPanel);
