const Constants = {
  BLOOD_PRESSURE_DIASTOLIC: 'BLOOD_PRESSURE_DIASTOLIC',
  BLOOD_PRESSURE_SYSTOLIC: 'BLOOD_PRESSURE_SYSTOLIC',
  FLUID_INTAKE: 'FLUID_INTAKE',
  PULSE_OX: 'PULSE_OX',
  STEPS: 'STEPS',
  URINE_OUTPUT: 'URINE_OUTPUT',
  WEIGHT: 'WEIGHT',
  TEMPERATURE: 'TEMPERATURE',
  BLOOD_GLUCOSE: 'BLOOD_GLUCOSE',
  MOOD: 'MOOD',
  MEDICATION_COMPLIANCE: 'MEDICATION_COMPLIANCE',
  LESS_THAN_3_MONTHS: 'LESS_THAN_3_MONTHS',
  GREATER_THAN_3_MONTHS: 'GREATER_THAN_3_MONTHS',
  OLIGURIA: 'oliguriaCondition',
  BMI: 'bmiCondition',
  DIABETES: 'diabetesCondition',
  HYPERTENSION: 'hypertensionCondition',
  COPD: 'copdCondition',
  DEPRESSION: 'depressionCondition',
  IS_EMAIL: 'isEmail',
  IS_TEXT: 'isText',
  IS_ALERT_ON: 'isAlertOn',
  EMAIL_ADDRESS: 'emailAddress',
  CONTACT_NUMBER: 'contactNumber',
  PHONE_REQUIRED: 'phoneRequired',
  EMAIL_REQUIRED: 'emailRequired',
  LOW: 'low',
  HIGH: 'high',
  PERCENTAGE: 'percentage',
  MEASURE_CHECK: 'measureCheck',
  IMMEDIATE_FREQUENCY: 'IMMEDIATE',
  DAILY_FREQUENCY: 'DAILY',
  EVERY_3_DAYS_FREQUENCY: 'EVERY_3_DAYS',
  WEEKLY_FREQUENCY: 'WEEKLY',
  MEASURE: 'MEASURE',
  CONDITION_CONTENT: 'Conditions',
  MEASURES_CONTENT: 'Measures',
  MEASURE_STATE: 'measuresState',
  ALERT_STATE: 'alertState',
  ERROR_VALIDATION: 'Please correct the errors before submitting the data.',
  ERROR_EMAILPHONE_REQUIRED:
    'Specify/check email or text when measures are selected',
  ERROR_PHONE_INVALID: 'Invalid phone number',
  ERROR_PHONE_REQUIRED: 'A valid phone number is required',
  ERROR_EMAIL_INVALID: 'Invalid email address',
  ERROR_EMAIL_REQUIRED: 'A valid Email is required',
  ERROR_NUMBER: 'Numeric value required',
  BIND_CLICK: 'Button Clicked',
};
export default Constants;
