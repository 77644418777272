import { Grid, Box } from '@material-ui/core';
import { TranslatedTypography } from 'Components/Localization';
import React from 'react';
import ImmunosuppressionLineChart from './ImmunosuppressionLineChart';
import Moment from 'moment';
import { NoDataChart } from './shared/NoDataChart';

function ImmunosuppressionWidgetChartRow({
  showXLabels,
  chartConfig,
  range,
  data,
}) {
  const noDataAlign = {
    display: 'flex',
    alignItems: 'center',
  };

  const hasData =
    data?.series?.length > 0 && data.series[0].elements.some((p) => p.value);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={4}
        alignItems="center"
        style={{ paddingTop: showXLabels && hasData ? 80 : 0 }}
      >
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            {chartConfig.labelIcon}
            <Box display="flex" flexDirection="column">
              <TranslatedTypography
                variant="h6"
                style={{
                  fontWeight: 600,
                  display:
                    chartConfig.id === 'medicationcompliance'
                      ? 'table-caption'
                      : '',
                }}
              >
                {chartConfig.label}
              </TranslatedTypography>
              <Box display="flex" flexDirection="column" alignItems="start">
                <TranslatedTypography variant="body2">
                  {chartConfig.id === 'tacroLevel'
                    ? Moment(data.series[0].elements[0].start).format(
                        'MM/DD/YYYY'
                      )
                    : ''}
                </TranslatedTypography>
                <TranslatedTypography variant="body2">
                  {chartConfig.id === 'tacroLevel' ? data.maxRangeValue : ''}
                  {chartConfig.id === 'tacroLevel' ? data.unit : ''}
                </TranslatedTypography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={8} style={!hasData ? noDataAlign : {}}>
        {data?.series?.length > 0 &&
        data.series[0].elements.some((p) => p.value) ? (
          <ImmunosuppressionLineChart
            dataSets={chartConfig.getDataSets(data)}
            chartConfiguration={chartConfig}
            range={range}
            showXLabels={showXLabels}
            canvasHeight={150}
            labelDayFormat={'MM/DD'}
            scaleXPadding={18}
          />
        ) : (
          <NoDataChart range={range}></NoDataChart>
        )}
      </Grid>
    </Grid>
  );
}

export default ImmunosuppressionWidgetChartRow;
