import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
import { ChartsConfiguration, ChartTypes } from '../../AlloCare/AlloCareConfig';
import AlloCareWidgetChartRow from './AlloCareWidgetChartRow';
import NoAlloCareData from './NoAlloCareData';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  chartsContainer: {
    display: 'flex',
    margin: '0px 0px 25px 15px',
    flexDirection: 'column',
  },
}));

function AlloCareWidget({ widgetId, data }) {
  const classes = useStyles();
  const { dispatch } = useContext(SettingsContext);
  let widgetData = data?.patient?.links?.alloCare;
  const { id } = useParams();
  if (widgetData.__typename === 'ResolverErrorType') {
    widgetData = null;
  }
  const charts = [
    ChartsConfiguration[ChartTypes.Temperature],
    ChartsConfiguration[ChartTypes.fluidIntake],
  ];

  const history = useHistory();
  useEffect(() => {
    const onClick = () => {
      history.push(`/patient/${id}/allocare`);
    };
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: onClick,
      },
    });
  }, [dispatch, history, id, widgetId]);

  return widgetData === null ? (
    <NoAlloCareData />
  ) : (
    <Grid className={classes.chartsContainer}>
      <Box>
        {charts.map((chartConfig, idx) => (
          <AlloCareWidgetChartRow
            key={idx}
            chartConfig={chartConfig}
            data={widgetData?.averages[chartConfig.id]}
            range={7}
            showXLabels={idx === 0}
          />
        ))}
      </Box>
    </Grid>
  );
}

export default AlloCareWidget;
