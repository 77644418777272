import React, { useRef, useState, useEffect } from 'react';
import { Badge, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Chart } from 'chart.js';
import { MethodistDashboardColors } from '../../../Utilities/Infrastucture/methodistDashboardColors';
import { alloSureHistrogramChartConfigurationMode } from '../../../Utilities/Infrastucture/alloSureHistrogramChartConfigurationMode';
import annotationPlugin from 'chartjs-plugin-annotation';
import './AlloIndividualChart.css';

Chart.plugins.register(annotationPlugin);
export default function AlloIndividualChart({
  width,
  height,
  configurationMode,
  alloSureData,
}) {
  const useStyles = makeStyles(() => ({
    chartContainer: {
      width: width,
      height: height,
    },
    cardcontent: {
      padding: '8px !important',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  }));

  const configurationBuilder = (mode) => {
    if (mode === alloSureHistrogramChartConfigurationMode.WIDGET) {
      return {
        scaleLabelFontSize: 10,
        hideAnnotationLabel: false,
        postFixAnnotationLabel: '',
        annotationLineColor: 'transparent', // Used in annotationFactory method
        hideXLabel: false,
        fontSize: 10, // Used in labelFactory method
        xAdjust: -10, // Used in labelFactory method
      };
    }

    return {
      scaleLabelFontSize: 20,
      hideAnnotationLabel: false,
      postFixAnnotationLabel: '        ',
      annotationLineColor: 'transparent',
      hideXLabel: false,
      fontSize: 18,
      xAdjust: 350,
    };
  };

  const configMode = configurationBuilder(configurationMode);
  const riskLevel = {
    default: '0',
    medium: '61',
    high: '149',
  };
  const tickFactory = (labelText) => {
    return 'RCV: > ' + labelText + '% ';
  };
  const annotationLabelFactory = (risk, text) => {
    return {
      drawTime: 'afterDraw', // overrides annotation.drawTime if set
      id: risk + 'label', // optional
      type: 'line',
      mode: 'horizontal',
      scaleID: 'B',
      value: parseFloat(risk),
      borderColor: 'transparent',
      borderWidth: 2,
      label: {
        fontColor: 'black',
        backgroundColor: 'transparent',
        content: '',
        enabled: true,
        position: 'left',
        yAdjust: 15,
        fontSize: configMode.fontSize,
      },
    };
  };

  const annotationsFactory = (mode) => {
    if (mode.hideAnnotationLabel) {
      return [];
    }

    return [
      annotationLabelFactory(
        riskLevel['medium'],
        'CHANGES ASSOCIATED WITH BIOLOGICAL VARIATION- 0.5'
      ),
      annotationLabelFactory(
        riskLevel['high'],
        'EXCEEDS BIOLOGICAL VARIATION- 1.5'
      ),
      annotationLabelFactory(
        riskLevel['aboveHigh'],
        'HIGH LIKELIHOOD OF INJURY- 2.5%'
      ),
    ];
  };

  const labelsFactory = (mode, labelSeed) => {
    if (mode.hideXLabel) {
      let labels = [];
      labelSeed.forEach(() => {
        labels.push('');
      });
      return labels;
    }
    if (configurationMode === alloSureHistrogramChartConfigurationMode.WIDGET) {
      labelSeed.push('');
    } else {
      labelSeed.push('');
      labelSeed.push('');
    }
    return labelSeed;
  };
  const rawAnnotations = annotationsFactory(configMode);
  var rawLabels = labelsFactory(configMode, []); //alloview_parameters.evaluation_date
  const checkAlloSureData = (data) => {
    if (data === undefined) {
      isAlloSureScore = false;
      return false;
    }
    if (data.allAlloSureResult === undefined) {
      isAlloSureScore = false;
      return false;
    }
    if (data.patient.links.alloView.alloViewRecords.length > 0) {
      isAlloSureScore = true;
      return true;
    }
    return false;
  };
  var isAlloSureScore = checkAlloSureData(alloSureData);

  const getRawData = () => {
    if (!isAlloSureScore) {
      return [];
    }
    var sortedArray = [...alloSureData.patient.links.alloView.alloViewRecords];
    sortedArray.reverse();
    //alloSureData.
    var alloSureScore = [];
    var alloSureScoreDate = [];
    if (
      configurationMode === alloSureHistrogramChartConfigurationMode.WIDGET &&
      sortedArray?.length > 5
    ) {
      for (
        let iRows = sortedArray.length - 5;
        iRows < sortedArray.length;
        iRows++
      ) {
        if (iRows >= sortedArray.length) {
          break;
        }
        alloSureScore.push(sortedArray[iRows]?.parameters?.alloSureScore);
        const d = new Date(sortedArray[iRows]?.parameters?.riskEvaluationDate);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const riskDate = year + '-' + month + '-' + day;
        alloSureScoreDate.push(riskDate);
      }
    } else {
      sortedArray.forEach((item) => {
        alloSureScore.push(item?.parameters?.alloSureScore);
        const d = new Date(item?.parameters?.riskEvaluationDate);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const riskDate = year + '-' + month + '-' + day;
        alloSureScoreDate.push(riskDate);
      });
    }
    rawLabels = labelsFactory(configMode, alloSureScoreDate);
    return alloSureScore;
  };
  var currentRCVBoxBackground = '';
  var currentRCVVal = '';
  const getRCVCurrentVal = (rcvVal) => {
    var currentRCVBoxBackgroundClass = '';
    if (rcvVal >= 0 && rcvVal < 61) {
      currentRCVBoxBackgroundClass = 'currentRCVBoxBackgroundGreen';
    } else if (rcvVal >= 61 && rcvVal < 149) {
      currentRCVBoxBackgroundClass = 'currentRCVBoxBackgroundYellow';
    } else if (rcvVal >= 149) {
      currentRCVBoxBackgroundClass = 'currentRCVBoxBackgroundRed';
    }
    return currentRCVBoxBackgroundClass;
  };
  var rawData = getRawData(); //alloview_parameters.allosure_result
  const getPercentageValue = (allosureScore) => {
    var rcv = [0];
    for (let i = 0; i < allosureScore.length - 1; i++) {
      rcv.push((allosureScore[i + 1] / allosureScore[i]) * 100);
      if (i === allosureScore.length - 2) {
        currentRCVVal = (allosureScore[i + 1] / allosureScore[i]) * 100;
        currentRCVBoxBackground = getRCVCurrentVal(currentRCVVal);
      }
    }
    return rcv;
  };

  const getPercentageChange = (rCVPercent) => {
    var rcvChange = [0];
    for (let i = 0; i < rCVPercent.length - 1; i++) {
      rcvChange.push(rCVPercent[i + 1] - rCVPercent[i]);
    }
    return rcvChange;
  };

  var rawAllosureRCVPercentChange = getPercentageValue(rawData);
  var rawAllosureRCVChangeMetadata = getPercentageChange(
    rawAllosureRCVPercentChange
  );

  // Above 0
  var rawAllosureRCVPercentChangeRectangle = (val) => {
    var boxes = [];
    for (let index = 0; index < rawData.length; index++) {
      boxes.push(val);
    }
    if (rawData.length > 0 && rawData.length <= 3) {
      boxes.push(val);
      boxes.push(val);
    }
    if (configurationMode === alloSureHistrogramChartConfigurationMode.WIDGET) {
      boxes.push(val);
    } else {
      boxes.push(val);
      boxes.push(val);
      boxes.push(val);
    }

    return boxes;
  };

  // Below 0
  var rawAllosureRCVPercentChangeRectangleBelowZero = () => {
    var boxes = [];
    for (let index = 0; index < rawData.length; index++) {
      boxes.push(-50);
    }
    if (rawData.length > 0 && rawData.length <= 3) {
      boxes.push(-50);
      boxes.push(-50);
    }
    if (configurationMode === alloSureHistrogramChartConfigurationMode.WIDGET) {
      boxes.push(-50);
    } else {
      boxes.push(-50);
      boxes.push(-50);
      boxes.push(-50);
    }
    return boxes;
  };

  const [annotations] = useState(rawAnnotations);
  const [labels] = useState(rawLabels);
  const [data] = useState(rawData);
  const [allosureRCVChangeMetadata] = useState(rawAllosureRCVChangeMetadata);
  const [allosureRCVPercentChange] = useState(rawAllosureRCVPercentChange);

  const [allosureRCVPercentChangeRectangle] = useState(
    rawAllosureRCVPercentChangeRectangle(350)
  );
  const [allosureRCVPercentFirstStopChangeRectangle] = useState(
    rawAllosureRCVPercentChangeRectangle(61)
  );

  const [allosureRCVPercentSecondStopChangeRectangle] = useState(
    rawAllosureRCVPercentChangeRectangle(149)
  );

  const [allosureRCVPercentChangeRectangleBelowZero] = useState(
    rawAllosureRCVPercentChangeRectangleBelowZero
  );

  const [chartOptions, setCharOptions] = useState(null);
  const canvasRef = useRef(null);
  const styles = useStyles();

  function getKeyByValue(object, value) {
    return Object.values(object).find((val) => val === value);
  }

  useEffect(() => {
    const chartRef = canvasRef.current;
    let allosureChart = null;
    if (chartRef && chartOptions) {
      const myChartRef = chartRef.getContext('2d');
      allosureChart = new Chart(myChartRef, chartOptions);
    }
    return () => {
      allosureChart?.destroy();
    };
  }, [chartOptions]);

  useEffect(() => {
    setCharOptions({
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          // Allosure result dataset
          {
            label: 'Interpretation of AlloSure Relative Change Value (RCV)',
            strokeColor: 'black',
            borderWidth: 8,
            color: 'black',
            yAxisID: 'A',
            // data: data,
            tension: 0,
            pointHoverRadius: 0,
            backgroundColor: 'white',
            fill: false,
          },
          {
            label: '',
            strokeColor: 'black',
            backgroundColor: 'white',
            borderColor: 'black',
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentChange,
            pointRadius: 4,
            pointHoverRadius: 4,
            fill: false,
            tension: 0,
          },
          // Trick to draw a rectangle gradient
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentFirstStopChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: MethodistDashboardColors.GREEN_COLOR,
          },
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentFirstStopChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: '#ffffff',
          },

          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentSecondStopChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: MethodistDashboardColors.YELLOW_COLOR,
          },
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentSecondStopChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: '#ffffff',
          },
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: MethodistDashboardColors.RED_COLOR,
          },

          // Trick to draw a rectangle gradient belowZero
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentChangeRectangleBelowZero,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: MethodistDashboardColors.GREEN_COLOR,
          },
        ],
      },
      options: {
        legend: {
          align: 'middle',
          labels: {
            boxWidth: 0,
          },
        },
        title: {
          display: true,
          position: 'left',
        },
        scales: {
          yAxes: [
            {
              id: 'A',
              display: true,
              position: 'right',
              gridLines: {
                display: false,
              },
              ticks: {
                // Include a dollar sign in the ticks
                callback: function () {
                  return '';
                },
              },
            },
            {
              id: 'B',
              display: true,
              position: 'left',
              gridLines: {
                display: false,
              },
              scaleLabel: {
                fontSize: configMode.scaleLabelFontSize,
                display: true,
                labelString: 'RCV(%)',
              },
              ticks: {
                min: -50,
                max: 350,
                stepSize: 1,
                autoSkip: false,
                fontSize: configMode.fontSize,
                callback: function (val, index) {
                  if (getKeyByValue(riskLevel, val.toString()) !== undefined) {
                    return tickFactory(val);
                  }
                  return '';
                },
              },
            },
            {
              id: 'C',
              display: true,
              position: 'right',
              gridLines: {
                display: false,
              },
              ticks: {
                callback: function () {
                  return '';
                },
                textStrokeWidth: 1,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              position: 'left',
              scaleLabel: {
                fontSize: configMode.scaleLabelFontSize,
                display: true,
                labelString: 'CONSECUTIVE TESTS',
              },
              ticks: {
                autoSkip: false,
                maxRotation:
                  configurationMode ===
                  alloSureHistrogramChartConfigurationMode.WIDGET
                    ? 0
                    : 90,
                minRotation:
                  configurationMode ===
                  alloSureHistrogramChartConfigurationMode.WIDGET
                    ? 0
                    : 90,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        font: {
          family: 'Trade Gothic LT Std,sans-serif,Roboto,Helvetica,Arial;',
          size: 25,
        },
        tooltips: {
          // Show tooltip for data set == 1
          filter: function (tooltipItem) {
            return tooltipItem.datasetIndex === 1;
          },
          callbacks: {
            label: function (tooltipItem) {
              if (tooltipItem.datasetIndex === 1) {
                return [
                  `RCV: ${parseFloat(tooltipItem.value).toFixed(2)}%`,
                  `RCV Change: ${parseFloat(
                    allosureRCVChangeMetadata[tooltipItem.index]
                  ).toFixed(2)}%`,
                ];
              }
              return [`AlloSure Score: ${tooltipItem.value}`];
            },
          },
        },

        annotation: {
          drawTime: 'afterDatasetsDraw', // (default)
          annotations: annotations,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.chartContainer + ' divChartContainer'}>
      <div
        style={{
          display: isAlloSureScore === true ? 'block' : 'none',
          cursor: 'pointer',
        }}
      >
        <div
          className={`three ${styles.titleContainer} ${
            configurationMode === 'widget'
              ? 'widget-container'
              : 'page-container'
          }`}
        >
          <Card sx={{ maxWidth: 50, width: 50 }}>
            <CardContent className={styles.cardcontent}>
              <Typography gutterBottom className="cardTopography">
                CURRENT RCV
              </Typography>
              <Divider />
              <Badge
                color="#000000"
                variant="dot"
                classes={{
                  badge: `${currentRCVBoxBackground} ${'currentRCVBoxBackground'}`,
                }}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
              >
                <Typography component="div">
                  {parseFloat(currentRCVVal).toFixed(2)}%
                </Typography>
              </Badge>
            </CardContent>
          </Card>
        </div>
        <canvas
          className="dashboardNew"
          ref={canvasRef}
          height={height}
          aria-label="AllowSure Individual Chart"
          role="img"
        ></canvas>
      </div>
      <h2
        className="notFoundH1"
        style={{
          display: isAlloSureScore === false ? 'block' : 'none',
        }}
      >
        No data found
      </h2>
    </div>
  );
}
