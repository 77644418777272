import React, { useState } from 'react';
import { TextField, makeStyles, CircularProgress } from '@material-ui/core';
import {
  TranslatedIconButton as IconButton,
  TranslatedTypography as Typography,
} from '../../Localization';
import { SaveOutlined, Backspace } from '@material-ui/icons';

import { buildFilterSchema, buildFieldSchema } from '../../../filterSchema';

import FieldPicker from './Fields/FieldPicker';

const useStyles = makeStyles((theme) => ({
  fields: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    overflow: 'auto',
  },
  card: {
    flex: '0 0 auto',
    marginLeft: '5px',
    marginRight: '5px',
    cursor: 'move',
    background: theme.palette.primary.main,
    userSelect: 'none',
  },
  inactiveCard: {
    flex: '0 0 auto',
    marginLeft: '5px',
    marginRight: '5px',
    cursor: 'not-allowed',
    background: theme.palette.primary.light,
    userSelect: 'none',
  },
  filler: {
    height: '20px',
  },
  outputScroller: {
    maxHeight: '500px',
    overflowY: 'scroll',
  },
  formControl: {
    display: 'block',
    marginBottom: '10px',
    minWidth: '200px',
  },
  sectionHeading: {
    marginBottom: '15px',
  },
  sortDirectionSelect: {
    minWidth: '200px',
  },
  container: {
    height: '75vh',
  },
  descriptionField: {
    marginLeft: '20px',
  },
}));

export default function ListBuilder({
  typeData,
  id,
  title,
  description,
  fields,
  isStaticList,
  collectionId,
  hasDefaultSort,
  defaultSortField,
  defaultSortDirection,
  upsertMyListConfig,
  saving,
  handleClose,
  userId,
  newList,
  refetch,
}) {
  const classes = useStyles();

  const [config, setConfig] = useState({
    id: id,
    title: title || '',
    description: description || '',
    fields: fields,
    isStaticList: isStaticList,
    collectionId: collectionId,
    hasDefaultSort: hasDefaultSort,
    defaultSortField: defaultSortField,
    defaultSortDirection: defaultSortDirection,
    fieldData: buildFieldSchema('ListRowType', typeData),
  });

  const filterSchema = buildFilterSchema('ListRowFilterType', typeData);

  const paths = Object.keys(filterSchema);
  for (let p = 0; p < paths.length; p++) {
    const path = paths[p];
    if (
      !['DateTime', 'DateTimeOffset', 'String', 'Int'].includes(
        filterSchema[path]
      )
    ) {
      delete filterSchema[path];
    }
  }

  const fieldData = config.fieldData;

  const handleTitleChange = function (e) {
    const value = e.target.value;
    setConfig({
      ...config,
      title: value,
    });
  };

  const handleDescriptionChange = function (e) {
    const value = e.target.value;
    setConfig({
      ...config,
      description: value,
    });
  };

  const handleFieldReorder = function (dragIndexName, hoverIndexName) {
    const updated = [...config.fields];

    let fieldList = Object.entries(config.fieldData);
    let dragShift;
    let hoverShift;
    fieldList.forEach((i) => {
      if (i[0] === dragIndexName) {
        dragShift = i;
      } else if (i[0] === hoverIndexName) {
        hoverShift = i;
      }
    });
    const dragIdx = fieldList.findIndex((di) => di === dragShift);
    const hoverIdx = fieldList.findIndex((hi) => hi === hoverShift);
    fieldList.splice(dragIdx, 1);
    fieldList.splice(hoverIdx, 0, dragShift);
    let someOtherList = Object.fromEntries(fieldList);

    const dragIndex = updated.findIndex((f) => f === dragIndexName);
    const hoverIndex = updated.findIndex((d) => d === hoverIndexName);
    const item = updated[dragIndex];
    updated.splice(dragIndex, 1);
    updated.splice(hoverIndex, 0, item);
    setConfig({
      ...config,
      fields: updated,
      fieldData: someOtherList,
    });
  };

  const handleFieldAddClick = function (name) {
    const updated = [...config.fields];
    let fieldList = Object.entries(config.fieldData);
    let fieldShift;
    fieldList.forEach((i) => {
      if (i[0] === name) {
        fieldShift = i;
      }
    });
    const idx = fieldList.findIndex((fld) => fld === fieldShift);
    fieldList.splice(idx, 1);
    fieldList.unshift(fieldShift);
    let someOtherList = Object.fromEntries(fieldList);
    updated.unshift(name);
    setConfig({
      ...config,
      fields: updated,
      fieldData: someOtherList,
    });
  };

  const handleFieldRemoveClick = function (name) {
    const updated = [...config.fields];
    let fieldList = Object.entries(config.fieldData);
    let fieldShift;
    fieldList.forEach((i) => {
      if (i[0] === name) {
        fieldShift = i;
      }
    });
    const idx = fieldList.findIndex((id) => id === fieldShift);
    fieldList.splice(idx, 1);
    const index = updated.findIndex((field) => field === name);
    updated.splice(index, 1);
    fieldList.splice(updated.length, 0, fieldShift);
    let someOtherList = Object.fromEntries(fieldList);
    setConfig({
      ...config,
      fields: updated,
      fieldData: someOtherList,
    });
  };

  function handleSaveClick() {
    if (newList) {
      if (!saving) {
        upsertMyListConfig({
          variables: {
            ...config,
            id: uuidv4(),
            filter: [],
            userId: userId,
            collectionId: config.id,
          },
        });
        refetch();
        handleClose();
      }
    } else {
      if (!saving) {
        upsertMyListConfig({
          variables: {
            ...config,
            filter: [],
            userId: userId,
            collectionId: config.id,
          },
        });
        refetch();
        handleClose();
      }
    }
  }

  function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  return (
    <div className={classes.container}>
      <TextField
        label={<Typography variant="h5">Title</Typography>}
        value={config.title}
        onChange={handleTitleChange}
      />
      <TextField
        label={<Typography variant="h5">Description</Typography>}
        value={config.description}
        onChange={handleDescriptionChange}
        className={classes.descriptionField}
      />
      <FieldPicker
        fieldData={fieldData}
        fields={config.fields}
        onFieldReorder={handleFieldReorder}
        onFieldRemoveClick={handleFieldRemoveClick}
        onFieldAddClick={handleFieldAddClick}
      />
      <IconButton onClick={handleSaveClick}>
        {saving ? <CircularProgress size={25} /> : <SaveOutlined />}
        Save
      </IconButton>
      <IconButton onClick={handleClose}>
        <Backspace />
        Exit
      </IconButton>
    </div>
  );
}
