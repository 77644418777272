import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import Plot from 'react-plotly.js';
import './AlloSureViolinChartWidget.css';
Chart.plugins.register(annotationPlugin);

export default function AlloSureViolinChart({ width, height, alloSureData }) {
  const facilityName = 'Methodist and Specialty Transplant ';
  function getTitle(facilityName) {
    return (
      'All time AlloSure Results from <br> ' +
      facilityName +
      '(N=' +
      csvRows.length +
      ') <br> <b> Min: ' +
      getMinValue() +
      ' Max: ' +
      getMaxValue() +
      ' Mean: ' +
      getMean() +
      ' Median: ' +
      getMedian() +
      '</b>'
    );
  }

  const useStyles = makeStyles((theme) => ({
    chartContainerVioline: {
      display: width === '100%' ? 'flex' : '',
      flexDirection: 'column',
      height: height,
      width: width,
    },
  }));
  function getMedian() {
    var values = csvRows;
    if (values.length === 0) {
      return 0;
    }

    var numberArry = values.map((x) => parseFloat(x));
    numberArry.sort(function (a, b) {
      return a - b;
    });
    var half = Math.floor(numberArry.length / 2);
    if (numberArry.length % 2) return numberArry[half];
    return parseFloat((numberArry[half - 1] + numberArry[half]) / 2.0).toFixed(
      2
    );
  }

  function getMaxValue() {
    var values = csvRows;
    return Math.max(...values);
  }

  function getMinValue() {
    var values = csvRows;
    return Math.min(...values);
  }

  function getMean() {
    var values = csvRows;
    let sumOfElements = 0;
    let size = csvRows.length;
    for (let i = 0; i < csvRows.length; i++) {
      sumOfElements += values[i];
    }
    return parseFloat(sumOfElements / size).toFixed(2);
  }

  const [data, setData] = useState([]);
  const [csvRows, setCsvRows] = useState([]);
  const styles = useStyles();
  useEffect(() => {
    getValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData([
      {
        type: 'violin',
        hoveron: 'points+kde',
        y: csvRows,
        points: false,
        jitter: 1,
        box: {
          visible: false,
        },
        boxpoints: false,
        line: {
          color: 'black',
        },
        fillcolor: '#ffffff',
        opacity: 1,
        size: 4,
        meanline: {
          visible: true,
        },
        x0: 'AlloSure Score',
      },
    ]);
  }, [csvRows]);
  const checkAlloSureData = (data) => {
    if (data === undefined) {
      isAlloSureScore = false;
      return false;
    }
    if (data.allAlloSureResult === undefined) {
      isAlloSureScore = false;
      return false;
    }
    if (alloSureData.allAlloSureResult.length > 0) {
      return true;
    }
    return false;
  };
  var isAlloSureScore = checkAlloSureData(alloSureData);
  let rows = [];

  const getValues = () => {
    if (!isAlloSureScore) return;
    if (alloSureData.allAlloSureResult != null) {
      alloSureData.allAlloSureResult.forEach((item) => {
        rows.push(item.value);
      });
      isAlloSureScore = true;
    }
    setCsvRows(rows);
  };

  return (
    <div className={styles.chartContainer}>
      <div
        style={{
          display: isAlloSureScore === true ? 'block' : 'none',
          cursor: 'pointer',
        }}
      >
        <Plot
          data={data}
          layout={{
            autosize: false,
            title: getTitle(facilityName),
            width: width,
            height: height,
            hovermode: 'closest',
            margin: {
              l: 150,
              r: 50,
              b: 50,
              t: 100,
              pad: 0,
            },
            xaxis: {
              autotick: false,
              ticks: 'outside',
              tickcolor: '#000',
            },
            yaxis: {
              tickvals: [
                0.1,
                getMedian(),
                0.5,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
              ],
              ticktext: [
                '0.1',
                ' Median:' + getMedian(),
                '0.5',
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
              ],
              tickcolor: '#000',
              showline: true,
              showgrid: false,
              minStats: 'min' | 'whiskerMin',
              maxStats: 'max' | 'whiskerMax',
            },
            shapes: [
              {
                type: 'line',
                x0: -1,
                y0: getMedian(),
                x1: 2,
                y1: getMedian(),
                line: {
                  color: 'rgb(50, 171, 96)',
                  width: 2,
                  dash: 'dot',
                },
              },
            ],
            font: {
              family: 'Trade Gothic LT Std,sans-serif,Roboto,Helvetica,Arial;',
              size: '9',
            },
          }}
          config={{
            responsive: true,
            displayModeBar: true,
            displaylogo: false,
            modeBarButtonsToRemove: ['resetScale2d'],
          }}
          onHover={(figure) => {}}
        />
      </div>
      <h2
        className="notFoundH1"
        id="noData"
        style={{
          display: isAlloSureScore === false ? 'block' : 'none',
        }}
      >
        No data found
      </h2>
    </div>
  );
}
