import * as React from 'react';

function SvgMedicineSm(props) {
  return (
    <svg {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M9.768 12.299l-.005-2.268-2.383-.003.008 2.363c0 .635.542 1.18 1.199 1.18.656.001 1.18-.547 1.181-1.182 0-.034.01-.06 0-.09"
          fill="#fff"
        />
        <path
          d="M9.768 12.299l-.005-2.268-2.383-.003.008 2.363c0 .635.542 1.18 1.199 1.18.656.001 1.18-.547 1.181-1.182 0-.034.01-.06 0-.09z"
          stroke="#231f20"
          strokeWidth={0.16060006}
        />
        <path
          d="M9.774 7.722l-.011 2.309-2.383-.003.013-2.403c.001-.636.545-1.2 1.202-1.199.656.001 1.18.56 1.179 1.196 0 .033.01.07 0 .1"
          fill="#ed1c24"
        />
        <path
          d="M9.774 7.722l-.011 2.309-2.383-.003.013-2.403c.001-.636.545-1.2 1.202-1.199.656.001 1.18.56 1.179 1.196 0 .033.01.07 0 .1z"
          stroke="#231f20"
          strokeWidth={0.16060006}
        />
        <path
          d="M5.024 12.163V12.1c.002-.421.175-.843.463-1.13a1.549 1.549 0 011.101-.454A1.582 1.582 0 018.175 12.1v.064z"
          fill="#fff"
        />
        <path
          d="M6.583 10.449v.134-.125h.005c-.434 0-.842.164-1.149.47-.3.299-.48.726-.482 1.165v.12H8.242v-.123a1.658 1.658 0 00-.483-1.158 1.635 1.635 0 00-1.177-.483m.005.134a1.528 1.528 0 011.52 1.53H5.091c.002-.401.15-.797.443-1.09.291-.29.672-.44 1.054-.44"
          fill="#231f20"
        />
        <path
          d="M6.622 13.695c-.429 0-.833-.168-1.138-.474-.312-.312-.462-.672-.46-1.084v-.074h3.151v.074c.002.423-.153.797-.462 1.105-.29.29-.678.453-1.091.453"
          fill="#ed1c24"
        />
        <path
          d="M8.242 12.013H4.958l-.001.133c-.002.43.155.797.48 1.122.317.319.738.494 1.185.494a1.6 1.6 0 001.138-.469c.322-.32.484-.707.482-1.147zm-.134.15c.002 0-.145.748-.442 1.044a1.464 1.464 0 01-1.044.426c-.394 0-.79-.147-1.09-.448-.296-.297-.443-.62-.441-1.022h2.997z"
          fill="#231f20"
        />
      </g>
    </svg>
  );
}

export default SvgMedicineSm;
