import React from 'react';
import {
  AlloViewThemeProvider,
  AlloViewCalculate,
  AlloViewContextProvider,
  NewScoreContextProvider,
  ErrorBoundary,
} from 'alloview-lib';
import { makeStyles } from '@material-ui/core/styles';
import { alloViewTheme } from '../../../../src/theme';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Typography, Box } from '@material-ui/core';
import { ApolloConsumer } from '@apollo/client';
import PatientAlloViewService from '../../Service/AlloView/AlloViewService';

//const premisePath = window.location.pathname.split('/')[1];

const submitSuccess = (val, result) => {
  // window.location.href = `/${premisePath}/AlloView/Results?successMessage=Success`;
  window.location.href = window.location.pathname.replace(
    'alloViewRecord',
    'alloView'
  );
};

const handleError = () => {
  console.log(1, 'Unable to create AlloView record');
};

// const handleCancel = (resp) => {
//   //window.location.href = `/${premisePath}/AlloView/Results`;
//   history.push(`/patient/${id}/AlloView`);
// };

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiBox-root': {
      '& .MuiTypography-body1': {
        fontSize: '0.85rem !important',
      },
    },
  },
}));

function AlloViewRecord() {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const handleCancel = (resp) => {
    history.push(`/patient/${id}/AlloView`);
  };
  return (
    <>
      <Box p={1}>
        <Typography variant="h6">
          New AiKidney &trade; Likelihood of Rejection Record
        </Typography>
      </Box>
      <ErrorBoundary>
        <ApolloConsumer>
          {(client) => (
            <>
              <AlloViewThemeProvider currentTheme={alloViewTheme}>
                <AlloViewContextProvider
                  alloViewService={new PatientAlloViewService(client)}
                  responseTransformer={() => []}
                  requestTransformer={() => []}
                >
                  <NewScoreContextProvider>
                    <AlloViewCalculate
                      className={classes.root}
                      patId={id || ''}
                      onSuccessSubmit={submitSuccess}
                      onErrorSubmit={handleError}
                      onCancel={handleCancel}
                    ></AlloViewCalculate>
                  </NewScoreContextProvider>
                </AlloViewContextProvider>
              </AlloViewThemeProvider>
            </>
          )}
        </ApolloConsumer>
      </ErrorBoundary>
    </>
  );
}
export default AlloViewRecord;
