import React from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  centeredText: {
    textAlign: 'center',
  },
  containerPadding: {
    paddingBottom: '25px',
  },
});

export default function AlloCareRowHeader() {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      className={classes.containerPadding}
    >
      <Grid item xs={2}>
        <Box borderRight={2} className={classes.centeredText}>
          <Typography>
            <strong>Select Measures</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box borderRight={2} className={classes.centeredText}>
          <Typography>
            <strong>Personalized Baseline</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box borderRight={2} className={classes.centeredText}>
          <Typography>
            <strong>Alert %</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Box className={classes.centeredText}>
          <Typography>
            <strong>Population Baseline</strong>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
