/* istanbul ignore file */

import Constants from '../Utilities/constants';

export const personalizationReducer = (state, action) => {
  switch (action.type) {
    case Constants.MEASURE:
      var currentMeasure = {
        ...state[action.payload.idx],
        [action.payload.type]: action.payload.value,
      };

      var newState = [...state];
      newState[action.payload.idx] = currentMeasure;

      /* When checking or unchecking the systolic measure, same value needs to be applied to diastolic */
      if (
        action.payload.type === Constants.IS_ALERT_ON &&
        action.payload.measureName === Constants.BLOOD_PRESSURE_SYSTOLIC
      ) {
        var diastolicMeassure = {
          ...state[action.payload.diastolicIdx],
          [action.payload.type]: action.payload.value,
        };

        newState[action.payload.diastolicIdx] = diastolicMeassure;
      }

      return newState;
    case Constants.MEASURE_STATE:
      return [...action.payload];
    case Constants.ALERT_STATE:
      return { ...action.payload };
    case Constants.BIND_CLICK:
      var updatedState = {
        ...state,
        Events: {
          SubmitClicked: action.payload.buttonClicked,
        },
      };
      return updatedState;
    default:
      return { ...state, [action.type]: action.payload };
  }
};
