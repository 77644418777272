import * as React from 'react';

function SvgSteps(props) {
  return (
    <svg {...props}>
      <g fillRule="evenodd">
        <path d="M4.84 10.01c-.54-.62-.72-1.45-.54-2.49.18-1.04.693-1.322 1.536-.844.83.844 1.149 1.716.955 2.616.014.366-.104.633-.353.802-.4.155-.698.232-.892.232-.194 0-.429-.106-.706-.317zM6.584 10.495c.304-.24.505-.148.601.274.146.633-.166 1.202-.643 1.35a.957.957 0 01-.872-.126c-.36-.324-.54-.563-.54-.718 0-.232-.02-.654.54-.548.374.07.678-.007.914-.232z" />
        <g>
          <path d="M9.976 8.322c.54-.62.72-1.449.54-2.49-.18-1.04-.692-1.322-1.536-.844-.83.844-1.148 1.716-.954 2.616-.014.366.103.633.352.802.402.155.699.232.893.232.193 0 .429-.105.705-.316zM8.233 8.807c-.304-.24-.505-.148-.602.274-.145.633.166 1.203.644 1.35a.957.957 0 00.871-.126c.36-.324.54-.563.54-.718 0-.232.02-.654-.54-.548-.373.07-.678-.007-.913-.232z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSteps;
