import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';
export const GET_ALLOCARE_PERSONALIZED_DATA = gql`
  query($id: String!) {
    patient(id: $id) {
      links {
        alloCare {
          ... on BaseAlloCareType {
            patientPersonalizationMeasure {
              patientId
              measureName
              measureLow
              measureHigh
              alertPercentage
              isAlertOn
              baseline
            }
            patientPersonalizationAlert {
              patientId
              isEmail
              emailAddress
              isText
              contactNumber
              frequency
            }
          }
          ... on ResolverErrorType {
            message
            details
          }
        }
      }
    }
  }
`;
export default function WithAlloCarePersonalizedData({ id, children }) {
  return (
    <WithQueryResults
      QUERY={GET_ALLOCARE_PERSONALIZED_DATA}
      variables={{ id }}
      options={{ fetchPolicy: 'no-cache' }}
    >
      {children}
    </WithQueryResults>
  );
}
