import React from 'react';
import { makeStyles } from '@material-ui/core';
import beautify from 'json-beautify';
import { TranslatedTypography as Typography } from '../../Localization';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    marginBottom: '15px',
  },
  code: {
    whiteSpace: 'pre-wrap',
    marginBottom: '15px',
  },
  codeWrapper: {
    height: '200px',
    overflowY: 'scroll',
  },
}));

export default function ErrorPage({ error, componentContext }) {
  const classes = useStyles();

  if (process.env.NODE_ENV !== 'production') {
    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.message}>
          There was an error displaying this page
        </Typography>
        <div>
          <Typography variant="h5">Stack trace</Typography>
          <div className={classes.codeWrapper}>
            <code className={classes.code}>{error.stack}</code>
          </div>
          <Typography variant="h5">Component context</Typography>
          <div className={classes.codeWrapper}>
            <code className={classes.code}>
              {beautify(componentContext, null, 2, 80)}
            </code>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <Typography variant="h4" className={classes.message}>
          There was an error displaying this page
        </Typography>
        <div>
          <Typography variant="h5">{error.message}</Typography>
        </div>
      </div>
    );
  }
}
