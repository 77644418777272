import React, { useState, useContext } from 'react';
import { Button, Dialog } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import AlloCareCheckConditions from '../PersonalizationBaseline/AlloCareCheckConditions';
import DialogTitleContent from '../PersonalizationBaseline/DialogTitleContent';
import PersonalizationData from '../PersonalizationBaseline/PersonalizationData';
import { useAlloCareConditionsMutation } from 'Components/GraphQL/useAlloCareConditionsMutation';
import { PersonalizationContext } from '../PersonalizationBaseline/state/PersonalizationContextProvider';
import { ValidationRules } from './Utilities/Validators';
import { DialogContentConfiguration } from './Utilities/DialogContentConfig';
import Constants from './Utilities/constants';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
    justifyContent: 'center',
  },
}))(MuiDialogActions);

export default function AlloCareCheckConditionsDialog({
  conditionDialogStatus,
  handleConditionDialogState,
  data,
  id,
  contentToDisplay,
  iBoxDetails,
}) {
  const {
    buttonsState,
    errorState,
    measuresState,
    alertState,
    dispatchError,
  } = useContext(PersonalizationContext);

  const validation = ValidationRules(
    dispatchError,
    measuresState,
    alertState,
    errorState
  );

  const [dialogContent, setDialogContent] = useState(contentToDisplay || '');
  const { mutate } = useAlloCareConditionsMutation();
  const [conditionState, setConditionState] = useState(data || {});

  const handleConditionChange = (event) => {
    setConditionState({
      ...conditionState,
      [event.target.name]: event.target.checked,
    });
  };

  //This function is just needed to send it as a parameter to the dialog config function.
  const setDialogContentState = (value) => {
    setDialogContent(value);
  };

  const updateState = () => {
    //Need to reset the status when closing the dialog, because the conditions state is cached by the dialog even when closed.
    if (dialogContent === Constants.CONDITION_CONTENT) setConditionState(data);
    setDialogContent(Constants.CONDITION_CONTENT);
    handleConditionDialogState(false);
    validation.resetErrors();
  };

  return (
    <>
      <Dialog
        id="checkConditionsDialog"
        onClose={() => updateState()}
        open={conditionDialogStatus}
        fullWidth={DialogContentConfiguration[dialogContent].fullWidth}
        maxWidth={DialogContentConfiguration[dialogContent].maxWidth}
      >
        <DialogTitle onClose={() => updateState()}>
          <DialogTitleContent marginLeft={'31%'} />
        </DialogTitle>
        <DialogContent>
          {dialogContent === Constants.CONDITION_CONTENT && (
            <AlloCareCheckConditions
              conditionState={conditionState}
              handleConditionChange={handleConditionChange}
              iBoxDetails={iBoxDetails}
            />
          )}
          {dialogContent === Constants.MEASURES_CONTENT && (
            <PersonalizationData
              id={id}
              handleConditionDialogState={updateState}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              if (dialogContent === Constants.CONDITION_CONTENT) {
                DialogContentConfiguration[dialogContent].onClick(
                  conditionState,
                  data,
                  setDialogContentState,
                  mutate
                );
              } else if (dialogContent === Constants.MEASURES_CONTENT) {
                DialogContentConfiguration[dialogContent].onClick(
                  errorState,
                  measuresState,
                  alertState,
                  buttonsState
                );
              }
            }}
            color="secondary"
            size="small"
          >
            {DialogContentConfiguration[dialogContent].buttonLabel}
          </Button>
          <Button variant="outlined" size="small" onClick={() => updateState()}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
