import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';
export const GET_IBOX_PATIENT = gql`
  query iboxData($pid: String!) {
    iBoxPatient(pid: $pid) {
      cibilPatientId
      pid
      firstName
      middleName
      lastName
      birthDate
      officialId
      transplantDate
      okraId
    }
  }
`;

export default function WithIBoxPatientQueryResults({ pid, children }) {
  return (
    <WithQueryResults QUERY={GET_IBOX_PATIENT} variables={{ pid }}>
      {children}
    </WithQueryResults>
  );
}
