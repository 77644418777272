import * as React from 'react';

function SvgCurrentConditionGreenIcon(props) {
  return (
    <svg
      width={27}
      height={35}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M26 32.216C26 33.747 24.768 35 23.263 35H2.737C1.232 35 0 33.747 0 32.216V2.784C0 1.253 1.232 0 2.737 0h20.526C24.768 0 26 1.253 26 2.784v29.432z"
        stroke="#654321"
        strokeWidth={0.8}
      />
      <path
        d="M3.104 21.095h4.463l2.2-9.56 4.01 17.102 2.586-12.825 1.262 5.223 5.27.112"
        stroke="#00A651"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.36 0v2.496l1.981 1.12s.16 1.952-2.246 1.952H6.872c-2.406 0-2.212-1.952-2.212-1.952l2.017-1.12V0"
        stroke="#654321"
        strokeWidth={0.8}
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCurrentConditionGreenIcon;
