import { Grid, Box, Divider } from '@material-ui/core';
import React from 'react';
function InfoRow({ range, frequency, startDate, endDate }) {
  return (
    <Grid style={{ fontWeight: 'bold' }} container item>
      <Grid container item xs={3} sm={3} lg={2} xl={2} justify="flex-end">
        {/* <Box pt={1} pb={1} className="range">
          {range} Day Average
        </Box> */}
      </Grid>
      <Grid container item xs={1} justify="center" alignItems="stretch">
        <Divider orientation="vertical" />
        <div style={{ width: '24px' }}></div>
      </Grid>
      <Grid item xs={8} sm={8} lg={9} xl={9}>
        <Box pt={1} pb={1} className="range-frequency-average">
          Range: {startDate} - {endDate}
          {/* Range: {range} Days | Frequency: {frequency} Day Average */}
        </Box>
      </Grid>
    </Grid>
  );
}

export default InfoRow;
