import { Grid, Box, Divider } from '@material-ui/core';
import { TranslatedTypography } from 'Components/Localization';
import { NoDataChart } from 'Components/Pages/Widgets/Immunosuppression/shared/NoDataChart';
import React from 'react';
import ImmunosuppressionLineChart from './ImmunosuppressionLineChart';

function ChartRow({ showXLabels, chartConfig, range, frequency, data }) {
  return (
    <Grid container style={chartConfig.containerStyle}>
      <Grid
        container
        item
        xs={3}
        sm={3}
        lg={2}
        xl={2}
        alignItems="center"
        justify="space-between"
        style={{ flexWrap: 'nowrap' }}
      >
        <Box
          ml={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          flex="1"
        >
          {chartConfig.labelIcon}
          <TranslatedTypography>{chartConfig.label} </TranslatedTypography>
        </Box>
        <TranslatedTypography>
          {`${data.average || 0} ${data.unit || ''}`}
        </TranslatedTypography>
      </Grid>
      <Grid container item xs={1} justify="center" alignItems="stretch">
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={8} sm={8} lg={9} xl={9}>
        {data?.series?.length > 0 &&
        data.series[0].elements.some((p) => p.value) ? (
          <ImmunosuppressionLineChart
            dataSets={chartConfig.getDataSets(data)}
            chartConfiguration={chartConfig}
            range={range}
            frequency={frequency}
            showXLabels={showXLabels}
            canvasHeight={150}
            labelDayFormat={'MM/DD/YYYY'}
            scaleXPadding={10}
          />
        ) : (
          <NoDataChart range={range}></NoDataChart>
        )}
      </Grid>
    </Grid>
  );
}

export default ChartRow;
