import React from 'react';
import { useHistory } from 'react-router-dom';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import { Box, makeStyles, Button } from '@material-ui/core';
import { TranslatedTypography as Typography } from '../Localization';
import { useSmartAuthentication } from '../Smart';

const useStyles = makeStyles((theme) => ({
  button: {
    flex: 1,
    textAlign: 'center',
    margin: theme.spacing(1),
  },
  horizontal: {
    padding: '15px',
    paddingBottom: '0px',
    display: 'flex',
    flexDirection: 'row',
  },
  rightControls: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column-reverse',
    textAlign: 'right',
  },
  leftControls: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
}));

export default function PatientLayout(props) {
  const history = useHistory();
  const classes = useStyles();
  const { authState } = useSmartAuthentication();
  const { children, header, subHeader, rightControls, leftControls } = props;

  const pathname = history.location.pathname;
  const idxLastFx = pathname.lastIndexOf('/');
  let newPathname = pathname.substring(0, idxLastFx);
  let friendlyBacktrackName = 'Dashboard';
  if (
    newPathname.endsWith('patient') ||
    newPathname.endsWith('patientcharts')
  ) {
    newPathname = '/';
    friendlyBacktrackName = 'Patient List';
  }

  function handleBackButtonClick() {
    history.push(newPathname);
  }
  return (
    <>
      {authState.showBanner && header}
      {(authState.showBanner || friendlyBacktrackName !== 'Patient List') && (
        <Box className={classes.horizontal}>
          <Box className={classes.leftControls}>{leftControls}</Box>
          <Box className={classes.button}>
            <Button
              color="primary"
              onClick={handleBackButtonClick}
              startIcon={<FormatListBulletedRoundedIcon />}
            >
              <Typography>{friendlyBacktrackName}</Typography>
            </Button>
          </Box>
          <Box>{subHeader}</Box>
          <Box className={classes.rightControls}>{rightControls}</Box>
        </Box>
      )}
      {children}
    </>
  );
}
