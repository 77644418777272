import React, { createContext, useMemo, useReducer } from 'react';
import { personalizationReducer } from '../Utilities/PersonalizationReducer';

const Events = {
  SubmitClicked: () => {},
};

const PersonalizationContext = createContext({
  measuresState: {},
  dispatchMeasure: ({ type, payload }) => undefined,
  alertState: {},
  dispatchAlert: ({ type, payload }) => undefined,
  errorState: {},
  dispatchError: ({ type, payload }) => undefined,
  buttonsState: Events,
  dispatchButtons: (payload) => undefined,
});

const PersonalizationContextProvider = ({ children, ...initialStates }) => {
  const [measuresState, dispatchMeasure] = useReducer(
    personalizationReducer,
    initialStates?.measuresState || []
  );

  const [alertState, dispatchAlert] = useReducer(
    personalizationReducer,
    initialStates?.alertState || {}
  );

  const [errorState, dispatchError] = useReducer(
    personalizationReducer,
    initialStates?.errorState || {}
  );

  const [buttonsState, dispatchButtons] = useReducer(
    personalizationReducer,
    initialStates?.buttonsState || {}
  );

  const value = useMemo(
    () => ({
      measuresState,
      alertState,
      errorState,
      buttonsState,
      dispatchMeasure,
      dispatchAlert,
      dispatchError,
      dispatchButtons,
    }),
    [measuresState, alertState, errorState, buttonsState]
  );

  return (
    <PersonalizationContext.Provider value={value}>
      {children}
    </PersonalizationContext.Provider>
  );
};

//const SettingsConsumer = PersonalizationContext.Consumer;

export {
  //   SettingsConsumer,
  PersonalizationContext,
  PersonalizationContextProvider,
};
