/* istanbul ignore file */

import React, { createContext, useMemo, useReducer } from 'react';
import { reducer } from '../Utilities/Data';

const IBoxContext = createContext({
  state: {},
  dispatch: ({ type, payload }) => undefined,
  patientState: {},
  dispatchPat: ({ type, payload }) => undefined,
  errorState: {},
  dispatchError: ({ type, payload }) => undefined,
});

const IBoxContextProvider = ({ children, ...initialStates }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialStates?.parameterState || {}
  );

  const [patientState, dispatchPat] = useReducer(
    reducer,
    initialStates?.patientState || {
      isNewPatient: true,
    }
  );
  const [errorState, dispatchError] = useReducer(
    reducer,
    initialStates?.errorState || {}
  );
  const value = useMemo(
    () => ({
      state,
      patientState,
      errorState,
      dispatch,
      dispatchPat,
      dispatchError,
    }),
    [state, patientState, errorState]
  );
  return <IBoxContext.Provider value={value}>{children}</IBoxContext.Provider>;
};

const SettingsConsumer = IBoxContext.Consumer;

export { SettingsConsumer, IBoxContext, IBoxContextProvider };
