import React, { useState } from 'react';
import PatientListTable from './PatientListTable';
import NotesPage from '../Patient/Notes/NotesPage';
import WithListConfigData from '../../GraphQL/WithListConfigData';
import WithPatientListData from '../../GraphQL/WithPatientListData';
import WithListConfigMutation from '../../GraphQL/WithListConfigMutation';
import WithTypesData from '../../GraphQL/WithTypesData';
import { buildFieldSchema } from '../../../filterSchema';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import ErrorPage from '../Error/ErrorPage';
import { TranslatedDialogTitle as DialogTitle } from '../../Localization';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeNoteDialog: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}));

function ViewPatientListPage({
  match: {
    params: { id, cid },
  },
}) {
  const classes = useStyles();
  const labReviewId = 'ea0bf4bf-29a5-4a87-85e2-fae7f7787c7e';

  const prevState = arguments[0].history.location.state;
  const [noteOpen, setNoteOpen] = useState(false);
  const [currentTargetId, setCurrentTargetId] = useState(null);
  const handleNoteClick = (id) => {
    setCurrentTargetId(id);
    setNoteOpen(true);
  };

  const handleNoteClose = () => {
    setNoteOpen(false);
  };

  return (
    <>
      <WithListConfigData
        id={!id ? labReviewId : id}
        includePatientCollection={!!cid}
        collectionId={cid || ''}
      >
        {({ listConfig, schema: { types } }) => {
          const typeNames = types.map((t) => t.name);
          return (
            <WithTypesData typeNames={typeNames}>
              {(typeData) => (
                <WithPatientListData config={listConfig} typeData={typeData}>
                  {({ list }) => {
                    const fieldTypes = buildFieldSchema(
                      'ListRowType',
                      typeData
                    );
                    const fieldsWithDescriptions = listConfig.fields.map(
                      (f) => {
                        const type = fieldTypes[f];
                        var title = type.description.split('|')[0];
                        return {
                          title: title,
                          type: type.type.name,
                          path: f,
                        };
                      }
                    );
                    return (
                      <WithListConfigMutation>
                        {(upsertListConfig, saving) => (
                          <PatientListTable
                            title={listConfig.title}
                            list={list}
                            fields={fieldsWithDescriptions}
                            hasDefaultSort={listConfig.hasDefaultSort}
                            defaultSortField={listConfig.defaultSortField}
                            defaultSortDirection={
                              listConfig.defaultSortDirection
                            }
                            listConfig={listConfig}
                            upsertListConfig={upsertListConfig}
                            saving={saving}
                            prevState={prevState}
                            onNoteClick={handleNoteClick}
                          />
                        )}
                      </WithListConfigMutation>
                    );
                  }}
                </WithPatientListData>
              )}
            </WithTypesData>
          );
        }}
      </WithListConfigData>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={noteOpen}
        onClose={handleNoteClose}
      >
        <DialogTitle>
          Notes
          <IconButton
            className={classes.closeNoteDialog}
            onClick={handleNoteClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <NotesPage patientId={currentTargetId} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoteClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ErrorBoundaryHOC(ViewPatientListPage, ErrorPage);
