import { SvgIcon } from '@material-ui/core';
import {
  BloodPressure,
  MedicineSm,
  PulseOx,
  Scale,
  Selector,
  Urine,
  Water,
} from 'icons/IconComponents';
import React from 'react';

const ChartTypes = {
  inpatientAdmission: 'inpatientAdmission',
  erVisit: 'erVisit',
  biopsy: 'biopsy',
  newDiagnosis: 'newDiagnosis',
  transplantMeds: 'transplantMeds',
  mycophenolateMofetil: 'mycophenolateMofetil',
  careDxTests: 'careDxTests',
  newStatus: 'newStatus',

  tacrolimus: 'tacrolimus',
  prednisone: 'prednisone',
  rapamune: 'rapamune',
  valganciclovirl: 'valganciclovirl',
  sirolimus: 'sirolimus',
  bactrim: 'bactrim',

  alloMap: 'alloMap',
  alloSure: 'alloSure',
};
const ChartsConfiguration = {};

const waterIcon = new Image();
waterIcon.height = 10;
waterIcon.width = 10;
waterIcon.src = '/images/water.svg';

// subgroup starts

ChartsConfiguration[ChartTypes.mycophenolateMofetil] = {
  id: ChartTypes.mycophenolateMofetil,
  parentId: ChartTypes.transplantMeds,
  chartType: 'line',
  label: 'Mycophenolate Mofetil',
  // labelIcon: <SvgIcon component={PulseOx} viewBox="3 3 8 9"></SvgIcon>,
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    const pointStyles = data.series[0].elements.map((d, i) => {
      if (i === 0) return 'circle';
      if (i === data.series[0].elements.length - 1) return 'line';
      else return 'triangle';
    });

    const rotations = data.series[0].elements.map((d, i) => {
      if (i === data.series[0].elements.length - 1) return 90;
      else return 0;
    });

    return [
      {
        pointStyle: pointStyles,
        rotation: rotations,
        pointRadius: 4,
        pointBorderColor: 'rgb(245, 206, 66)',
        label: 'Mycophenolate Mofetil',
        borderWidth: 2,
        backgroundColor: 'rgb(245, 206, 66)',
        borderColor: 'rgb(245, 206, 66)',
        data: data.series[0].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.tacrolimus] = {
  id: ChartTypes.tacrolimus,
  parentId: ChartTypes.transplantMeds,
  label: 'Tacrolimus',
  chartType: 'line',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    const pointStyles = data.series[0].elements.map((d, i) => {
      if (i === 0) return 'circle';
      if (i === data.series[0].elements.length - 1) return 'line';
      else return 'triangle';
    });

    const rotations = data.series[0].elements.map((d, i) => {
      if (i === data.series[0].elements.length - 1) return 90;
      else return 0;
    });
    return [
      {
        label: 'Tacrolimus',
        backgroundColor: 'rgb(245, 206, 66)',
        borderColor: 'rgb(245, 206, 66)',
        borderWidth: 2,
        pointRadius: 3,
        data: data.series[0].elements,
        pointStyle: pointStyles,
        rotation: rotations,
      },
    ];
  },
  tickCallBack: () => {},
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.prednisone] = {
  id: ChartTypes.prednisone,
  parentId: ChartTypes.transplantMeds,
  label: 'Prednisone',
  chartType: 'line',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    const pointStyles = data.series[0].elements.map((d, i) => {
      if (i === 0) return 'circle';
      if (i === data.series[0].elements.length - 1) return 'line';
      else return 'triangle';
    });

    const rotations = data.series[0].elements.map((d, i) => {
      if (i === data.series[0].elements.length - 1) return 90;
      else return 0;
    });
    return [
      {
        label: 'Prednisone',
        backgroundColor: 'rgb(245, 206, 66)',
        borderColor: 'rgb(245, 206, 66)',
        borderWidth: 2,
        pointRadius: 3,
        data: data.series[0].elements,
        pointStyle: pointStyles,
        rotation: rotations,
      },
    ];
  },
  tickCallBack: () => {},
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.rapamune] = {
  id: ChartTypes.rapamune,
  parentId: ChartTypes.transplantMeds,
  label: 'Rapamune',
  chartType: 'line',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    const pointStyles = data.series[0].elements.map((d, i) => {
      if (i === 0) return 'circle';
      if (i === data.series[0].elements.length - 1) return 'line';
      else return 'triangle';
    });

    const rotations = data.series[0].elements.map((d, i) => {
      if (i === data.series[0].elements.length - 1) return 90;
      else return 0;
    });
    return [
      {
        label: 'Rapamune',
        backgroundColor: 'rgb(245, 206, 66)',
        borderColor: 'rgb(245, 206, 66)',
        borderWidth: 2,
        pointRadius: 3,
        data: data.series[0].elements,
        pointStyle: pointStyles,
        rotation: rotations,
      },
    ];
  },
  tickCallBack: () => {},
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.valganciclovirl] = {
  id: ChartTypes.valganciclovirl,
  parentId: ChartTypes.transplantMeds,
  label: 'Valganciclovirl',
  chartType: 'line',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    const pointStyles = data.series[0].elements.map((d, i) => {
      if (i === 0) return 'circle';
      if (i === data.series[0].elements.length - 1) return 'line';
      else return 'triangle';
    });

    const rotations = data.series[0].elements.map((d, i) => {
      if (i === data.series[0].elements.length - 1) return 90;
      else return 0;
    });
    return [
      {
        label: 'Valganciclovirl',
        backgroundColor: 'rgb(245, 206, 66)',
        borderColor: 'rgb(245, 206, 66)',
        borderWidth: 2,
        pointRadius: 3,
        data: data.series[0].elements,
        pointStyle: pointStyles,
        rotation: rotations,
      },
    ];
  },
  tickCallBack: () => {},
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.sirolimus] = {
  id: ChartTypes.sirolimus,
  parentId: ChartTypes.transplantMeds,
  label: 'Sirolimus',
  chartType: 'line',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    const pointStyles = data.series[0].elements.map((d, i) => {
      if (i === 0) return 'circle';
      if (i === data.series[0].elements.length - 1) return 'line';
      else return 'triangle';
    });

    const rotations = data.series[0].elements.map((d, i) => {
      if (i === data.series[0].elements.length - 1) return 90;
      else return 0;
    });
    return [
      {
        label: 'Sirolimus',
        backgroundColor: 'rgb(245, 206, 66)',
        borderColor: 'rgb(245, 206, 66)',
        borderWidth: 2,
        pointRadius: 3,
        data: data.series[0].elements,
        pointStyle: pointStyles,
        rotation: rotations,
      },
    ];
  },
  tickCallBack: () => {},
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.bactrim] = {
  id: ChartTypes.bactrim,
  parentId: ChartTypes.transplantMeds,
  label: 'Bactrim',
  chartType: 'line',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    const pointStyles = data.series[0].elements.map((d, i) => {
      if (i === 0) return 'circle';
      if (i === data.series[0].elements.length - 1) return 'line';
      else return 'triangle';
    });

    const rotations = data.series[0].elements.map((d, i) => {
      if (i === data.series[0].elements.length - 1) return 90;
      else return 0;
    });
    return [
      {
        label: 'Bactrim',
        backgroundColor: 'rgb(245, 206, 66)',
        borderColor: 'rgb(245, 206, 66)',
        borderWidth: 2,
        pointRadius: 3,
        data: data.series[0].elements,
        pointStyle: pointStyles,
        rotation: rotations,
      },
    ];
  },
  tickCallBack: () => {},
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.alloMap] = {
  id: ChartTypes.alloMap,
  parentId: ChartTypes.careDxTests,
  label: 'AlloMap',
  chartType: 'line',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'AlloMap',
        backgroundColor: 'rgb(140, 221, 255)',
        borderColor: 'rgb(140, 221, 255)',
        borderWidth: 2,
        pointRadius: 4,
        data: data.series[0].elements,
      },
    ];
  },
  tickCallBack: () => {},
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.alloSure] = {
  id: ChartTypes.alloSure,
  parentId: ChartTypes.careDxTests,
  label: 'AlloSure',
  chartType: 'line',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'AlloSure',
        backgroundColor: 'rgb(140, 221, 255)',
        borderColor: 'rgb(134, 240, 96)',
        borderWidth: 2,
        pointBorderColor: 'red',

        pointRadius: 4,
        data: data.series[0].elements,
        // pointStyle: waterIcon,
      },
    ];
  },
  tickCallBack: () => {},
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
//subgroup ends

ChartsConfiguration[ChartTypes.inpatientAdmission] = {
  id: ChartTypes.inpatientAdmission,
  title: 'Encounters',
  label: 'Inpatient Admission',
  chartType: 'scatter',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'Inpatient Admission',
        backgroundColor: 'purple',
        borderColor: 'purple',
        borderWidth: 2,
        pointRadius: 4,
        data: data.series[0].elements,
        // pointStyle: waterIcon,
      },
    ];
  },
  tickCallBack: () => {},
  labelIcon: <SvgIcon component={Water} viewBox="4 4 10 10"></SvgIcon>,
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.erVisit] = {
  id: ChartTypes.erVisit,
  chartType: 'scatter',
  label: 'ER Visit',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'ER Visit',
        backgroundColor: 'purple',
        borderColor: 'purple',
        borderWidth: 2,
        pointRadius: 4,
        data: data.series[0].elements,
      },
    ];
  },
  labelIcon: <SvgIcon component={Urine} viewBox="3 4 8 8"></SvgIcon>,
  group: 1,
  datalabels: {
    color: '#36A2EB',
  },
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.biopsy] = {
  id: ChartTypes.biopsy,
  title: 'Procedures',
  chartType: 'scatter',
  label: 'Biopsy',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        pointRadius: 4,
        backgroundColor: 'skyblue',
        borderColor: 'skyblue',
        borderWidth: 2,
        label: 'Biopsy',
        data: data.series[0].elements,
      },
    ];
  },
  labelIcon: <SvgIcon component={Scale} viewBox="4 4 10 10"></SvgIcon>,
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.newDiagnosis] = {
  id: ChartTypes.newDiagnosis,
  title: 'Diagnostics',
  chartType: 'scatter',
  labelIcon: <SvgIcon component={BloodPressure} viewBox="4 4 8 8"></SvgIcon>,
  label: 'New Diagnosis',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        pointRadius: 4,
        backgroundColor: 'blue',
        borderColor: 'blue',
        label: 'New Diagnosis',
        borderWidth: 2,
        data: data.series[0].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 3,
  dataLabelsOffSet: 2,
};

ChartsConfiguration[ChartTypes.transplantMeds] = {
  id: ChartTypes.transplantMeds,
  chartType: 'scatter',
  title: 'Medications',
  label: 'Transplant Meds',
  labelIcon: <SvgIcon component={PulseOx} viewBox="3 3 8 9"></SvgIcon>,
  color: (value) => {
    return 'black';
  },
  hasChildren: true,
  getDataSets: (data) => {
    return [
      {
        pointRadius: 4,
        label: 'Mycophenolate Mofetil',
        borderWidth: 2,
        backgroundColor: 'rgb(245, 206, 66)',
        borderColor: 'rgb(245, 206, 66)',
        data: data.series[0].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.careDxTests] = {
  id: ChartTypes.careDxTests,
  title: 'Lab Tests',
  chartType: 'scatter',
  labelIcon: <SvgIcon component={MedicineSm} viewBox="4 4 10 10"></SvgIcon>,
  label: 'CareDx Tests',
  color: (value) => {
    return 'black';
  },
  hasChildren: true,
  getDataSets: (data) => {
    return [
      {
        // pointStyle: 'circle',
        pointRadius: 4,
        // pointBorderColor: 'rgb(250, 140, 230)',
        label: 'CareDx Tests',
        borderWidth: 2,
        backgroundColor: 'rgb(208, 47, 29)',
        borderColor: 'rgb(208, 47, 29)',
        data: data.series[0].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.newStatus] = {
  id: ChartTypes.newStatus,
  title: 'Life Events',
  chartType: 'scatter',
  label: 'New Status',
  labelIcon: <SvgIcon component={Selector} viewBox="3 4 8 9"></SvgIcon>,
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'New Status',
        // backgroundColor: 'red',
        borderWidth: 2,
        backgroundColor: 'green',
        borderColor: 'green',
        pointRadius: 4,
        data: data.series[0].elements,
      },
    ];
  },
  group: 3,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
const EventTimelineReportFrequency = {
  daily: 1,
  threeDays: 3,
  tenDays: 10,
};

const EventTimelineReportRange = {
  week: 7,
  month: 30,
  quarter: 90,
};

export {
  ChartsConfiguration,
  ChartTypes,
  EventTimelineReportRange,
  EventTimelineReportFrequency,
};
