import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
} from '@material-ui/core';
import { Delete as TrashIcon } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const StyledCard = styled(Card)(() => ({
  marginTop: '10px',
}));

export default function NoteCard({
  id,
  patientId,
  userFullName,
  note,
  createdAt,
  onDeleteClick,
}) {
  const { t } = useTranslation();
  const handleDeleteClick = () => {
    onDeleteClick(id, patientId);
  };
  return (
    <StyledCard>
      <CardHeader
        avatar={<Avatar>{userFullName[0]}</Avatar>}
        action={
          <IconButton onClick={handleDeleteClick}>
            <TrashIcon />
          </IconButton>
        }
        title={userFullName}
        subheader={t('formattedDate.LLL', { date: new Date(createdAt) })}
      />
      <CardContent>{note}</CardContent>
    </StyledCard>
  );
}
