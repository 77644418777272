import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';
export const GET_ALLOCARE_CONDITIONS_DATA = gql`
  query($id: String!) {
    patient(id: $id) {
      links {
        alloCare {
          ... on BaseAlloCareType {
            patientPersonalizationCondition {
              patientId
              oliguriaCondition
              bmiCondition
              diabetesCondition
              hypertensionCondition
              copdCondition
              depressionCondition
            }
          }
          ... on ResolverErrorType {
            message
            details
          }
        }
      }
    }
  }
`;

export default function WithAlloCareConditionsData({ id, children }) {
  return (
    <WithQueryResults
      QUERY={GET_ALLOCARE_CONDITIONS_DATA}
      variables={{ id }}
      options={{ fetchPolicy: 'no-cache' }}
    >
      {children}
    </WithQueryResults>
  );
}
