import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ErrorBoundaryHOC from '../../../ErrorBoundaryHOC';
import ErrorPage from '../../Error/ErrorPage';
import AlloIndividualChart from './AlloIndividualChart';
import PatientLayout from 'Components/Layout/PatientLayout';
import { alloSureHistrogramChartConfigurationMode } from '../../../Utilities/Infrastucture/alloSureHistrogramChartConfigurationMode';
import { useParams } from 'react-router-dom';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';

export function AlloIndividualChartPage() {
  const { id } = useParams();
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
      includeAllocare={true}
      includeImmunosuppression={true}
      days={7}
      averageSliceSize={1}
    >
      {(data) => {
        return (
          <div>
            <Card sx={{ maxWidth: 50, width: 50 }}>
              <CardContent>
                <PatientLayout>
                  <AlloIndividualChart
                    width={'100%'}
                    height={'80'}
                    configurationMode={
                      alloSureHistrogramChartConfigurationMode.PAGE
                    }
                    alloSureData={data}
                  />
                </PatientLayout>
              </CardContent>
            </Card>
          </div>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(AlloIndividualChartPage, ErrorPage);
