import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import { TextField } from '@material-ui/core';

function EventTimelineRange({ onDateChange }) {
  const _start = moment(new Date(2016, 8, 20, 0, 0, 0, 0));
  const _end = moment(_start).add(5, 'days').subtract(1, 'second');

  const [start, setStart] = React.useState(_start);
  const [end, setEnd] = React.useState(_end);

  const applyCallback = (startDate, endDate) => {
    console.log('Apply Callback');
    console.log(startDate.format('DD-MM-YYYY HH:mm'));
    console.log(endDate.format('DD-MM-YYYY HH:mm'));
    setStart(startDate);
    setEnd(endDate);
  };

  useEffect(() => {
    onDateChange(start.format('MM-DD-YYYY'), end.format('MM-DD-YYYY'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end]);

  const rangeCallback = (index, value) => {
    console.log(index, value);
  };

  let now = new Date();
  let startToday = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  );
  let endToday = moment(startToday).add(1, 'days').subtract(1, 'seconds');

  const todaySubstracted = () =>
    moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
  let ranges = {
    'Last 1 month': [todaySubstracted().subtract(1, 'months'), startToday],
    'Last 3 months': [todaySubstracted().subtract(3, 'months'), startToday],
    'Last 6 months': [todaySubstracted().subtract(6, 'months'), startToday],
    'Year to date': [todaySubstracted().subtract(1, 'years'), startToday],
  };
  let local = {
    format: 'MM-DD-YYYY',
    sundayFirst: false,
  };
  let maxDate = moment(endToday).add(24, 'hour');

  let value = `${start.format('MM-DD-YYYY')} - ${end.format('MM-DD-YYYY')}`;
  let disabled = true;

  return (
    <Grid>
      <DateTimeRangeContainer
        ranges={ranges}
        start={start}
        end={end}
        local={local}
        maxDate={maxDate}
        applyCallback={applyCallback}
        rangeCallback={rangeCallback}
        smartMode
      >
        <TextField
          name="dateRangeFrom"
          InputLabelProps={{ shrink: true }}
          style={{ width: '50%' }}
          label="Date Range"
          type="text"
          variant="outlined"
          autoComplete="off"
          size="small"
          value={value}
          disabled={disabled}
        />
      </DateTimeRangeContainer>
    </Grid>
  );
}

export default EventTimelineRange;
