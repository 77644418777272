import React from 'react';
import { CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
  },
  content: {
    float: 'right',
    textAlign: 'left',
    width: '90%',
    display: 'flex',
  },
  title: {
    width: '30%',
  },
  subheader: {
    float: 'right',
    width: '70%',
  },
}));

export default function FieldCard({ field, action }) {
  const classes = useStyles();

  const desc = field.description;
  const descParts = desc.split('|');
  const title = descParts[0];
  const description = descParts[1];

  return (
    <CardHeader
      classes={{
        root: classes.root,
        content: classes.content,
        title: classes.title,
        subheader: classes.subheader,
      }}
      action={action}
      title={title}
      subheader={description}
    />
  );
}
