import React from 'react';

export default function MedicationRequest({ data }) {
  var codings = data.medication.coding;
  const displayCodings = [];
  for (let code of codings) {
    displayCodings.push(
      <div key={code.code}>
        <span>{code.display}</span>
      </div>
    );
  }

  return <div>{displayCodings}</div>;
}
