import { Grid, Box, Divider } from '@material-ui/core';
import { TranslatedTypography } from 'Components/Localization';
import React from 'react';
import AlloCareLineChart from './AlloCareLineChart';

function ChartRow({ showXLabels, chartConfig, range, frequency, data }) {
  return (
    <Grid container>
      <Grid
        container
        item
        xs={3}
        sm={3}
        lg={2}
        xl={2}
        alignItems="center"
        justify="space-between"
        //style={{ padding: '10px' }}
      >
        <Box ml={1} display="flex" flexDirection="row">
          {chartConfig.labelIcon}
          <TranslatedTypography>{chartConfig.label} </TranslatedTypography>
        </Box>
        <TranslatedTypography>
          {`${data.average || 0} ${data.unit || ''}`}
        </TranslatedTypography>
      </Grid>
      <Grid container item xs={1} justify="center" alignItems="stretch">
        <Divider orientation="vertical" />
      </Grid>
      <Grid item xs={8} sm={8} lg={9} xl={9}>
        {data.series?.length > 0 && data.series[0].elements.length > 0 ? (
          <AlloCareLineChart
            dataSets={chartConfig.getDataSets(data)}
            chartConfiguration={chartConfig}
            range={range}
            frequency={frequency}
            showXLabels={showXLabels}
            canvasHeight={150}
            labelDayFormat={'MM/DD/YYYY'}
            scaleXPadding={10}
          />
        ) : (
          <TranslatedTypography className="no-data">
            {`Last ${data.days || 0} No Data Available`}
          </TranslatedTypography>
        )}
      </Grid>
    </Grid>
  );
}

export default ChartRow;
