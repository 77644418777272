import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  makeStyles,
} from '@material-ui/core';
import ErrorBoundaryHOC from '../../../ErrorBoundaryHOC';
import ErrorPage from '../../Error/ErrorPage';
import IBoxReport from './IBoxReport';
import IBoxCalculatorDataEntry from './IBoxCalculatorDataEntry';
import { useIBoxReportMutation } from 'Components/GraphQL/useIBoxReportMutation';
import { IBoxContext } from './state/IBoxContextProvider';
import DataLoading from 'Components/GraphQL/DataLoading';
import { ValidationRules } from './Utilities/Validators';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    overflowY: 'auto',
    height: 'calc(100vh - 250px)',
  },
  itemContainer: {
    maxHeight: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
    },
  },
  item: {
    width: '48%',
    margin: '0 1%',
    maxHeight: '100%',
    height: 'calc(100vh - 360px)',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0',
    },
  },
  itemForm: {
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
    },
  },
  buttonContainer: {
    width: theme.spacing(14),
  },
  cardActions: {
    height: `${theme.spacing(10)}px`,
  },
  cardContent: {
    height: `calc(100% - ${theme.spacing(10)}px)`,
  },
}));

const Report = ({ reportData, rest }) => (
  <DataLoading {...rest}>
    <IBoxReport
      iboxData={reportData}
      widgetView={false}
      hasResult={true}
      height={300}
    ></IBoxReport>
  </DataLoading>
);
const MemoReport = React.memo(Report);

export function IBoxCalculatorContainer({
  formattedReportData,
  record,
  fhirPatient,
}) {
  const classes = useStyles();
  const [reportData, setReportData] = useState(formattedReportData);
  const [formRef, setFormRef] = useState(null);
  const { state, patientState, dispatchError } = useContext(IBoxContext);
  const { mutate, rest } = useIBoxReportMutation();
  const validation = ValidationRules(
    dispatchError,
    state,
    patientState,
    record?.details?.dateReported
  );
  const setFormRefCallback = function (element) {
    setFormRef(element);
  };
  const handleRunClick = function (e) {
    const submitEvent = new Event('submit');
    formRef?.dispatchEvent(submitEvent);
  };
  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.itemContainer}
        >
          <Grid item className={classes.item}>
            <MemoReport reportData={reportData} rest={rest}></MemoReport>
          </Grid>
          <Grid item className={[classes.item, classes.itemForm].join(' ')}>
            <IBoxCalculatorDataEntry
              setFormRefCallback={setFormRefCallback}
              validation={validation}
              mutateIBoxReport={mutate}
              setReportData={setReportData}
              formattedReportData={formattedReportData}
              fhirPatient={fhirPatient}
            ></IBoxCalculatorDataEntry>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Grid container justify="center">
          <Button
            className={classes.buttonContainer}
            variant="contained"
            color="secondary"
            onClick={handleRunClick}
          >
            Run
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
}

export default ErrorBoundaryHOC(IBoxCalculatorContainer, ErrorPage);
