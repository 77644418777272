import React from 'react';
import WithMyListConfigData from '../../GraphQL/WithMyListConfigData';
import WithListConfigData from '../../GraphQL/WithListConfigData';
import WithTypesData from '../../GraphQL/WithTypesData';
import WithMyListConfigMutation from '../../GraphQL/WithMyListConfigMutation';
import ListBuilder from './ListBuilder';
import ErrorPage from '../Error/ErrorPage';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import { useAuthentication } from '../../OAuth2';

export const ListBuilderEditPage = ErrorBoundaryHOC(function ({
  match: {
    params: { id, cid, handleClose, refetch },
  },
}) {
  const { authService } = useAuthentication();
  const userInfo = authService.getUser();

  return (
    <>
      {userInfo && (
        <WithMyListConfigData id={id} userId={userInfo.sub}>
          {({ myListConfig: { ...rest }, schema: { types } }) => {
            const typeNames = types.map((t) => t.name);
            const newList = false;
            return (
              <WithTypesData typeNames={typeNames}>
                {(data) => (
                  <WithMyListConfigMutation>
                    {(upsertMyListConfig, saving) => (
                      <ListBuilder
                        {...rest}
                        typeData={data}
                        collectionId={cid}
                        upsertMyListConfig={upsertMyListConfig}
                        saving={saving}
                        handleClose={handleClose}
                        userId={userInfo.sub}
                        newList={newList}
                        refetch={refetch}
                      />
                    )}
                  </WithMyListConfigMutation>
                )}
              </WithTypesData>
            );
          }}
        </WithMyListConfigData>
      )}
    </>
  );
}, ErrorPage);

export const ListBuilderNewPage = ErrorBoundaryHOC(function ({
  match: {
    params: { id, cid, handleClose, refetch },
  },
}) {
  const { authService } = useAuthentication();
  const userInfo = authService.getUser();

  return (
    <>
      {userInfo && (
        <WithListConfigData
          id={id}
          collectionId={cid}
          includePatientCollection={!!cid}
        >
          {({ listConfig: { ...rest }, schema: { types } }) => {
            const typeNames = types.map((t) => t.name);
            const newList = true;
            // const { filter, ...rest } = {
            //     filter: [],
            //     fields: [],
            //     title: "",
            //     id: id,
            //     isStaticList: false,
            //     collectionId: null,
            //     patientCollection: [],
            //     hasDefaultSort: false,
            //     defaultSortField: "",
            //     defaultSortDirection: ""
            // };
            return (
              <WithTypesData typeNames={typeNames}>
                {(data) => (
                  <WithMyListConfigMutation>
                    {(upsertMyListConfig, saving) => (
                      <ListBuilder
                        {...rest}
                        typeData={data}
                        upsertMyListConfig={upsertMyListConfig}
                        saving={saving}
                        userId={userInfo.sub}
                        handleClose={handleClose}
                        newList={newList}
                        refetch={refetch}
                      />
                    )}
                  </WithMyListConfigMutation>
                )}
              </WithTypesData>
            );
          }}
        </WithListConfigData>
      )}
    </>
  );
}, ErrorPage);
