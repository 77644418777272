import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  makeStyles,
  Container,
  Box,
} from '@material-ui/core';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import ErrorPage from '../Error/ErrorPage';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    padding: theme.spacing(2.5),
    width: '100%',
    maxHeight: 200,
  },
  tableTitle: {
    fontWeight: 600,
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: '#fff',
    },
  },
  tableHeaderText: {
    fontWeight: 600,
    fontSize: '1em',
  },
}));

export function PrescribedISXReport({ medications }) {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <Box className={classes.toolbar}>
        <Typography className={classes.tableTitle} variant="subtitle1">
          Prescribed
        </Typography>
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow className={classes.tableTitle}>
              <TableCell>Meditation</TableCell>
              <TableCell>Daily Dose</TableCell>
              <TableCell>Instruction</TableCell>
              <TableCell>Rx Date</TableCell>
              <TableCell>Data Source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {medications?.map((medication, i) => (
              <TableRow className={classes.tableRow} key={i}>
                <TableCell>{medication.medicationDisplay}</TableCell>
                <TableCell>{medication.dailyDosage}</TableCell>
                <TableCell>
                  {(medication.instrauctions || []).join('; ')}
                </TableCell>
                <TableCell>{medication.rxDate}</TableCell>
                <TableCell>EMR</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default ErrorBoundaryHOC(PrescribedISXReport, ErrorPage);
