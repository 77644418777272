import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AlloIndividualChart from './AlloIndividualChart/AlloIndividualChart';
import { Grid, Box } from '@material-ui/core';
import { alloSureHistrogramChartConfigurationMode } from '../../Utilities/Infrastucture/alloSureHistrogramChartConfigurationMode';
import AlloSureHistrogramChart from './AlloSureHistogram/AlloSureHistrogramChart';
import AlloSureViolinChart from './AlloSureViolinChart/AlloSureViolinChart';
import { useHistory } from 'react-router-dom';
import DemographicsHeader from '../../Display/DemographicsHeader';
import PatientLayout from '../../Layout/PatientLayout';
import { Typography, Paper } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';
import './DashboardNew.css';

export default function DashboardPage(props) {
  const history = useHistory();
  const { id } = useParams();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  function openGraph(graphName) {
    const url = window.location.href;
    const Pid = url.split('/').pop();
    history.push({
      pathname: `/patientcharts/${Pid}/` + graphName,
      state: {
        prevPath: history.location.pathname,
      },
    });
  }
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
      includeAllocare={true}
      includeImmunosuppression={true}
      days={7}
      averageSliceSize={1}
    >
      {(data) => {
        var header = <DemographicsHeader id={id} data={data} />;
        return (
          <div>
            <Card sx={{ maxWidth: 50, width: 50 }}>
              <CardContent>
                <PatientLayout
                  header={header}
                  subHeader={<Typography variant="h5">Dashboard</Typography>}
                >
                  <Box sx={{ width: '100%' }}>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{
                          padding: '1em',
                          maxHeight: '300px !important',
                        }}
                      >
                        <Item>
                          <Box
                            onClick={() =>
                              openGraph('alloSureHistrogramChartPage')
                            }
                          >
                            <h4
                              style={{
                                textAlign: 'center',
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              AlloSure
                            </h4>
                            <AlloSureHistrogramChart
                              width={'100%'}
                              height={'70'}
                              configurationMode={
                                alloSureHistrogramChartConfigurationMode.WIDGET
                              }
                              alloSureData={data}
                            />
                          </Box>
                        </Item>
                        <Item className="indivdualChart">
                          {' '}
                          <Box
                            onClick={() => openGraph('alloIndividualChartPage')}
                          >
                            <h4
                              style={{
                                textAlign: 'center',
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              AlloSure RCV
                            </h4>
                            <AlloIndividualChart
                              width={'100%'}
                              height={'70'}
                              configurationMode={
                                alloSureHistrogramChartConfigurationMode.WIDGET
                              }
                              alloSureData={data}
                            />
                          </Box>
                        </Item>
                      </Grid>
                      <Grid item xs={6} style={{ padding: '1em' }}>
                        <Item>
                          <Box
                            onClick={() => openGraph('alloSureViolinChartPage')}
                          >
                            <h4
                              style={{
                                textAlign: 'center',
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              AlloSure Violin Chart
                            </h4>
                            <AlloSureViolinChart
                              alloSureData={data}
                              width={'800'}
                              height={'615'}
                            />
                          </Box>
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>
                </PatientLayout>
                <div className="cardContent">
                  <Typography gutterBottom className="cardTopographyHeader">
                    <h3> Test Interpretation</h3>
                  </Typography>
                  <div className="contentBody">
                    <p>
                      AlloSure measures the percent of donor-derived cell-free
                      DNA (dd-cfDNA) in the total cell-free DNA present in
                      kidney transplant recipients.1 When interpreting AlloSure:
                    </p>
                    <ul>
                      <li>
                        {'> '}
                        1.0% dd-cfDNA is associated with a probability of active
                        rejection.
                      </li>
                      <li>
                        0.21% dd-cfDNA is the median observed in a reference
                        population of stable recipients
                      </li>
                      <li>
                        {'> '}61% increase in dd-cfDNA from a prior sample
                        exceeds the biological variability observed in the
                        reference population
                      </li>
                    </ul>
                    <p>
                      * The relative change value (&quot;RCV&quot;) represents
                      the percentage change between sequential AlloSure values.
                    </p>
                    <b>NR - No Result, US - Unacceptable Sample</b>
                    <p>
                      Clinical validity of the AlloSure test was established in
                      single-kidney transplant recipients who were at least 14
                      days post-transplant. AlloSure should be interpreted in
                      the context of clinical findings and relevant patient
                      history. For more information about AlloSure, please visit
                      AlloSure.com.
                    </p>
                    <p>
                      Specimens from kidney retransplant recipients in whom the
                      prior kidney allograft(s) remain in situ are acceptable
                      for testing. AlloSure may also be used for pediatric
                      patients.4 AlloSure is not intended for recipients of
                      other multi-organ transplants, recipients who are
                      pregnant, recipients of a transplant from a monozygotic
                      twin, or recipients of allogeneic bone marrow transplant.
                      Transfusion of blood components containing white blood
                      cells in the 30 days prior to blood draw may lead to
                      aberrant result. Renal biopsies performed in the 24 hours
                      prior to AlloSure specimen collection may elevate dd-cfDNA
                    </p>
                    <ol type="1">
                      <li>Grskovic et al., J Mol Diagn 2016;</li>
                      <li>Bloom et al., J Am Soc Nephrol 2017</li>
                      <li>Bromberg et al., J Appl Lab Med 2017</li>
                      <li>Bromberg et al., J Appl Lab Med 2017</li>
                    </ol>
                    <p>
                      The AlloSure donor-derived cell-free DNA test was
                      developed and its performance characteristics were
                      determined by the CareDx laboratory. The test has not been
                      cleared or approved by the U.S. Food and Drug
                      Administration, nor is it currently required to be. The
                      laboratory is certified under the Clinical Laboratory
                      Improvement Amendments of 1988 (CLIA &apos;88) and
                      accredited by the College of American Pathologists (CAP)
                      as qualified to perform high complexity clinical
                      laboratory testing.
                    </p>
                    <p>
                      The contents of this report are confidential and intended
                      solely for the use of authorized personnel.
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}
