import React from 'react';
import WithMyListConfigData from '../../GraphQL/WithMyListConfigData';
import WithTypesData from '../../GraphQL/WithTypesData';
import WithMyListConfigMutation from '../../GraphQL/WithMyListConfigMutation';
import MyListModal from './MyListModal';
import ErrorPage from '../Error/ErrorPage';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import { useAuthentication } from '../../OAuth2';

export const ViewMyListsModal = ErrorBoundaryHOC(function () {
  const { authService } = useAuthentication();
  const userInfo = authService.getUser();

  return (
    <>
      {userInfo && (
        <WithMyListConfigData id={''} userId={userInfo.sub}>
          {({ myListConfig: { ...rest }, schema: { types } }) => {
            const typeNames = types.map((t) => t.name);
            return (
              <WithTypesData typeNames={typeNames}>
                {(data) => (
                  <WithMyListConfigMutation>
                    {(upsertMyListConfig, saving) => (
                      <MyListModal
                        upsertMyListConfig={upsertMyListConfig}
                        saving={saving}
                        userId={userInfo.sub}
                      />
                    )}
                  </WithMyListConfigMutation>
                )}
              </WithTypesData>
            );
          }}
        </WithMyListConfigData>
      )}
    </>
  );
}, ErrorPage);
