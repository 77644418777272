import moment from 'moment';

const convertISODateToLocal = (date) => {
  return date ? moment(date).format('YYYY-MM-DD') : '';
};
const convertLocalDateToISO = (date) => {
  return date ? new Date(date) : '';
};

export { convertISODateToLocal, convertLocalDateToISO };
