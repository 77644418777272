import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';
export const GET_ALLOCARE_DATA = gql`
  query alloCareResult($id: String!, $days: Int!, $averageSliceSize: Int!) {
    alloCare(id: $id, days: $days, averageSliceSize: $averageSliceSize) {
      fluidIntake {
        ...AlloCareDetails
      }
      glucose: bloodGlucose {
        ...AlloCareDetails
      }
      bloodPressure {
        ...AlloCareDetails
      }
      steps {
        ...AlloCareDetails
      }
      urine: urineOutput {
        ...AlloCareDetails
      }
      weight {
        ...AlloCareDetails
      }
      oxygen {
        ...AlloCareDetails
      }
      temperature {
        ...AlloCareDetails
      }
    }
  }
  fragment AlloCareDetails on GraphDataType {
    unit
    averageWindowDays
    days
    average
    count
    series {
      name
      elements {
        start
        end
        value
        subCount
      }
    }
  }
`;
export default function WithAlloCareData({
  id,
  days,
  averageSliceSize,
  children,
}) {
  return (
    <WithQueryResults
      QUERY={GET_ALLOCARE_DATA}
      variables={{ id, days, averageSliceSize }}
    >
      {children}
    </WithQueryResults>
  );
}
