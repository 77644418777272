import React, { useState, useEffect } from 'react';
import { Paper, FormControl, MenuItem, makeStyles } from '@material-ui/core';
import {
  TranslatedSelect as Select,
  TranslatedInputLabel as InputLabel,
  TranslatedIconButton as IconButton,
} from '../Localization';
import { AddCircleOutline } from '@material-ui/icons';
import InputSwitchHOC from '../DataEntry/InputSwitch';

import { operationTypes, operationLabel } from '../../Utilities/tableFiltering';

const useStyles = makeStyles((theme) => ({
  filterMenu: {
    minWidth: '300px',
    background: 'white',
    padding: '10px',
  },
  selectMenu: {
    zIndex: 9999,
  },
  formControl: {
    width: '100%',
  },
  filterSelects: {
    display: 'block',
  },
}));

/**
 * Shows inputs for creating list filters.
 * Use onFilterInputActivate and onFilterInputDeactivate if you need to use this within a ClickAwayListener.
 * @component
 */
export default function FilterInput({
  onFilterAdd,
  fields,
  onFilterInputActivate,
  onFilterInputDeactivate,
}) {
  const classes = useStyles();

  const [incompleteFilter, setIncompleteFilter] = useState({
    field: '',
    operation: '',
    value: '',
    start: '',
    end: '',
    type: '',
  });

  const [popperSelectOpen, setPopperSelectOpen] = useState({
    field: false,
    operation: false,
  });

  const inputActive = popperSelectOpen.field || popperSelectOpen.operation;
  useEffect(
    function () {
      if (inputActive) {
        onFilterInputActivate();
      } else {
        onFilterInputDeactivate();
      }
    },
    [inputActive, onFilterInputActivate, onFilterInputDeactivate]
  );

  const handleFilterSelectOpen = (property) => {
    return function () {
      setPopperSelectOpen({
        ...popperSelectOpen,
        [property]: true,
      });
    };
  };

  const handleFilterSelectClose = (property) => {
    return function () {
      setPopperSelectOpen({
        ...popperSelectOpen,
        [property]: false,
      });
    };
  };

  const handleChange = (property) => {
    return function (event) {
      const value = event.target.value;
      setIncompleteFilter({
        ...incompleteFilter,
        [property]: value,
      });
    };
  };

  const handleFilterValueChange = (property) => {
    return function (event) {
      const value = unwrapValue(event);
      setIncompleteFilter({
        ...incompleteFilter,
        [property]: value,
      });
    };
  };

  const handleFieldChange = (event) => {
    const field = event.target.value;
    const fieldType = fields.filter((f) => f.path === field)[0];
    setIncompleteFilter({
      ...incompleteFilter,
      field: field,
      type: fieldType.type,
    });
  };

  const handleFilterAddClick = () => {
    const { field, ...rest } = incompleteFilter;
    const fieldType = fields.filter((f) => f.path === field)[0];
    const title = fieldType.title;
    onFilterAdd({
      [field]: {
        title,
        ...rest,
      },
    });
    setIncompleteFilter({
      field: '',
      operation: '',
      value: '',
      start: '',
      end: '',
      type: '',
    });
  };

  const canAddFilter =
    incompleteFilter.field !== '' &&
    incompleteFilter.operation !== '' &&
    (incompleteFilter.value ||
      (incompleteFilter.start && incompleteFilter.end));

  const [unwrapValue, InputField] = InputSwitchHOC(incompleteFilter.type);

  return (
    <Paper elevation={5} className={classes.filterMenu}>
      <FormControl className={classes.filterSelects}>
        <InputLabel>Field</InputLabel>
        <Select
          classes={{ selectMenu: classes.selectMenu }}
          className={classes.formControl}
          id="filter-field-select"
          onOpen={handleFilterSelectOpen('field')}
          onClose={handleFilterSelectClose('field')}
          onChange={handleFieldChange}
          value={incompleteFilter['field']}
        >
          {fields.map((f) => {
            return (
              <MenuItem key={f.path} value={f.path}>
                {f.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl className={classes.filterSelects}>
        <InputLabel>Operation</InputLabel>
        <Select
          classes={{ selectMenu: classes.selectMenu }}
          className={classes.formControl}
          id="filter-operation-select"
          onOpen={handleFilterSelectOpen('operation')}
          onClose={handleFilterSelectClose('operation')}
          onChange={handleChange('operation')}
          value={incompleteFilter['operation']}
        >
          {operationTypes().map((op) => (
            <MenuItem key={op} value={op}>
              {operationLabel(op)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {incompleteFilter.operation !== 'between' ? (
        <InputField
          className={classes.filterSelects}
          label="Value"
          value={incompleteFilter.value}
          onChange={handleFilterValueChange('value')}
        />
      ) : (
        <div>
          <InputField
            className={classes.filterSelects}
            label="Start"
            value={incompleteFilter.start}
            onChange={handleFilterValueChange('start')}
          />
          <InputField
            className={classes.filterSelects}
            label="End"
            value={incompleteFilter.end}
            onChange={handleFilterValueChange('end')}
          />
        </div>
      )}
      {/* {incompleteFilter.type} */}
      <IconButton disabled={!canAddFilter} onClick={handleFilterAddClick}>
        <AddCircleOutline />
        Add filter
      </IconButton>
    </Paper>
  );
}
