import React, { useEffect } from 'react';
import Moment from 'moment';
import { Box, Paper, makeStyles } from '@material-ui/core';
import DemographicsTimeline from '../DemographicsTimeline/DemographicsTimeline';
import { useGenAlloViewScoreByAlloSureMutation } from '../GraphQL/useGenAlloViewScoreByAlloSureMutation';

const useStyles = makeStyles((theme) => ({
  tablePaperContainer: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingBottom: 30,
    justifyContent: 'start',
    display: 'flex',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:1280px)']: {
      flexDirection: 'column',
      height: 180,
    },
  },
  labelBold: {
    fontWeight: 'bold',
    paddingRight: 5,
  },
  timelineContainer: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75px',
    position: 'relative',
  },
}));

export default function DemographicsHeader({ id, data, showTimeLine }) {
  const { mutate } = useGenAlloViewScoreByAlloSureMutation();
  const birthDate = data?.patient?.fhir?.birthDate;
  const gender = data?.patient?.fhir?.gender;
  useEffect(() => {
    mutate({
      variables: {
        patientId: id,
        birthDate: birthDate,
        gender: gender,
      },
    });
  }, [id, birthDate, gender, mutate]);
  const classes = useStyles();
  const demographicInfo =
    data.patient?.star === null
      ? data.patient?.fhir
      : data.patient?.star.demographicInfo;
  const eventTimeline = data.patient?.eventTimeline;

  const lastName =
    data.patient?.star === null && demographicInfo?.name.length > 0
      ? demographicInfo?.name[0].family + ' '
      : demographicInfo?.patientName;

  const firstName =
    data.patient?.star === null && demographicInfo?.name.length > 0
      ? demographicInfo?.name[0].given
      : '';

  const dateOfBirth = demographicInfo?.birthDate;

  const fullName = lastName + ' ' + firstName;
  let transPlantDates = data.patient?.eventTimeline?.patientEvent
    .filter((item) => item.eventName === 'Transplant')
    .map((ele) => ele.eventDate);
  const transPlantDate = transPlantDates[0];
  const transplantOrgan =
    data?.patient?.links?.ibox?.iBoxRecords?.length > 0
      ? data?.patient?.links?.ibox?.iBoxRecords[0].details?.transplantOrgan
      : '';

  return (
    <Box
      className={classes.tablePaperContainer}
      boxShadow={4}
      component={Paper}
    >
      <Box style={{ display: 'flex' }}>
        <Box
          style={{
            display:
              fullName === ' null' || fullName === '' || fullName === undefined
                ? 'none'
                : 'block',
          }}
        >
          <label className={classes.labelBold}>Name: </label>
          {fullName}
        </Box>
        <Box
          paddingLeft={30}
          style={{
            display:
              dateOfBirth === '' || dateOfBirth === undefined
                ? 'none'
                : 'block',
          }}
        >
          <label className={classes.labelBold}>DOB : </label>
          {Moment(dateOfBirth).format('MM-DD-YYYY')}
        </Box>

        <Box
          paddingLeft={30}
          style={{
            display: gender === '' || gender === undefined ? 'none' : 'block',
          }}
        >
          <label className={classes.labelBold}>Gender: </label>
          {gender?.toLowerCase().charAt(0).toUpperCase() + gender.slice(1)}
        </Box>
        <Box
          paddingLeft={30}
          style={{
            display:
              transPlantDate === '' || transPlantDate === undefined
                ? 'none'
                : 'block',
          }}
        >
          <label className={classes.labelBold}>Tx Date: </label>
          {Moment(transPlantDate).format('MM-DD-YYYY')}
        </Box>

        <Box
          paddingLeft={30}
          style={{
            display:
              transplantOrgan === '' || transplantOrgan === undefined
                ? 'none'
                : 'block',
          }}
        >
          <label className={classes.labelBold}>Tx Organ: </label>
          {transplantOrgan}
        </Box>
      </Box>
      {showTimeLine && eventTimeline && (
        <Box className={classes.timelineContainer}>
          <DemographicsTimeline data={eventTimeline} />
        </Box>
      )}
    </Box>
  );
}
