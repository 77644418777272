// @ts-nocheck
import React, { useEffect } from 'react';
import { Chart } from 'chart.js';

//plugins
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-moment';
import { Box, Typography } from '@material-ui/core';

const ImmunosuppressionLineChart = ({
  chartConfiguration,
  range,
  frequency,
  dataSets,
  showXLabels,
  canvasHeight,
  labelDayFormat,
  scaleXPadding,
}) => {
  const chartRef = React.createRef();
  useEffect(() => {
    var myDataSets = dataSets.map(function (ds) {
      return {
        ...ds,
        data: ds.data.map(function (d) {
          return {
            x: d.start,
            y: d.value,
          };
        }),
        fill: false,
        lineTension: 0,
      };
    });
    const myChartRef = chartRef.current.getContext('2d');
    let chartElementRef = new Chart(myChartRef, {
      type: 'line',
      data: {
        datasets: myDataSets,
      },
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 20,
            right: 30,
            top: 25,
            bottom: 10,
          },
        },
        annotation: {
          annotations: chartConfiguration.getAnnotations
            ? chartConfiguration.getAnnotations(dataSets, frequency, range)
            : [],
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: function (context) {
              var value = context.dataset.data[context.dataIndex]['y'];
              return chartConfiguration.color(value);
            },
            formatter: function (value, context) {
              if (chartConfiguration.dataLabel) {
                return chartConfiguration.dataLabel(
                  context.dataset.data[context.dataIndex]['y']
                );
              }
              return context.dataset.data[context.dataIndex]['y'];
            },
            font: {
              size: 11,
              weight: 'bold',
            },
            align: function (context) {
              return context.datasetIndex === 0 ? 'end' : 'start';
            },
            offset: chartConfiguration.dataLabelsOffSet,
            padding: chartConfiguration.dataLabelsPadding,
            clamp: true,
            display: 'auto',
          },
        },
        scales: {
          xAxes: [
            {
              id: 'x',
              type: 'time',
              time: {
                parser: 'YYYY-MM-DD HH:mm:ss',
                unit: 'day',
                displayFormats: {
                  minute: 'YYYY-MM-DD HH:mm:ss',
                  hour: 'YYYY-MM-DD HH:mm:ss',
                  day: labelDayFormat,
                  week: 'MM/DD/YYYY',
                  month: 'MM/DD/YYYY',
                },
              },
              ticks: {
                source: 'auto',
                align: 'center',
                userCallback: function (label) {
                  return label;
                },
                // minRotation: 45,
                padding: scaleXPadding,
                color: 'black',
              },
              position: 'top',
              gridLines: {
                display: false,
                color: 'red',
                drawBorder: false,
              },
              display: chartConfiguration.showXLabels || showXLabels,
            },
          ],
          yAxes: [
            {
              id: 'y',
              display: false,
              ticks: {
                userCallback: function (value) {
                  return value;
                },
              },
              gridLines: {
                display: false,
                color: 'red',
              },
            },
          ],
        },
      },
      plugins: [ChartDataLabels],
    });
    return () => {
      Chart.plugins.unregister(ChartDataLabels);
      chartElementRef.destroy();
    };
  }, [
    chartConfiguration,
    chartRef,
    dataSets,
    frequency,
    labelDayFormat,
    range,
    scaleXPadding,
    showXLabels,
  ]);

  return (
    <div>
      {dataSets.length === 0 ? (
        <Box fontWeight="bold" pt={1} pb={1}>
          <Typography style={{ fontWeight: '600' }} variant="subtitle1">
            Last {range} Days - No Data Available
          </Typography>
        </Box>
      ) : (
        <canvas height={canvasHeight} ref={chartRef} />
      )}
    </div>
  );
};

export default ImmunosuppressionLineChart;
