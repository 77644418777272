/* istanbul ignore file */

const Constants = {
  PATIENT_ID: 'patientId',
  RISK_EVALUATION_DATE: 'riskEvaluationDate',
  DATE_OF_EGFR_TAKEN: 'dateOfEgfrTaken',
  EGFR_STATUS: 'egfrStatus',
  EGFR: 'egfr',
  DATE_OF_PROTEINURIA_TAKEN: 'dateOfProteinuriaTaken',
  PROTEINURIA_UNIT: 'proteinuriaUnit',
  PROTEINURIA_IN_G_G: 'proteinuriaInGG',
  PROTEINURIA_DIPSTICK: 'proteinuriaDipstick',
  DATE_OF_DSA_TAKEN: 'dateOfDsaTaken',
  DSA_STATUS: 'dsaStatus',
  DSA_MFI: 'dsaMfi',
  DSA_BINARY: 'dsaBinary',
  DATE_OF_HISTOLOGY_TAKEN: 'dateOfHistologyTaken',
  HISTOLOGY_STATUS: 'histologyStatus',
  DIAGNOSIS_ABMR: 'diagnosisAbmr',
  DIAGNOSIS_TCMR: 'diagnosisTcmr',
  DIAGNOSIS_RECURRENCE: 'diagnosisRecurrence',
  DIAGNOSIS_BK: 'diagnosisBk',
  DIAGNOSIS_CNI: 'diagnosisCni',
  DIAGNOSIS_AKI: 'diagnosisAki',
  DIAGNOSIS_OTHER: 'diagnosisOther',
  AGE: 'age',
  TRANSPLANT_DATE: 'transplantDate',
  BIRTH_DATE: 'birthDate',
  IS_FEMALE: 'isFemale',
  IS_BLACK: 'isBlack',
  CREATININE: 'creatinine',
  G_SCORE: 'gScore',
  PTC_SCORE: 'ptcScore',
  I_SCORE: 'iScore',
  T_SCORE: 'tScore',
  CG_SCORE: 'cgScore',
  IFTA_SCORE: 'iftaScore',
  REQUIRED: 'Field is Required',
  ERROR_DATE_OF_EGFR_TAKEN:
    'Date of Blood Draw must be within 7 days of AlloSure',
  ERROR_DATE_OF_PROTEINURIA_TAKEN:
    'Date of Urine Collection must be within 7 days of AlloSure',
  ERROR_DATE_OF_DSA_TAKEN:
    'Date of DSA Taken must be within 31 days of AlloSure',
  ERROR_DATE_OF_HISTOLOGY_TAKEN:
    'Date of Histology Taken must be within 31 days of AlloSure',
  ERROR_EGFR: 'eGFR value must be 0.0-120.0',
  ERROR_CREATININE:
    'Serum Creatinine should be mg/dL. Max 2 decimals. Numerical only, no symbols.',
  ERROR_PROTEINURIA_IN_G_G: 'PCR value must be 0-12',
  ERROR_DSA_MFI: 'MFI value must be 0-20000',
  ERROR_AGE: 'Must not exceed 150',
};
export default Constants;
