import React from 'react';

import {
  AlloViewThemeProvider,
  AlloViewScore,
  AlloViewContextProvider,
  NewScoreContextProvider,
  ErrorBoundary,
} from 'alloview-lib';
import { alloViewTheme } from '../../../../src/theme';
import { Typography, Box, Button, makeStyles, Grid } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { ApolloConsumer } from '@apollo/client';
import PatientAlloViewService from '../../Service/AlloView/AlloViewService';

const useStyles = makeStyles((theme) => ({
  titleItemRight: {
    float: 'right',
  },
}));

function AlloViewChart() {
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const add = () => {
    return history.push(`/patient/${id}/alloViewRecord`);
  };
  const exit = () => {
    return history.push(`/patient/${id}/alloView`);
  };
  return (
    <>
      <Box>
        <Button className={classes.titleItemRight} onClick={add}>
          <AddBoxIcon fontSize="medium"></AddBoxIcon>
        </Button>
      </Box>
      <Box p={1}>
        <Typography variant="h6">
          AiKidney &trade; Likelihood of Rejection Results
        </Typography>
      </Box>
      <ErrorBoundary>
        <ApolloConsumer>
          {(client) => (
            <>
              <AlloViewThemeProvider currentTheme={alloViewTheme}>
                <AlloViewContextProvider
                  alloViewService={new PatientAlloViewService(client)}
                >
                  <NewScoreContextProvider>
                    <AlloViewScore></AlloViewScore>
                  </NewScoreContextProvider>
                </AlloViewContextProvider>
              </AlloViewThemeProvider>
            </>
          )}
        </ApolloConsumer>
      </ErrorBoundary>
      <Box>
        <Grid container justify="center">
          <Button style={{ fontWeight: '600' }} onClick={exit}>
            Exit
          </Button>
        </Grid>
      </Box>
    </>
  );
}

export default AlloViewChart;
