import {
  Paper,
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  Box,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import React, { useState } from 'react';
import AssessmentIcon from '@material-ui/icons/Assessment';
const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    borderRadius: '10px',
    width: '100%',
    '& > .MuiCardContent-root': {
      fontSize: 12,
      paddingBottom: '0px',
      textAlign: 'center',
      paddingTop: '0px',
      height: theme.spacing(15),
      overflow: 'auto',
    },
  },
  cardHeader: {
    padding: theme.spacing(1.5),
  },
  highlight: {
    backgroundColor: '#40b6fe',
    borderBottom: '1px solid gray',
  },
  cardHeaderNoPadding: {
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingTop: '0px',
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    border: 'none',
    boxShadow: 'none',
    padding: theme.spacing(2),
    '&:after': {
      content: '',
      flex: 'auto',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  displayInline: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  tile: {
    height: '100%',
    width: '100%',
    fontSize: '12px',
  },
  tileContent: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '10px',
    '& > *': {
      flex: '1 1 0px',
      textAlign: 'left',
    },
  },

  root: {
    flexGrow: 1,
    flexWrap: 'wrap',
  },
}));

const currentConditionIcons = (text) => {
  switch (text) {
    case 'AssessmentIcon':
      return <AssessmentIcon fontSize="large" />;
    default:
      return;
  }
};

function PatientCurrentCondition(props) {
  const { searchText, starPatientCurrentCondition } = props;
  const classes = useStyles();
  const [config] = useState(starPatientCurrentCondition);
  const lowerCaseSearchText = searchText.toLowerCase();
  return starPatientCurrentCondition ? (
    <>
      <div style={{ padding: 20 }} className={classes.root}>
        <Grid component={Card} className={classes.grid} container spacing={2}>
          {config
            .filter(
              (eachCard) =>
                !searchText ||
                (!!searchText &&
                  eachCard.fields.some(
                    (x) =>
                      x.text.toLowerCase().includes(lowerCaseSearchText) ||
                      x.value.toLowerCase().includes(lowerCaseSearchText)
                  ))
            )
            .map((eachCard, idx) => {
              const icon = currentConditionIcons(eachCard.icon);
              return (
                <Grid key={idx} item xl={4} xs={12} sm={6} md={4}>
                  <Card component={Paper} className={`${classes.card}`}>
                    <CardHeader
                      title={
                        <div
                          className={`${classes.displayInline} ${
                            icon
                              ? classes.cardHeaderNoPadding
                              : classes.cardHeader
                          }`}
                        >
                          <IconButton aria-label={eachCard.title}>
                            {icon}
                          </IconButton>
                          <Typography component={'span'}>
                            <Box
                              display="inline"
                              fontWeight="fontWeightMedium"
                              m={1}
                            >
                              <label className={classes.bold}>
                                {eachCard.title}
                              </label>
                            </Box>
                          </Typography>
                        </div>
                      }
                    ></CardHeader>

                    <CardContent>
                      {eachCard.fields.map((field, idx) => {
                        const containsText =
                          field.text
                            .toLowerCase()
                            .includes(lowerCaseSearchText) ||
                          field.value
                            .toLowerCase()
                            .includes(lowerCaseSearchText);
                        if (
                          !lowerCaseSearchText ||
                          (!!lowerCaseSearchText && containsText)
                        ) {
                          return (
                            <div
                              key={idx}
                              className={`${classes.tileContent} ${
                                !!lowerCaseSearchText && containsText
                                  ? classes.highlight
                                  : ''
                              }`}
                            >
                              <Box
                                display="inline"
                                fontWeight="fontWeightMedium"
                              >
                                <label className={classes.bold}>
                                  {field.text}:{' '}
                                </label>
                              </Box>
                              <Box display="inline">{field.value}</Box>
                            </div>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </>
  ) : null;
}

export default PatientCurrentCondition;
