import {
  Tabs,
  Tab,
  makeStyles,
  fade,
  Button,
  CssBaseline,
  Box,
  Paper,
  IconButton,
} from '@material-ui/core';
import React from 'react';
import PatientTransplantHistory from './PatientTransplantHistory';
import PatientCurrentCondition from './PatientCurrentCondition';
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded';
import {
  TranslatedTextField,
  TranslatedTypography,
} from 'Components/Localization';
import { grey } from '@material-ui/core/colors';
import { Search } from '@material-ui/icons';
import { useState } from 'react';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    flexGrow: 1,
    '&.MuiTabs-indicator': {
      backgroundColor: 'white',
    },
    '&.Mui-selected': {
      borderBottom: '0px',
    },
  },
  tab: {
    borderRadius: '10px',
    textTransform: 'none',
    border: `2px solid ${grey[300]}`,
    boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 10%)`,
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px',
  },
  tabPanel: {
    height: `calc(100% - ${theme.spacing(7)}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    margin: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  height40: {
    height: '40px',
    '& > .MuiOutlinedInput-root': {
      height: '40px',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  thickBorder: {
    border: `2px solid ${grey[300]}`,
    boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 15%)`,
  },
  inputRoot: {
    color: 'inherit',
  },
  tabsContainerBox: {
    height: theme.spacing(6),
    marginBottom: '-2px',
  },
  aitracSearchBox: {
    '& > .MuiOutlinedInput-root': {
      borderRadius: '20px',
      height: '40px',
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const TabPanel = (props) => {
  const { children, value, index } = props;
  const classes = useStyles();
  return (
    <>
      {value === index && (
        <Paper
          role="tabpanel"
          id={`scrollable-auto-tabpanel-${index}`}
          aria-labelledby={`scrollable-auto-tab-${index}`}
          className={classes.thickBorder}
          style={{ height: '100%', overflow: 'auto', flexGrow: 1 }}
          elevation={2}
        >
          {children}
        </Paper>
      )}
    </>
  );
};

const tabNameToIndex = {
  0: 'currentcondition',
  1: 'transplanthistory',
};

const indexToTabName = {
  currentcondition: 0,
  transplanthistory: 1,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PatientProfileDetails(props) {
  const classes = useStyles();
  const { match, history, initialLocation, starPatient } = props;
  const { params } = match;
  const { page } = params;
  const currentCondition = starPatient?.patient?.star?.currentCondition;
  const transplantHistory = starPatient?.patient?.star?.transplantHistory;
  const pathname = history.location.pathname;
  const idxLastFx = pathname.lastIndexOf('/details');
  let newPathname = pathname.substring(0, idxLastFx);

  const [selectedTab, setSelectedTab] = useState(indexToTabName[page]);
  const [searchPatientText, setSearchPatientText] = useState('');
  const handleChange = (event, newValue) => {
    history.push(`${initialLocation}/${tabNameToIndex[newValue]}`);
    setSelectedTab(newValue);
  };

  const handleSearch = (e) => {
    setSearchPatientText(e.target.value);
  };
  const handlePatientListClick = () => {
    history.push(newPathname);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Box display="flex" className={`${classes.tabsContainerBox}`}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab
            className={classes.tab}
            {...a11yProps(0)}
            label="Current Condition"
          />
          <Tab
            className={classes.tab}
            {...a11yProps(1)}
            label="Transplant History"
          />
        </Tabs>
        <Button
          color="primary"
          onClick={handlePatientListClick}
          className={classes.button}
          startIcon={<FormatListBulletedRoundedIcon />}
        >
          <TranslatedTypography>Dashboard</TranslatedTypography>
        </Button>
        <TranslatedTextField
          className={classes.aitracSearchBox}
          size="small"
          variant="outlined"
          label="Search Patient Profile"
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <IconButton>
                <Search fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
      <TabPanel value={selectedTab} index={0} classes={classes}>
        <PatientCurrentCondition
          searchText={searchPatientText}
          starPatientCurrentCondition={currentCondition}
        ></PatientCurrentCondition>
      </TabPanel>
      <TabPanel value={selectedTab} index={1} classes={classes}>
        <PatientTransplantHistory
          transplantHistory={transplantHistory}
        ></PatientTransplantHistory>
      </TabPanel>
    </div>
  );
}
