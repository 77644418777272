import React from 'react';
import NoteCard from './NoteCard';
import NewNote from './NewNote';
import WithPatientNotesData from '../../../GraphQL/WithPatientNotesData';
import ErrorBoundaryHOC from '../../../ErrorBoundaryHOC';
import ErrorPage from '../../Error/ErrorPage';
import { stableSort, getComparator } from '../../../../Utilities/tableSorting';

export function NotesPageContent({
  handleAddNote,
  handleDeleteClick,
  handleDeleteAllNotes,
  data,
}) {
  const comparator = getComparator('desc', 0);
  function dateComparator(a, b) {
    return comparator.bind(
      null,
      [new Date(a.createdAt)],
      [new Date(b.createdAt)]
    )();
  }
  const sortedNotes = stableSort(data.notes, dateComparator);
  return (
    <div>
      <NewNote
        onAddNote={handleAddNote}
        onDeleteAllNotes={handleDeleteAllNotes}
      />
      {sortedNotes.map((n) => (
        <NoteCard
          key={n.id}
          id={n.id}
          patientId={n.patientId}
          userFullName={n.userFullName}
          note={n.note}
          createdAt={n.createdAt}
          onDeleteClick={handleDeleteClick}
        />
      ))}
    </div>
  );
}

function NotesPage({ patientId }) {
  return (
    <WithPatientNotesData patientId={patientId}>
      <NotesPageContent />
    </WithPatientNotesData>
  );
}

export default ErrorBoundaryHOC(NotesPage, ErrorPage);
