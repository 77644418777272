import React from 'react';
import WithAlloCarePersonalizedData from '../../../GraphQL/WithAlloCarePersonalizedData';
import AlloCarePersonalizationMeasures from './AlloCarePersonalizationMeasures';

export default function PersonalizationData({
  id,
  handleConditionDialogState,
}) {
  return (
    <WithAlloCarePersonalizedData id={id}>
      {(data) => {
        return (
          <AlloCarePersonalizationMeasures
            handleConditionDialogState={handleConditionDialogState}
            measuresOrigData={
              data.patient.links.alloCare.patientPersonalizationMeasure
            }
            alertsOrigData={
              data.patient.links.alloCare.patientPersonalizationAlert
            }
          />
        );
      }}
    </WithAlloCarePersonalizedData>
  );
}
