import { Grid, Box, Divider } from '@material-ui/core';
import React from 'react';
import { TranslatedTypography } from 'Components/Localization';
import HighlightOff from '@material-ui/icons/HighlightOff';

function TitleRow({ title }) {
  return (
    <Grid style={{ height: 25 }} container item>
      <Grid container item xs={3} sm={3} lg={2} xl={2} justify="flex-start">
        <Box pt={1} pb={1} direction="row" display="flex" alignItems="center">
          <TranslatedTypography style={{ fontWeight: 'bold', padding: '15px' }}>
            {title}
          </TranslatedTypography>
          <HighlightOff fontSize="small"></HighlightOff>
        </Box>
      </Grid>
      <Grid container item xs={1} justify="center" alignItems="stretch">
        <Divider orientation="vertical" />
        <div style={{ width: '24px' }}></div>
      </Grid>
      <Grid item xs={8} sm={8} lg={9} xl={9}></Grid>
    </Grid>
  );
}

export default TitleRow;
