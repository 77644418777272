import React, { useEffect, useContext } from 'react';
import { getRandomString } from '../../utility';
import { SmartAuthenticationContext } from './SmartAuthentication';
import PageLoadingIndicator from '../Indicators/PageLoadingIndicator';
import { useParams } from 'react-router-dom';

export default function Launch() {
  const { issuer } = useParams();
  const {
    config: { clientId, redirectUri, scope },
  } = useContext(SmartAuthenticationContext);

  const params = new URLSearchParams(window.location.search);
  const launch = params.get('launch');
  const issuerUri = params.get('iss');

  useEffect(function () {
    async function getMetadata() {
      const metadataUrl = `${issuerUri}/metadata`;
      const metadataResponse = await fetch(metadataUrl, {
        headers: {
          Accept: 'application/json',
          'Epic-Client-ID': clientId,
        },
      });
      const metadata = await metadataResponse.json();
      const urls = metadata.rest[0].security.extension[0].extension;
      const tokenUri = urls.filter((e) => e.url === 'token')[0].valueUri;
      const authorizeUrl = urls.filter((e) => e.url === 'authorize')[0]
        .valueUri;

      const state = {
        clientIssuer: issuer,
        issuerUri,
        clientId,
        redirectUri,
        tokenUri,
        authorizeUrl,
      };

      const stateKey = getRandomString(32);
      sessionStorage.setItem(stateKey, JSON.stringify(state));

      const launchParams = new URLSearchParams();
      launchParams.append('response_type', 'code');
      launchParams.append('client_id', clientId);
      launchParams.append('redirect_uri', redirectUri);
      launchParams.append('scope', scope);
      launchParams.append('launch', launch);
      launchParams.append('state', stateKey);
      launchParams.append('aud', issuerUri);
      window.location.href = `${authorizeUrl}?${launchParams.toString()}`;
    }

    getMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PageLoadingIndicator>Authenticating...</PageLoadingIndicator>;
}
