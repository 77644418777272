import { ChartsConfiguration, ChartTypes } from './EventTimelineConfig';

export const ChartGroups = [
  [
    ChartsConfiguration[ChartTypes.inpatientAdmission],
    ChartsConfiguration[ChartTypes.erVisit],
  ],
  [ChartsConfiguration[ChartTypes.biopsy]],
  [ChartsConfiguration[ChartTypes.newDiagnosis]],

  [
    ChartsConfiguration[ChartTypes.transplantMeds],
    ChartsConfiguration[ChartTypes.mycophenolateMofetil],
    ChartsConfiguration[ChartTypes.tacrolimus],
    ChartsConfiguration[ChartTypes.prednisone],
    ChartsConfiguration[ChartTypes.rapamune],
    ChartsConfiguration[ChartTypes.valganciclovirl],
    ChartsConfiguration[ChartTypes.sirolimus],
    ChartsConfiguration[ChartTypes.bactrim],
  ],

  [
    ChartsConfiguration[ChartTypes.careDxTests],
    ChartsConfiguration[ChartTypes.alloMap],
    ChartsConfiguration[ChartTypes.alloSure],
  ],

  [ChartsConfiguration[ChartTypes.newStatus]],
];
