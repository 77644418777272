import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';

export const GET_EXTERNAL_ID_QUERY = gql`
  query ExternalIdentifierQuery(
    $externalId: String!
    $identifierType: IdentifierTypeEnum!
  ) {
    externalIdentifier(
      filter: { externalId: $externalId, identifierType: $identifierType }
    ) {
      id
      patientId
      identifierType
      externalId
    }
  }
`;

export default function WithPatientExternalIdData({ children, id, type }) {
  return (
    <WithQueryResults
      QUERY={GET_EXTERNAL_ID_QUERY}
      variables={{ externalId: id, identifierType: type }}
    >
      {children}
    </WithQueryResults>
  );
}
