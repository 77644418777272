import DemographicsHeader from '../../../Display/DemographicsHeader';
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PatientLayout from '../../../Layout/PatientLayout';
import IBoxDataPage from './IBoxDataPage';
import ErrorBoundaryHOC from 'Components/ErrorBoundaryHOC';
import ErrorPage from 'Components/Pages/Error/ErrorPage';
import { TranslatedTypography } from 'Components/Localization';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';
import { useParams } from 'react-router-dom';

function IBoxPage(props) {
  const params = useParams();
  const id = params['id'];
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
    >
      {function (data) {
        return (
          <PatientLayout
            header={<DemographicsHeader id={id} data={data} />}
            subHeader={
              <Typography style={{ margin: 'auto' }} variant="h5">
                AiKidney &trade; iBox Long-Term Prognosis
              </Typography>
            }
          >
            <Box textAlign="center">
              <TranslatedTypography variant="caption">
                AiKidney &trade; iBox Long-Term Prognosis scores are for
                Research User Only and are not intended for diagnostic
                procedures. Results are not approved for publication or any
                other use without written permission by CareDx, inc.
              </TranslatedTypography>
            </Box>
            <IBoxDataPage />
          </PatientLayout>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(IBoxPage, ErrorPage);
