import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { ImmunosuppressionReportRangeFrequency } from './Chart/ImmunosuppressionConfig';

function MeasurementSelection({ rangeFrequency, onRangeFrequencyChange }) {
  return (
    <Grid container item justify="flex-end">
      <Box m={1}>
        <FormControl variant="outlined" size="small">
          <InputLabel id="range-label">Range/Frequency</InputLabel>
          <Select
            labelId="range-label"
            id="range-select-outlined"
            value={rangeFrequency}
            onChange={onRangeFrequencyChange}
            label="Range/Frequency"
            className="range-select"
          >
            <MenuItem value={ImmunosuppressionReportRangeFrequency.weekDaily}>
              7 Days / Daily Average
            </MenuItem>
            <MenuItem
              value={ImmunosuppressionReportRangeFrequency.monthThreeDays}
            >
              30 Days / 3 Day Average
            </MenuItem>
            <MenuItem
              value={ImmunosuppressionReportRangeFrequency.quarterTenDays}
            >
              90 Days / 10 Day Average
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Grid>
  );
}

export default MeasurementSelection;
