import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthenticationContext } from './Authentication';

export default function Callback({ loadingIndicator }) {
  const {
    config,
    authState,
    authService,
    setIsLoading,
    setHasError,
    setTokenResponse,
  } = useContext(AuthenticationContext);
  const history = useHistory();

  useEffect(
    function () {
      async function TokenRequest() {
        try {
          setIsLoading(true);
          const params = new URLSearchParams(window.location.search);
          const code = params.get('code');
          const state = params.get('state');
          const stateObject = sessionStorage.getItem(state);
          const codeVerifier = JSON.parse(stateObject).codeVerifier;
          const tokenResponse = await authService.getToken(code, codeVerifier);
          setTokenResponse(tokenResponse);
          setIsLoading(false);

          // Send the browser back to the app-root, but don't reload the page.
          history.push('/');
        } catch (error) {
          setHasError(true);
        }
      }

      if (
        !(
          authState.isAuthenticated ||
          authState.hasError ||
          authState.isLoading
        )
      ) {
        TokenRequest();
      } else if (authState.hasError) {
        throw new Error('OAuth2 Callback error');
      }
    },
    [
      history,
      config,
      authState.isAuthenticated,
      authState.hasError,
      authState.isLoading,
      authService,
      setIsLoading,
      setHasError,
      setTokenResponse,
    ]
  );

  let content = null;
  if (authState.isLoading) {
    if (loadingIndicator) {
      content = loadingIndicator;
    } else {
      content = <div>Loading...</div>;
    }
  }
  return content;
}
