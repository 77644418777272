import { SvgIcon } from '@material-ui/core';
import {
  BloodPressure,
  IsxNoLevels,
  PulseOx,
  Scale,
  Urine,
} from 'icons/IconComponents';
import React from 'react';

const ChartTypes = {
  TacroLevel: 'tacrolevel',
  SiroLevel: 'sirolevel',
  CycloLevel: 'cyclolevel',
  MedicationCompliance: 'medicationcompliance',
  Mood: 'mood',
};
const ChartsConfiguration = {};

const waterIcon = new Image();
waterIcon.height = 10;
waterIcon.width = 10;
waterIcon.src = '/images/water.svg';
ChartsConfiguration[ChartTypes.TacroLevel] = {
  id: ChartTypes.TacroLevel,
  label: 'Tacro Level',
  color: (value) => {
    return 'black';
    // return value !== null && value >= 2 && value <= 3 ? 'black' : 'red';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'Tacro Level',
        backgroundColor: 'darkgray',
        borderColor: 'darkgray',
        pointBackgroundColor: 'green',
        pointBorderColor: 'green',
        borderWidth: 2,
        pointRadius: 4,
        data: data.series[0].elements,
        pointStyle: 'circle',
      },
    ];
  },
  tickCallBack: () => {},
  labelIcon: <SvgIcon component={IsxNoLevels} viewBox="4 4 10 10"></SvgIcon>,
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.SiroLevel] = {
  id: ChartTypes.SiroLevel,
  label: 'Siro Level',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'Siro Level',
        backgroundColor: 'darkgray',
        borderColor: 'darkgray',
        pointBackgroundColor: 'green',
        pointBorderColor: 'green',
        pointStyle: 'circle',
        borderWidth: 2,
        pointRadius: 4,
        data: data.series[0].elements,
      },
    ];
  },
  labelIcon: <SvgIcon component={Urine} viewBox="3 4 8 8"></SvgIcon>,
  group: 1,
  datalabels: {
    color: '#36A2EB',
  },
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.CycloLevel] = {
  id: ChartTypes.CycloLevel,
  label: 'Cyclo Level',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        pointStyle: 'circle',
        pointRadius: 4,
        backgroundColor: 'darkgray',
        borderColor: 'darkgray',
        pointBackgroundColor: 'green',
        pointBorderColor: 'green',
        borderWidth: 2,
        label: 'Mpa Level',
        data: data.series[0].elements,
      },
    ];
  },
  labelIcon: <SvgIcon component={Scale} viewBox="4 4 10 10"></SvgIcon>,
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.MedicationCompliance] = {
  id: ChartTypes.MedicationCompliance,

  labelIcon: <SvgIcon component={BloodPressure} viewBox="4 4 8 8"></SvgIcon>,
  label: 'Medication Compliance',
  containerStyle: {
    background: 'rgb(232, 232, 232)',
  },
  color: (value) => {
    return 'black';
  },
  dataLabel: (value) => {
    return value + '%';
  },
  getDataSets: (data) => {
    const pointColors = data.series[0].elements.map((d) => {
      if (d.value <= 25) {
        // baseline values //todo get this from wen
        return 'red';
      } else if (d.value <= 50) {
        return 'orange';
      } else if (d.value <= 75) {
        return '#FFD564';
      } else {
        return 'green';
      }
    });

    return [
      {
        pointStyle: 'circle',
        pointRadius: 3,
        backgroundColor: 'darkgray',
        borderColor: 'darkgray',
        pointBackgroundColor: pointColors,
        pointBorderColor: pointColors,
        label: 'Diastolic',
        borderWidth: 2,
        data: data.series[0].elements,
      },
    ];
  },
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.Mood] = {
  id: ChartTypes.Mood,
  label: 'Mood',
  labelIcon: <SvgIcon component={PulseOx} viewBox="3 3 8 9"></SvgIcon>,
  containerStyle: {
    background: 'rgb(232, 232, 232)',
  },
  color: (value) => {
    return 'black';
  },
  dataLabel: (value) => {
    if (+value <= 3) {
      return 'sad';
    } else if (+value <= 6) {
      return 'calm';
    } else {
      return 'happy';
    }
  },
  getDataSets: (data) => {
    const pointColors = data.series[0].elements.map((d) => {
      if (+d.value <= 3) {
        return 'red';
      } else if (+d.value <= 6) {
        return '#FFD564';
      } else {
        return 'green';
      }
    });

    return [
      {
        pointStyle: 'circle',
        pointRadius: 4,
        label: 'Mood',
        borderWidth: 2,
        backgroundColor: 'darkgray',
        borderColor: 'darkgray',
        pointBackgroundColor: pointColors,
        pointBorderColor: pointColors,
        data: data.series[0].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 5,
  dataLabelsOffSet: 4,
};

const ImmunosuppressionReportFrequency = {
  daily: 1,
  threeDays: 3,
  tenDays: 10,
};

const ImmunosuppressionReportRange = {
  week: 7,
  month: 30,
  quarter: 90,
};

const ImmunosuppressionReportRangeFrequency = {
  weekDaily: '7:1',
  monthThreeDays: '30:3',
  quarterTenDays: '90:10',
};

export {
  ChartsConfiguration,
  ChartTypes,
  ImmunosuppressionReportRange,
  ImmunosuppressionReportFrequency,
  ImmunosuppressionReportRangeFrequency,
};
