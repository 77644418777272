import React from 'react';
import { Badge, IconButton } from '@material-ui/core';
import { Note as NoteIcon } from '@material-ui/icons';

export default function NoteCountBadge({ count, id, onClick }) {
  let content = null;
  if (count > 0) {
    content = count;
  }

  const handleClick = (e) => {
    onClick(id);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <IconButton onClick={handleClick}>
      <Badge badgeContent={content} color="primary">
        <NoteIcon />
      </Badge>
    </IconButton>
  );
}
