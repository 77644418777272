import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as dayjs from 'dayjs';

//TODO: Need to use i18next-http-backend
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          'Choose fields': 'Choose fields for list filtering',
          Start: 'Start',
          End: 'End',
          Value: 'Value',
          Field: 'Field',
          Operation: 'Operation',
          'Add filter': 'Add filter',
          Equals: 'Equals',
          'Less than': 'Less than',
          'Greater than': 'Greater than',
          'Equal to or less than': 'Equal to or less than',
          'Equal to or greater than': 'Equal to or greater than',
          Between: 'Between',
          Contains: 'Contains',
          'Starts with': 'Starts with',
          'Ends with': 'Ends with',
          FilterPanelChip: {
            between: '{{field}} between {{start}} and {{end}}',
            default: '{{field}} {{operation}} {{value}}',
          },
          formattedDate: {
            L: '{{date, L}}',
            LL: '{{date, LL}}',
            LLL: '{{date, LLL}}',
          },
          numberFormat: '{{value, true}}',
          unknownCoding: 'Unknown coding',
          unknownEncounterType: 'Unknown Encounter Type',
          unknownProcedureType: 'Unknown Procedure Type',
          noProcedureDate: 'No Procedure Date',
          missingPersonName: 'Missing Name',
        },
      },
      fr: {
        translation: {
          Title: 'Titre',
          'New title': 'Nouveau titre',
          Save: 'Enregistrer',
          Start: 'Début',
          End: 'Fin',
          Value: 'Valeur',
          Field: 'Champ',
          Operation: 'Opération',
          'Add filter': 'Ajouter un filtre',
          Equals: 'Égal à',
          'Less than': 'Moins que',
          'Greater than': 'Plus grand que',
          'Equal to or less than': 'Égale ou inférieure à',
          'Equal to or greater than': 'Égale ou supérieure à',
          Between: 'Entre',
          Contains: 'Contient',
          'Starts with': 'Commence avec',
          'Ends with': 'Se termine par',
          FilterPanelChip: {
            between: '{{field}} entre {{start}} et {{end}}',
            startsWith: '{{field}} commence avec {{value}}',
            endsWith: '{{field}} se termine par {{value}}',
            contains: '{{field}} contient {{value}}',
            default: '{{field}} {{operation}} {{value}}',
          },
          'Default Sort': 'Ordre par défaut',
          'Static List': 'Liste statique',
          'Save as static list': 'Enregistrer en tant que liste statique',
          Filters: 'Filtres',
          'Field Catalog': 'Catalogue de Champs de Formulaire',
          'Sort Direction': 'Ordre de direction',
          Fields: 'Champs de Formulaire',
          'Show preview': "Afficher l'aperçu",
          Dashboard: 'Tableau de bord',
          'Patient Profile Widget': 'Profil du Patient',
          'Patient List': 'Liste de Patients',
          Name: 'Nom',
          Age: 'Âge',
          Gender: 'Le Sexe',
          'Tx Organ': 'Organe TX',
          'Post Tx': 'Après Tx',
        },
      },
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        let result;
        if (value instanceof Date) {
          const date = dayjs(value);
          result = date.format(format);
        } else if (
          !Number.isInteger(value) &&
          Number.isFinite(value) &&
          typeof value === 'number'
        ) {
          result = Number.parseFloat(value).toLocaleString(dayjs.locale());
        } else if (
          Number.isInteger(value) &&
          Number.isFinite(value) &&
          typeof value === 'number'
        ) {
          result = Number.parseInt(value).toLocaleString(dayjs.locale());
        } else {
          result = value;
        }
        return result;
      },
    },
  });
