import React, { createContext, useState } from 'react';
import SmartAuthenticationConfig from './SmartAuthenticationConfig';

const SmartAuthenticationContext = createContext({
  config: {
    clientId: null,
    redirectUri: null,
    scope: null,
  },
  authState: {
    accessToken: null,
    patient: null,
    showBanner: true,
  },
  server: {
    issuerUri: null,
    tokenUri: null,
    authorizeUri: null,
  },
  setServerInfo() {},
  setAuthState() {},
});

function SmartAuthentication({ children, config }) {
  const [serverInfo, setServerInfo] = useState({
    issuerUri: null,
    tokenUri: null,
    authorizationUri: null,
  });

  const [authState, setAuthState] = useState({
    accessToken: null,
    patient: null,
    showBanner: true,
  });

  if (!(config instanceof SmartAuthenticationConfig)) {
    throw new Error(
      'Parameter `config` must be of type SmartAuthenticationConfig'
    );
  }

  const contextValue = {
    config,
    authState,
    server: serverInfo,
    setServerInfo,
    setAuthState,
  };

  return (
    <SmartAuthenticationContext.Provider value={contextValue}>
      {children}
    </SmartAuthenticationContext.Provider>
  );
}

export { SmartAuthentication, SmartAuthenticationContext };
