import * as React from 'react';

function SvgOrganTransplantIcon(props) {
  return (
    <svg
      width={47}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.006 1.232s4.157-2.089 7.232.79c3.076 2.88 2.563 5.025 1.367 8.525 0 0-1.595 2.766 1.538 3.218 3.132.452 4.385-4.573 3.417-5.589"
        stroke="#654321"
        strokeWidth={0.8}
      />
      <path
        d="M4.012 2.982S-.373 6.708.026 14.894c.313 6.435 3.183 13.374 9.846 13.374 7.182 0 5.93-6.722 4.733-10.222 0 0-1.595-2.766 1.538-3.218 8.2 0 4.442 6.558 5.069 20.672M39.995 1.232s-4.158-2.089-7.233.79c-3.076 2.88-2.563 5.025-1.367 8.525 0 0 1.595 2.766-1.538 3.218-3.132.452-4.385-4.573-3.417-5.589"
        stroke="#654321"
        strokeWidth={0.8}
      />
      <path
        d="M41.988 2.982s4.385 3.726 3.986 11.912c-.313 6.435-3.183 13.374-9.846 13.374-7.182 0-5.93-6.722-4.733-10.222 0 0 1.595-2.766-1.538-3.218-8.2 0-4.442 6.558-5.069 20.672"
        stroke="#654321"
        strokeWidth={0.8}
      />
    </svg>
  );
}

export default SvgOrganTransplantIcon;
