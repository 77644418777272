import * as React from 'react';

function SvgBloodPressure(props) {
  return (
    <svg {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M18 19.654C18 24.815 13.97 29 9 29s-9-4.185-9-9.346C0 14.493 9 0 9 0c2.603 4.363 9 14.493 9 19.654"
          fill="#231f20"
          mask="url(#blood-pressure_svg__a)"
          transform="matrix(.26458 0 0 .26458 4.77 3.572)"
        />
        <path
          stroke="#eee"
          strokeWidth={0.3161731}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.77 8.958h1.293l.242-.79.508 1.49.6-2.382.357 1.682h.97"
        />
        <path
          d="M9.798 5.556a1.19 1.19 0 11-2.382 0 1.19 1.19 0 012.382 0z"
          stroke="#eee"
          strokeWidth={0.81305434}
        />
        <path
          d="M9.798 5.556a1.19 1.19 0 11-2.382 0 1.19 1.19 0 012.382 0z"
          stroke="#231f20"
          strokeWidth={0.3161731}
        />
        <path
          d="M9.798 5.556a1.19 1.19 0 11-2.382 0 1.19 1.19 0 012.382 0"
          fill="#fff"
        />
        <path
          stroke="#231f20"
          strokeWidth={0.18070814}
          d="M8.475 4.63v.265M8.475 6.218v.264M7.681 5.424h.265M9.268 5.424h.265"
        />
        <path
          d="M7.946 4.895c.054.046.114.088.175.128a2.963 2.963 0 00.4.22c.072.03.147.06.23.083.186.05.293.227.239.397-.054.17-.248.267-.433.217a.331.331 0 01-.248-.258 1.338 1.338 0 00-.061-.22 2.55 2.55 0 00-.186-.39 1.533 1.533 0 00-.116-.177"
          fill="#231f20"
        />
      </g>
    </svg>
  );
}

export default SvgBloodPressure;
