import React from 'react';
import { gql } from '@apollo/client';

import WithQueryResults from './WithQueryResults';

import { buildFieldSchema } from '../../filterSchema';

import * as util from '../../utility';

export default function WithPatientListData({ children, config, typeData }) {
  // Need to include the ID for when we select patients from lists.
  const fieldSchema = buildFieldSchema('ListRowType', typeData);
  const query = util.pathsToQuery(
    ['id', ...(config?.fields || [])],
    fieldSchema
  );
  // Note: below, `useAllFragments` is never rendered because of the include directive is always false.
  //       This is a hack to get around the "unused fragments" error.
  const GET_LIST_DATA = gql`
        fragment CodeableConceptParts on CodeableConceptType {
            coding {
                code
                system
                display
            }
            text
        }

        fragment QuantityParts on QuantityType {
            value
            unit
        }

        fragment HumanNameParts on HumanNameType {
            family
            given
            prefix
        }

        fragment ObservationParts on ObservationType {
            id
            code {
                ...CodeableConceptParts
            }
            component {
                code {
                    ...CodeableConceptParts
                }
                value {
                    ...QuantityParts
                }
            }
            value {
                ...QuantityParts
            }
        }

        fragment MedicationRequestParts on MedicationRequestType {
            id
            status
            medication {
                ...CodeableConceptParts
            }
            dosageInstruction {
                doseAndRate {
                    type {
                        ...CodeableConceptParts
                    }
                }
                doseAndRate {
                    dose {
                        ...QuantityParts
                    }
                }
            }
        }

        fragment EncounterParts on EncounterType {
            id
            type {
                ...CodeableConceptParts
            }
            period {
                start
                end
            }
        }

        fragment ProcedureParts on ProcedureType {
            id
            code {
                ...CodeableConceptParts
            }
            performed {
                ... on PeriodType {
                    start
                    end
                }
            }
        }

        fragment PractitionerParts on PractitionerType {
            id
            name {
                ...HumanNameParts
            }
        }

        fragment MedicationAdministrationParts on MedicationAdministrationType {
            id
        }


        fragment CareTeamParts on CareTeamType {
            name
            participant
        }

        query listQuery($filter: [ListRowFilterInputType], $collectionId: String) {
            useAllFragments: list(filter: []) @include(if: false) {
                bloodPressure {
                    ...ObservationParts
                }
                ibuprofen {
                    ...MedicationRequestParts
                }
                checkup {
                    ...EncounterParts
                }
                medResolution {
                    ...ProcedureParts
                }
                generalPractitioner {
                    ...PractitionerParts
                }
                methotrexate {
                    ...MedicationAdministrationParts
                }
                careTeam {
                    ...CareTeamParts
                }
            }
            list(filter: $filter, collectionId: $collectionId) ${query}
        }
    `;

  const variables = {};
  if (config?.isStaticList) {
    variables.collectionId = config.collectionId;
  } else {
    const cleanedFilter = util.removeTypeName(config.filter);
    variables.filter = cleanedFilter;
  }

  return (
    <WithQueryResults QUERY={GET_LIST_DATA} variables={variables}>
      {children}
    </WithQueryResults>
  );
}
