import { compoundTypes, compoundToFragment } from './filterSchema';
import aitCrypto from './Utilities/aitCrypto';

export function path(path, object) {
  // return path.reduce((r, l) => r[l], object);
  for (let p = 0; p < path.length; p++) {
    const pathPart = path[p];
    object = object[pathPart];
    if (!object) {
      break;
    }
  }
  return object;
}

export function setPath(path, value, obj) {
  const parts = path.split('.');
  for (let i = 0; i < parts.length; i++) {
    const pathPart = parts[i];
    const next = obj[pathPart];
    if (Object.keys(next).length > 0) {
      obj = obj[pathPart];
    } else {
      obj[pathPart] = value;
    }
  }
}

export function pathsToQuery(paths, fieldSchema) {
  const obj = objectFromPaths(paths, null, fieldSchema);

  const query = JSON.stringify(obj).replace(/(\{\}|:|")/gi, '');

  return query;
}

export function objectFromPaths(paths, object, fieldSchema) {
  const sortedPaths = [...paths].sort();
  const fieldPaths = sortedPaths.map((f) => f.split('.'));
  const obj = object || {};

  for (let p = 0; p < fieldPaths.length; p++) {
    const path = fieldPaths[p];
    // Only used for the side effects.
    path.reduce((r, l) => {
      if (r[l] == null) {
        if (
          fieldSchema &&
          fieldSchema[sortedPaths[p]]?.type.name &&
          compoundTypes.includes(fieldSchema[sortedPaths[p]].type.name)
        ) {
          r[l] = `{...${
            compoundToFragment[fieldSchema[sortedPaths[p]].type.name]
          }}`;
        } else {
          r[l] = {};
        }
      }
      return r[l];
    }, obj);
  }

  return obj;
}

// https://github.com/apollographql/apollo-feature-requests/issues/6
export function removeTypeName(object) {
  const json = JSON.stringify(object);
  const typeNameRemoved = JSON.parse(json, function (prop, value) {
    if (prop === '__typename') {
      return;
    } else {
      return value;
    }
  });
  return typeNameRemoved;
}

export function removeProperties(object, props) {
  const json = JSON.stringify(object);
  const propertiesRemoved = JSON.parse(json, function (prop, value) {
    if (props.some((x) => x === prop)) {
      return;
    } else {
      return value;
    }
  });
  return propertiesRemoved;
}

export function swap(array, source_index, destination_index) {
  const l = [...array];
  const source = l[source_index];
  const destination = l[destination_index];
  l.splice(source_index, 1, destination);
  l.splice(destination_index, 1, source);
  return l;
}

export function getRandomString(length) {
  const randomBytes = new Uint8Array(length);
  aitCrypto.getRandomValues(randomBytes);
  const charMapping =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let chars = '';
  for (let c = 0; c < randomBytes.length; c++) {
    const char = charMapping[randomBytes[c] % charMapping.length];
    chars += char;
  }
  return chars;
}
