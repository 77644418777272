import React, { useContext, useEffect } from 'react';
import IBoxReport from './IBoxReport';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
import WithIBoxPatientQueryResults from '../../../GraphQL/WithIBoxPatientQueryResults';
import ErrorBoundaryHOC from '../../../ErrorBoundaryHOC';
import { useHistory, useParams } from 'react-router-dom';
import ErrorPage from '../../Error/ErrorPage';
import { formatIBoxData } from './Utilities/Data';
const Content = ({ iBoxRecords, patient }) => {
  if (iBoxRecords != null && patient != null) {
    return (
      <IBoxReport
        iboxData={formatIBoxData(iBoxRecords, patient.iBoxPatient)}
        widgetView={true}
        hasResult={true}
        height={380}
      ></IBoxReport>
    );
  }
  return <IBoxReport iboxData={null} widgetView={true} hasResult={false} />;
};

const SetIboxPatient = ({ iBox }) => {
  const iBoxRecords = iBox?.iBoxRecords;
  const iBoxPatient = iBox?.iBoxPatient;
  if (iBoxRecords != null) {
    return (
      <WithIBoxPatientQueryResults pid={iBoxPatient?.pid}>
        {(iBoxPatient) => {
          return (
            <Content iBoxRecords={iBoxRecords} patient={iBoxPatient}></Content>
          );
        }}
      </WithIBoxPatientQueryResults>
    );
  }
  return <IBoxReport iboxData={null} widgetView={true} hasResult={false} />;
};

export function IBoxDetails({ widgetId, data }) {
  const { dispatch } = useContext(SettingsContext);
  const widgetData = data?.patient?.links?.ibox;
  const history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    const onClick = () => {
      history.push(`/patient/${id}/iBoxData`);
    };
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: onClick,
      },
    });
  }, [dispatch, history, widgetId, id]);

  return <SetIboxPatient iBox={widgetData}></SetIboxPatient>;
}

export default ErrorBoundaryHOC(IBoxDetails, ErrorPage);
