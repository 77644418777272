import React, { useState } from 'react';
import {
  TranslatedTextField,
  TranslatedIconButton,
} from '../../../Localization';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  Delete as TrashIcon,
  Add as AddIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  actionButton: {
    marginLeft: 'auto',
  },
  textField: {
    width: '100%',
  },
});

export default function NewNote({ onAddNote, onDeleteAllNotes }) {
  const classes = useStyles();
  const [note, setNote] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleAddNote = () => {
    setNote('');
    onAddNote(note);
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const handleMenuClose = () => {
    setMenuOpen(false);
  };
  const handleDeleteAllNotes = () => {
    setMenuOpen(false);
    onDeleteAllNotes();
  };
  return (
    <Card>
      <CardHeader
        action={
          <>
            <TranslatedIconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </TranslatedIconButton>
            <Menu open={menuOpen} onClose={handleMenuClose} anchorEl={anchorEl}>
              <MenuItem onClick={handleDeleteAllNotes}>
                Delete all notes
                <TrashIcon />
              </MenuItem>
            </Menu>
          </>
        }
      />
      <CardContent>
        <TranslatedTextField
          className={classes.textField}
          label="New Note"
          rows={4}
          multiline
          variant="outlined"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </CardContent>
      <CardActions>
        <TranslatedIconButton
          disabled={!note}
          className={classes.actionButton}
          onClick={handleAddNote}
        >
          Add Note <AddIcon />
        </TranslatedIconButton>
      </CardActions>
    </Card>
  );
}
