import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
import { ChartsConfiguration, ChartTypes } from './ImmunosuppressionConfig';
import ImmunosuppressionWidgetChartRow from './ImmunosuppressionWidgetChartRow';
import NoAlloCareData from './NoImmunosuppressionData';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  chartsContainer: {
    display: 'flex',
    margin: '0px 0px 25px 15px',
    flexDirection: 'column',
  },
}));

function ImmunosuppressionWidget({ widgetId, data }) {
  const classes = useStyles();
  const { dispatch } = useContext(SettingsContext);
  const widgetData = {
    tacrolevel: data?.w?.links?.ehr?.averages?.tacrolevel || {},
    medicationcompliance:
      data?.patient?.links?.alloCare?.averages?.medicationcompliance || {},
  };
  const { id } = useParams();
  // if (widgetData.__typename === 'ResolverErrorType') {
  //   widgetData = null;
  // }
  const charts = [
    ChartsConfiguration[ChartTypes.MedicationCompliance],
    ChartsConfiguration[ChartTypes.TacroLevel],
  ];

  const history = useHistory();
  useEffect(() => {
    const onClick = () => {
      history.push(`/patient/${id}/immunosuppression`);
    };
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: onClick,
      },
    });
  }, [dispatch, history, widgetId, id]);

  return widgetData === null ? (
    <NoAlloCareData />
  ) : (
    <Grid className={classes.chartsContainer}>
      <Box>
        {charts.map((chartConfig, idx) => (
          <ImmunosuppressionWidgetChartRow
            key={idx}
            chartConfig={chartConfig}
            //data={widgetData?.averages[chartConfig.id]} //Uncomment when there is more test data
            data={widgetData[chartConfig.id]} //Comment when there is more test data
            range={7}
            showXLabels={idx === 0}
          />
        ))}
      </Box>
    </Grid>
  );
}

export default ImmunosuppressionWidget;
