import { gql, useMutation } from '@apollo/client';

export const UPSERT_MEASURES_ALERTS = gql`
  mutation(
    $measuresList: [PatientPersonalizationMeasureInputType!]
    $alerts: PatientPersonalizationAlertInputType!
    $includeMeasures: Boolean!
    $includeAlerts: Boolean!
  ) {
    upsertPatientPersonalizationMeasures(
      patientPersonalizationMeasures: $measuresList
    ) @include(if: $includeMeasures) {
      patientId
      measureName
      measureLow
      measureHigh
      alertPercentage
      isAlertOn
    }
    upsertPatientPersonalizationAlert(patientPersonalizationAlert: $alerts)
      @include(if: $includeAlerts) {
      patientId
      isEmail
      emailAddress
      isText
      contactNumber
      frequency
    }
  }
`;

export function useAlloCareMeasuresMutation() {
  const [mutate, { data, ...rest }] = useMutation(UPSERT_MEASURES_ALERTS);
  return {
    mutate,
    data,
    rest,
  };
}
