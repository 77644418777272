import { gql, useMutation } from '@apollo/client';

export const GENERATE_REPORT = gql`
  mutation GenerateReport(
    $patient: IBoxPatientInputType!
    $parameters: IBoxParametersInputType!
    $gender: String!
  ) {
    generatePatientRecord(
      patient: $patient
      parameters: $parameters
      sex: $gender
    ) {
      survival1Years
      survival3Years
      survival5Years
      survival7Years
      survival10Years
      cStatistic
    }
  }
`;

export function useIBoxReportMutation() {
  const [mutate, { data, ...rest }] = useMutation(GENERATE_REPORT);
  return {
    mutate,
    data,
    rest,
  };
}
