import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
} from '@material-ui/core';
import { AlloCareReportFrequency, AlloCareReportRange } from './AlloCareConfig';
import DemographicsHeader from '../../Display/DemographicsHeader';
import AlloCare from './AlloCare';
import PatientLayout from '../../Layout/PatientLayout';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import ErrorPage from '../Error/ErrorPage';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';
import { useParams } from 'react-router-dom';
import ConditionsData from '../AlloCare/PersonalizationBaseline/ConditionsData';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
}));

const getFrequencyByRange = (range) => {
  switch (range) {
    case AlloCareReportRange.week:
      return { text: 'Daily Average', value: AlloCareReportFrequency.daily };
    case AlloCareReportRange.month:
      return {
        text: '3 Day Average',
        value: AlloCareReportFrequency.threeDays,
      };
    case AlloCareReportRange.quarter:
      return { text: '10 Day Average', value: AlloCareReportFrequency.tenDays };
    default:
      return null;
  }
};
function AlloCareTitle() {
  const classes = useStyles();

  return (
    <Grid container item justify="flex-end">
      <Box
        display="inline"
        component="div"
        flexGrow={1}
        style={{ paddingLeft: '10%' }}
        className={classes.title}
      >
        <Box display="inline" component="div">
          <img
            src={`/images/AlloCare_Logo_RGB.png`}
            alt="AlloCare logo"
            width="120px"
          ></img>
          <Typography style={{ fontWeight: '600' }} variant="subtitle1">
            Patient Reported Measures
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

function AlloCareLeftControls({ id, iBoxDetails }) {
  return <ConditionsData id={id} iBoxDetails={iBoxDetails} />;
}

function AlloCareRightControls({ range, onRangeChange }) {
  const currentFrequency = getFrequencyByRange(range);
  return (
    <Grid container item justify="flex-end">
      <Box m={1}>
        <FormControl variant="outlined" size="small">
          <InputLabel id="range-label">Range</InputLabel>
          <Select
            labelId="range-label"
            id="range-select-outlined"
            value={range}
            onChange={onRangeChange}
            label="Range"
          >
            <MenuItem value={AlloCareReportRange.week}>7 Days</MenuItem>
            <MenuItem value={AlloCareReportRange.month}>30 Days</MenuItem>
            <MenuItem value={AlloCareReportRange.quarter}>90 Days</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box m={1}>
        <FormControl variant="outlined" size="small">
          <label>
            Frequency
            <div style={{ fontWeight: 600 }}>{currentFrequency?.text}</div>
          </label>
        </FormControl>
      </Box>
    </Grid>
  );
}

function AlloCarePage() {
  const [range, setRange] = useState(AlloCareReportRange.week);
  const frequency = getFrequencyByRange(range);

  function handleRangeChange(e) {
    setRange(e.target.value);
  }
  const params = useParams();
  const id = params['id'];
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
      includeAllocare={true}
      days={7}
      averageSliceSize={1}
    >
      {function (data) {
        return (
          <PatientLayout
            header={<DemographicsHeader id={id} data={data} />}
            subHeader={<AlloCareTitle />}
            rightControls={
              <AlloCareRightControls
                range={range}
                onRangeChange={handleRangeChange}
              />
            }
            leftControls={
              <AlloCareLeftControls
                id={params['id']}
                iBoxDetails={data?.patient?.links?.ibox?.iBoxRecords[0].details}
              />
            }
          >
            <AlloCare
              patientId={params['id']}
              range={range}
              frequency={frequency?.value}
            />
          </PatientLayout>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(AlloCarePage, ErrorPage);
