import { useContext } from 'react';
import { SmartAuthenticationContext } from './SmartAuthentication';

export default function useSmartAuthentication() {
  const { authState, server } = useContext(SmartAuthenticationContext);
  return {
    authState,
    server,
  };
}
