import * as React from 'react';

function SvgPulseOx(props) {
  return (
    <svg {...props}>
      <defs>
        <mask id="pulse-ox_svg__a" fill="#fff">
          <use width="100%" height="100%" />
        </mask>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M8.075 7.92a2.336 2.336 0 01-2.343 2.328 2.336 2.336 0 01-2.344-2.329c0-1.286 2.344-4.896 2.344-4.896.678 1.087 2.343 3.61 2.343 4.896z"
          stroke="#ed1c24"
          strokeWidth={0.2831006}
        />
        <text
          fontFamily="Arial-BoldMT, Arial"
          fontSize={10.914}
          fontWeight="bold"
          fill="#ed1c24"
          transform="matrix(.26458 0 0 .26458 2.381 2.31)"
        >
          <tspan x={6.925} y={21.469}>
            {'O'}
          </tspan>
        </text>
        <text
          fontFamily="Arial-BoldMT, Arial"
          fontSize={5.35}
          fontWeight="bold"
          fill="#ed1c24"
          transform="matrix(.26458 0 0 .26458 2.381 2.31)"
        >
          <tspan x={15.511} y={23.944}>
            {'2'}
          </tspan>
        </text>
        <path
          d="M3.483 5.578c0 .3-.246.544-.55.544a.548.548 0 01-.552-.544c0-.3.247-.545.551-.545.305 0 .551.244.551.545M4.259 3.944c0 .195-.16.353-.358.353a.355.355 0 01-.357-.353c0-.194.16-.352.357-.352.198 0 .358.158.358.352M9.047 6.704c0 .195-.16.353-.357.353a.355.355 0 01-.357-.353c0-.194.16-.352.357-.352.197 0 .357.158.357.352M3.788 2.818a.284.284 0 01-.286.282.284.284 0 01-.285-.282c0-.156.128-.282.285-.282.158 0 .286.126.286.282M8.406 5.465a.284.284 0 01-.286.283.284.284 0 01-.286-.283c0-.156.128-.282.286-.282.158 0 .286.126.286.282"
          fill="#ed1c24"
        />
        <path
          d="M1.728.853a.859.859 0 01-.864.854A.859.859 0 010 .853C0 .383.387 0 .864 0s.864.382.864.853"
          fill="#ed1c24"
          mask="url(#pulse-ox_svg__a)"
          transform="matrix(.26458 0 0 .26458 4.414 2.31)"
        />
        <path
          d="M9.26 5.578a.227.227 0 01-.228.226.227.227 0 01-.229-.226c0-.125.103-.226.229-.226s.228.101.228.226"
          fill="#ed1c24"
        />
      </g>
    </svg>
  );
}

export default SvgPulseOx;
