import {
  Grid,
  Card,
  Paper,
  CardHeader,
  Typography,
  Box,
  CardContent,
  makeStyles,
  Divider,
} from '@material-ui/core';
import React from 'react';
import Moment from 'moment';
const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.common.black,
  },
  card: {
    borderRadius: '10px',
    width: '100%',
    minHeight: '100%',
    '& > .MuiCardHeader-root': {
      paddingBottom: '0px',
    },
    '& > .MuiCardContent-root': {
      textAlign: 'center',
      height: '100%',
      overflow: 'auto',
    },
    '& > .MuiCardHeader-root > .MuiCardHeader-content > .MuiCardHeader-subheader': {
      fontWeight: 'bold',
    },
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    minHeight: '100%',
    margin: '0px',
    padding: theme.spacing(1),
    '&:after': {
      content: '',
      flex: 'auto',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  tileContainer: {
    display: 'flex',
    justifyContent: 'left',
    color: 'black',
    margin: theme.spacing(1),
    '& > *': {
      fontSize: theme.typography.subtitle2.fontSize,
      textAlign: 'left',
    },
  },
  root: {
    flexGrow: 1,
    flexWrap: 'wrap',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const labelVal = (displayText, displayValue) =>
  ((useStyles) => {
    const classes = useStyles();
    return (
      <Typography variant="subtitle2">
        <div className={classes.tileContainer}>
          <Box display="inline" fontWeight="fontWeightMedium">
            <label style={{ fontWeight: 'bold' }}>{displayText}:</label>
          </Box>
          <Box display="inline" style={{ paddingLeft: '5px' }}>
            {displayValue}
          </Box>
        </div>
      </Typography>
    );
  })(useStyles);

const divider = () =>
  ((useStyles) => {
    const classes = useStyles();
    return <Divider classes={{ root: classes.divider }} variant="fullWidth" />;
  })(useStyles);

const currenTransplantCard = (currentTransplant) =>
  ((useStyles) => {
    const classes = useStyles();
    return (
      <Card component={Paper} className={classes.card}>
        <CardHeader subheader="Current Transplant"></CardHeader>
        <CardContent>
          {labelVal('Tx Type', currentTransplant.txType)}
          {labelVal(
            'Tx Date',
            Moment(currentTransplant.txDate).format('MM/DD/YYYY')
          )}

          {labelVal('Recepient Center', currentTransplant.recipientCenter)}
          {labelVal('Follow-Up Center', currentTransplant.followupCenter)}
          {labelVal('Primary Physician', currentTransplant.primaryPhysician)}
          {divider()}
          {labelVal('HLA Match', currentTransplant.hLAMatch)}
          {labelVal('PRA at Tx', currentTransplant.dSATx)}
          {labelVal('DSA at Tx', currentTransplant.pRATx)}
          {labelVal('Induction', currentTransplant.induction)}
          {labelVal(
            'Creatinine at Discharge',
            currentTransplant.creatinineDischarge
          )}
        </CardContent>
      </Card>
    );
  })(useStyles);

const donorOrganCard = (donorDonorOrgan) =>
  ((useStyles) => {
    const classes = useStyles();
    return (
      <Card component={Paper} className={classes.card}>
        <CardHeader subheader="Donor / Donor Organ"></CardHeader>
        <CardContent>
          {labelVal('Organ Type', donorDonorOrgan.organType)}
          {labelVal('Donor Type', donorDonorOrgan.donorType)}
          {labelVal('Donor Age', donorDonorOrgan.donorAge)}
          {labelVal(
            'Living Donor Creatinine',
            donorDonorOrgan.livingDonorCreatinine
          )}
          {divider()}
          {labelVal('KDPI', donorDonorOrgan.kDPI)}
          {labelVal('Cold Ischemic Time', donorDonorOrgan.coldIschemicTime)}
          {labelVal(
            'Deceased Donor Creatinine',
            donorDonorOrgan.deceasedDonorCreatinine
          )}
          {labelVal(
            'Cause Of Death',
            donorDonorOrgan.deceasedDonorCauseOfDeath
          )}
          {divider()}
          {labelVal('CMV Status', donorDonorOrgan.cMVStatus)}
          {labelVal('EBV Status', donorDonorOrgan.eBVStatus)}
          {labelVal('Help B Status', donorDonorOrgan.hepCStatus)}
          {labelVal('Increased Risk', donorDonorOrgan.increasedRisk)}
        </CardContent>
      </Card>
    );
  })(useStyles);

const pastMedicalHistoryCard = (pastMedicalHistory) =>
  ((useStyles) => {
    const classes = useStyles();
    return (
      <Card component={Paper} className={classes.card}>
        <CardHeader subheader="Past Medical History"></CardHeader>
        <CardContent>
          {labelVal(
            'Previous Transplants',
            pastMedicalHistory.previousTransplants
          )}
          {labelVal(
            'Multi-Organ Transplant',
            pastMedicalHistory.multiOrganTransplant
          )}
          {labelVal(
            'Primary Diagnosis at Tx',
            pastMedicalHistory.primaryDiagnosisTx
          )}
          {divider()}
          {labelVal(
            'Date of First Dialysis',
            Moment(pastMedicalHistory.dateOfFirstDialysis).format('MM/DD/YYYY')
          )}

          {labelVal('Years on Dialysis', pastMedicalHistory.yearsOnDialysis)}
          {divider()}
          {labelVal('Pregnancy', pastMedicalHistory.preganacy)}
          {labelVal('History of Cancer', pastMedicalHistory.historyOfCancer)}
          {labelVal(
            'Other Medical History',
            pastMedicalHistory.otherMedicalHistory
          )}
        </CardContent>
      </Card>
    );
  })(useStyles);

export default function PatientTransplantHistory(props) {
  const classes = useStyles();
  const { transplantHistory } = props;
  return (
    <div className={classes.root}>
      <Grid component={Card} className={classes.grid} container spacing={2}>
        <Grid item xl={4} xs={12} sm={6} md={4}>
          {currenTransplantCard(transplantHistory.currentTransplant)}
        </Grid>
        <Grid item xl={4} xs={12} sm={6} md={4}>
          {donorOrganCard(transplantHistory.donorDonorOrgan)}
        </Grid>
        <Grid item xl={4} xs={12} sm={6} md={4}>
          {pastMedicalHistoryCard(transplantHistory.pastMedicalHistory)}
        </Grid>
      </Grid>
    </div>
  );
}
