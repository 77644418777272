import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import NoAlloViewData from '../Widgets/AlloView/NoAlloViewData';
import { useParams } from 'react-router-dom';
import {
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Toolbar,
} from '@material-ui/core';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import { ScoreIndicator } from './ScoreIndicator';
import useTable from '../Widgets/shared/useTable';
import { Add } from '@material-ui/icons';
import './AlloViewResults.css';

const headCells = [
  {
    id: '',
    label: '',
    sortable: false,
    className: 'alignCenter',
  },
  {
    id: 'result.alloViewScore',
    label: 'Score',
    sortable: false,
    className: 'alignCenter',
  },
  {
    id: 'parameters.riskEvaluationDate',
    label: 'Evaluation Date',
    sortable: true,
    className: 'alignCenter',
  },
  {
    id: 'parameters.alloSureScore',
    label: 'AlloSure',
    sortable: true,
    className: 'alignCenter',
  },
  {
    id: 'parameters.dsaMfi',
    label: 'DSA',
    sortable: true,
    className: 'alignCenter',
  },
  {
    id: 'parameters.creatinine',
    label: 'Creatinine',
    sortable: true,
    className: 'alignCenter',
  },
  {
    id: 'parameters.previousCreatinine',
    label: 'Previous Creatinine',
    sortable: true,
    className: 'alignCenter',
  },
  {
    id: 'parameters.egfr',
    label: 'eGFR',
    sortable: true,
    className: 'alignCenter',
  },
  {
    id: 'parameters.previousRejection',
    label: 'Previous Rejection',
    sortable: true,
    className: 'alignCenter',
  },
  {
    id: 'result.systemGenerated',
    label: 'Calculation',
    sortable: true,
    className: 'alignCenter',
  },
];

function AlloViewResults({ data }) {
  const widgetData = data?.patient?.links.alloView.alloViewRecords;
  console.log('results page', widgetData);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    setFilterFn({
      fn: (items) => {
        return items;
      },
    });
  }, []);
  const add = () => {
    return history.push(`/patient/${id}/alloViewRecord`);
  };
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(widgetData, headCells, filterFn, 20, [20, 40, 60]);
  const content = () => {
    if (widgetData.length > 0) {
      return (
        <>
          <Paper elevation={0} className="paperStyles">
            <Toolbar variant="dense" className="toolbar">
              <Button
                className="addButtonLeft"
                onClick={add}
                size="small"
                variant="contained"
                color="primary"
                startIcon={<Add></Add>}
              >
                <span>New Scores</span>
              </Button>
            </Toolbar>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow key={row.result.alloViewReportId}>
                    <TableCell className="cell">
                      <Link
                        style={{ textDecoration: 'none' }}
                        to={`/patient/${id}/alloViewChart`}
                      >
                        <ScoreIndicator
                          score={row.result.alloViewScore}
                        ></ScoreIndicator>
                      </Link>
                    </TableCell>
                    <TableCell className="cell">
                      {row.result.alloViewScore}
                    </TableCell>
                    <TableCell className="cell">
                      {Moment(row.parameters.riskEvaluationDate).format(
                        'MM/DD/YY'
                      )}
                    </TableCell>
                    <TableCell className="cell">
                      {row.parameters.alloSureScore}
                    </TableCell>
                    <TableCell className="cell">
                      {row.parameters.dsaMfi}
                    </TableCell>
                    <TableCell className="cell">
                      {row.parameters.creatinine}
                    </TableCell>
                    <TableCell className="cell">
                      {row.parameters.previousCreatinine}
                    </TableCell>
                    <TableCell className="cell">
                      {row.parameters.egfr}
                    </TableCell>
                    <TableCell className="cell">
                      {row.parameters.previousRejection === true ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell className="cell">
                      {row.result.systemGenerated === true
                        ? 'Automatic'
                        : 'Manual'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination className="pagination" />
          </Paper>
        </>
      );
    }
    return <NoAlloViewData></NoAlloViewData>;
  };

  return <>{content()}</>;
}

export default AlloViewResults;
