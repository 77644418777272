// @ts-nocheck
import React, { useEffect } from 'react';
import { Chart } from 'chart.js';
import Moment from 'moment';

//plugins
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import { DragIndicator } from '@material-ui/icons';
var image = new Image(12, 12);
image.src = <DragIndicator></DragIndicator>;
Chart.plugins.register(annotationPlugin);
const EventTimelineLineChart = ({
  chartConfiguration,
  range,
  frequency,
  dataSets,
  showXLabels,
  canvasHeight,
  labelDayFormat,
  scaleXPadding,
}) => {
  const rotation = dataSets.length > 0 && dataSets[0].data.length > 15 ? 45 : 0;

  const chartRef = React.createRef();
  useEffect(() => {
    var myDataSets = dataSets.map(function (ds) {
      return {
        ...ds,
        data: ds.data.map(function (d) {
          return {
            x: d.start,
            y: d.value,
          };
        }),
        fill: false,
        lineTension: 0,
      };
    });
    const myChartRef = chartRef.current.getContext('2d');
    let chartElementRef = new Chart(myChartRef, {
      type: chartConfiguration.chartType || 'line',
      data: {
        datasets: myDataSets,
      },
      options: {
        tooltips: {
          position: 'nearest',
          backgroundColor: '#fff',
          bodyFontColor: 'black',
          borderColor: 'blue',
          borderWidth: 1,
          yPadding: chartConfiguration.chartType === 'scatter' ? 6 : 0,
          titleSpacing: 0,
          titleMarginBottom: 0,
          callbacks: {
            beforeLabel: function (tooltipItem, data) {
              return Moment(tooltipItem.xLabel).format('MM/DD/YYYY');
            },
            label: function (tooltipItem, data) {
              return data.datasets[0].label + ' : ' + tooltipItem.yLabel;
            },
            afterLabel: function (tooltipItem, data) {
              return 'Rx by:';
            },
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 15,
            bottom: 10,
          },
        },
        annotation: {
          annotations: chartConfiguration.getAnnotations
            ? chartConfiguration.getAnnotations(dataSets, frequency, range)
            : [],
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: function (context) {
              var value = context.dataset.data[context.dataIndex]['y'];
              return chartConfiguration.color(value);
            },
            formatter: function (value, context) {
              return context.dataset.data[context.dataIndex]['y'];
            },
            font: {
              size: 11,
              weight: 'bold',
            },
            align: function (context) {
              return context.datasetIndex === 0 ? 'end' : 'start';
            },
            offset: chartConfiguration.dataLabelsOffSet,
            padding: chartConfiguration.dataLabelsPadding,
            clamp: true,
            display: 'auto',
          },
        },
        scales: {
          xAxes: [
            {
              id: 'x',
              type: 'time',
              time: {
                parser: 'YYYY-MM-DD HH:mm:ss',
                unit: 'day',
                displayFormats: {
                  minute: 'YYYY-MM-DD HH:mm:ss',
                  hour: 'YYYY-MM-DD HH:mm:ss',
                  day: labelDayFormat,
                  week: 'MM/DD/YYYY',
                  month: 'MM/DD/YYYY',
                },
              },
              ticks: {
                //stepSize: 2,
                source: 'auto',
                align: 'center',
                userCallback: function (label) {
                  return label;
                },
                minRotation: rotation,
                padding: scaleXPadding,
                color: 'black',
              },
              position: 'top',
              gridLines: {
                display: false,
                color: 'red',
                drawBorder: false,
              },
              display: chartConfiguration.showXLabels || showXLabels,
            },
          ],
          yAxes: [
            {
              id: 'y',
              display: false,
              ticks: {
                userCallback: function (value) {
                  return value;
                },
              },
              gridLines: {
                display: false,
                color: 'red',
              },
            },
          ],
        },
      },
      plugins: [ChartDataLabels],
    });
    return () => {
      Chart.plugins.unregister(ChartDataLabels);
      chartElementRef.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartConfiguration,
    chartRef,
    dataSets,
    frequency,
    labelDayFormat,
    range,
    scaleXPadding,
    showXLabels,
  ]);

  return (
    <div>
      <canvas height={canvasHeight} ref={chartRef} style={{ zIndex: 10000 }} />
    </div>
  );
};

export default EventTimelineLineChart;
