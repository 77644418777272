import React, { useState, useEffect, createContext } from 'react';
import AuthenticationConfig from './AuthenticationConfig';
import AuthenticationService from './AuthenticationService';
import TokenRepository from './TokenRepository';

const tokenRepository = new TokenRepository();

const AuthenticationContext = createContext({
  config: {
    clientId: null,
    redirectUri: null,
    tokenUri: null,
    authorizeUri: null,
    responseType: null,
    scope: null,
  },
  authState: {
    isLoading: false,
    isAuthenticated: false,
    hasError: false,
    tokenResponse: null,
  },
  authService: {
    async login() {},
    async logout(refreshToken) {},
    async exchangeToken(subjectToken, clientIssuer) {},
    async refreshToken(accessToken, refreshToken) {},
    async getToken(code, codeVerifier) {},
  },
  tokenRepository,
  setHasError() {},
  setIsLoading() {},
  setTokenResponse() {},
  removeTokenResponse() {},
});

function Authentication({ children, config }) {
  if (!(config instanceof AuthenticationConfig)) {
    throw new Error('Parameter `config` must be of type AuthenticationConfig');
  }

  const [authState, updateAuthState] = useState({
    isLoading: false,
    isAuthenticated: false,
    hasError: false,
    tokenResponse: null,
  });

  useEffect(
    function () {
      const tokenResponse = tokenRepository.get();
      if (tokenResponse) {
        const isAuthenticated = tokenResponse.access_token;
        updateAuthState((prev) => ({
          ...prev,
          tokenResponse,
          isAuthenticated,
        }));
      }
    },
    [updateAuthState]
  );

  const authService = new AuthenticationService(config);

  const contextValue = {
    config,
    authState,
    authService,
    tokenRepository,
    setHasError(hasError) {
      updateAuthState((prev) => ({
        ...prev,
        hasError,
      }));
    },
    setIsLoading(isLoading) {
      updateAuthState((prev) => ({
        ...prev,
        isLoading,
      }));
    },
    setTokenResponse(tokenResponse) {
      tokenRepository.put(tokenResponse);
      const isAuthenticated = tokenResponse && tokenResponse.access_token;
      updateAuthState((prev) => ({
        ...prev,
        tokenResponse,
        isAuthenticated,
        hasError: !isAuthenticated,
      }));
    },
    removeTokenResponse() {
      tokenRepository.remove();
      updateAuthState((prev) => ({
        ...prev,
        tokenResponse: null,
        isAuthenticated: false,
      }));
    },
  };

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {children}
    </AuthenticationContext.Provider>
  );
}

export { Authentication, AuthenticationContext };
