import { path } from '../utility.js';

function descendingComparator(a, b, orderBy) {
  const bVal =
    orderBy != null
      ? typeof orderBy === 'string'
        ? path(orderBy.split('.'), b) || ''
        : path(orderBy, b) || ''
      : orderBy;
  const aVal =
    orderBy != null
      ? typeof orderBy === 'string'
        ? path(orderBy.split('.'), a) || ''
        : path(orderBy, a) || ''
      : orderBy;

  if (bVal < aVal) {
    return -1;
  }
  if (bVal > aVal) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
