import { SvgIcon } from '@material-ui/core';
import {
  BloodPressure,
  MedicineSm,
  PulseOx,
  Scale,
  Selector,
  Steps,
  Urine,
  Water,
} from 'icons/IconComponents';
import React from 'react';

const ChartTypes = {
  fluidIntake: 'fluidIntake',
  Urine: 'urine',
  Weight: 'weight',
  BloodPressure: 'bloodPressure',
  Steps: 'steps',
  Oxygen: 'oxygen',
  Temperature: 'temperature',
  BloodGlucose: 'glucose',
};
const ChartsConfiguration = {};

const waterIcon = new Image();
waterIcon.height = 10;
waterIcon.width = 10;
waterIcon.src = '/images/water.svg';
ChartsConfiguration[ChartTypes.fluidIntake] = {
  id: ChartTypes.fluidIntake,
  label: 'Fluid Intake',
  color: (value) => {
    return value !== null && value >= 2 && value <= 3 ? 'black' : 'red';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'Fluid Intake',
        backgroundColor: 'skyblue',
        borderColor: 'skyblue',
        borderWidth: 2,
        pointRadius: 4,
        data: data.series[0].elements,
        pointStyle: waterIcon,
      },
    ];
  },
  tickCallBack: () => {},
  labelIcon: <SvgIcon component={Water} viewBox="4 4 10 10"></SvgIcon>,
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.Urine] = {
  id: ChartTypes.Urine,
  label: 'Urine Output',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'Urine Output',
        backgroundColor: 'yellow',
        borderColor: 'yellow',
        pointStyle: 'rectRot',
        borderWidth: 2,
        pointRadius: 4,
        data: data.series[0].elements,
      },
    ];
  },
  labelIcon: <SvgIcon component={Urine} viewBox="3 4 8 8"></SvgIcon>,
  group: 1,
  datalabels: {
    color: '#36A2EB',
  },
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

ChartsConfiguration[ChartTypes.Weight] = {
  id: ChartTypes.Weight,
  label: 'Weight',
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        pointStyle: 'rect',
        pointRadius: 4,
        backgroundColor: 'purple',
        borderColor: 'purple',
        borderWidth: 2,
        label: 'Weight',
        data: data.series[0].elements,
      },
    ];
  },
  labelIcon: <SvgIcon component={Scale} viewBox="4 4 10 10"></SvgIcon>,
  group: 1,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};

const annotationMinMax = (startDate, range) => {
  //This is a temporary fix while we research more about box not being displayed correctly.
  return [startDate, startDate];

  // switch (range) {
  //   case AlloCareReportRange.week:
  //     return [
  //       moment(startDate).subtract(2, 'hour').toDate(),
  //       moment(startDate).add(2, 'hour').toDate(),
  //     ];
  //   case AlloCareReportRange.month:
  //     return [
  //       moment(startDate).subtract(10, 'hour').toDate(),
  //       moment(startDate).add(10, 'hour').toDate(),
  //     ];
  //   case AlloCareReportRange.quarter:
  //     return [
  //       moment(startDate).subtract(30, 'hour').toDate(),
  //       moment(startDate).add(30, 'hour').toDate(),
  //     ];
  //   default:
  //     return [
  //       moment(startDate).subtract(2, 'hour').toDate(),
  //       moment(startDate).add(2, 'hour').toDate(),
  //     ];
  // }
};
ChartsConfiguration[ChartTypes.BloodPressure] = {
  id: ChartTypes.BloodPressure,

  labelIcon: <SvgIcon component={BloodPressure} viewBox="4 4 8 8"></SvgIcon>,
  label: 'Blood Pressure',
  color: (value) => {
    return 'black';
  },
  getAnnotations: (dataSets, frequency, range) => {
    const annotations = dataSets[0].data.reduce((curr, acc, idx) => {
      if (dataSets[0].data[idx].value) {
        const xAnnotations = annotationMinMax(acc.start, frequency, range);
        curr[idx] = {
          type: 'box',
          xScaleID: 'x',
          yScaleID: 'y',
          xMin: xAnnotations[0],
          xMax: xAnnotations[1],
          yMin: dataSets[1].data[idx].value - 2,
          yMax: dataSets[0].data[idx].value + 2,
          backgroundColor: 'rgba(255, 99, 132, 0.001)',
          borderColor: '#808080',
        };
        return curr;
      }
      return curr;
    }, []);
    return annotations;
  },
  getDataSets: (data) => {
    return [
      {
        pointStyle: 'circle',
        pointRadius: 3,
        backgroundColor: 'green',
        borderColor: 'green',
        label: 'Diastolic',
        borderWidth: 2,
        data: data.series[0].elements,
      },
      {
        pointStyle: 'circle',
        pointRadius: 3,
        backgroundColor: 'blue',
        borderColor: 'blue',
        borderWidth: 2,
        label: 'Systolistic',
        data: data.series[1].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 3,
  dataLabelsOffSet: 2,
};

ChartsConfiguration[ChartTypes.Oxygen] = {
  id: ChartTypes.Oxygen,
  label: 'Oxygen',
  labelIcon: <SvgIcon component={PulseOx} viewBox="3 3 8 9"></SvgIcon>,
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        pointStyle: 'circle',
        pointRadius: 4,
        pointBorderColor: 'rgb(250, 140, 230)',
        label: 'Oxygen',
        borderWidth: 2,
        backgroundColor: 'rgb(250, 140, 230)',
        borderColor: 'rgb(250, 140, 230)',
        data: data.series[0].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.Temperature] = {
  id: ChartTypes.Temperature,
  labelIcon: <SvgIcon component={MedicineSm} viewBox="4 4 10 10"></SvgIcon>,
  label: 'Temperature',
  color: (value) => {
    return value !== null && value >= 97 && value <= 99 ? 'black' : 'red';
  },
  getDataSets: (data) => {
    return [
      {
        pointStyle: 'circle',
        pointRadius: 4,
        pointBorderColor: 'rgb(250, 140, 230)',
        label: 'Temperature',
        borderWidth: 2,
        backgroundColor: 'rgb(148, 223, 255)',
        borderColor: 'rgb(148, 223, 255)',
        data: data.series[0].elements,
      },
    ];
  },
  group: 2,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.BloodGlucose] = {
  id: ChartTypes.BloodGlucose,
  label: 'Blood Glucose',
  labelIcon: <SvgIcon component={Selector} viewBox="3 4 8 9"></SvgIcon>,
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'Blood Glucose',
        backgroundColor: 'red',
        borderWidth: 2,
        borderColor: 'red',
        pointStyle: 'star',
        pointRadius: 5,
        data: data.series[0].elements,
      },
    ];
  },
  group: 3,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
ChartsConfiguration[ChartTypes.Steps] = {
  id: ChartTypes.Steps,
  label: 'Steps',
  labelIcon: <SvgIcon component={Steps} viewBox="4 4 10 10"></SvgIcon>,
  color: (value) => {
    return 'black';
  },
  getDataSets: (data) => {
    return [
      {
        label: 'Steps',
        backgroundColor: 'orange',
        borderColor: 'orange',
        pointStyle: 'triangle',
        borderWidth: 2,
        pointRadius: 4,
        data: data.series[0].elements,
      },
    ];
  },
  group: 3,
  dataLabelsPadding: 0,
  dataLabelsOffSet: 4,
};
const AlloCareReportFrequency = {
  daily: 1,
  threeDays: 3,
  tenDays: 10,
};

const AlloCareReportRange = {
  week: 7,
  month: 30,
  quarter: 90,
};

export {
  ChartsConfiguration,
  ChartTypes,
  AlloCareReportRange,
  AlloCareReportFrequency,
};
