import React from 'react';
import { TranslatedTypography } from 'Components/Localization';

export default function KidneyCareDisclaimer({ variant }) {
  return (
    <TranslatedTypography variant={variant}>
      <strong>Research Use Only:</strong> AlloMap-Kidney gene expression and
      AiKidney &trade; iBox Long-Term Prognosis scores are for Research Use Only
      and are not intended for diagnostic procedures. Results are not approved
      for publication or any other use without written permission from CareDx,
      Inc.
    </TranslatedTypography>
  );
}
