import React, { useState } from 'react';
import {
  TableBody,
  TableRow,
  TableCell,
  Paper,
  makeStyles,
  Link,
  Box,
  Typography,
} from '@material-ui/core';
import Moment from 'moment';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { grey, red } from '@material-ui/core/colors';
import PDFReportDialog from '../shared/PDFReportDialog';
import useTable from '../shared/useTable';
import KidneyCareDisclaimer from './KidneyCareDisclaimer';

const headCells = [
  { id: 'details.dateReported', label: 'Report Date', sortable: true },
  { id: 'details.accessionId', label: 'Accession ID', sortable: true },
  {
    id: 'details.prescriber',
    label: 'Ordered By (Client)',
    sortable: true,
  },
  { id: 'flag', label: 'Flag', sortable: false },
  {
    id: 'details.dateCollected',
    label: 'AlloSure Draw Date',
    sortable: true,
  },
  {
    id: 'details.asScore',
    label: 'AlloSure Score (0.12%-16%)',
    sortable: true,
  },
  {
    id: 'details.amReportDate',
    label: 'AlloMap Draw Date',
    sortable: true,
  },
  {
    id: 'details.amScore',
    label: 'AlloMap Score (0-20)',
    sortable: true,
  },
  {
    id: 'parameters.Creatinine',
    label: 'SCr Level (<2)',
    sortable: true,
  },
  {
    id: 'parameters.dateOfProteinuriaTaken',
    label: 'Urine Collection Date',
    sortable: true,
  },
  {
    id: 'parameters.proteinuriaInGG',
    label: 'Protein/Creatinine Ratio',
    sortable: true,
  },
  {
    id: 'parameters.proteinuriaDipstick',
    label: 'Urine Dipstick',
    sortable: true,
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100vw',
    backgroundColor: 'white',
    paddingBottom: '20px',
    paddingTop: '20px',
    height: 'calc(100vh - 120px)',
    justifyContent: 'flex-start',
  },
  disclaimerBox: {
    paddingLeft: '41px',
    paddingRight: '41px',
    paddingTop: '20px',
    paddingBottom: '10px',
    textAlign: 'center',
    display: 'block',
    width: '100%',
  },
  paperStyles: {
    marginBottom: theme.spacing(2),
    marginLeft: '40px',
    marginRight: '40px',
    borderRadius: 15,
    backgroundColor: 'white',
    border: `1px solid ${grey[300]}`,
    overflowX: 'auto',
    padding: '15px',
    maxHeight: '525px',
  },
  flaggedCell: {
    color: 'red',
  },
}));

export default function KidneyCareDetails({ filterFn, data }) {
  const classes = useStyles();
  const baseUrl = `${process.env.REACT_APP_API_V1}/allosure/report/`;

  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(data, headCells, filterFn);

  const isCellFlagged = (cellId, value) => {
    if (value != null) {
      let numberValue = Number(value.replace('%', ''));
      switch (cellId) {
        case 'amScore':
          if (numberValue < 0 || numberValue > 20) {
            return true;
          }
          break;
        case 'creatinine':
          if (numberValue >= 2) {
            return true;
          }
          break;
        case 'asScore':
          if (numberValue < 0.12 || numberValue > 16) {
            return true;
          }
          break;
        default:
          break;
      }
    }
    return false;
  };

  const getIcon = (item) => {
    if (
      isCellFlagged('asScore', item.details.asScore) ||
      isCellFlagged('amScore', item.details.amScore) ||
      isCellFlagged('creatinine', item.parameters.Creatinine)
    )
      return <ReportProblemOutlinedIcon style={{ color: red[500] }} />;
    else return '';
  };

  const getTextColor = (cellId, value) => {
    if (isCellFlagged(cellId, value)) return classes.flaggedCell;
    else return '';
  };

  const [reportState, setReportState] = useState({
    open: false,
    url: '',
    header: 'KidneyCare Report',
  });

  const openKCReport = (pid, oid) => {
    setReportState((prevState) => ({
      ...prevState,
      open: true,
      url: baseUrl + `${pid}/${oid}`,
    }));
  };

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box className={classes.container}>
          <Box className={classes.disclaimerBox} m={1} component="div">
            <KidneyCareDisclaimer variant="body2" />
          </Box>
          <Paper elevation={3} className={classes.paperStyles}>
            <Box p={1}>
              <Typography variant="h6">Historical Results</Typography>
            </Box>
            <TblContainer>
              <TblHead />
              <TableBody>
                {recordsAfterPagingAndSorting().map((row) => (
                  <TableRow key={row.oid}>
                    <TableCell>
                      {row.details?.dateReported != null
                        ? Moment(row.details?.dateReported).format('MM-DD-YYYY')
                        : ''}
                    </TableCell>
                    <TableCell>
                      <Link
                        href="#"
                        onClick={() => openKCReport(row.pid, row.oid)}
                      >
                        {row.details.accessionId}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {`${row.details.prescriber} (${row.details._Client})`}
                    </TableCell>
                    <TableCell>{getIcon(row)}</TableCell>
                    <TableCell>
                      {row.details?.dateCollected != null
                        ? Moment(row.details?.dateCollected).format(
                            'MM-DD-YYYY'
                          )
                        : ''}
                    </TableCell>
                    <TableCell
                      className={getTextColor('asScore', row.details.asScore)}
                    >
                      {row.details.asScore}
                    </TableCell>
                    <TableCell>
                      {row.details?.amReportDate != null
                        ? Moment(row.details?.amReportDate).format('MM-DD-YYYY')
                        : ''}
                    </TableCell>
                    <TableCell
                      className={getTextColor('amScore', row.details.amScore)}
                    >
                      {row.details.amScore}
                    </TableCell>
                    <TableCell
                      className={getTextColor(
                        'creatinine',
                        row.parameters.Creatinine
                      )}
                    >
                      {row.parameters.Creatinine}
                    </TableCell>
                    <TableCell>
                      {row.parameters?.dateOfProteinuriaTaken != null
                        ? Moment(row.parameters?.dateOfProteinuriaTaken).format(
                            'MM-DD-YYYY'
                          )
                        : ''}
                    </TableCell>
                    <TableCell>{row.parameters.proteinuriaInGG}</TableCell>
                    <TableCell>{row.parameters.proteinuriaDipstick}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            <TblPagination />
          </Paper>
          {reportState?.open && (
            <PDFReportDialog
              reportState={reportState}
              toggleReportModal={setReportState}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
