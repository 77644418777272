import React from 'react';
import { Link } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import PageLoadingIndicator from '../../Indicators/PageLoadingIndicator';
import { makeStyles } from '@material-ui/core/styles';

import {
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Radio,
} from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  radio: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  checked: {},
}));

export default function EditListsPage({
  loading,
  error,
  data,
  userId,
  handleEditOpen,
  upsertMyListConfig,
  saving,
}) {
  const classes = useStyles();

  const handleRadioChange = (row, listConfigAll) => {
    let listConfig = [];
    listConfigAll.forEach((i) => {
      if (i.hasDefaultSort) {
        var item = {
          ...i,
          userId: userId,
          filter: [],
          description: i.description ? i.description : '',
          hasDefaultSort: false,
        };
        listConfig.push(item);
      }
    });

    listConfig.forEach((d) => {
      if (!saving) {
        upsertMyListConfig({
          variables: {
            ...d,
          },
        });
      }
    });

    if (!saving) {
      upsertMyListConfig({
        variables: {
          ...row,
          userId: userId,
          filter: [],
          description: row.description ? row.description : '',
          hasDefaultSort: true,
        },
      });
    }
  };

  const DELETE_LIST = gql`
    mutation DeleteMyListConfig($id: String!, $userId: String!) {
      deleteMyListConfig(id: $id, userId: $userId) {
        id
        userId
      }
    }
  `;

  const [deleteMyListConfig] = useMutation(DELETE_LIST, {
    update(cache, { data: { deleteMyListConfig } }) {
      cache.modify({
        fields: {
          myListConfigAll(existingLists) {
            const deletedRef = cache.writeFragment({
              data: deleteMyListConfig,
              fragment: gql`
                fragment DeletedConfig on MyListConfigType {
                  id
                  userId
                }
              `,
            });
            const updatedList = existingLists.filter(
              (r) => r.__ref !== deletedRef.__ref
            );
            return [...updatedList];
          },
        },
      });
    },
  });

  const handleRemoveListClick = (id) => {
    deleteMyListConfig({
      variables: {
        id: id,
        userId: userId,
      },
    });
  };

  let content;
  if (loading) {
    content = (
      <PageLoadingIndicator>Loading List Collection...</PageLoadingIndicator>
    );
  } else if (error) {
    //console.error(error);
    content = JSON.stringify(error);
  } else {
    const lists = data.myListConfigAll.map((l) => {
      function onRemoveListClick() {
        handleRemoveListClick(l.id);
      }
      function onEditListClick() {
        handleEditOpen(l.id, l.collectionId);
      }
      function onRadioClick() {
        handleRadioChange(l, data.myListConfigAll);
      }
      let viewLink;
      if (l.isStaticList) {
        viewLink = `/view-list/${l.id}/${l.collectionId}`;
      } else {
        viewLink = `/view-list/${l.id}`;
      }
      return (
        <TableRow key={l.id}>
          <TableCell component="th" scope="row">
            <Radio
              classes={{ root: classes.radio, checked: classes.checked }}
              onClick={onRadioClick}
              checked={l.hasDefaultSort}
            />
          </TableCell>
          <TableCell align="right">
            <Link to={viewLink}>{l.title}</Link>
          </TableCell>
          <TableCell align="right">{l.description}</TableCell>
          <TableCell align="right">
            <IconButton onClick={onEditListClick}>
              <EditIcon />
            </IconButton>
          </TableCell>
          <TableCell align="right">
            <IconButton onClick={onRemoveListClick}>
              <RemoveCircleOutline />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
    content = (
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Default</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Description</TableCell>
                <TableCell align="right">Modify</TableCell>
                <TableCell align="right">Trash</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{lists}</TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return <div>{content}</div>;
}
