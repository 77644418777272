import React from 'react';
import ErrorBoundaryHOC from '../../../ErrorBoundaryHOC';
import ErrorPage from '../../Error/ErrorPage';
import AlloSureHistrogramChart from './AlloSureHistrogramChart';
import PatientLayout from 'Components/Layout/PatientLayout';
import { useParams } from 'react-router-dom';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';
import { alloSureHistrogramChartConfigurationMode } from '../../../Utilities/Infrastucture/alloSureHistrogramChartConfigurationMode';
export function AlloSureHistrogramChartPage() {
  const { id } = useParams();
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
      includeAllocare={true}
      includeImmunosuppression={true}
      days={7}
      averageSliceSize={1}
    >
      {(data) => {
        return (
          <PatientLayout>
            <AlloSureHistrogramChart
              width={'100%'}
              height={'100'}
              configurationMode={alloSureHistrogramChartConfigurationMode.PAGE}
              alloSureData={data}
            />
          </PatientLayout>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(AlloSureHistrogramChartPage, ErrorPage);
