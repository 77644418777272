import React from 'react';
import { useQuery, gql } from '@apollo/client';
import PageLoadingIndicator from '../../Indicators/PageLoadingIndicator';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  checkbox: {
    '&$checked': {
      color: '#4B8DF8',
    },
  },
  checked: {},
}));

export default function SmartListsPage({ handleOnCheck, checkedList }) {
  const classes = useStyles();

  const handleChecked = (row) => {
    handleOnCheck(row);
  };

  const GET_LISTS = gql`
    query getPatientCollections {
      listConfigAll {
        id
        title
        description
        isStaticList
        collectionId
        fields
        hasDefaultSort
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_LISTS);

  let content;
  if (loading) {
    content = (
      <PageLoadingIndicator>Loading List Collection...</PageLoadingIndicator>
    );
  } else if (error) {
    content = JSON.stringify(error);
  } else {
    const lists = data.listConfigAll.map((l) => {
      function onChecked() {
        handleChecked(l);
      }
      const isChecked =
        checkedList.findIndex((d) => d.id === l.id) === -1 ? false : true;
      return (
        <TableRow key={l.id}>
          <TableCell component="th" scope="row">
            <Checkbox
              classes={{ root: classes.checkbox, checked: classes.checked }}
              onChange={onChecked}
              checked={isChecked}
            />
          </TableCell>
          <TableCell align="right">{l.title}</TableCell>
          <TableCell align="right">{l.description}</TableCell>
        </TableRow>
      );
    });
    content = (
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{lists}</TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  return <div>{content}</div>;
}
