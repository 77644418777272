import * as React from 'react';

function SvgScale(props) {
  return (
    <svg {...props}>
      <g
        transform="matrix(.26458 0 0 .26458 5.557 5.295)"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M1.826 5.681L7.37 3.314v0h10.58l6.15 2.405a2.375 2.375 0 011.453 2.732l-3.977 17.695A2.375 2.375 0 0119.259 28H6.84a2.375 2.375 0 01-2.315-1.843L.445 8.397a2.375 2.375 0 011.381-2.716z"
          stroke="#000"
          strokeWidth={0.939}
          fill="#fff"
        />
        <ellipse
          stroke="#000"
          strokeWidth={0.994}
          fill="#fff"
          cx={12.674}
          cy={6.075}
          rx={5.983}
          ry={6.075}
        />
        <path
          stroke="#000"
          strokeWidth={0.5}
          strokeLinecap="round"
          d="M5.528 15.237h14.944M5.528 18.109h14.944M5.528 20.981h14.944M5.528 23.853h14.944"
        />
        <path
          stroke="#000"
          strokeWidth={0.607}
          strokeLinecap="square"
          d="M12.724 10.852v.994M12.724.359v.994"
        />
        <path
          stroke="#000"
          strokeWidth={0.607}
          fill="#fff"
          strokeLinecap="square"
          d="M9.336 2.803l-.704-.69M16.77 10.092l-.704-.69M10.577 1.871l-.444-.886M15.269 11.22l-.444-.886M8.427 4.171l-.894-.405M17.869 8.439l-.895-.405"
        />
        <path
          stroke="#000"
          strokeWidth={0.607}
          strokeLinecap="square"
          d="M14.825 1.871l.444-.886M10.133 11.22l.444-.886M16.336 3.114l.761-.626M8.305 9.717l.76-.625M17.197 4.793l.942-.274M7.263 7.686l.941-.274M8.023 6.125h-.979M18.358 6.125h-.979"
        />
        <g transform="translate(11.64 2.65)">
          <ellipse fill="#000" cx={1.033} cy={3.424} rx={1.033} ry={1.049} />
          <path
            stroke="#000"
            strokeWidth={0.607}
            strokeLinecap="round"
            d="M1.083 3.452V.028"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgScale;
