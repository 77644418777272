import React, { useContext, useEffect, useState } from 'react';
import AllosureChart from './AllosureChart';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
//import PDFReportDialog from '../shared/PDFReportDialog';
import NoAlloSureData from './NoAlloSureData';
import NoDsaData from './NoDsaData';
import { Box, Grid, Select, FormControl, MenuItem } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import './AlloSureKidneyWidget.css';
import DsaChart from './DsaChart';

const LabsRange = {
  Last10Days: 10,
  ThreeMonths: 90,
  SixMonths: 180,
  OneYear: 365,
};

function AlloSureKidneyWidget({ widgetId, data }) {
  const [value, setValue] = React.useState('1');
  const [range, setRange] = React.useState(LabsRange.Last10Days);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleLabsRangeChange = (event) => {
    setRange(event.target.value);
  };

  const iBoxRecords = data?.patient?.links?.ibox?.iBoxRecords;
  const dsaRecords = data?.patient?.alloSure?.dsaResults;
  const { dispatch } = useContext(SettingsContext);
  const baseUrl = `${process.env.REACT_APP_API_V1}/allosure/dxconnectreport/`;
  const [url, setUrl] = useState(baseUrl);
  //eslint-disable-next-line
  const [reportState, setReportState] = useState({
    open: false,
    url: '',
    header: 'Allosure Kidney Report',
  });
  useEffect(() => {
    if (iBoxRecords?.length > 0) {
      setUrl(
        baseUrl + `${iBoxRecords[0]?.details.unosCode}/${iBoxRecords[0]?.oid}`
      );
    }
  }, [baseUrl, iBoxRecords]);
  useEffect(() => {
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: (props) => {
          setReportState((prevState) => ({
            ...prevState,
            open: true,
            url: url,
          }));
        },
      },
    });
  }, [dispatch, widgetId, url]);

  let content;
  let creatinineContent;
  let dsaContent;

  if (dsaRecords?.length > 0) {
    dsaContent = (
      <>
        <DsaChart
          dsaData={dsaRecords?.map((d) => ({
            score: parseInt(d.value),
            date: new Date(d.date),
          }))}
        ></DsaChart>
      </>
    );
  } else {
    dsaContent = <NoDsaData />;
  }

  if (iBoxRecords?.length > 0) {
    // prettier-ignore
    var dummyAllosureData= [
      { score: 0.25, receivedDate: new Date('2022-09-05T00:00:00'), monthsPostTx: 5, tab: 'AlloSure' },
      { score: 0.3, receivedDate: new Date('2022-08-05T00:00:00'), monthsPostTx: 6, tab: 'AlloSure' },
      { score: 0.3, receivedDate: new Date('2022-07-05T00:00:00'), monthsPostTx: 7, tab: 'AlloSure' },
      { score: 0.25, receivedDate: new Date('2022-06-05T00:00:00'), monthsPostTx: 8, tab: 'AlloSure' },
      { score: 0.2, receivedDate: new Date('2022-05-05T00:00:00'), monthsPostTx: 9, tab: 'AlloSure' },
      { score: 0.15, receivedDate: new Date('2022-04-05T00:00:00'), monthsPostTx: 10, tab: 'AlloSure' },
    ]

    // prettier-ignore
    var dummyCreatinineData= [
      { score: 10.0, receivedDate: new Date('2022-09-05T00:00:00'), monthsPostTx: 5, tab: 'creatinine' },
      { score: 2.0, receivedDate: new Date('2022-08-05T00:00:00'), monthsPostTx: 6, tab: 'creatinine' },
      { score: 3.0, receivedDate: new Date('2022-07-05T00:00:00'), monthsPostTx: 7, tab: 'creatinine' },
      { score: 2.0, receivedDate: new Date('2022-06-05T00:00:00'), monthsPostTx: 8, tab: 'creatinine' },
      { score: 6.0, receivedDate: new Date('2022-05-05T00:00:00'), monthsPostTx: 9, tab: 'creatinine' },
      { score: 10.0, receivedDate: new Date('2022-04-05T00:00:00'), monthsPostTx: 10, tab: 'creatinine' },
    ]

    content = (
      <>
        <AllosureChart
          //TODO: this code functions but is commented out at this time to use the above allosure dummy data for demo purposes
          // acData={iBoxRecords?.map((p) => ({
          //   score: parseFloat(p.details.asScore),
          //   receivedDate: new Date(p.details.dateReceived),
          //   monthsPostTx: p.details.monthsPostTx,
          //   tab: 'AlloSure',
          // }))}
          acData={dummyAllosureData}
        ></AllosureChart>
        {/* {reportState?.open && (
          <PDFReportDialog
            reportState={reportState}
            toggleReportModal={setReportState}
          />
        )} */}
      </>
    );

    creatinineContent = (
      <>
        <AllosureChart
          //TODO: this code functions but is commented out at this time to use the above creatinine dummy data for demo purposes
          // acData={iBoxRecords?.map((p) => ({
          //   score: parseFloat(p.parameters.creatinine),
          //   receivedDate: new Date(p.parameters.riskEvaluationDate),
          //   monthsPostTx: p.details.monthsPostTx,
          //   tab: 'creatinine',
          // }))}
          acData={dummyCreatinineData}
        ></AllosureChart>
      </>
    );
  } else {
    content = <NoAlloSureData />;
  }

  return (
    <div>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab  tabs ">
            <Tab className="tabSize" label="AlloSure" value="1" />
            <Tab className="tabSize" label="Creatinine" value="2" />
            <Tab className="tabSize" label="DSA" value="3" />
            <Tab className="tabSize" label="Tacrolimus Level" value="4" />
            <Tab className="tabSize" label="Proteinuria" value="5" />
          </TabList>
        </Box>
        <TabPanel value="1" className="tabContent">
          {content}
          <hr className="hrSeparator" />
          <Grid container item justify="flex-end">
            <FormControl variant="standard" size="small">
              <Select
                className="rightTypograph"
                labelId="range-label"
                id="range-select-outlined"
                value={range}
                onChange={handleLabsRangeChange}
                label="Range"
              >
                <MenuItem value={LabsRange.Last10Days}>Last 10 values</MenuItem>
                <MenuItem value={LabsRange.ThreeMonths}>3 months</MenuItem>
                <MenuItem value={LabsRange.SixMonths}>6 months</MenuItem>
                <MenuItem value={LabsRange.OneYear}>1 year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </TabPanel>
        <TabPanel value="2" className="tabContent">
          {creatinineContent}
          <hr className="hrSeparator" />
          <Grid container item justify="flex-end">
            <FormControl variant="standard" size="small">
              <Select
                className="rightTypograph"
                labelId="range-label"
                id="range-select-outlined"
                value={range}
                onChange={handleLabsRangeChange}
                label="Range"
              >
                <MenuItem value={LabsRange.Last10Days}>Last 10 values</MenuItem>
                <MenuItem value={LabsRange.ThreeMonths}>3 months</MenuItem>
                <MenuItem value={LabsRange.SixMonths}>6 months</MenuItem>
                <MenuItem value={LabsRange.OneYear}>1 year</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </TabPanel>
        <TabPanel value="3" className="tabContent">
          {dsaContent}
          <hr className="hrSeparator" />
        </TabPanel>
        <TabPanel value="4">Tacrolimus Level</TabPanel>
        <TabPanel value="5">Proteinuria</TabPanel>
      </TabContext>
    </div>
  );
}

export default AlloSureKidneyWidget;
