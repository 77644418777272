import * as validation from '../../../../Utilities/Validations';
import Constants from '../Utilities/constants';

function validateMeassureValue(isAlertOn, val, type, dispatchError) {
  let message =
    (isAlertOn &&
      (val?.toString().trim() === '' ||
        !validation.validateNumber(val.toString()))) ||
    (val?.toString().trim() !== '' &&
      !validation.validateNumber(val.toString()))
      ? Constants.ERROR_NUMBER
      : '';
  dispatchError({
    type: type,
    payload: message,
  });

  return message;
}

function validateEmail(emailAddress, isEmail) {
  let message = '';
  if (isEmail) {
    if (
      emailAddress?.trim() === '' ||
      !validation.validateEmail(emailAddress)
    ) {
      message = Constants.ERROR_EMAIL_REQUIRED;
    }
  } else {
    if (
      emailAddress?.trim() !== '' &&
      !validation.validateEmail(emailAddress)
    ) {
      message = Constants.ERROR_EMAIL_INVALID;
    }
  }
  return message;
}

function validatePhone(contactNumber, isText) {
  let message = '';
  let cleanValue = contactNumber.replace(/[()-\s]/g, '');
  if (isText) {
    if (cleanValue === '' || cleanValue?.length < 10) {
      message = Constants.ERROR_PHONE_REQUIRED;
    }
  } else {
    if (cleanValue !== '' && cleanValue?.length < 10) {
      message = Constants.ERROR_PHONE_INVALID;
    }
  }
  return message;
}

function validateRowFields(val, idx, dispatchError, measuresState) {
  let message = validateMeassureValue(
    val,
    measuresState[idx].measureLow,
    `${idx}.measureLow`,
    dispatchError
  );
  message += validateMeassureValue(
    val,
    measuresState[idx].measureHigh,
    `${idx}.measureHigh`,
    dispatchError
  );
  message += validateMeassureValue(
    val,
    measuresState[idx].alertPercentage,
    `${idx}.alertPercentage`,
    dispatchError
  );
  return message;
}

export function ValidationRules(
  dispatchError,
  measuresState,
  alertState,
  errorState
) {
  const rules = {};

  rules[Constants.EMAIL_ADDRESS] = (val) => {
    let message = validateEmail(val, alertState[Constants.IS_EMAIL]);
    dispatchError({
      type: Constants.EMAIL_ADDRESS,
      payload: message,
    });
    return message;
  };

  rules[Constants.CONTACT_NUMBER] = (val) => {
    let message = validatePhone(val, alertState[Constants.IS_TEXT]);
    dispatchError({
      type: Constants.CONTACT_NUMBER,
      payload: message,
    });
    return message;
  };

  rules[Constants.LOW] = (val, idx) => {
    return validateMeassureValue(
      measuresState[idx].isAlertOn,
      val,
      `${idx}.measureLow`,
      dispatchError
    );
  };

  rules[Constants.HIGH] = (val, idx) => {
    return validateMeassureValue(
      measuresState[idx].isAlertOn,
      val,
      `${idx}.measureHigh`,
      dispatchError
    );
  };

  rules[Constants.PERCENTAGE] = (val, idx) => {
    return validateMeassureValue(
      measuresState[idx].isAlertOn,
      val,
      `${idx}.alertPercentage`,
      dispatchError
    );
  };

  rules[Constants.MEASURE_CHECK] = (val, idx, diastolicIdx) => {
    let message = validateRowFields(val, idx, dispatchError, measuresState);
    if (measuresState[idx].measureName === Constants.BLOOD_PRESSURE_SYSTOLIC) {
      message = validateRowFields(
        val,
        diastolicIdx,
        dispatchError,
        measuresState
      );
    }
    return message;
  };

  rules[Constants.EMAIL_REQUIRED] = (val) => {
    let message = '';
    if (
      Object.keys(measuresState).some((p) => !!measuresState[p].isAlertOn) &&
      !val &&
      !alertState.isText
    ) {
      message = Constants.ERROR_EMAILPHONE_REQUIRED;
    } else {
      message = validateEmail(alertState.emailAddress, val);
    }

    dispatchError({
      type: Constants.EMAIL_ADDRESS,
      payload: message,
    });
    return message;
  };

  rules[Constants.PHONE_REQUIRED] = (val) => {
    let message = '';
    if (
      Object.keys(measuresState).some((p) => !!measuresState[p].isAlertOn) &&
      !alertState.isEmail &&
      !val
    ) {
      message = Constants.ERROR_EMAILPHONE_REQUIRED;
    } else {
      message = validatePhone(alertState.contactNumber, val);
    }
    dispatchError({
      type: Constants.CONTACT_NUMBER,
      payload: message,
    });
    return message;
  };

  return {
    rules: rules,
    resetErrors: function () {
      const keys = Object.keys(errorState);
      keys.forEach((p) => {
        dispatchError({
          type: p,
          payload: null,
        });
      });
    },
  };
}
