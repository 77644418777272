function TransformInputData(parameters) {
  const inputParameters = {
    patientId: parameters.patientId,
    riskEvaluationDate: parameters.riskEvaluationDate,
    alloSureScore: parameters?.alloSureScore,
    creatinine: parameters?.creatinine,
    previousCreatinine: parameters?.previousCreatinine,
    egfr: parameters?.egfr,
    dsaMfi: parameters?.dsaMfi,
    transplantDate: parameters?.transplantDate,
    previousRejection: parameters?.previousRejection ?? false,
    rejectionDiagnosisType: parameters?.rejectionDiagnosisType ?? null,
    rejectionDiagnosisDate: parameters?.rejectionDiagnosisDate ?? null,
    hasPreviousTransplant: parameters?.hasPreviousTransplant,
    bkViremia: parameters?.bkViremia === 1 ? true : false,
    previousParameters: {
      patientId: parameters?.previousParameters?.patientId,
      riskEvaluationDate: parameters?.previousParameters?.riskEvaluationDate,
      alloSureScore: parameters.previousParameters.alloSureScore,
      creatinine: parameters?.previousParameters?.creatinine,
      previousCreatinine: parameters?.previousParameters?.previousCreatinine,
      egfr: parameters?.previousParameters?.egfr,
      dsaMfi: parameters?.previousParameters?.dsaMfi,
      transplantDate: parameters?.previousParameters?.transplantDate,
      previousRejection: parameters?.previousParameters?.previousRejection,
      rejectionDiagnosisType:
        parameters?.previousParameters?.rejectionDiagnosisType,
      rejectionDiagnosisDate:
        parameters?.previousParameters?.rejectionDiagnosisDate,
      hasPreviousTransplant:
        parameters?.previousParameters?.hasPreviousTransplant,
      bkViremia: parameters?.previousParameters?.bkViremia,
      // age: parameters?.previousParameters?.age,
    },
  };
  return inputParameters;
}

export default TransformInputData;
