import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Grid, Divider, Box, makeStyles } from '@material-ui/core';
import InfoRow from './InfoRow';
import ChartRow from './ChartRow';
import { DragIndicator } from '@material-ui/icons';
import TitleRow from './TitleRow';

const DND_ITEM_TYPE = 'draggableChartContainer';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > svg': {
      margin: theme.spacing(2),
    },
  },
  drag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}));
const DraggableChartContainer = ({
  containerData,
  index,
  moveChartGroup,
  range,
  data,
  frequency,
  startDate,
  endDate,
}) => {
  const [expanded, setExpanded] = React.useState([]);

  const toggleExpand = (chartId) => {
    let localExpanded = [...expanded];
    if (localExpanded.indexOf(chartId) > -1) {
      localExpanded.splice(localExpanded.indexOf(chartId), 1);
    } else {
      localExpanded.push(chartId);
    }
    setExpanded(localExpanded);
  };

  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    hover(item, monitor) {
      if (!dropRef.current) {
        return;
      }
      if (item.index === index) {
        return;
      }
      const hoverBoundingRect = dropRef.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (item.index < index && hoverClientY < hoverMiddleY) {
        return;
      }
      if (item.index > index && hoverClientY > hoverMiddleY) {
        return;
      }
      moveChartGroup(item.index, index);
      item.index = index;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DND_ITEM_TYPE, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  // preview(drop(dropRef));
  preview(dragRef);
  drop(dropRef);
  drag(dragRef);
  const classes = useStyles();
  return (
    <>
      <div ref={dropRef} className={classes.root}>
        <Box display="flex" flexDirection="row" style={{ opacity }}>
          <div
            ref={dragRef}
            onMouseDown={(e) => {
              e.currentTarget.style.cursor = 'pointer';
            }}
            onMouseUp={(e) => {
              e.currentTarget.style.cursor = 'auto';
            }}
            className={classes.drag}
          >
            <DragIndicator></DragIndicator>
          </div>
          <Box justifyContent="center" alignItems="stretch" pl={0.5} pr={0.5}>
            <Divider orientation="vertical" />
          </Box>
          <Box display="flex" width="95%" justifyContent="start">
            <Grid container direction="column">
              {index === 0 && (
                <InfoRow
                  range={range}
                  frequency={frequency}
                  startDate={startDate}
                  endDate={endDate}
                ></InfoRow>
              )}
              {containerData.map(
                (chartConfig, idx) =>
                  data[chartConfig.id] &&
                  (!chartConfig.parentId ||
                    expanded.indexOf(chartConfig.parentId) > -1) && (
                    <Box direction="column">
                      {chartConfig.title && (
                        <TitleRow title={chartConfig.title}></TitleRow>
                      )}
                      <ChartRow
                        key={idx}
                        chartConfigId={chartConfig.id}
                        chartConfig={chartConfig}
                        data={data[chartConfig.id]}
                        range={range}
                        frequency={frequency}
                        showXLabels={index === 0 && idx === 0}
                        toggleExpand={toggleExpand}
                        isExpanded={expanded.indexOf(chartConfig.id) > -1}
                      ></ChartRow>
                    </Box>
                  )
              )}
            </Grid>
          </Box>
        </Box>
        <Divider />
      </div>
    </>
  );
};

export default DraggableChartContainer;
