import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  Toolbar,
  Paper,
  ClickAwayListener,
  Popper,
  makeStyles,
  Checkbox,
  Box,
} from '@material-ui/core';
import { Launch, ReportProblem, Schedule } from '@material-ui/icons';

import FilterInput from '../../Filtering/FilterInput';
import FilterPanel from '../../Filtering/FilterPanel';

import { SearchBox } from '../../DataEntry/SearchBox';

import Observations from '../../Display/Fhir/Observations';
import MedicationRequest from '../../Display/Fhir/MedicationRequest';
import Encounter from '../../Display/Fhir/Encounter';
import Procedure from '../../Display/Fhir/Procedure';
import Practitioner from '../../Display/Fhir/Practitioner';
import NoteCountBadge from '../../Display/NoteCountBadge';

import PinNewList from './PinNewList';

import * as util from '../../../utility';
import { stableSort, getComparator } from '../../../Utilities/tableSorting';
import { filter } from '../../../Utilities/tableFiltering';
import { useTranslation } from 'react-i18next';
import { ViewMyListsModal } from './ViewMyListsModal';

// eslint-disable-next-line no-unused-vars
function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '95vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  container: {
    maxHeight: '80vh',
  },
  popper: {
    zIndex: 999,
  },
  filterMenu: {
    minWidth: '300px',
    background: 'white',
    padding: '10px',
  },
  selectMenu: {
    zIndex: 9999,
  },
  formControl: {
    width: '100%',
  },
  filterSelects: {
    display: 'block',
  },
  tableRow: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  myListsBtn: {
    color: '#40b6fe',
    backgroundColor: 'white',
  },
  tableContainer: {
    padding: '20px',
    height: '600px',
    borderRadius: '1em',
  },
  tableTitle: {
    fontWeight: 600,
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  tableHeaderText: {
    fontWeight: 600,
    fontSize: '1em',
  },
}));

function DisplayCellContents({ data, path, id, onNoteClick }) {
  let result;
  switch (typeof data) {
    case 'object':
      if (!data) {
        result = null;
      } else {
        switch (data.__typename) {
          case 'ObservationType':
            result = <Observations data={data} />;
            break;
          case 'MedicationRequestType':
            result = <MedicationRequest data={data} />;
            break;
          case 'EncounterType':
            result = <Encounter data={data} />;
            break;
          case 'ProcedureType':
            result = <Procedure data={data} />;
            break;
          case 'PractitionerType':
            result = <Practitioner data={data} />;
            break;
          default:
            result = 'No Data';
            break;
        }
      }
      break;
    default:
      switch (path) {
        case 'reviewed':
          result = <Checkbox />;
          break;
        case 'lab':
          result = <Launch color="error" />;
          break;
        case 'risk':
          result = <ReportProblem color="error" />;
          break;
        case 'overdue':
          result = <Schedule color="error" />;
          break;
        case 'notes':
          result = (
            <NoteCountBadge count={data} id={id} onClick={onNoteClick} />
          );
          break;
        default:
          result = data.toString();
          break;
      }
  }
  return result;
}

export default function PatientListTable({
  title,
  list,
  fields,
  hasDefaultSort,
  defaultSortField,
  defaultSortDirection,
  listConfig,
  upsertListConfig,
  saving,
  prevState,
  onNoteClick,
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = useState(
    prevState ? prevState.order : hasDefaultSort ? defaultSortDirection : 'asc'
  );
  const [orderBy, setOrderBy] = useState(
    prevState
      ? prevState.orderBy
      : hasDefaultSort
      ? defaultSortField
      : fields[0].path
  );
  const [showFilterPopout, setShowFilterPopout] = useState(false);
  const [showPinPopout, setShowPinPopout] = useState(false);
  const [popperAnchorElement, setPopperAnchorElement] = useState(null);
  const [popperActive, setPopperActive] = useState(false);
  const [filters, setFilters] = useState(prevState ? prevState.filters : {});

  const handleSortLabelClick = (event) => {
    const path = event.currentTarget.dataset.fieldPath;
    const isAsc = orderBy === path && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(path);
  };

  const handleListFilterPopperClickAway = (event) => {
    if (!popperActive) {
      setShowFilterPopout(false);
    }
    setShowPinPopout(false);
  };

  const handleFilterListClick = (event) => {
    setPopperAnchorElement(event.currentTarget);
    setShowFilterPopout((prev) => !prev);
    setShowPinPopout(false);
  };

  // eslint-disable-next-line no-unused-vars
  const handlePinListClick = (event) => {
    setPopperAnchorElement(event.currentTarget);
    setShowPinPopout((prev) => !prev);
    setShowFilterPopout(false);
  };

  const handleFilterInputDeactivate = () => {
    setPopperActive(false);
  };

  const handleFilterInputActivate = () => {
    setPopperActive(true);
  };

  const handleFilterAdd = (filter) => {
    setFilters({
      ...filters,
      ...filter,
    });
  };

  const handleRowClick = (id) => {
    var dashboardName =
      listConfig.dashboardName === '' ? 'patient' : listConfig.dashboardName;
    history.push({
      pathname: `/${dashboardName}/${id}`,
      state: {
        order: order,
        orderBy: orderBy,
        filters: filters,
        prevPath: history.location.pathname,
      },
    });
  };

  const handleFilterDelete = (property) => {
    const updatedFilters = { ...filters };
    delete updatedFilters[property];
    setFilters({
      ...updatedFilters,
    });
  };

  const headerCells = fields.map((f) => {
    return (
      <TableCell key={f.path}>
        <TableSortLabel
          data-field-path={f.path}
          active={f.path === orderBy}
          direction={f.path === orderBy ? order : 'desc'}
          onClick={handleSortLabelClick}
        >
          <Typography className={classes.tableHeaderText} variant="h6">
            {f.title}
          </Typography>
        </TableSortLabel>
      </TableCell>
    );
  });

  const rows = list.map((l, i) => {
    const row = {};
    for (let j = 0; j < fields.length; j++) {
      const field = fields[j];
      let value = util.path(field.path.split('.'), l);
      if (field.type === 'DateTime' || field.type === 'DateTimeOffset') {
        value = t('formattedDate.LLL', { date: new Date(value) });
      } else if (field.type === 'Int') {
        if (value % 1 === 0) {
          value = t('numberFormat', { value: parseInt(value) });
        } else {
          value = t('numberFormat', { value: parseFloat(value) });
        }
      } else if (field.type === 'Float') {
        value = t('numberFormat', { value: parseFloat(value) });
      } else if (field.type === 'Decimal') {
        value = t('numberFormat', { value: parseFloat(value) });
      }
      row[field.path] = value;
    }
    row.id = l.id; // always included as part of query.
    return row;
  });

  let filteredAndSortedObj = stableSort(
    filter(rows, filters),
    getComparator(order, orderBy)
  );
  const [filteredAndSorted, updateDate] = useState(filteredAndSortedObj);

  //performs search on name only.
  let searchHandler = (event) => {
    var lowerCase = event.target.value.toLowerCase();
    if (lowerCase === '') {
      updateDate(list);
      return;
    }
    var searchResult = list.filter((x) =>
      x.patientName.toLowerCase().includes(lowerCase)
    );
    updateDate(searchResult);
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Toolbar className={classes.toolbar}>
        <FilterPanel filter={filters} onFilterDelete={handleFilterDelete} />

        <Typography className={classes.tableTitle} variant="h5">
          {title}
        </Typography>
        <Box display="flex">
          <ViewMyListsModal />
          <ClickAwayListener onClickAway={handleListFilterPopperClickAway}>
            <div>
              <SearchBox
                label="Search"
                rAdornmentClick={handleFilterListClick}
                searchTextChangeHandler={searchHandler}
              />
              <Popper
                open={showFilterPopout || showPinPopout}
                anchorEl={popperAnchorElement}
                className={classes.popper}
              >
                {showFilterPopout && (
                  <FilterInput
                    onFilterAdd={handleFilterAdd}
                    fields={fields}
                    onFilterInputActivate={handleFilterInputActivate}
                    onFilterInputDeactivate={handleFilterInputDeactivate}
                  />
                )}
                {showPinPopout && (
                  <PinNewList
                    config={listConfig}
                    patients={filteredAndSorted}
                    filters={filters}
                    order={order}
                    orderBy={orderBy}
                    upsertListConfig={upsertListConfig}
                    saving={saving}
                  />
                )}
              </Popper>
            </div>
          </ClickAwayListener>
        </Box>
      </Toolbar>
      <Box width="95vw" mt={3}>
        <TableContainer
          component={Paper}
          elevation={4}
          className={classes.tableContainer}
        >
          <Table>
            <TableHead>
              <TableRow>{headerCells}</TableRow>
            </TableHead>
            <TableBody>
              {filteredAndSorted.map((row, i) => {
                return (
                  <TableRow
                    key={
                      row.id + Math.floor(Math.random() * 0x10000).toString(16)
                    }
                    hover
                    className={classes.tableRow}
                    onClick={() => handleRowClick(row.id)}
                  >
                    {fields.map((field) => {
                      return (
                        <TableCell key={field.path}>
                          <DisplayCellContents
                            id={row.id}
                            data={row[field.path]}
                            path={field.path}
                            onNoteClick={onNoteClick}
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
