import {
  Grid,
  Checkbox,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useContext } from 'react';
import { convertISODateToLocal } from 'Utilities/dateHelpers';
import { IBoxContext } from '../state/IBoxContextProvider';
import Constants from '../Utilities/constants';

function DSA({ validators }) {
  const { dispatch, state, errorState, patientState } = useContext(IBoxContext);

  const onDSAStatusChange = (e) => {
    dispatch({
      type: e.target.value === 'mfi' ? Constants.DSA_BINARY : Constants.DSA_MFI,
      payload: null,
    });

    dispatch({
      type: Constants.DSA_STATUS,
      payload: e.target.value,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item container direction="row" justify="space-between">
        <Typography component="div">
          <Box fontWeight="fontWeightBold">DSA</Box>
        </Typography>
        <Checkbox
          checked={!(state?.dsaStatus === 'not_available')}
          inputProps={{ 'aria-label': 'Checkbox A' }}
          onChange={(e) => {
            dispatch({
              type: Constants.DSA_STATUS,
              payload: !e.target.checked ? 'not_available' : 'mfi',
            });
          }}
        />
      </Grid>

      {state?.dsaStatus !== 'not_available' && (
        <>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <DatePicker
                name={Constants.DATE_OF_DSA_TAKEN}
                format="MM/DD/YYYY"
                margin="normal"
                required
                label="Date DSA Taken"
                value={convertISODateToLocal(state?.dateOfDsaTaken)}
                minDate={patientState[Constants.TRANSPLANT_DATE] || undefined}
                error={!!errorState[Constants.DATE_OF_DSA_TAKEN]}
                onChange={(e) => {
                  dispatch({
                    type: Constants.DATE_OF_DSA_TAKEN,
                    payload: e?.$d,
                  });
                  validators.rules[Constants.DATE_OF_DSA_TAKEN](e?.$d);
                }}
                helperText={
                  !!errorState[Constants.DATE_OF_DSA_TAKEN] &&
                  errorState[Constants.DATE_OF_DSA_TAKEN]
                }
                invalidLabel={''}
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              <FormControlLabel
                value="mfi"
                name={Constants.DSA_STATUS}
                checked={state?.dsaStatus === 'mfi'}
                onChange={onDSAStatusChange}
                control={<Radio />}
                label="MFI"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="binary"
                name={Constants.DSA_STATUS}
                checked={state?.dsaStatus === 'binary'}
                onChange={onDSAStatusChange}
                control={<Radio />}
                label="Binary"
              />
            </Grid>
          </Grid>
          <Grid item container direction="row">
            <Grid item xs={6}>
              {state?.dsaStatus === 'mfi' && (
                <TextField
                  name={Constants.DSA_MFI}
                  InputLabelProps={{ shrink: true }}
                  required
                  label="anti-HLA DSA MFA (0-20000)"
                  style={{ width: '70%' }}
                  type="text"
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  onChange={(e) => {
                    dispatch({
                      type: Constants.DSA_MFI,
                      payload: e.target.value,
                    });
                    validators.rules[Constants.DSA_MFI](e.target.value);
                  }}
                  value={state?.dsaMfi || ''}
                  error={!!errorState[Constants.DSA_MFI]}
                  helperText={
                    !!errorState[Constants.DSA_MFI] &&
                    errorState[Constants.DSA_MFI]
                  }
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {state?.dsaStatus === 'binary' && (
                <FormControl id="binary" component="fieldset">
                  <RadioGroup
                    row
                    name={Constants.DSA_BINARY}
                    value={state?.dsaBinary || ''}
                    onChange={(e) => {
                      dispatch({
                        type: Constants.DSA_BINARY,
                        payload: e.target.value,
                      });
                      validators.rules[Constants.DSA_BINARY](e.target.value);
                    }}
                  >
                    <FormControlLabel
                      value="presence"
                      control={<Radio />}
                      label="Presence"
                    />
                    <FormControlLabel
                      value="absence"
                      control={<Radio />}
                      label="Absence"
                    />
                  </RadioGroup>
                  <FormHelperText error={!!errorState[Constants.DSA_BINARY]}>
                    Must select at least one
                  </FormHelperText>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default DSA;
