import React from 'react';
import {
  TranslatedTextField as TextField,
  TranslatedDatePicker as DatePicker,
  TranslatedDateTimePicker as DateTimePicker,
} from '../Localization';

import NumberFormatField from '../DataEntry/NumberFormatField';

export default function InputSwitchHOC(typeName) {
  let input;
  let unwrap;

  switch (typeName) {
    case 'DateTime':
      input = DatePickerHOC('L', DatePicker);
      unwrap = function (datejs) {
        return datejs.toDate();
      };
      break;
    case 'DateTimeOffset':
      input = DatePickerHOC('L LT', DateTimePicker);
      unwrap = function (datejs) {
        return datejs.toDate();
      };
      break;
    case 'Int':
    case 'Float':
    case 'Decimal':
      input = NumberFormatField;
      unwrap = function (event) {
        return event.target.value;
      };
      break;
    default:
      input = TextField;
      unwrap = function (event) {
        return event.target.value;
      };
      break;
  }

  return [unwrap, input];
}

function DatePickerHOC(format, PickerComponent) {
  return function PickerHoc(props) {
    return <PickerComponent format={format} {...props} />;
  };
}
