/* istanbul ignore file */

import { useContext } from 'react';
import { AuthenticationContext } from './Authentication';

export default function useAuthentication() {
  const {
    authState,
    authService,
    setTokenResponse,
    removeTokenResponse,
  } = useContext(AuthenticationContext);

  return {
    authState,
    authService: {
      login: authService.login,
      async logout() {
        const refreshToken = authState.tokenResponse.refresh_token;
        removeTokenResponse();
        await authService.logout(refreshToken);
        // Send the browser back to the app-root and reload the page.
        window.location = '/';
      },
      async refresh() {
        if (
          !(
            authState.tokenResponse &&
            authState.tokenResponse.access_token &&
            authState.tokenResponse.refresh_token
          )
        ) {
          return null;
        }
        const accessToken = authState.tokenResponse.access_token;
        const refreshToken = authState.tokenResponse.refresh_token;
        const refreshedTokenResponse = await authService.refreshToken(
          accessToken,
          refreshToken
        );
        setTokenResponse(refreshedTokenResponse);
        return refreshedTokenResponse;
      },
      async exchange(subjectToken, clientIssuer) {
        const tokenResponse = await authService.exchangeToken(
          subjectToken,
          clientIssuer
        );
        setTokenResponse(tokenResponse);
        return tokenResponse;
      },
      getUser() {
        if (
          !(
            authState &&
            authState.tokenResponse &&
            authState.tokenResponse.id_token
          )
        ) {
          return null;
        }

        const idToken = authState.tokenResponse.id_token;
        const tokenParts = idToken.split('.');
        const payloadJson = atob(tokenParts[1]);
        const payload = JSON.parse(payloadJson);
        return payload;
      },
    },
  };
}
