import React from 'react';
import { Grid, Divider, Box, makeStyles } from '@material-ui/core';
import InfoRow from './InfoRow';
import ChartRow from './ChartRow';
import { ChartGroups } from './ChartGroups';

const useStyles = makeStyles((theme) => ({}));
const ChartContainer = ({ range, data, frequency }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Box display="flex" flexDirection="row">
          <Box display="flex" flex="1" justifyContent="start">
            <Grid container direction="column">
              <InfoRow range={range} frequency={frequency}></InfoRow>
              {ChartGroups.map(
                (chartConfig, idx) =>
                  data[chartConfig.id] && (
                    <ChartRow
                      key={idx}
                      chartConfig={chartConfig}
                      data={data[chartConfig.id]}
                      range={range}
                      frequency={frequency}
                      showXLabels={idx === 0}
                    ></ChartRow>
                  )
              )}
            </Grid>
          </Box>
        </Box>
        <Divider />
      </div>
    </>
  );
};

export default ChartContainer;
