import React from 'react';
import WithIBoxQueryResults from '../../../GraphQL/WithIBoxQueryResults';
import { useParams } from 'react-router-dom';
import KidneyCareReport from './KidneyCareReport';

export default function KidneyCareList({ filterFn }) {
  const { id } = useParams();
  return (
    <WithIBoxQueryResults
      id={id}
      includeResult={false}
      includeParameters={true}
      includeDetails={true}
    >
      {(iboxData) => {
        return (
          <KidneyCareReport filterFn={filterFn} data={iboxData.iBoxRecords} />
        );
      }}
    </WithIBoxQueryResults>
  );
}
