import React from 'react';
import { Box, Typography } from '@material-ui/core';

export default function DialogTitleContent({ marginLeft }) {
  return (
    <>
      <Box component="div" style={{ float: 'left' }}>
        <img
          src={`/images/AlloCare_Logo_RGB.png`}
          alt="AlloCare logo"
          width="160px"
        ></img>
      </Box>
      <Box component="div" style={{ marginLeft: marginLeft }}>
        <Typography
          style={{ fontWeight: '600', marginTop: '15px' }}
          variant="subtitle1"
        >
          Patient Reported Measures Personalization
        </Typography>
      </Box>
    </>
  );
}
