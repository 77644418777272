import React, { useRef, useState, useEffect } from 'react';
import { Badge, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Chart } from 'chart.js';
import { MethodistDashboardColors } from '../../../Utilities/Infrastucture/methodistDashboardColors';
import { alloSureHistrogramChartConfigurationMode } from '../../../Utilities/Infrastucture/alloSureHistrogramChartConfigurationMode';
import annotationPlugin from 'chartjs-plugin-annotation';
import './AlloSureHistrogramChart.css';

Chart.plugins.register(annotationPlugin);
Chart.plugins.register(annotationPlugin);
export default function AlloSureHistrogramChart({
  width,
  height,
  configurationMode,
  alloSureData,
}) {
  const useStyles = makeStyles(() => ({
    chartContainer: {
      width: width,
      height: height,
    },
    cardcontent: {
      padding: '8px !important',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
  }));

  const configurationBuilder = (mode) => {
    if (mode === alloSureHistrogramChartConfigurationMode.WIDGET) {
      return {
        scaleLabelFontSize: 12,
        hideAnnotationLabel: false,
        postFixAnnotationLabel: '',
        annotationLineColor: 'transparent', // Used in annotationFactory method
        hideXLabel: false,
        fontSize: 10, // Used in labelFactory method
        xAdjust: -10, // Used in labelFactory method
      };
    }

    return {
      scaleLabelFontSize: 20,
      hideAnnotationLabel: false,
      postFixAnnotationLabel: '        ',
      annotationLineColor: 'transparent',
      hideXLabel: false,
      fontSize: 18,
      xAdjust: 350,
    };
  };

  const configMode = configurationBuilder(configurationMode);

  const labelsFactory = (mode, labelSeed) => {
    if (mode.hideXLabel) {
      let labels = [];
      labelSeed.forEach(() => {
        labels.push('');
      });
      return labels;
    }
    if (configurationMode === alloSureHistrogramChartConfigurationMode.WIDGET) {
      labelSeed.push('');
    } else {
      labelSeed.push('');
      labelSeed.push('');
    }
    return labelSeed;
  };

  const validYAxisTicks = [0, 0.21, 0.5, 1, 16];

  var rawLabels = labelsFactory(configMode, []); //alloview_parameters.evaluation_date
  const checkAlloSureData = (data) => {
    if (data === undefined) {
      isAlloSureScore = false;
      return false;
    }
    if (data.allAlloSureResult === undefined) {
      isAlloSureScore = false;
      return false;
    }
    if (data?.patient?.links?.alloView?.alloViewRecords?.length > 0) {
      isAlloSureScore = true;
      return true;
    }
    return false;
  };

  var isAlloSureScore = checkAlloSureData(alloSureData);
  var sortedArray = [];
  const getRawData = () => {
    if (!isAlloSureScore) {
      return [];
    }

    var sortedArray = [...alloSureData.patient.links.alloView.alloViewRecords];
    sortedArray.reverse();
    var alloSureScore = [];
    var alloSureScoreDate = [];
    if (
      configurationMode === alloSureHistrogramChartConfigurationMode.WIDGET &&
      sortedArray.length > 5
    ) {
      for (
        let iRows = sortedArray.length - 5;
        iRows < sortedArray.length;
        iRows++
      ) {
        if (iRows >= sortedArray.length) {
          break;
        }
        alloSureScore.push(sortedArray[iRows].parameters.alloSureScore);
        const d = new Date(sortedArray[iRows].parameters.riskEvaluationDate);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const riskDate = year + '-' + month + '-' + day;
        alloSureScoreDate.push(riskDate);
      }
    } else {
      sortedArray.forEach((item) => {
        alloSureScore.push(item.parameters.alloSureScore);
        const d = new Date(item.parameters.riskEvaluationDate);
        const year = d.getFullYear();
        const month = (d.getMonth() + 1).toString().padStart(2, '0');
        const day = d.getDate().toString().padStart(2, '0');
        const riskDate = year + '-' + month + '-' + day;
        alloSureScoreDate.push(riskDate);
      });
    }
    rawLabels = labelsFactory(configMode, alloSureScoreDate);
    currentRCVVal =
      sortedArray[sortedArray?.length - 1].parameters?.alloSureScore;
    currentRCVBoxBackground = getRCVCurrentVal(currentRCVVal);
    return alloSureScore;
  };
  var currentRCVBoxBackground = '';
  var currentRCVVal = '';
  const getRCVCurrentVal = (rcvVal) => {
    var currentRCVBoxBackgroundClass = '';
    if (rcvVal >= 0 && rcvVal < 0.5) {
      currentRCVBoxBackgroundClass = 'currentRCVBoxBackgroundGreen';
    } else if (rcvVal >= 0.5 && rcvVal < 1) {
      currentRCVBoxBackgroundClass = 'currentRCVBoxBackgroundYellow';
    } else if (rcvVal >= 1) {
      currentRCVBoxBackgroundClass = 'currentRCVBoxBackgroundRed';
    }
    return currentRCVBoxBackgroundClass;
  };
  var rawData = getRawData();
  // Above 0
  var rawAllosureRCVPercentChangeRectangle = (val) => {
    var boxes = [];
    for (let index = 0; index < rawData.length; index++) {
      boxes.push(val);
    }
    if (rawData.length > 0 && rawData.length <= 3) {
      boxes.push(val);
      boxes.push(val);
    }
    if (configurationMode === alloSureHistrogramChartConfigurationMode.WIDGET) {
      boxes.push(val);
    } else {
      boxes.push(val);
      boxes.push(val);
    }
    return boxes;
  };

  const [labels] = useState(rawLabels);
  const [data] = useState(rawData);
  const [allosureRCVPercentChangeRectangle] = useState(
    rawAllosureRCVPercentChangeRectangle(3)
  );
  const [allosureRCVPercentFirstStopChangeRectangle] = useState(
    rawAllosureRCVPercentChangeRectangle(0.5)
  );

  const [allosureRCVPercentSecondStopChangeRectangle] = useState(
    rawAllosureRCVPercentChangeRectangle(1)
  );
  const [chartOptions, setCharOptions] = useState(null);
  const canvasRef = useRef(null);
  const styles = useStyles();
  useEffect(() => {
    const chartRef = canvasRef.current;
    let allosureChart = null;
    if (chartRef && chartOptions) {
      const myChartRef = chartRef.getContext('2d');
      allosureChart = new Chart(myChartRef, chartOptions);
    }
    return () => {
      allosureChart?.destroy();
    };
  }, [chartOptions]);

  useEffect(() => {
    setCharOptions({
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          // Allosure result dataset
          {
            label: '',
            strokeColor: 'black',
            backgroundColor: 'white',
            borderColor: 'black',
            yAxisID: 'A',
            data: data,
            tension: 0,
            pointRadius: 4,
            pointHoverRadius: 4,
            fill: false,
          },
          // Trick to draw a rectangle gradient
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentFirstStopChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: MethodistDashboardColors.GREEN_COLOR,
          },
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentFirstStopChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: '#ffffff',
          },

          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentSecondStopChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: MethodistDashboardColors.YELLOW_COLOR,
          },
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentSecondStopChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: '#ffffff',
          },
          {
            label: '',
            pointRadius: 0,
            strokeColor: 'transparent',
            borderWidth: 0,
            color: 'black',
            yAxisID: 'B',
            data: allosureRCVPercentChangeRectangle,
            tension: 0,
            fill: true,
            pointHoverRadius: 0,
            backgroundColor: MethodistDashboardColors.RED_COLOR,
          },
        ],
      },
      options: {
        legend: {
          align: 'middle',
          labels: {
            boxWidth: 0,
          },
        },
        title: {
          display: true,
          position: 'left',
        },
        scales: {
          yAxes: [
            {
              id: 'A',
              display: true,
              position: 'left',
              gridLines: {
                display: false,
                drawTicks: true,
                padding: 20,
              },
              afterFit: function (scale) {
                scale.height = 40;
              },
              scaleLabel: {
                fontSize: configMode.scaleLabelFontSize,
                display: true,
                labelString: 'dd-cfDNA',
                padding: 20,
              },
              ticks: {
                min: 0,
                max: 3,
                fontSize: configMode.fontSize,
                stepSize: 0.01,
                autoSkip: false,
                display: true,
                // Include a dollar sign in the ticks
                callback: function (val, index) {
                  if (validYAxisTicks.includes(val)) {
                    return val + '%';
                  }
                  if (val === 3) {
                    return '16%';
                  }
                },
              },
            },
            {
              id: 'B',
              display: true,
              position: 'right',
              gridLines: {
                display: false,
              },
              ticks: {
                min: 0,
                max: 3,
                stepSize: 1,
                autoSkip: true,
                callback: function (val, index) {
                  return '';
                },
              },
            },
            {
              id: 'C',
              display: true,
              position: 'right',
              gridLines: {
                display: false,
              },
              ticks: {
                callback: function () {
                  return '';
                },
                textStrokeWidth: 1,
              },
            },
          ],
          xAxes: [
            {
              display: true,
              position: 'left',
              scaleLabel: {
                fontSize: configMode.scaleLabelFontSize,
                display: true,
                labelString: 'CONSECUTIVE TESTS',
              },
              ticks: {
                autoSkip: false,
                maxRotation:
                  configurationMode ===
                  alloSureHistrogramChartConfigurationMode.WIDGET
                    ? 0
                    : 90,
                minRotation:
                  configurationMode ===
                  alloSureHistrogramChartConfigurationMode.WIDGET
                    ? 0
                    : 90,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
        font: {
          family: 'Trade Gothic LT Std,sans-serif,Roboto,Helvetica,Arial;',
          size: 25,
        },
        tooltips: {
          // Show tooltip for data set == 1
          filter: function (tooltipItem) {
            return tooltipItem.datasetIndex === 0;
          },
          callbacks: {
            label: function (tooltipItem) {
              if (tooltipItem.datasetIndex === 0) {
                return [
                  `AlloSure: ${parseFloat(
                    sortedArray[tooltipItem.index].parameters.alloSureScore
                  ).toFixed(2)}%`,
                ];
              }
              return [
                `AlloSure Score: ${
                  sortedArray[tooltipItem.index].parameters.alloSureScore
                }`,
              ];
            },
          },
        },

        annotation: {
          drawTime: 'afterDatasetsDraw', // (default)
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.chartContainer + ' divChartContainer'}>
      <div
        style={{
          display: isAlloSureScore === true ? 'block' : 'none',
          cursor: 'pointer',
        }}
      >
        <div
          className={`three ${styles.titleContainer} ${
            configurationMode === 'widget'
              ? 'widget-container'
              : 'page-container'
          }`}
        >
          <Card sx={{ maxWidth: 50, width: 50 }}>
            <CardContent className={styles.cardcontent}>
              <Typography gutterBottom className="cardTopography">
                CURRENT RESULT
              </Typography>
              <Divider />
              <Typography gutterBottom className="cardTopography">
                dd-cfDNA
              </Typography>
              <Divider />
              <Badge
                color="#000000"
                variant="dot"
                classes={{
                  badge: `${currentRCVBoxBackground} ${'currentRCVBoxBackground'}`,
                }}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
              >
                <Typography component="div">
                  {parseFloat(currentRCVVal).toFixed(2)}%
                </Typography>
              </Badge>
            </CardContent>
          </Card>
        </div>
        <canvas
          className="canvas-chart dashboardNew"
          ref={canvasRef}
          height={height}
          aria-label="AllowSure Individual Chart"
          role="img"
        ></canvas>
      </div>
      <h2
        className="notFoundH1"
        style={{
          display: isAlloSureScore === false ? 'block' : 'none',
        }}
      >
        No data found
      </h2>
    </div>
  );
}
