import React, { useState, useCallback } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { ChartGroups } from './ChartGroups';
import DraggableChartContainer from './DraggableChartContainer';
//import { data } from './data';
import WithAlloCareData from '../../GraphQL/WithAlloCareData';
const isTouchDevice = () => 'ontouchstart' in window;

const backendForDND = isTouchDevice() ? TouchBackend : HTML5Backend;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
  },
  chartsContainer: {
    display: 'flex',
    margin: '0px 10px 10px 10px',
    border: '1px solid #d8d8',
    flexDirection: 'column',
  },
  container: {
    width: 'calc(100vw - 50px)',
    backgroundColor: 'white',
    paddingBottom: '20px',
    paddingTop: '20px',
    justifyContent: 'flex-start',
  },
}));

function AlloCare({ patientId, range, frequency }) {
  const classes = useStyles();

  const [chartGroups, setChartGroups] = useState(ChartGroups);

  const moveChartGroup = useCallback(
    (dragIndex, hoverIndex) => {
      const dragChartGroup = chartGroups[dragIndex];
      chartGroups.splice(dragIndex, 1);
      chartGroups.splice(hoverIndex, 0, dragChartGroup);
      setChartGroups([...chartGroups]);
    },
    [chartGroups]
  );

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box className={classes.container}>
        <Grid className={classes.root} container direction="column">
          <DndProvider backend={backendForDND}>
            <Grid className={classes.chartsContainer}>
              <Box>
                <WithAlloCareData
                  id={patientId}
                  days={range}
                  averageSliceSize={frequency}
                >
                  {(data) => {
                    return (
                      <>
                        {chartGroups.map((a, i) => (
                          <DraggableChartContainer
                            key={i}
                            containerData={a}
                            data={data.alloCare}
                            //data={data}
                            index={i}
                            moveChartGroup={moveChartGroup}
                            range={range}
                            frequency={frequency}
                          />
                        ))}
                      </>
                    );
                  }}
                </WithAlloCareData>
              </Box>
            </Grid>
          </DndProvider>
        </Grid>
      </Box>
    </Box>
  );
}

export default AlloCare;
