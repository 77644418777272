import React from 'react';
import {
  Typography,
  Grid,
  Box,
  TextField,
  makeStyles,
} from '@material-ui/core';
import Constants from '../PersonalizationBaseline/Utilities/constants';
import MeasureSelect from '../PersonalizationBaseline/MeasureSelect';

const useStyles = makeStyles((theme) => ({
  centerText: {
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  input: {
    height: 35,
  },
  boxStyle: {
    marginBottom: '12px',
    width: '100%',
    paddingBottom: '5px',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  boxStyleSystolic: {
    width: '100%',
  },
  boxStyleDiastolic: {
    marginBottom: '12px',
    width: '100%',
    paddingBottom: '12px',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  boxStyleMood: {
    marginBottom: '15px',
    width: '100%',
  },
}));

function GetBoxStyle(measureConfig, classes) {
  switch (measureConfig?.id) {
    case Constants.BLOOD_PRESSURE_SYSTOLIC:
      return classes.boxStyleSystolic;
    case Constants.BLOOD_PRESSURE_DIASTOLIC:
      return classes.boxStyleDiastolic;
    case Constants.MOOD:
      return classes.boxStyleMood;
    default:
      return classes.boxStyle;
  }
}

export default function AlloCareMeasureRow({
  idx,
  diastolicIdx,
  measureConfig,
  validation,
  measuresState,
  dispatchMeasure,
  errorState,
}) {
  const classes = useStyles();

  return (
    <Box className={GetBoxStyle(measureConfig, classes)}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={2}>
          <Box display="flex" flexDirection="row">
            {measureConfig.id !== Constants.BLOOD_PRESSURE_DIASTOLIC ? (
              <MeasureSelect
                idx={idx}
                diastolicIdx={diastolicIdx}
                measureConfig={measureConfig}
                validation={validation}
                dispatchMeasure={dispatchMeasure}
                measuresState={measuresState}
              />
            ) : (
              <Typography
                variant="body1"
                style={{ marginLeft: '67px', marginTop: '9px' }}
              >
                {measureConfig.label}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="row" justifyContent="center">
            <TextField
              name={measureConfig.inputLowId}
              label="Low"
              variant="outlined"
              autoComplete="off"
              size="small"
              error={!!errorState[`${idx}.measureLow`]}
              helperText={
                !!errorState[`${idx}.measureLow`] &&
                errorState[`${idx}.measureLow`]
              }
              onChange={(e) => {
                dispatchMeasure({
                  type: Constants.MEASURE,
                  payload: {
                    value: e.target.value,
                    idx,
                    type: 'measureLow',
                  },
                });
                validation.rules[Constants.LOW](e.target.value, idx);
              }}
              value={measuresState[idx]?.measureLow}
              style={{ paddingRight: '20px' }}
              InputProps={{
                className: classes.input,
              }}
            />
            <TextField
              name={measureConfig.inputHighId}
              label="High"
              variant="outlined"
              autoComplete="off"
              onChange={(e) => {
                dispatchMeasure({
                  type: Constants.MEASURE,
                  payload: {
                    value: e.target.value,
                    idx,
                    type: 'measureHigh',
                  },
                });
                validation.rules[Constants.HIGH](e.target.value, idx);
              }}
              value={measuresState[idx]?.measureHigh}
              size="small"
              error={!!errorState[`${idx}.measureHigh`]}
              helperText={
                !!errorState[`${idx}.measureHigh`] &&
                errorState[`${idx}.measureHigh`]
              }
              InputProps={{
                className: classes.input,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <TextField
            name={measureConfig.inputPercentageId}
            InputLabelProps={{ shrink: true }}
            label=""
            variant="outlined"
            autoComplete="off"
            size="small"
            error={!!errorState[`${idx}.alertPercentage`]}
            helperText={
              !!errorState[`${idx}.alertPercentage`] &&
              errorState[`${idx}.alertPercentage`]
            }
            onChange={(e) => {
              dispatchMeasure({
                type: Constants.MEASURE,
                payload: {
                  value: e.target.value,
                  idx,
                  type: 'alertPercentage',
                },
              });
              validation.rules[Constants.PERCENTAGE](e.target.value, idx);
            }}
            value={measuresState[idx]?.alertPercentage}
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" style={{ textAlign: 'center' }}>
            {measuresState[idx]?.baseline}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
