import React from 'react';
import { TranslatedTextField as TextField } from '../Localization';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

export default function NumberFormatField({ inputProps, ...rest }) {
  return (
    <TextField
      {...rest}
      InputProps={{
        inputComponent: CustomNumberFormat,
      }}
    />
  );
}

function CustomNumberFormat({ inputRef, onChange, ...rest }) {
  const {
    i18n: { language },
  } = useTranslation();

  const handleValueChange = (values) => {
    onChange({
      target: {
        value: values.value,
      },
    });
  };

  return (
    <NumberFormat
      {...rest}
      onValueChange={handleValueChange}
      getInputRef={inputRef}
      isNumericString={true}
      decimalSeparator={Intl.NumberFormat(language).format(1.1).substring(1, 2)}
      thousandSeparator={Intl.NumberFormat(language)
        .format(1234)
        .substring(1, 2)}
    />
  );
}
