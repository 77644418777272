import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
}));

function ImmunosuppresionTitle() {
  const classes = useStyles();
  return (
    <Grid container item justify="flex-end">
      <Box
        display="inline"
        component="div"
        flexGrow={1}
        style={{ paddingLeft: '10%' }}
        className={classes.title}
      >
        <Box display="inline" component="div">
          <img
            src={`/images/AlloCare_Logo_RGB.png`}
            alt="AlloCare logo"
            width="120px"
          ></img>
          <Typography style={{ fontWeight: '600' }} variant="subtitle1">
            Immunosuppression
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

export default ImmunosuppresionTitle;
