import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';

const UPSERT_CONFIG = gql`
  fragment StringFilter on StringFilterType {
    eq
    startsWith
    endsWith
    contains
  }

  fragment IntegerFilter on IntegerFilterType {
    eq
    gt
    ge
    lt
    le
  }

  fragment DateTimeFilter on DateTimeFilterType {
    eq
    gt
    ge
    lt
    le
  }

  fragment DateTimeOffsetFilter on DateTimeOffsetFilterType {
    eq
    gt
    ge
    lt
    le
  }

  fragment OptnStarFilter on OptnStarFilterType {
    firstName {
      ...StringFilter
    }
    lastName {
      ...StringFilter
    }
    pra {
      ...IntegerFilter
    }
    kdpi {
      ...IntegerFilter
    }
    previousTransplant {
      ...StringFilter
    }
    previousTransplantDate {
      ...DateTimeOffsetFilter
    }
    dateOfFirstDialysis {
      ...DateTimeOffsetFilter
    }
  }

  mutation UpsertListConfig(
    $id: String
    $title: String!
    $fields: [String]!
    $filter: [ListRowFilterType]!
    $isStaticList: Boolean!
    $collectionId: String
    $patientIds: [String]
    $hasDefaultSort: Boolean!
    $defaultSortField: String
    $defaultSortDirection: String
  ) {
    upsertPatientCollection(id: $id, patientIds: $patientIds)
      @include(if: $isStaticList) {
      id
      patientIds
    }
    upsertListConfig(
      listConfig: {
        id: $id
        title: $title
        fields: $fields
        filter: $filter
        isStaticList: $isStaticList
        collectionId: $collectionId
        hasDefaultSort: $hasDefaultSort
        defaultSortField: $defaultSortField
        defaultSortDirection: $defaultSortDirection
      }
    ) {
      id
      title
      fields
      filter {
        firstName {
          ...StringFilter
        }
        lastName {
          ...StringFilter
        }
        dateOfBirth {
          ...DateTimeFilter
        }
        star {
          ...OptnStarFilter
        }
      }
      isStaticList
      collectionId
      hasDefaultSort
      defaultSortField
      defaultSortDirection
    }
  }
`;

export default function WithListConfigMutation({ children }) {
  const [saving, setSaving] = useState(false);
  const [upsertListConfig] = useMutation(UPSERT_CONFIG, {
    update(cache, { data: { id, upsertListConfig, upsertPatientCollection } }) {
      // setConfig({
      //     ...upsertListConfig,
      //     collectionId: upsertPatientCollection && upsertPatientCollection.id,
      //     patientIds: upsertPatientCollection && upsertPatientCollection.patientIds,
      // });
      setSaving(false);
      cache.modify({
        id: id,
        fields: {
          listConfigAll(existingConfig) {
            const upsertedRef = cache.writeFragment({
              data: upsertListConfig,
              fragment: gql`
                fragment UpsertedConfig on ListConfigType {
                  id
                  title
                }
              `,
            });
            if (
              existingConfig.filter((r) => r.__ref === upsertedRef.__ref)
                .length > 0
            ) {
              return [...existingConfig];
            } else {
              return [...existingConfig, upsertedRef];
            }
          },
          listConfig(existingConfig) {
            cache.writeFragment({
              data: upsertListConfig,
              fragment: gql`
                fragment UpsertedConfig on ListConfigType {
                  id
                  title
                }
              `,
            });
            return existingConfig;
          },
          patientCollection(existingConfig) {
            cache.writeFragment({
              data: upsertPatientCollection,
              fragment: gql`
                fragment UpsertedPatientCollection on PatientCollectionType {
                  id
                  patientIds
                }
              `,
            });
            return existingConfig;
          },
          list(existingConfig) {
            return existingConfig;
          },
        },
      });
    },
  });

  const handleUpsert = (variables) => {
    setSaving(true);
    upsertListConfig(variables);
  };

  return children(handleUpsert, saving);
}
