export function buildFilterSchema(startType, data) {
  const fields = data[startType].fields;
  const paths = {};
  for (let f = 0; f < fields.length; f++) {
    const field = fields[f];
    const path = [field];
    while (path.length > 0) {
      const head = path[path.length - 1];
      const pathName = path.map((pf) => pf.name).join('.');
      if (!paths[pathName]) {
        paths[pathName] = head.type.name;
      }
      let headAssigned = false;
      if (head.type.kind !== 'SCALAR') {
        const type = data[head.type.name];
        const frontier = type.fields;
        if (frontier) {
          for (let ff = 0; ff < frontier.length; ff++) {
            const candidateField = frontier[ff];
            const candidate = [
              ...path.map((pf) => pf.name),
              candidateField.name,
            ].join('.');
            if (!paths[candidate]) {
              headAssigned = true;
              path.push(candidateField);
              break;
            }
          }
        }
      }
      if (!headAssigned) {
        path.pop();
      }
    }
  }
  return paths;
}

export const compoundTypes = [
  'ObservationType',
  'MedicationRequestType',
  'MedicationAdministrationType',
  'EncounterType',
  'ProcedureType',
  'PractitionerType',
  'CareTeamType',
];
export const compoundToFragment = {
  ObservationType: 'ObservationParts',
  MedicationRequestType: 'MedicationRequestParts',
  MedicationAdministrationType: 'MedicationAdministrationParts',
  EncounterType: 'EncounterParts',
  ProcedureType: 'ProcedureParts',
  PractitionerType: 'PractitionerParts',
  CareTeamType: 'CareTeamParts',
};

export function buildFieldSchema(startType, data) {
  const fields = data[startType].fields;
  const paths = {};
  for (let f = 0; f < fields.length; f++) {
    const field = fields[f];
    const path = [field];
    while (path.length > 0) {
      const head = path[path.length - 1];
      const pathName = path.map((pf) => pf.name).join('.');
      if (!paths[pathName]) {
        paths[pathName] = head;
      }
      let headAssigned = false;
      if (
        head.type.kind !== 'SCALAR' &&
        !compoundTypes.includes(head.type.name)
      ) {
        const type = data[head.type.name];
        if (!type) {
          throw new Error(
            JSON.stringify({
              name: head.type.name,
              kind: head.type.kind,
            })
          );
        }
        const frontier = type.fields;
        if (frontier) {
          for (let ff = 0; ff < frontier.length; ff++) {
            const candidateField = frontier[ff];
            const candidate = [
              ...path.map((pf) => pf.name),
              candidateField.name,
            ].join('.');
            if (
              !paths[candidate] &&
              !(
                [...path.map((pf) => pf.name)].indexOf(candidateField.name) >= 0
              )
            ) {
              headAssigned = true;
              path.push(candidateField);
              break;
            }
          }
        }
      }
      if (!headAssigned) {
        path.pop();
      }
    }
  }
  return paths;
}
