/* istanbul ignore file */

import DateUtility from './Date';

const reducer = (state, action) => {
  // const newObj = Object.assign({}, state);
  // newObj[action.type] = action.payload;
  switch (action.type) {
    case 'Histology':
    case 'calculated':
      return { ...state, ...action.payload };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const formatIBoxData = (allRecords, patient) => {
  const cMonthsDiff = DateUtility.monthsDifference(
    new Date(patient.transplantDate),
    new Date(allRecords[0].parameters.riskEvaluationDate)
  );
  const pMonthsDiff =
    allRecords[1] &&
    DateUtility.monthsDifference(
      new Date(patient.transplantDate),
      new Date(allRecords[1].parameters.riskEvaluationDate)
    );
  const current =
    allRecords[0] && allRecords[0].result.survival3Years
      ? [
          {
            x: cMonthsDiff + 36,
            y: allRecords[0].result.survival3Years / 100,
          },
          {
            x: cMonthsDiff + 60,
            y: allRecords[0].result.survival5Years / 100,
          },
          {
            x: cMonthsDiff + 84,
            y: allRecords[0].result.survival7Years / 100,
          },
        ]
      : [];
  const prior =
    allRecords[1] && allRecords[1].result.survival3Years
      ? [
          {
            x: pMonthsDiff + 36,
            y: allRecords[1].result.survival3Years / 100,
          },
          {
            x: pMonthsDiff + 60,
            y: allRecords[1].result.survival5Years / 100,
          },
          {
            x: pMonthsDiff + 84,
            y: allRecords[1].result.survival7Years / 100,
          },
        ]
      : [];
  return {
    current,
    prior,
  };
};

const formatCibilData = (state, patient, result) => {
  const cMonthsDiff = DateUtility.monthsDifference(
    new Date(patient.transplantDate),
    new Date(state.riskEvaluationDate)
  );
  const current = result.survival3Years
    ? [
        {
          x: cMonthsDiff + 36,
          y: result.survival3Years / 100,
        },
        {
          x: cMonthsDiff + 60,
          y: result.survival5Years / 100,
        },
        {
          x: cMonthsDiff + 84,
          y: result.survival7Years / 100,
        },
      ]
    : [];
  return {
    current,
  };
};

export { reducer, formatIBoxData, formatCibilData };
