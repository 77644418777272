import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import React, { useState } from 'react';
import { IconButton } from '@material-ui/core';
import SnackBar from 'Components/Controls/SnackBar';

export default function AccessTokenCopyToClipBoard({ className, accessToken }) {
  const [copyToken, setCopyToken] = useState(false);

  const handleClick = (e) => {
    setCopyToken(true);
    navigator.clipboard.writeText(accessToken);
  };

  return (
    <>
      <IconButton
        classes={className}
        aria-label="copy access token"
        onClick={handleClick}
        color="primary"
      >
        <AccountBalanceWalletIcon></AccountBalanceWalletIcon>
      </IconButton>
      <SnackBar
        message="Token Copied !!"
        duration={3000}
        open={copyToken}
        onClose={() => setCopyToken(false)}
      ></SnackBar>
    </>
  );
}
