import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { IBoxContext } from '../state/IBoxContextProvider';
const numbers = {
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
};
function IBoxSelect({ id, label, name, validators }) {
  const { state, dispatch, errorState } = useContext(IBoxContext);
  return (
    <FormControl fullWidth size="small" variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        required
        value={state[name] || ''}
        labelId={id}
        label={label}
        error={!!errorState[name]}
        onChange={(e) => {
          dispatch({
            type: name,
            payload: e.target.value,
          });
          validators.rules[name](e.target.value);
        }}
      >
        <MenuItem value="">Not Available</MenuItem>
        {[0, 1, 2, 3].map((p, i) => (
          <MenuItem key={i} value={p.toString()}>
            {numbers[p].toString()}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{!!errorState[name] && errorState[name]}</FormHelperText>
    </FormControl>
  );
}

export default IBoxSelect;
