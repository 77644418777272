import React, { useState } from 'react';
import { Modal, makeStyles, Button } from '@material-ui/core';
import EditListsPage from '../EditLists/EditListsPage';
import SmartListsPage from '../EditLists/SmartListsPage';
import {
  ListBuilderEditPage,
  ListBuilderNewPage,
} from '../ListBuilder/ListBuilderPage';
import { useQuery, gql } from '@apollo/client';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: '1em',
  },
  paper2: {
    position: 'absolute',
    width: 1200,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '1em',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  smartListsButtons: {
    display: 'inline-flex',
    marginBottom: '10px',
    marginTop: '10px',
  },
  myListsBtn: {
    color: '#40b6fe',
    backgroundColor: 'white',
  },
}));

export default function MyListModal({ upsertMyListConfig, saving, userId }) {
  const classes = useStyles();
  const [open, setModalOpen] = React.useState(false);
  const [openEdit, setEditOpen] = useState(false);
  const [openCustomize, setCustomizeOpen] = useState(false);
  const [modalId, setModalId] = React.useState(null);
  const [modalCollectionId, setModalCollectionId] = React.useState(null);
  const [checked, setChecked] = React.useState([]);
  const [modalStyle] = React.useState(getModalStyle);

  function handleEditOpen(id, collectionId) {
    setModalId(id);
    setModalCollectionId(collectionId);
    setEditOpen(true);
    setModalOpen(false);
  }

  const handleEditClose = () => {
    setEditOpen(false);
    setModalOpen(true);
  };

  const handleCustomizeClose = () => {
    setCustomizeOpen(false);
    setModalOpen(true);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  function handleChecked(row) {
    var idx = checked.findIndex((d) => d.id === row.id);
    if (idx === -1) {
      setChecked((checked) => [...checked, row]);
    } else {
      checked.splice(idx, 1);
      setChecked((checked) => [...checked]);
    }
  }

  function handleAddClick() {
    checked.forEach((i) => {
      if (!saving) {
        upsertMyListConfig({
          variables: {
            ...i,
            userId: userId,
            filter: [],
          },
        });
      }
    });
  }

  function handleEditClick() {
    if (checked.length === 1) {
      setModalId(checked[0].id);
      setModalCollectionId(checked[0].collectionId);
      setCustomizeOpen(true);
      setModalOpen(false);
    }
  }

  const GET_LISTS = gql`
    query myListConfigAll($userId: String) {
      myListConfigAll(userId: $userId) {
        id
        title
        description
        isStaticList
        collectionId
        fields
        hasDefaultSort
      }
    }
  `;

  const { loading, error, data, refetch } = useQuery(GET_LISTS, {
    variables: { userId: userId },
  });

  return (
    <div>
      <Button
        variant="contained"
        color="secondary"
        className={classes.myListsBtn}
        onClick={handleModalOpen}
      >
        My Lists
      </Button>
      <Modal open={open} onClose={handleModalClose}>
        <div style={modalStyle} className={classes.paper}>
          <h3>My Lists</h3>
          <EditListsPage
            loading={loading}
            error={error}
            data={data}
            userId={userId}
            handleEditOpen={handleEditOpen}
            upsertMyListConfig={upsertMyListConfig}
            saving={saving}
          />
          <div className={classes.smartListsButtons}>
            <h3>SMART Lists</h3>
            <Button
              className={classes.myListsBtn}
              variant="contained"
              color="secondary"
              disabled={checked.length > 0 ? false : true}
              onClick={handleAddClick}
            >
              Add to My List
            </Button>
            <Button
              className={classes.myListsBtn}
              variant="contained"
              color="secondary"
              disabled={checked.length === 1 ? false : true}
              onClick={handleEditClick}
            >
              Customize List
            </Button>
          </div>
          <SmartListsPage handleOnCheck={handleChecked} checkedList={checked} />
        </div>
      </Modal>
      <Modal open={openEdit} onClose={handleEditClose}>
        <div style={modalStyle} className={classes.paper2}>
          <ListBuilderEditPage
            match={{
              params: {
                id: modalId,
                cid: modalCollectionId,
                handleClose: handleEditClose,
                refetch: refetch,
              },
            }}
          />
        </div>
      </Modal>
      <Modal open={openCustomize} onClose={handleCustomizeClose}>
        <div style={modalStyle} className={classes.paper2}>
          <ListBuilderNewPage
            match={{
              params: {
                id: modalId,
                cid: modalCollectionId,
                handleClose: handleCustomizeClose,
                refetch: refetch,
              },
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
