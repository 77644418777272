import React from 'react';
import { gql } from '@apollo/client';
import WithQueryResults from './WithQueryResults';

const GET_LIST_CONFIG = gql`
  fragment StringFilter on StringFilterType {
    eq
    startsWith
    endsWith
    contains
  }

  fragment IntegerFilter on IntegerFilterType {
    eq
    gt
    ge
    lt
    le
  }

  fragment DateTimeFilter on DateTimeFilterType {
    eq
    gt
    ge
    lt
    le
  }

  fragment DateTimeOffsetFilter on DateTimeOffsetFilterType {
    eq
    gt
    ge
    lt
    le
  }

  fragment OptnStarFilter on OptnStarFilterType {
    firstName {
      ...StringFilter
    }
    lastName {
      ...StringFilter
    }
    pra {
      ...IntegerFilter
    }
    kdpi {
      ...IntegerFilter
    }
    previousTransplant {
      ...StringFilter
    }
    previousTransplantDate {
      ...DateTimeOffsetFilter
    }
    dateOfFirstDialysis {
      ...DateTimeOffsetFilter
    }
  }

  query myQuery(
    $id: String!
    $collectionId: String!
    $includePatientCollection: Boolean!
  ) {
    listConfig(id: $id) {
      id
      title
      description
      dashboardName
      filter {
        firstName {
          ...StringFilter
        }
        lastName {
          ...StringFilter
        }
        dateOfBirth {
          ...DateTimeFilter
        }
        star {
          ...OptnStarFilter
        }
      }
      fields
      isStaticList
      collectionId
      hasDefaultSort
      defaultSortField
      defaultSortDirection
    }
    patientCollection(id: $collectionId)
      @include(if: $includePatientCollection) {
      id
      patientIds
    }
    schema: __schema {
      types {
        name
        description
      }
    }
  }
`;

export default function WithListConfigData({
  children,
  id,
  collectionId,
  includePatientCollection,
}) {
  return (
    <WithQueryResults
      QUERY={GET_LIST_CONFIG}
      variables={{ id, collectionId, includePatientCollection }}
    >
      {children}
    </WithQueryResults>
  );
}
