import React from 'react';
import { useQuery } from '@apollo/client';
import DataLoading from './DataLoading';

export default function WithQueryResults({
  QUERY,
  children,
  variables,
  options,
}) {
  const { data, ...rest } = useQuery(QUERY, { ...options, variables });
  let augmentedChildren;

  if (children instanceof Array) {
    augmentedChildren = [];
    for (let c = 0; c < children.length; c++) {
      const child = children[c];
      if (React.isValidElement(child)) {
        const augmentedChild = React.cloneElement(child, {
          ...child.props,
          data: data,
        });
        augmentedChildren.push(augmentedChild);
      }
    }
  } else if (children instanceof Function) {
    augmentedChildren = children.bind(null, data, rest.refetch);
  } else {
    const child = children;
    if (React.isValidElement(child)) {
      const augmentedChild = React.cloneElement(child, {
        ...child.props,
        data: data,
      });
      augmentedChildren = augmentedChild;
    }
  }

  return <DataLoading {...rest}>{augmentedChildren}</DataLoading>;
}
