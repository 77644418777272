import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import WidgetContainer from './WidgetContainer';
import { generatePath, useHistory } from 'react-router-dom';
import { useAuthentication } from '../../OAuth2';
import { useSmartAuthentication } from '../../Smart';
import WithDashboardUserWidgets from '../../GraphQL/WithDashboardUserWidgets';
import { WidgetSettingsProvider } from './state/WidgetSettingsProvider';
import { AugmentChildren } from 'Components/Utilities/ReactExtensions';
import { WidgetTypes } from './shared/config';
import { useUserWidgetMutation } from '../../GraphQL/useUserWidgetMutation';

const widgetStyle = (theme) => ({
  marginLeft: theme.spacing(3),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  paddingLeft: 2,
  paddingRight: 2,
  borderRadius: 10,
  border: `2px solid ${grey[300]}`,
  boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      ...widgetStyle(theme),
      width: theme.spacing(90), //This sets the width of the widgets
      height: theme.spacing(43), //This sets the height of the widgets
    },
  },
  rootNoShowBanner: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      ...widgetStyle(theme),
      width: theme.spacing(75), //This sets the width of the widgets coming from Epic
      height: theme.spacing(41), //This sets the height of the widgets coming from Epic
    },
  },
}));

export default function Widgets({ id, data }) {
  const classes = useStyles();
  const history = useHistory();

  const handleWidgetClick = (widget) => {
    if (widget.url != null) {
      return history.push(generatePath(widget.url, { id }));
    }
  };
  const { authService } = useAuthentication();
  const { authState: smartAuthState } = useSmartAuthentication();
  const userInfo = authService.getUser();

  return (
    <>
      {userInfo && (
        <WidgetSettingsProvider>
          <WithDashboardUserWidgets userId={userInfo.sub}>
            {(widgetList) => {
              return (
                <WidgetListDnD
                  classes={classes}
                  widgetListIn={widgetList}
                  smartAuthState={smartAuthState}
                  handleWidgetClick={handleWidgetClick}
                  data={data}
                  userId={userInfo.sub}
                />
              );
            }}
          </WithDashboardUserWidgets>
        </WidgetSettingsProvider>
      )}
    </>
  );
}

function WidgetListDnD({
  classes,
  widgetListIn,
  smartAuthState,
  handleWidgetClick,
  data,
  userId,
}) {
  const { mutate } = useUserWidgetMutation();

  const [widgetList, setWidgetList] = useState(widgetListIn);

  const moveWidgetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      console.log('callback');
      console.log(dragIndex);
      console.log(hoverIndex);
      const userWidgets = [...widgetList.userWidgets];
      const dragWidgetList = userWidgets[dragIndex];
      userWidgets.splice(dragIndex, 1);
      userWidgets.splice(hoverIndex, 0, dragWidgetList);
      setWidgetList({ userWidgets: userWidgets });
      console.log(widgetList);
    },
    [widgetList]
  );

  //save widget order callback
  const saveWidgetOrder = () => {
    console.log('save widget order...');
    console.log(widgetList.userWidgets);
    console.log(userId);
    const newUserWidgetList = [];
    widgetList.userWidgets.map((w, i) =>
      newUserWidgetList.push({
        widgetId: w.widgetId,
        title: w.title,
        description: w.description,
        displayOrder: i + 1,
        identifier: w.identifier,
        url: w.url,
        imageName: w.imageName,
      })
    );
    console.log(newUserWidgetList);
    mutate({
      variables: {
        userWidgetList: newUserWidgetList,
      },
    });
  };

  return (
    <div
      className={
        smartAuthState.showBanner ? classes.root : classes.rootNoShowBanner
      }
    >
      {widgetList.userWidgets.map((w, i) => (
        <WidgetContainer
          index={i}
          key={w.identifier}
          wid={w.identifier}
          text={w.title}
          image={w.imageName}
          open={(ev) => handleWidgetClick(w)}
          data={data}
          moveWidgetListItem={moveWidgetListItem}
          saveWidgetOrderCallback={saveWidgetOrder}
        >
          {(() => {
            if (WidgetTypes[w.identifier]) {
              const Component = WidgetTypes[w.identifier];
              return AugmentChildren(<Component />, {
                data: data,
                widgetId: w.identifier,
              });
            } else {
              console.error(`widget setting not available for ${w.identifier}`);
            }
          })()}
        </WidgetContainer>
      ))}
    </div>
  );
}
