/** Translate a React element's children with the react-i18next (t)translate function.
 *
 * @param {(text: string) => string} t
 * @param {Array<String>|String|Array<JSX.Element>} children
 */
export default function TranslateChildren(t, children) {
  let translatedChildren;
  if (typeof children === 'string') {
    translatedChildren = t(children);
  } else if (children instanceof Array) {
    translatedChildren = children.map((c) => {
      let tc;
      if (typeof c === 'string') {
        tc = t(c);
      } else {
        tc = c;
      }
      return tc;
    });
  } else {
    translatedChildren = children;
  }
  return translatedChildren;
}
