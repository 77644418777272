/**
 * Applies a filter object to a collection of rows.
 * @param {array} rows
 * @param {object} filter
 */
export function filter(rows, filter) {
  const filterKeys = Object.keys(filter);
  const filteredRows = rows.filter((row) => {
    return filterKeys.every(testFilter(row, filter));
  });
  return filteredRows;
}

function testFilter(row, filter) {
  return function (property) {
    const element = filter[property];
    const rowValue = row[property];
    const test = operationData[element.operation].test;
    return test(rowValue, element.value, element.start, element.end);
  };
}

const operationData = {
  equals: {
    label: 'Equals',
    abbreviation: 'eq',
    symbol: '=',
    test(i, j) {
      if (typeof j === 'string' && typeof i === 'string') {
        return i.toLowerCase() === j.toLowerCase();
      } else {
        return i === j;
      }
    },
  },
  lessThan: {
    label: 'Less than',
    abbreviation: 'lt',
    symbol: '<',
    test(i, j) {
      return i < j;
    },
  },
  greaterThan: {
    label: 'Greater than',
    abbreviation: 'gt',
    symbol: '>',
    test(i, j) {
      return i > j;
    },
  },
  lessThanOrEqual: {
    label: 'Equal to or less than',
    abbreviation: 'le',
    symbol: '<=',
    test(i, j) {
      return i <= j;
    },
  },
  greaterThanOrEqual: {
    label: 'Equal to or greater than',
    abbreviation: 'ge',
    symbol: '>=',
    test(i, j) {
      return i >= j;
    },
  },
  between: {
    label: 'Between',
    abbreviation: 'between',
    symbol: 'between',
    test(i, j, start, end) {
      return i >= start && i <= end;
    },
  },
  contains: {
    label: 'Contains',
    abbreviation: 'contains',
    symbol: 'contains',
    test(i, j) {
      return i.toString().toLowerCase().includes(j.toString().toLowerCase());
    },
  },
  startsWith: {
    label: 'Starts with',
    abbreviation: 'startsWith',
    symbol: 'starts with',
    test(i, j) {
      return i.toString().toLowerCase().startsWith(j.toString().toLowerCase());
    },
  },
  endsWith: {
    label: 'Ends with',
    abbreviation: 'endsWith',
    symbol: 'ends with',
    test(i, j) {
      return i.toString().toLowerCase().endsWith(j.toString().toLowerCase());
    },
  },
};

export function operationTypes() {
  return Object.keys(operationData);
}

export function operationLabel(operationType) {
  return operationData[operationType].label;
}

export function operationSymbol(operationType) {
  return operationData[operationType].symbol;
}

export function operationAbbreviation(operationType) {
  return operationData[operationType].abbreviation;
}
