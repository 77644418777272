import React from 'react';

export default function ErrorBoundaryHOC(Component, ErrorComponent) {
  return class ErrorHOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        hasError: false,
      };
    }

    static getDerivedStateFromError(error) {
      return {
        error: error,
        hasError: true,
      };
    }

    componentDidCatch(error, errorInfo) {
      //TODO: need to add logging service.
    }

    render() {
      let content;
      if (this.state.hasError) {
        content = (
          <ErrorComponent
            error={this.state.error}
            componentContext={{
              props: this.props,
              component: Component,
            }}
          />
        );
      } else {
        content = <Component {...this.props} />;
      }
      return content;
    }
  };
}
