import React from 'react';
import { Select } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import translateChildren from './translateChildren';

function TranslatedSelect({ t, children, ...rest }) {
  let translatedChildren;

  if (children instanceof Array) {
    translatedChildren = [];
    for (let c = 0; c < children.length; c++) {
      const child = children[c];
      if (React.isValidElement(child)) {
        const translatedGrandchildren = translateChildren(
          t,
          child.props.children
        );
        const augmentedChild = React.cloneElement(child, {
          ...child.props,
          children: translatedGrandchildren,
        });
        translatedChildren.push(augmentedChild);
      }
    }
  } else {
    const child = children;
    if (React.isValidElement(child)) {
      const translatedGrandchildren = translateChildren(
        t,
        child.props.children
      );
      const augmentedChild = React.cloneElement(child, {
        ...child.props,
        children: translatedGrandchildren,
      });
      translatedChildren = augmentedChild;
    }
  }

  return <Select {...rest}>{translatedChildren}</Select>;
}

export default withTranslation()(TranslatedSelect);
