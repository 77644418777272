/* istanbul ignore file */

import { ChartsConfiguration, ChartTypes } from './AlloCareConfig';

export const ChartGroups = [
  [
    ChartsConfiguration[ChartTypes.fluidIntake],
    ChartsConfiguration[ChartTypes.Urine],
    ChartsConfiguration[ChartTypes.Weight],
  ],
  [
    ChartsConfiguration[ChartTypes.BloodPressure],
    ChartsConfiguration[ChartTypes.Oxygen],
    ChartsConfiguration[ChartTypes.Temperature],
  ],
  [
    ChartsConfiguration[ChartTypes.BloodGlucose],
    ChartsConfiguration[ChartTypes.Steps],
  ],
];
