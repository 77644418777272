const ACTIONS = {
  // SET_WIDGET_SETTINGS: 'Update Widget Settings',
  BIND_CLICK: 'Widget Clicked',
};

const Reducer = (state, action) => {
  switch (action.type) {
    // case ACTIONS.SET_WIDGET_SETTINGS: {
    //   const newState = {
    //     ...state,
    //     [action.payload.id]: {
    //       ...state[action.payload.id],
    //       hasData: action.payload.hasData,
    //     },
    //   };
    //   return newState;
    // }
    case ACTIONS.BIND_CLICK: {
      const newState = {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          widgetClicked: action.payload.widgetClicked,
        },
      };
      return newState;
    }
    default:
      return state;
  }
};

export { ACTIONS, Reducer };
