import React, { useState } from 'react';
import { Box, makeStyles, Grid, Typography } from '@material-ui/core';
import {
  EventTimelineReportFrequency,
  EventTimelineReportRange,
} from './EventTimelineConfig';
import DemographicsHeader from '../../Display/DemographicsHeader';
import EventTimeline from './EventTimeline';
import PatientLayout from '../../Layout/PatientLayout';
import ErrorBoundaryHOC from '../../ErrorBoundaryHOC';
import ErrorPage from '../Error/ErrorPage';
import WithIBoxQueryWithLinks from 'Components/GraphQL/WithIBoxQueryWithLinks';
import { useParams } from 'react-router-dom';
import EventTimelineRange from './EventTimelineRange';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
}));

const getFrequencyByRange = (range) => {
  switch (range) {
    case EventTimelineReportRange.week:
      return {
        text: 'Daily Average',
        value: EventTimelineReportFrequency.daily,
      };
    case EventTimelineReportRange.month:
      return {
        text: '3 Day Average',
        value: EventTimelineReportFrequency.threeDays,
      };
    case EventTimelineReportRange.quarter:
      return {
        text: '10 Day Average',
        value: EventTimelineReportFrequency.tenDays,
      };
    default:
      return null;
  }
};
function EventTimelineTitle() {
  const classes = useStyles();

  return (
    <Grid container item justify="flex-end">
      <Box
        display="inline"
        component="div"
        flexGrow={1}
        style={{ paddingLeft: '10%' }}
        className={classes.title}
      >
        <Box display="inline" component="div">
          <Typography
            style={{ fontWeight: '600', width: '120px' }}
            variant="subtitle1"
          >
            Event Timeline
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
}

function EventTimelinePage() {
  //const today = moment(new Date());
  const [range] = useState(EventTimelineReportRange.week);
  const frequency = getFrequencyByRange(range);
  const params = useParams();
  const id = params['id'];
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };
  console.log(startDate);
  console.log(endDate);
  const mStartDate = moment(startDate, 'MM-DD-YYYY');
  const mEndtDate = moment(endDate, 'MM-DD-YYYY');
  const days = mEndtDate.diff(mStartDate, 'days');
  console.log(days);
  return (
    <WithIBoxQueryWithLinks
      id={id}
      includeResult={true}
      includeDetails={true}
      includeParameters={true}
      includeAllocare={true}
      includeImmunosuppression={true}
      days={range}
      averageSliceSize={1}
    >
      {function (data) {
        return (
          <PatientLayout
            header={
              <DemographicsHeader id={id} data={data} showTimeLine={true} />
            }
            subHeader={<EventTimelineTitle />}
            rightControls={<EventTimelineRange onDateChange={onDateChange} />}
          >
            <EventTimeline
              patientId={params['id']}
              range={days}
              frequency={frequency?.value}
              startDate={startDate}
              endDate={endDate}
            />
          </PatientLayout>
        );
      }}
    </WithIBoxQueryWithLinks>
  );
}

export default ErrorBoundaryHOC(EventTimelinePage, ErrorPage);
