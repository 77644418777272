import React from 'react';
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormGroup,
  FormControl,
  Checkbox,
  makeStyles,
} from '@material-ui/core';
import Constants from '../PersonalizationBaseline/Utilities/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
  },
  mainBox: {
    paddingLeft: '60px',
  },
  boxRight: {
    paddingLeft: '80px',
  },
  formControl: {
    margin: theme.spacing(1),
  },
}));

export default function AlloCareCheckConditions({
  conditionState,
  handleConditionChange,
  iBoxDetails,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.mainBox}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          <Typography>
            <strong>Tx Type:</strong> {iBoxDetails?.transplantOrgan}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography>
            <strong>Donor Type:</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Typography>
            <strong>PostTx Time:</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <RadioGroup name="postTxTime" value={true} row>
            <FormControlLabel
              value={iBoxDetails.monthsPostTx <= 3}
              control={<Radio required />}
              label="<= 3 months"
            />
            <FormControlLabel
              value={iBoxDetails.monthsPostTx > 3}
              control={<Radio required />}
              label="> 3 months"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <strong>Condition:</strong>
          </Typography>
        </Grid>
      </Grid>
      <Box component="div" className={classes.boxRight}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionState.oliguriaCondition}
                  onChange={handleConditionChange}
                  name={Constants.OLIGURIA}
                />
              }
              label="Oliguria"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionState.copdCondition}
                  onChange={handleConditionChange}
                  name={Constants.COPD}
                />
              }
              label="COPD"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionState.bmiCondition}
                  onChange={handleConditionChange}
                  name={Constants.BMI}
                />
              }
              label="BMI > 30"
            />
          </FormGroup>
        </FormControl>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionState.diabetesCondition}
                  onChange={handleConditionChange}
                  name={Constants.DIABETES}
                />
              }
              label="Diabetes"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionState.hypertensionCondition}
                  onChange={handleConditionChange}
                  name={Constants.HYPERTENSION}
                />
              }
              label="Hypertension"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={conditionState.depressionCondition}
                  onChange={handleConditionChange}
                  name={Constants.DEPRESSION}
                />
              }
              label="Depression"
            />
          </FormGroup>
        </FormControl>
      </Box>
    </Box>
  );
}
