import {
  Grid,
  TextField,
  FormControlLabel,
  Radio,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  FormHelperText,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useContext } from 'react';
import { convertISODateToLocal } from 'Utilities/dateHelpers';
import { IBoxContext } from '../state/IBoxContextProvider';
import Constants from '../Utilities/constants';
function Proteinuria({ validators }) {
  const { dispatch, state, errorState, patientState } = useContext(IBoxContext);
  const onUnitChange = (e) => {
    dispatch({
      type:
        e.target.value === 'g/g'
          ? Constants.PROTEINURIA_DIPSTICK
          : Constants.PROTEINURIA_IN_G_G,
      payload: null,
    });
    dispatch({
      type: Constants.PROTEINURIA_UNIT,
      payload: e.target.value,
    });
    validators.resetValidators([
      e.target.value === 'g/g'
        ? Constants.PROTEINURIA_DIPSTICK
        : Constants.PROTEINURIA_IN_G_G,
    ]);
  };

  return (
    <Grid container spacing={3}>
      <Grid item container direction="row">
        <Grid item xs={12}>
          <Typography component="div">
            <Box fontWeight="fontWeightBold">Proteinuria</Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item xs={6}>
          <DatePicker
            name={Constants.DATE_OF_PROTEINURIA_TAKEN}
            format="MM/DD/YYYY"
            margin="normal"
            required
            minDate={patientState[Constants.TRANSPLANT_DATE] || undefined}
            label="Date of Urine Collection"
            value={convertISODateToLocal(state?.dateOfProteinuriaTaken)}
            error={!!errorState[Constants.DATE_OF_PROTEINURIA_TAKEN]}
            onChange={(e) => {
              dispatch({
                type: Constants.DATE_OF_PROTEINURIA_TAKEN,
                payload: e?.$d,
              });
              validators.rules[Constants.DATE_OF_PROTEINURIA_TAKEN](e?.$d);
            }}
            helperText={
              !!errorState[Constants.DATE_OF_PROTEINURIA_TAKEN] &&
              errorState[Constants.DATE_OF_PROTEINURIA_TAKEN]
            }
            invalidLabel={''}
          />
        </Grid>
      </Grid>

      <Grid item container direction="row">
        <Grid item xs={6}>
          <FormControlLabel
            value="g/g"
            name={Constants.PROTEINURIA_UNIT}
            checked={state?.proteinuriaUnit === 'g/g'}
            onChange={onUnitChange}
            control={<Radio />}
            label="PCR [g/g]"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            value="dipstick"
            name={Constants.PROTEINURIA_UNIT}
            checked={state?.proteinuriaUnit === 'dipstick'}
            onChange={onUnitChange}
            control={<Radio />}
            label="Dipstick"
          />
        </Grid>
      </Grid>
      <Grid item container direction="row">
        <Grid item xs={6}>
          {state?.proteinuriaUnit === 'g/g' && (
            <TextField
              name={Constants.PROTEINURIA_IN_G_G}
              InputLabelProps={{ shrink: true }}
              required
              label="Urine Ratio"
              autoComplete="off"
              placeholder="protein/creatinine 0-12"
              type="text"
              variant="outlined"
              size="small"
              onChange={(e) => {
                dispatch({
                  type: Constants.PROTEINURIA_IN_G_G,
                  payload: e.target.value,
                });
                validators.rules[Constants.PROTEINURIA_IN_G_G](e.target.value);
              }}
              error={!!errorState[Constants.PROTEINURIA_IN_G_G]}
              value={state.proteinuriaInGG || ''}
              helperText={
                !!errorState[Constants.PROTEINURIA_IN_G_G] &&
                errorState[Constants.PROTEINURIA_IN_G_G]
              }
            />
          )}
        </Grid>
        <Grid item xs={6}>
          {state?.proteinuriaUnit === 'dipstick' && (
            <FormControl fullWidth size="small" variant="outlined">
              <InputLabel>DipStick</InputLabel>
              <Select
                labelId="DipStick"
                required
                value={state.proteinuriaDipstick || ''}
                onChange={(e) => {
                  dispatch({
                    type: Constants.PROTEINURIA_DIPSTICK,
                    payload: e.target.value,
                  });
                  validators.rules[Constants.PROTEINURIA_DIPSTICK](
                    e.target.value
                  );
                }}
                error={!!errorState[Constants.PROTEINURIA_DIPSTICK]}
                label="DipStick"
              >
                <MenuItem value="absence">Absence</MenuItem>
                <MenuItem value="traces">Traces</MenuItem>
                <MenuItem value="+">+</MenuItem>
                <MenuItem value="++">++</MenuItem>
                <MenuItem value="+++">+++</MenuItem>
                <MenuItem value="++++">++++</MenuItem>
              </Select>
              {!!errorState[Constants.PROTEINURIA_DIPSTICK] && (
                <FormHelperText>{Constants.REQUIRED}</FormHelperText>
              )}
            </FormControl>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Proteinuria;
