/* istanbul ignore file */

import React from 'react';
import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import { useAuthentication } from '../OAuth2';
const useStyles = makeStyles((theme) => ({
  text: {
    color: 'white',
  },
}));

export default function LoginIndicator() {
  const { authState, authService } = useAuthentication();
  const classes = useStyles();
  const login = () => authService.login();
  const logout = () => authService.logout();

  let loginUi = null;
  if (authState.isPending) {
    loginUi = <CircularProgress color="secondary" />;
  } else if (!authState.isAuthenticated) {
    loginUi = (
      <Button style={{ color: 'white' }} classes={classes} onClick={login}>
        Sign In
      </Button>
    );
  } else {
    loginUi = (
      <Button style={{ color: 'white' }} classes={classes} onClick={logout}>
        Sign Out
      </Button>
    );
  }

  return loginUi;
}
