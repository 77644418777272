import { Chart, Title, Tooltip, Legend } from 'chart.js';
import React, { useRef, useState, useEffect } from 'react';
import 'chartjs-adapter-moment';
import moment from 'moment';

Chart.plugins.register(Title, Tooltip, Legend);

const getXAxisDataSet = (data) => {
  const obj = {};
  if (data.length === 0) return obj;
  const recentReportDate = data[data.length - 1];

  if (recentReportDate.monthsPostTx > 12) {
    const closestMultipleOf3 =
      3.0 * Math.ceil(recentReportDate.monthsPostTx / 3.0);
    for (let i = 0; i < 12; i++) {
      obj[closestMultipleOf3 - i] = new Date(
        recentReportDate.receivedDate.getFullYear(),
        i === 0 && closestMultipleOf3 - recentReportDate.monthsPostTx > 0
          ? recentReportDate.receivedDate.getMonth() + 1
          : recentReportDate.receivedDate.getMonth() - i,
        1
      );
    }
  } else {
    for (let i = 0; i < 12; i++) {
      obj[i] = new Date(
        recentReportDate.receivedDate.getFullYear(),
        recentReportDate.receivedDate.getMonth() - i,
        1
      );
    }
  }
  return obj;
};

function AllosureChart({ acData }) {
  var acDataReplica = [];
  acData.forEach((element) => {
    if (element.score <= 30) {
      acDataReplica.push(element);
    } else {
      acDataReplica.push({
        score: 30,
        receivedDate: element.receivedDate,
        monthsPostTx: element.monthsPostTx,
      });
    }
  });
  const [data] = useState(acDataReplica || []);
  const [dataOrignal] = useState(acData || []);

  const [chartOptions, setCharOptions] = useState(null);
  const canvasRef = useRef(null);
  useEffect(() => {
    const chartRef = canvasRef.current;
    let allosureChart = null;
    if (chartRef && chartOptions) {
      Chart.defaults.global.defaultFontFamily = `"Trade Gothic LT Std", "sans-serif", "Roboto", "Helvetica", "Arial"`;
      const myChartRef = chartRef.getContext('2d');
      allosureChart = new Chart(myChartRef, chartOptions);
    }
    return () => {
      allosureChart?.destroy();
    };
  }, [chartOptions]);
  useEffect(() => {
    var maxTickVal = 3;
    var labelString = 'AlloSure Score dd-cfDNA (%)';
    var tabType = '';
    switch (data[0].tab) {
      case 'AlloSure':
        maxTickVal = 3;
        labelString = 'AlloSure Score dd-cfDNA (%)';
        tabType = 'AlloSure';
        break;
      case 'creatinine':
        maxTickVal = 30;
        labelString = 'Creatinine (mg/dL)';
        tabType = 'creatinine';
        break;
      default:
        maxTickVal = 3;
        labelString = 'AlloSure Score dd-cfDNA (%)';
        tabType = 'AlloSure';
    }
    // data was already sorted by dateReported
    const dataSorted = data.sort((a, b) =>
      a.monthsPostTx > b.monthsPostTx ? 0 : -1
    );
    var dataSet = [];

    const xLabels = getXAxisDataSet(dataSorted);
    if (tabType === 'AlloSure') {
      const xAxisLabels = Object.keys(xLabels).map((p) => +p);
      xAxisLabels.forEach((p) => {
        const dat = dataSorted.filter((z) => z.monthsPostTx === p);
        if (dat.length === 0) {
          dataSet.push({
            monthsPostTx: p,
            receivedDate: xLabels[p],
          });
        } else {
          dataSet.push(...dat);
        }
      });
    } else {
      dataSet = [...data];
    }
    setCharOptions({
      type: 'line',
      data: {
        datasets: [
          {
            data: dataSet.map((d) => ({
              y: d.score,
              x: d.receivedDate,
            })),
            backgroundColor: 'transparent',
            borderColor: '#1E38A0',
            hoverOffset: 4,
            tension: 0,
          },
        ],
        labels: xLabels,
      },
      fill: false,
      options: {
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: () => {},
        plugins: {
          legend: {
            display: false,
            text: (ctx) =>
              'Point Style: ' + ctx.chart.data.datasets[0].pointStyle,
            labels: {},
          },
        },
        scales: {
          yAxes: [
            {
              id: 'y',
              position: 'left',
              color: 'black',
              gridLines: {
                borderColor: 'black',
                drawOnChartArea: false,
                color: 'rgb(106,106,106)',
                drawTicks: false,
              },
              scaleLabel: {
                padding: 2,
                fontColor: '#8997CD',
                fontWeight: 'regular',
                fontSize: 14,
                display: true,
                labelString: labelString,
                fontFamily: 'Calibri',
              },
              ticks: {
                beginAtZero: true,
                align: 'center',
                callback: function (label) {
                  return label;
                },
                padding: 5,
                min: 0.0,
                max: maxTickVal,
                stepSize: 1,
              },
            },
            {
              gridLines: {
                drawOnChartArea: false,
                drawTicks: false,
                borderColor: 'black',
              },
              ticks: {
                display: false,
                align: 'center',
                callback: function (label) {
                  return label;
                },
              },
              position: 'right',
            },
          ],
          xAxes: [
            {
              type: 'time',
              id: 'x',
              color: 'black',
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderColor: 'black',
              },
              time: {
                unit: 'month',
                displayFormats: {
                  month: 'MMM',
                },
                min: xLabels[0],
                max: xLabels[xLabels.length - 1],
              },
              labels: xLabels,
              ticks: {
                beginAtZero: true,
                align: 'center',
                minRotation: 30,
              },
              position: 'bottom',
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (context) {
              var val = '';
              if (context.index >= dataOrignal.length) {
                return '';
              }
              switch (tabType) {
                case 'AlloSure':
                  val = ` ${moment(context.raw.receivedDate).format(
                    'MM/DD/YYYY'
                  )}, ${context.raw.score}, ${context.raw.monthsPostTx} `;
                  break;
                case 'creatinine':
                  val = ` ${moment(context.label).format('MM/DD/YYYY')}, ${
                    dataOrignal[dataOrignal.length - 1 - context.index].score
                  } `;
                  break;
                default:
                  val = ` ${moment(context.raw.receivedDate).format(
                    'MM/DD/YYYY'
                  )}, ${context.raw.score}, ${context.raw.monthsPostTx} `;
                  break;
              }
              return val;
            },
          },
        },
      },
    });
  }, [data, dataOrignal]);

  return (
    <div className="chartjs-chartContainer">
      <canvas
        ref={canvasRef}
        height={190}
        aria-label="Allosure Kidney Care Report"
        role="img"
      ></canvas>
    </div>
  );
}

export default AllosureChart;
