import React from 'react';
import { Container, Link } from '@material-ui/core';
import { TranslatedTypography } from 'Components/Localization';

export default function NoImmunosuppressionData() {
  return (
    <Container>
      <TranslatedTypography
        variant="h5"
        align="center"
        style={{ paddingBottom: '48px' }}
      >
        Patient did not use Immunosuppression patient app in the last 7 days.
        <br />
        Learn more about the AlloCare patient app by visiting
        <Link
          style={{ paddingLeft: '6px' }}
          href="http://caredx.com/allocare"
          variant="h5"
          target="_blank"
          rel="noopener"
        >
          http://caredx.com/allocare
        </Link>
      </TranslatedTypography>
    </Container>
  );
}
