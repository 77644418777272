/* istanbul ignore file */

import AuthenticationConfig from './AuthenticationConfig';
import { getRandomString } from '../../utility';
import aitCrypto from '../../Utilities/aitCrypto';

async function base64UrlEncode(typedArray) {
  const data = await base64Encode(typedArray);
  const trimmedData = data
    .replace(/=/gi, '')
    .replace(/\+/gi, '-')
    .replace(/\//gi, '_');
  return trimmedData;
}

function base64Encode(typedArray) {
  return new Promise(function (resolve, reject) {
    try {
      const blob = new Blob([typedArray.buffer]);
      const fileReader = new FileReader();
      fileReader.addEventListener('load', function () {
        const dataUrl = fileReader.result;
        const data = dataUrl.split(',')[1];
        resolve(data);
      });
      fileReader.readAsDataURL(blob);
    } catch (error) {
      reject(error);
    }
  });
}

export default function AuthenticationService(config) {
  if (!(config instanceof AuthenticationConfig)) {
    throw new Error('Parameter `config` must be of type AuthenticationConfig');
  }

  const fetchPostConfig = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
  };

  return {
    _config: config,
    async login() {
      const stateArray = new Uint8Array(16);
      aitCrypto.getRandomValues(stateArray);
      const state = await base64Encode(stateArray);
      const codeVerifier = getRandomString(43);
      const codeVerifierArray = new Uint8Array(43);
      for (let c = 0; c < codeVerifier.length; c++) {
        const charCode = codeVerifier[c].charCodeAt(0);
        codeVerifierArray[c] = charCode;
      }
      const codeChallengeArrayBuffer = await aitCrypto.subtle.digest(
        'SHA-256',
        codeVerifierArray
      );
      const codeChallengeArray = new Uint8Array(codeChallengeArrayBuffer);
      const codeChallenge = await base64UrlEncode(codeChallengeArray);
      const codeChallengeMethod = 'S256';
      sessionStorage.setItem(
        state,
        JSON.stringify({
          codeVerifier,
        })
      );
      const params = [
        `response_type=${config.responseType}`,
        `code_challenge=${codeChallenge}`,
        `code_challenge_method=${codeChallengeMethod}`,
        `client_id=${config.clientId}`,
        `redirect_uri=${encodeURIComponent(config.redirectUri)}`,
        `scope=${encodeURIComponent(config.scope)}`,
        `state=${encodeURIComponent(state)}`,
      ];
      const paramsString = params.join('&');
      const newLocation = `${config.authorizeUri}?${paramsString}`;
      window.location = newLocation;
    },
    async logout(refreshToken) {
      const tokenRequest = [
        `client_id=${encodeURIComponent(config.clientId)}`,
        `refresh_token=${refreshToken}`,
      ];
      const requestBody = tokenRequest.join('&');
      const response = await fetch(config.logoutUri, {
        ...fetchPostConfig,
        body: requestBody,
      });
      return response;
    },
    async exchangeToken(subjectToken, clientIssuer) {
      const tokenRequest = [
        `client_id=${encodeURIComponent(config.clientId)}`,
        'grant_type=urn:ietf:params:oauth:grant-type:token-exchange',
        `subject_token=${subjectToken}`,
        `subject_issuer=${encodeURIComponent(clientIssuer)}`,
        'token_type=urn:ietf:params:oauth:token-type:id_token',
        `audience=${encodeURIComponent(config.clientId)}`,
        'scope=openid',
      ];
      const requestBody = tokenRequest.join('&');
      const response = await fetch(config.tokenUri, {
        ...fetchPostConfig,
        body: requestBody,
      });
      const tokenResponse = await response.json();
      return tokenResponse;
    },
    async refreshToken(accessToken, refreshToken) {
      const tokenRequest = [
        'grant_type=refresh_token',
        `refresh_token=${refreshToken}`,
        `client_id=${encodeURIComponent(config.clientId)}`,
      ];
      const requestBody = tokenRequest.join('&');
      const response = await fetch(config.tokenUri, {
        ...fetchPostConfig,
        body: requestBody,
        headers: {
          ...fetchPostConfig.headers,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const tokenResponse = await response.json();
      return tokenResponse;
    },
    async getToken(code, codeVerifier) {
      const tokenRequest = [
        `client_id=${config.clientId}`,
        `redirect_uri=${encodeURIComponent(config.redirectUri)}`,
        'grant_type=authorization_code',
        `code=${code}`,
        `code_verifier=${codeVerifier}`,
      ];
      const requestBody = tokenRequest.join('&');
      const response = await fetch(config.tokenUri, {
        ...fetchPostConfig,
        body: requestBody,
      });
      const tokenResponse = await response.json();
      return tokenResponse;
    },
  };
}
