import React from 'react';
import beautify from 'json-beautify';
import PageLoadingIndicator from '../Indicators/PageLoadingIndicator';

export default function DataLoading({
  loading,
  error,
  loadingMessage,
  children,
}) {
  let content;
  if (loading) {
    content = (
      <PageLoadingIndicator>
        {loadingMessage || 'Loading...'}
      </PageLoadingIndicator>
    );
  } else if (error) {
    if (process.env.NODE_ENV !== 'production') {
      content = <code>{beautify(error, null, 2, 80)}</code>;
    } else {
      content = <p className="errorMessage">{error.message}</p>;
    }
  } else {
    if (children instanceof Function) {
      content = children();
    } else {
      content = children;
    }
  }

  return content;
}
