import { Grid, Box } from '@material-ui/core';
import { TranslatedTypography } from 'Components/Localization';
import React from 'react';
import AlloCareLineChart from '../../AlloCare/AlloCareLineChart';

function AlloCareWidgetChartRow({ showXLabels, chartConfig, range, data }) {
  return (
    <Grid container>
      <Grid
        container
        item
        xs={4}
        alignItems="center"
        style={{ paddingTop: showXLabels ? 80 : 0 }}
      >
        <Box>
          <Box display="flex" flexDirection="row" alignItems="top">
            {chartConfig.labelIcon}
            <TranslatedTypography variant="body2">
              {chartConfig.label}
            </TranslatedTypography>
          </Box>
          <TranslatedTypography variant="body2">
            {range} Day Average {`${data.average || 0}${data.unit || ''}`}
          </TranslatedTypography>
          <TranslatedTypography variant="body2">
            {`${data.percentageWithinRange}% within ${data.minRangeValue}${data.unit} - ${data.maxRangeValue}${data.unit}`}
          </TranslatedTypography>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <AlloCareLineChart
          dataSets={chartConfig.getDataSets(data)}
          chartConfiguration={chartConfig}
          range={range}
          showXLabels={showXLabels}
          canvasHeight={150}
          labelDayFormat={'MM/DD'}
          scaleXPadding={18}
        />
      </Grid>
    </Grid>
  );
}

export default AlloCareWidgetChartRow;
