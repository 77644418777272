import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  coding: {
    marginRight: '2px',
    fontStyle: 'italic',
  },
  value: {
    fontWeight: 'bold',
    marginRight: '2px',
  },
  unit: {
    fontWeight: 'bold',
  },
}));

export default function Observations({ data }) {
  const { t } = useTranslation();
  const classes = useStyles();
  let values;
  if (data.value) {
    values = [{ value: { ...data.value }, code: data.code }];
  } else {
    values = data.component;
  }

  const displayValues = [];
  for (let value of values) {
    let codingDisplay = 'blah';
    const code = value.code;
    if (code) {
      const codings = code.coding;
      if (codings && codings.length > 0) {
        codingDisplay = codings[0].display;
      } else {
        codingDisplay = t('unknownCoding');
      }
    } else {
      codingDisplay = t('unknownCoding');
    }
    displayValues.push(
      <div key={codingDisplay}>
        <span className={classes.coding}>{codingDisplay}</span>
        <span className={classes.value}>
          {t('numberFormat', { value: parseFloat(value.value.value) })}
        </span>
        <span className={classes.unit}>{value.value.unit}</span>
      </div>
    );
  }

  return <div>{displayValues}</div>;
}
