import React from 'react';
import WithPatientExternalIdData from '../GraphQL/WithPatientExternalIdData';
import { Redirect, useParams } from 'react-router-dom';
import AddMissingPatient from './AddMissingPatient';

export default function PatientMatch() {
  const { id, type } = useParams();

  return (
    <WithPatientExternalIdData id={id} type={type}>
      <PatientMatchContent id={id} type={type} />
    </WithPatientExternalIdData>
  );
}

function PatientMatchContent({ data, id, type }) {
  if (data && data.externalIdentifier && data.externalIdentifier.length > 0) {
    const patientId = data.externalIdentifier[0].patientId;
    return <Redirect to={`/patient/${patientId}`} />;
  } else {
    return <AddMissingPatient id={id} type={type} />;
  }
}
