import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import AlloCarePersonalization from '../PersonalizationBaseline/AlloCarePersonalization';
import { PersonalizationContext } from '../PersonalizationBaseline/state/PersonalizationContextProvider';
import { ValidationRules } from './Utilities/Validators';
import Constants from './Utilities/constants';
import { useAlloCareMeasuresMutation } from 'Components/GraphQL/useAlloCareMeasuresMutation';
import { removeProperties, removeTypeName } from 'utility';
import _ from 'lodash';
import SnackBar from 'Components/Controls/SnackBar';

export default function AlloCarePersonalizationMeasures({
  handleConditionDialogState,
  measuresOrigData,
  alertsOrigData,
}) {
  const { mutate } = useAlloCareMeasuresMutation();

  const {
    alertState,
    measuresState,
    errorState,
    dispatchError,
    dispatchButtons,
    dispatchAlert,
    dispatchMeasure,
  } = useContext(PersonalizationContext);
  const validation = ValidationRules(dispatchError, measuresState, alertState);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    severity: '',
  });

  const resetAlertMessage = () => {
    setAlertMessage({
      message: '',
      severity: '',
    });
  };

  const updatedElementsInArray = (origArray, newArray) => {
    let updatedRows = [];
    if (newArray?.length > 0) {
      for (let i = 0; i < origArray.length; i++) {
        if (!_.isEqual(origArray[i], newArray[i])) {
          if (newArray[i].measureLow.toString().trim() === '')
            newArray[i].measureLow = 0;
          if (newArray[i].measureHigh.toString().trim() === '')
            newArray[i].measureHigh = 0;
          if (newArray[i].alertPercentage.toString().trim() === '')
            newArray[i].alertPercentage = 0;
          updatedRows.push(
            removeProperties(newArray[i], ['__typename', 'baseline'])
          );
        }
      }
    }
    return updatedRows;
  };

  const onClick = (errorState, measuresState, alertState) => {
    // check if this method using
    if (Object.keys(errorState).some((p) => !!errorState[p])) {
      setAlertMessage({
        message: Constants.ERROR_VALIDATION,
        severity: 'error',
      });
    } else {
      let updatedMeasures = updatedElementsInArray(
        measuresOrigData,
        measuresState
      );
      let includeMeasures = updatedMeasures.length > 0;
      let includeAlerts = !_.isEqual(alertsOrigData, alertState);
      try {
        mutate({
          variables: {
            measuresList: updatedMeasures,
            alerts: includeAlerts ? removeTypeName(alertState) : {},
            includeMeasures: includeMeasures,
            includeAlerts: includeAlerts,
          },
        }).then(() => {
          handleConditionDialogState();
        });
      } catch (error) {
        setAlertMessage({
          message: Constants.ERROR_VALIDATION,
          severity: 'error',
        });
      }
    }
  };

  useEffect(() => {
    dispatchMeasure({
      type: Constants.MEASURE_STATE,
      payload: measuresOrigData,
    });

    dispatchAlert({
      type: Constants.ALERT_STATE,
      payload: alertsOrigData,
    });

    dispatchButtons({
      type: Constants.BIND_CLICK,
      payload: {
        id: 'Submit',
        buttonClicked: onClick,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <SnackBar
        message={alertMessage.message}
        duration={3000}
        open={!!alertMessage.message}
        onClose={() => resetAlertMessage()}
        severity={alertMessage.severity}
      ></SnackBar>
      <AlloCarePersonalization
        validation={validation}
        diastolicIdx={measuresOrigData?.findIndex(
          (m) => m.measureName === Constants.BLOOD_PRESSURE_DIASTOLIC
        )}
        alertState={alertState}
        measuresState={measuresState}
        dispatchAlert={dispatchAlert}
        dispatchMeasure={dispatchMeasure}
        errorState={errorState}
      />
    </Grid>
  );
}
