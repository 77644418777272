import { gql, useMutation } from '@apollo/client';

export const GEN_ALLOVIEWSCORE_BY_ALLOSURE = gql`
  mutation($patientId: Guid!, $birthDate: DateTime!, $gender: String!) {
    genAlloViewScoreByAlloSure(
      patientId: $patientId
      birthDate: $birthDate
      gender: $gender
    ) {
      alloViewReportId
      id
      alloViewScore
      createdDate
      systemGenerated
    }
  }
`;

export function useGenAlloViewScoreByAlloSureMutation() {
  const [mutate, { data, ...rest }] = useMutation(
    GEN_ALLOVIEWSCORE_BY_ALLOSURE
  );
  return {
    mutate,
    data,
    rest,
  };
}
