import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Toolbar,
  Paper,
  makeStyles,
  Box,
  Container,
} from '@material-ui/core';
import Moment from 'moment';
import ErrorBoundaryHOC from '../../../ErrorBoundaryHOC';
import ErrorPage from '../../Error/ErrorPage';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
import KidneyCareDisclaimer from './KidneyCareDisclaimer';
import { TranslatedTypography } from 'Components/Localization';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '38vw',
    minHeight: '25px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  tableTitle: {
    fontWeight: 600,
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '8px',
    left: 11,
    right: 0,
    textAlign: 'left',
  },
  tableContainer: {
    border: 'none',
    boxShadow: 'none',
  },
  cell: {
    padding: '3px',
    fontSize: '1.02em',
    lineHeight: '1.0rem',
    borderWidth: '2px',
  },
  disclaimer: {
    paddingLeft: '11px',
    paddingRight: '8px',
    margin: 0,
    paddingBottom: 0,
    paddingTop: '10px',
  },
}));

export function KidneyCareReport({ widgetId, data }) {
  const history = useHistory();
  const classes = useStyles();
  const { dispatch } = useContext(SettingsContext);
  const widgetData = data?.patient?.links?.ibox;
  const { id } = useParams();
  useEffect(() => {
    const onClick = () => {
      history.push(`/patient/${id}/kidneyCareReport`);
    };
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: onClick,
      },
    });
  }, [dispatch, history, widgetId, id]);

  const content = () => {
    if (widgetData?.iBoxRecords != null) {
      return (
        <>
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.tableTitle} variant="subtitle1">
              Historical Results
            </Typography>
          </Toolbar>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.cell}>Report Date</TableCell>
                  <TableCell className={classes.cell}>Accession ID</TableCell>
                  <TableCell className={classes.cell}>Ordered By</TableCell>
                  <TableCell className={classes.cell}>
                    AlloSure Draw Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {widgetData.iBoxRecords.slice(0, 3).map((row) => (
                  <TableRow key={row.oid}>
                    <TableCell className={classes.cell}>
                      {row.details?.dateReported != null
                        ? Moment(row.details?.dateReported).format('MM/DD/YYYY')
                        : ''}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.details.accessionId}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {`${row.details.prescriber} (${row.details._Client})`}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {row.details?.dateCollected != null
                        ? Moment(row.details?.dateCollected).format(
                            'MM/DD/YYYY'
                          )
                        : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={classes.disclaimer}>
            <KidneyCareDisclaimer variant="caption" />
          </Box>
        </>
      );
    }
    return (
      <Container>
        <TranslatedTypography
          variant="h5"
          align="center"
          style={{ paddingBottom: '48px' }}
        >
          Patient has NO record of KidneyCare (AlloSure, AlloMap Kidney, and
          iBox). <br />
          KidneyCare is currently available as a research tool through the OKRA
          Registry.
        </TranslatedTypography>
      </Container>
    );
  };

  return <>{content()}</>;
}

export default ErrorBoundaryHOC(KidneyCareReport, ErrorPage);
