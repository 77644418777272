import { Divider, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: theme.palette.common.black,
    height: '3px',
  },
}));

function ThickDivider() {
  const classes = useStyles();
  return <Divider classes={{ root: classes.divider }} variant="fullWidth" />;
}

export default ThickDivider;
