import React, { useContext, useRef } from 'react';
import {
  Paper,
  Card,
  CardHeader,
  CardContent,
  createStyles,
  makeStyles,
  Box,
  Typography,
} from '@material-ui/core';
import { SettingsContext } from './state/WidgetSettingsProvider';
import { useDrag, useDrop } from 'react-dnd';

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxHeight: 60,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    cardNoBorder: {
      height: '100%',
      border: 'none',
      boxShadow: 'none',
      paddingBottom: 0,
    },
    cardContent: {
      padding: '0 12px !important',
      paddingLeft: '2px',
      height: 'calc(100% - 48px)',
      '&:last-child': {
        paddingBottom: 0,
      },
      paddingTop: 0,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activePaperStyles: {
      '&:hover': {
        borderColor: '#782F40',
      },
      cursor: 'pointer',
    },
    inactivePaperStyles: {
      cursor: 'default',
      filter: 'alpha(opacity = 60)',
    },
    noPadding: {
      margin: '0px',
      padding: '0px',
      display: 'flex',
      verticalAlign: 'middle',
    },
    title: {
      padding: '0 !important',
      paddingLeft: 12,
      display: 'inline-block',
      color: 'black',
      fontSize: 22,
      fontWeight: 400,
    },
  })
);

function isWidgetActive(wid, data) {
  const links = data?.patient.links;
  switch (wid) {
    case 'KidneyCare':
      return (
        links.ibox?.__typename !== 'ResolverErrorType' &&
        links.ibox?.iBoxRecords?.length > 0
      );
    case 'AlloCare':
    case 'AlloSure':
      return data?.patient.links.alloCare?.__typename !== 'ResolverErrorType';
    case 'Immunosuppression':
      return true;
    case 'PatientProfile':
      return true;
    case 'EventTimeline':
      return true;
    case 'AlloSureHistogram':
      return true;
    case 'IndividualChart':
      return true;
    case 'ViolinChart':
      return true;
    case 'AlloView':
      return false; // Always true for now because we're using test data.
    case 'IBox':
      return true; // Always true because user can just run calculation.
    default:
      break;
  }
}

export default function WidgetContainer({
  index,
  wid,
  text,
  image,
  open,
  children,
  moveWidgetListItem,
  saveWidgetOrderCallback,
}) {
  const classes = useStyles();
  const { widgetsState } = useContext(SettingsContext);

  const active = isWidgetActive(wid, children?.props?.data);
  const onClick = (e) => {
    open(e);
    widgetsState[wid]?.widgetClicked();
  };

  const HandleTitle = () => {
    let titleStyles = classes.title;
    if (wid != null && image != null) {
      let heightVal = 30;
      let widthVal = 120;

      switch (wid) {
        case 'KidneyCare':
          heightVal = 30;
          widthVal = 150;
          break;
        case 'IBox':
          widthVal = 80;
          break;
        default:
          break;
      }

      return (
        <div className={classes.noPadding}>
          <img
            src={`/images/${image}`}
            alt=""
            width={widthVal}
            height={heightVal}
          ></img>
          <Typography
            className={titleStyles}
            style={{ fontWeight: '600', fontSize: 22 }}
          >
            {text}
          </Typography>
        </div>
      );
    }
    return wid === 'AlloView' ? (
      <Typography className={titleStyles}>
        {<text>AiKidney &trade; Likelihood of Rejection</text>}
      </Typography>
    ) : wid === 'IBox' ? (
      <Typography className={titleStyles}>
        {<text>AiKidney &trade; iBox Long-Term Prognosis</text>}
      </Typography>
    ) : wid === 'AlloSure' ? (
      <Typography className={titleStyles}>{<text>Labs</text>}</Typography>
    ) : (
      <Typography className={titleStyles}>
        {text === 'AlloSure Histogram'
          ? 'AlloSure'
          : text === 'Violin Chart'
          ? 'AlloSure Violin Chart'
          : text === 'Individual Chart'
          ? 'AlloSure RCV'
          : text}
      </Typography>
    );
  };

  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: 'dndWidget',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      if (item.index === index) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (item.index < index && hoverClientY < hoverMiddleY) {
        return;
      }
      if (item.index > index && hoverClientY > hoverMiddleY) {
        return;
      }
      moveWidgetListItem(item.index, index);
      item.index = index;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: 'dndWidget', index, wid },
    end: (item, monitor) => {
      saveWidgetOrderCallback();
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const dragDropRef = drag(drop(ref));
  console.log('dragDropRef===>', dragDropRef);
  return (
    <Paper
      ref={dragDropRef}
      className={[
        classes.activePaperStyles,
        !active && classes.inactivePaperStyles,
      ].join(' ')}
      onClick={active ? onClick : undefined}
    >
      <Card className={classes.cardNoBorder}>
        <CardHeader
          ref={preview}
          style={{ paddingBottom: '0px' }}
          // action={
          //   wid === 'AlloView' ? (
          //     <Button onClick={add}>
          //       <AddBoxIcon fontSize="medium"></AddBoxIcon>
          //     </Button>
          //   ) : (
          //     ''
          //   )
          // }
          /*action={
            <FormControl
              size="small"
              variant="filled"
              margin="dense"
              className={classes.formControl}
            >
              <InputLabel htmlFor="dashboard-widget">Widget</InputLabel>
              <Select
                style={{ paddingTop: '0px' }}
                onChange={handleChange}
                input={<OutlinedInput margin="dense" />}
                inputProps={{
                  name: 'widget',
                  id: text + '-widget',
                }}
              >
                {widgets?.map((x, idx) => (
                  <MenuItem key={idx} value={x.value}>
                    {x.key}
                  </MenuItem>
                ))}
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
              </Select>
            </FormControl>
          }*/
          title={<HandleTitle />}
        />
        <CardContent
          className={classes.cardContent}
          style={{ display: isDragging ? 'none' : 'block' }}
        >
          <Box width="100%">{children}</Box>
        </CardContent>
      </Card>
    </Paper>
  );
}
