import { gql, useMutation } from '@apollo/client';

export const UPSERT_CONDITIONS = gql`
  mutation(
    $patientPersonalizationCondition: PatientPersonalizationConditionInputType!
  ) {
    upsertPatientPersonalizationCondition(
      patientPersonalizationCondition: $patientPersonalizationCondition
    ) {
      patientId
      oliguriaCondition
      copdCondition
      bmiCondition
      diabetesCondition
      hypertensionCondition
      depressionCondition
    }
  }
`;

export function useAlloCareConditionsMutation() {
  const [mutate, { data, ...rest }] = useMutation(UPSERT_CONDITIONS);
  return {
    mutate,
    data,
    rest,
  };
}
