import React, { useEffect, useContext, useState } from 'react';

import { useHistory } from 'react-router';
import { SettingsContext } from '../state/WidgetSettingsProvider';
import { ACTIONS } from '../state/Reducer';
import NoAlloViewData from './NoAlloViewData';
import { useParams } from 'react-router-dom';
import useTable from '../../Widgets/shared/useTable';
import { Link } from 'react-router-dom';
import {
  TableBody,
  TableRow,
  TableCell,
  Paper,
  makeStyles,
  Box,
  ThemeProvider,
  Button,
  Toolbar,
} from '@material-ui/core';
import Moment from 'moment';
import { ScoreIndicator } from 'Components/Pages/AlloView/ScoreIndicator';
import theme from '../../../../theme';
import Add from '@material-ui/icons/Add';
import { ArrowForwardIos } from '@material-ui/icons';
import './AlloView.css';

const useStyles = makeStyles((theme) => ({}));

function AlloViewWidget({ widgetId, data }) {
  const classes = useStyles();
  const { dispatch } = useContext(SettingsContext);
  const widgetData = data?.patient?.links.alloView.alloViewRecords;
  const { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    const onClick = () => {
      history.push(`/patient/${id}/AlloView`);
    };
    dispatch({
      type: ACTIONS.BIND_CLICK,
      payload: {
        id: widgetId,
        widgetClicked: onClick,
      },
    });

    setFilterFn({
      fn: (items) => {
        return items;
      },
    });
  }, [dispatch, widgetId, id, history]);

  const add = () => {
    return history.push(`/patient/${id}/alloViewRecord`);
  };

  const viewAll = () => {
    return history.push(`/patient/${id}/AlloView`);
  };
  const headCells = [
    {
      id: '',
      label: '',
      sortable: false,
      className: 'alignCenter',
    },
    {
      id: 'result.alloViewScore',
      label: 'Score',
      sortable: false,
      className: 'alignCenter',
    },
    {
      id: 'parameters.riskEvaluationDate',
      label: 'Evaluation Date',
      sortable: false,
      className: 'alignCenter',
    },
    {
      id: 'parameters.alloSureScore',
      label: 'AlloSure',
      sortable: false,
      className: 'alignCenter',
    },
    {
      id: 'parameters.dsaMfi',
      label: 'DSA',
      sortable: false,
      className: 'alignCenter',
    },
    {
      id: 'parameters.creatinine',
      label: 'Creatinine',
      sortable: false,
      className: 'alignCenter',
    },
    {
      id: 'parameters.previousCreatinine',
      label: 'Previous Creatinine',
      sortable: true,
      className: 'alignCenter',
    },

    {
      id: 'parameters.egfr',
      label: 'eGFR',
      sortable: false,
      className: 'alignCenter',
    },
    {
      id: 'parameters.previousRejection',
      label: 'Previous Rejection',
      sortable: false,
      className: 'alignCenter',
    },
  ];

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const {
    TblContainer,
    TblHead,
    TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(widgetData, headCells, filterFn, 3, []);
  return widgetData.length === 0 ? (
    <NoAlloViewData />
  ) : (
    <>
      <ThemeProvider theme={theme}>
        <Paper elevation={0} className={classes.paperStyles}>
          <Toolbar variant="dense" className="toolbar">
            <Button
              className="addButtonLeft"
              onClick={add}
              size="small"
              variant="contained"
              color="primary"
              startIcon={<Add className={classes.addIcon}></Add>}
            >
              <span>New Score</span>
            </Button>
          </Toolbar>
          <TblContainer className="tableContainer">
            <TblHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((row) => (
                <TableRow key={row.result.alloViewReportId}>
                  <TableCell className="cell">
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/patient/${id}/alloViewChart`}
                    >
                      <ScoreIndicator
                        score={row.result.alloViewScore}
                      ></ScoreIndicator>
                    </Link>
                  </TableCell>
                  <TableCell className="cell">
                    {row.result.alloViewScore}
                  </TableCell>
                  <TableCell className="cell">
                    {Moment(row.parameters.riskEvaluationDate).format(
                      'MM/DD/YY'
                    )}
                  </TableCell>
                  <TableCell className="cell">
                    {row.parameters.alloSureScore}
                  </TableCell>
                  <TableCell className="cell">
                    {row.parameters.dsaMfi}
                  </TableCell>
                  <TableCell className="cell">
                    {row.parameters.creatinine}
                  </TableCell>
                  <TableCell className="cell">
                    {row.parameters.previousCreatinine}
                  </TableCell>
                  <TableCell className="cell">{row.parameters.egfr}</TableCell>
                  <TableCell className="cell">
                    {row.parameters.previousRejection === true ? 'Yes' : 'No'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Paper>
      </ThemeProvider>
      <hr className="hrSeparator" />
      <Box className="disclaimer">
        <Button
          onClick={viewAll}
          className="titleItemRight"
          endIcon={<ArrowForwardIos style={{ fontSize: 15 }} />}
          variant="text"
          color="primary"
          size="small"
        >
          View All
        </Button>
      </Box>
    </>
  );
}
export default AlloViewWidget;
